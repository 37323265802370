export const convertName = (gender, firstname, lastname) => {
    let result = ''
    switch (gender) {
      case 'MALE':
        result = 'ທ້າວ';
        break;
      
      case 'FEMALE':
        result = 'ນາງ';
        break;
    
      default:
        break;
    }
    return result + " " + firstname + " " + lastname
}
export const convertNameE = (gender, firstname, lastname) => {
    let result = ''
    switch (gender) {
      case 'MALE':
        result = 'Mr. ';
        break;
      
      case 'FEMALE':
        result = 'Mrs. ';
        break;
    
      default:
        break;
    }
    return result + " " + firstname + " " + lastname
}