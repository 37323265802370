import React, { useState } from "react";
import { WhiteMobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { ACTIVITIES } from "./const";
import "./index.css";
const OutcourseActivityDetail = () => {
  const { history, match } = useReactRouter();
  const id = match.params.id;
  const [key, setKey] = useState("detail");

  return (
    <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
      <WhiteMobileHeader
        title="ລາຍລະອຽດເພີ່ມເຕີມ"
        showLeftArrow
        leftButtonClick={() => history.push("/activity-list")}
      />
      <div style={{ paddingTop: 60 }}>
        {ACTIVITIES.filter((_data) => _data?.id === id).map((item, index) => (
          <div key={index}>
            <img
              src={item.img ? item.img : "assets/wallpaper-image.png"}
              alt=""
              className="ImgDetail"
            />
            <div
              className=" border-bottom-0 border-left-0 border-right-0"
              style={{
                boxShadow: "0px -1px 1px #bdeafd",
              }}
            >
              <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <Row
                  className="pt-3 pl-3 text-center"
                  style={{
                    borderBottom: "2px solid #7BB500",
                  }}
                >
                  <Col>
                    <Nav.Link
                      eventKey="detail"
                      className={key === "detail" ? "activeTab" : "inActiveTab"}
                    >
                      ລາຍລະອຽດ
                    </Nav.Link>
                  </Col>
                  <Col />
                  <Col />
                </Row>

                <Tab.Content>
                  <Tab.Pane eventKey="detail">
                    <div className="pt-3 pl-4 pr-4 pb-3">
                        <b>{item.title}</b>
                      <div className="mt-3">
                        <div dangerouslySetInnerHTML={{ __html: item?.detail }} />
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default OutcourseActivityDetail;
