import React, {useEffect, useState} from 'react'
import { MobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import { Card, Row, Col } from "react-bootstrap";
import { faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VIDEOS } from "./videos"
function Medialist() {
  const { history } = useReactRouter();
  const [dataVideo, setDataVideo] = useState([])
  useEffect(() => {
    setDataVideo(VIDEOS.sort().reverse())
  }, [VIDEOS])
  return (
    <div>
      <MobileHeader
        title="ສື່ມີເດຍ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <div style={{ marginTop: 80, height: "100hv" }}>
        <div style={{ fontSize: 14, marginLeft: 15 }}> ທັງໝົດ {VIDEOS.length} ວີດີໂອ</div>
        {VIDEOS &&
          dataVideo.map((store, index) => (
            <Card
              onClick={() =>
                history.push({ pathname: `/media/${store.id}`, state: store })
              }
              key={index}
              style={{ border: "1px solid #057CAE",marginLeft:15,marginTop:15,width:"91%" }}
            >
              <Row>
                <Col xs={12}>
                  <div
                    style={{ width: "100%", height: 200, overflow: "hidden" }}
                  >
                     <img
                      style={{ width: "100%" }}
                      src={store?.image ?? "assets/wallpaper-image.png"}
                    />
                  </div>
                  <Row
                    // className='media-padding'
                    style={{ marginTop: "1%",marginBottom: 5, paddingLeft: 20, paddingRight: 20 }}
                  >
                    <div style={{ width: "10%" }}>
                    <FontAwesomeIcon icon={faPhotoVideo} aria-hidden="true"color="#057CAE" />
                    </div>
                    <div style={{ width: "90%" }}>
                      <p
                        style={{
                          color: "#057CAE",
                          width: "100%",
                          overflowX: "hidden",
                          overflowY: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {store.name}
                      </p>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Card>
          ))}
      </div>
    </div>
  )
}

export default Medialist