/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { View, StyleSheet, Button, Linking } from 'react-native';

import { Row, Col } from "react-bootstrap";
import * as _ from "lodash";
import Consts from "../../consts";
import ListAccessableFaculty from "./ListAccessableFaculty";
import { MobileHeader } from "../../common";
import {
  formatDateTime,
  formatDate,
  noticeStatus,
  setSwich,
} from "../../super";
import { useLazyQuery } from "@apollo/react-hooks";
import { NOTIC, NOTICS } from "../../apollo/notic";

function NoticeDetailMobile() {
  const { history, match } = useReactRouter();

  let noticType = match?.params?.type;
  const [noticId, setNoticId] = useState(match?.params?.id);
  const [showDetail, setShowDetail] = useState(false);
  const [listFaculty, setListFaculty] = useState(false);
  const [dataNotice, setDataNotice] = useState([]);

  const _showListFaculty = () => {
    setListFaculty(!listFaculty);
  };

  const [loadDataNotice, { data: noticData }] = useLazyQuery(NOTIC);
  const [loadData, { data }] = useLazyQuery(NOTICS);

  useEffect(() => {
    loadDataNotice({
      variables: {
        where: {
          id: noticId,
        },
      },
    });
    loadData({
      variables: {
        where: {
          type: noticType,
          isPublish: true,
          // id: noticId,
          // AND: [{ type: noticType }, { id_not: noticId }],
        },
        orderBy: "createdAt_DESC",
      },
    });
  }, []);

  useEffect(() => {
    if (!noticData?.notic) return;
    setDataNotice(noticData?.notic);
  }, [noticData]);

  return (
    <div>
      <MobileHeader
        showLeftArrow
        leftButtonClick={() => history.push("/notice-list")}
        title="ແຈ້ງການ ແລະ ລະບຽບການ"
      />
      <div style={{ marginTop: 80, backgroundColor: "white", height: "100hv" }}>
        <img
          alt=""
          style={{ width: "100%" }}
          src={
            dataNotice?.coverImage?.url
              ? dataNotice?.coverImage?.url
              : "/assets/wallpaper-image.png"
          }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 20,
          }}
        >
          <a style={{ fontSize: 14 }}>
            ສ້າງໂດຍ:{dataNotice?.createdBy?.firstname}{" "}
            {dataNotice?.createdBy?.lastname
              ? dataNotice?.createdBy?.lastname
              : ""}
          </a>
          <a style={{ fontSize: 14 }}>{noticeStatus(dataNotice?.type)}</a>
        </div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bolder",
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 10,
          }}
        >
          {dataNotice?.title}
        </div>
        <div
          style={{
            fontSize: 14,
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 10,
          }}
        >
          <a
            style={{
              color: Consts.PRIMARY_COLOR,
              textDecoration: "underline",
              fontSize: 14,
            }}
            onClick={() => setShowDetail(!showDetail)}
          >
            ເບີ່ງລາຍລະອຽດ
          </a>
          <a style={{ fontSize: 14 }}>
            {formatDateTime(dataNotice?.createdAt)}
          </a>
        </div>
        <div
          style={{
            display: showDetail === true ? "block" : "none",
          }}
        >
          <Row style={{ paddingLeft: 20, paddingRight: 20, fontSize: 14 }}>
            <Col>ຄະນະ:</Col>
            <Col>
              <div
                onClick={_showListFaculty}
                style={{
                  width: "50%",
                  padding: 2,
                  textAlign: "center",
                  backgroundColor: "rgb(5, 124, 174)",
                  borderRadius: 5,
                  color: "white",
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}
              >
                <a style={{ fontSize: 14 }}>
                  {noticData?.notic?.accessableFaculty.length + " ຄະນະ"}
                </a>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 20, paddingRight: 20, fontSize: 14 }}>
            <Col>ມື້ເປີດ - ປິດ:</Col>
            <Col>
              {formatDate(dataNotice?.startDate)} ~{" "}
              {formatDate(dataNotice?.endDate)}
            </Col>
          </Row>
          <Row style={{ paddingLeft: 20, paddingRight: 20, fontSize: 14 }}>
            <Col>ສະຖານະ:</Col>
            <Col>{setSwich(dataNotice?.isPublish)}</Col>
          </Row>
          <Row style={{ paddingLeft: 20, paddingRight: 20, fontSize: 14 }}>
            <Col>ໄຟລ໌ເອກະສານ:</Col>
            <Col>
              {dataNotice?.files?.map((data, index) => {
                return (
                  <Row>
                    {["jpeg", "png", "jpg", "xlsx", "docx", "ppt"].includes(
                      data?.title?.split(".").pop()
                    ) ? (
                      <Col>
                        <a href={data?.file} target="_blank">
                          {data.title}
                        </a>
                      </Col>
                    ) : (
                      <Col>
                        <u
                          onClick={() =>
                            history.push(
                              `/notice-detail/${noticId}/${noticType}/pdf`,
                              data?.title
                            )
                          }
                        >
                          {data.title}
                        </u>
                      </Col>
                    )}
                  </Row>
                );
              })}
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 14,
          }}
        >
          <div style={{ textAlign: "justify", textIndent: 25 }}>
            <div
              className="ckImage"
              dangerouslySetInnerHTML={{ __html: dataNotice?.content }}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: 20 }}>
        <div
          style={{
            display: data?.notics?.length < 1 ? "none" : "flex",
            justifyContent: "space-between",
          }}
        >
          <a style={{ fontWeight: "bolder" }}>ຂ່າວທີ່ກ່ຽວຂ້ອງ</a>
          <a
            style={{ color: Consts.PRIMARY_COLOR, textDecoration: "underline" }}
            onClick={() => {
              history.push(`/notice-relation/${noticType}`);
            }}
          >
            ເບີ່ງທັງໝົດ
            <FontAwesomeIcon icon={["fa", "angle-right"]} />
          </a>
        </div>
        <div
          style={{
            display: data?.notics?.length < 1 ? "flex" : "none",
            justifyContent: "center",
            marginTop: 20,
            opacity: 0.3,
          }}
        >
          <h1>ບໍ່ມີຂ່າວທີ່ກ່ຽວຂ້ອງ</h1>
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 50%",
            flexWrap: "wrap",
            justifyContent: "space-between",
            paddingTop: 20,
          }}
        >
          {data?.notics?.data?.map((x, index) => {
            return (
              <div
                onClick={() => history.push(`/notice-detail/${x.id}/${x.type}`)}
                key={index}
                style={{
                  backgroundColor: "#ffffff",
                  width: "48%",
                  border: "1px solid #ddd",
                  borderRadius: 5,
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    height: 100,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt=""
                    style={{ width: "100%" }}
                    src={
                      x?.coverImage?.url ||
                      "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                    }
                  />
                </div>

                <div style={{ padding: 10 }}>
                  <div
                    style={{
                      width: "100%",
                      // display: "flex",
                      // justifyContent: "space-between",
                    }}
                  >
                    <a style={{ fontSize: 14 }}>
                      ສ້າງໂດຍ:{x?.createdBy?.firstname}{" "}
                      {x?.createdBy?.lastname ? x?.createdBy?.lastname : ""}
                    </a>
                    <a style={{ fontSize: 14, fontWeight: "bold" }}>
                      {noticeStatus(x.type)}
                    </a>
                  </div>
                  <a
                    className="title"
                    style={{
                      width: "100%",
                      fontSize: 16,
                      marginBottom: 5,
                      marginTop: 5,
                      marginLeft: -1,
                      color: Consts.FONT_COLOR_PRIMARY,
                      fontWeight: "bolder",
                      overflowX: "hidden",
                      overflowY: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {x?.title}
                  </a>{" "}
                  <a style={{ fontSize: 14 }}>{formatDateTime(x.createdAt)}</a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ListAccessableFaculty
        data={dataNotice}
        showList={listFaculty}
        closeList={_showListFaculty}
      />
    </div>
  );
}

export default NoticeDetailMobile;
