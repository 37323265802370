import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Nav, Navbar, Row, Col } from "react-bootstrap";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FREQUENTLY_ASKED_QUESTIONS } from "../../consts/router";
import { MobileHeader } from "../../common";

export default function Detail() {
  const { history, location } = useReactRouter();

  console.log("history: ", history.location.state);
  const detail = history.location.state;

  const [content, setContent] = useState(<></>);
  const [title, setTitle] = useState("");

  useEffect(() => {
    switch (detail) {
      case "1":
        setContent(
          <div className="row">
            <div className="col-12 text-start">
              <p> {"ໃຊ້ໄວ້ເພື່ອຮັບສ່ວນຫຼຸດພິເສດຈາກຮ້ານຄ້າທີ່ຮ່ວມລາຍການ"}</p>
            </div>
          </div>
        );
        setTitle("ບັດນັກສືກສາໃຊ້ເພືອຫຍັງ?");
        break;
      case "2":
        setContent(
          <div className="row">
            <div className="col-12 text-start">
              <p>
                {
                  "ເເມ່ນເບີ່ງແຈ້ງການ-ຂ່າວສານໄດ້ທ່ວງທັນເວລາ ຈາກ ມຊ ຫຼື ຄະນະຂອງຕົນເອງ ມີທັງໝົດສາມໝວດໝູູ່ຄື ເເຈ້ງການ, ຂໍ້ຕົກລົງ ເເລະ ລະບຽບ"
                }
              </p>
            </div>
          </div>
        );
        setTitle("ເເຈ້ງການເເລະລະບຽບເເມ່ນຫຍັງ?");
        break;
      case "3":
        setContent(
          <div className="row">
            <div className="col-12 text-start">
              <p>{"ເເມ່ນການລົງທະບຽນ ວິຊາທີ່ຕົນເອງໄດ້ຮຽນໃນປີນັ້ນ"}</p>
            </div>
          </div>
        );
        setTitle("ລົງທະບຽນວິຊາຮຽນເເມ່ນເເນວໃດ?");
        break;
      case "4":
        setContent(
          <div className="row">
            <div className="col-12 text-start">
              <p>{"ເເມ່ນວິຊາທີ່ໄດ້ລົງທະບຽນສໍາເລັດເເລ້ວ"}</p>
            </div>
          </div>
        );
        setTitle("ວິຊາທີ່ລົງທະບຽນເເມ່ນຫຍັງ?");
        break;
      case "5":
        setContent(
          <div className="row">
            <div className="col-12 text-start">
              <p>
                {
                  "ເເມ່ນການລົງທະບຽນວິຊາຮຽນທີ່ຄະເເນນຂອງຕົນເອງບໍ່ໄດ້ຕາມຄວາມຄາດໝາຍ                  "
                }
              </p>
            </div>
          </div>
        );
        setTitle("ລົງທະບຽນເເກ້ເກຣດເເນວໃດ?");
        break;
      case "6":
        setContent(
          <div className="row">
            <div className="col-12 text-start">
              <p>
                {
                  "ເເມ່ນການຕິດຕາມຕາຕະລາງຮຽນ, ຫ້ອງຮຽນ ເເລະ ເວລາຂື້ນຫ້ອງສອນຂອງອາຈານ ສາມາດດາວໂຫຼດເອກະສານບົດຮຽນໄດ້ຢ່າງສະດວກ"
                }
              </p>
            </div>
          </div>
        );
        setTitle("ຕາຕະລາງຮຽນເເມ່ນຫຍັງ ?");
        break;
      case "7":
        setContent(
          <div className="row">
            <div className="col-12 text-start">
              <p>
                {
                  "ຫລັງຈາກທີ່ ນັກສຶກສາໄດ້ລົງທະບຽນເຂົ້າເປັນນັກສືກສາ ມຊ ສໍາເລັດ ຈະໄດ້ໄອດີ ເເລະ ລະຫັດ ໃນpreregistration ເພື່ອເຂົ້າ ໃຊ້ລະບົບ                  "
                }
              </p>
            </div>
          </div>
        );
        setTitle("ວິທີ່ເຂົ້າ App SLMS ?");
        break;
      case "8":
        setContent(
          <div className="row">
            <div className="col-12 text-start">
              <p>
                {
                  "ເຮົາສາມາດກົດເຂົ້າໃນຟັງຊັນຂອງ ຄະເເນນ ເພື່ອເບີ່ງຄະເເນນຕົນເອງຍ້ອນຫລັງໄດ້ "
                }
              </p>
            </div>
          </div>
        );
        setTitle("ເຮົາຈະເບີ່ງຄະເເນນໄດ້ເເນວໃດ ?");
        break;
      default:
        setContent(
          <div>
            <h1>Default Page</h1>
          </div>
        );
      // Handle other cases or provide a default content
    }
  }, [detail]);

  return (
    <div className="container">
      <MobileHeader
        title={title}
        showLeftArrow
        leftButtonClick={() => history.push("/frequently-asked-questions/")}
      />
      <div className="mt-5 pt-4">{content}</div>
    </div>
  );

  //   return (
  //     <div>
  //       {detail === 1 ? (
  //         <div
  //           style={{
  //             height: 56,
  //             backgroundColor: "#057CAE",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               color: "white",
  //             }}
  //           >
  //               <h1>Hello how are you</h1>
  //             <div
  //               style={{
  //                 marginTop: 18,
  //                 marginLeft: 15,
  //               }}
  //             >
  //               <FontAwesomeIcon
  //                 style={{ height: 20, width: 20 }}
  //                 icon={faArrowLeft}
  //                 onClick={() => history.push(FREQUENTLY_ASKED_QUESTIONS)}
  //               />
  //             </div>
  //             <Col
  //               style={{
  //                 marginTop: 15,
  //                 display: "flex",

  //                 fontSize: 20,
  //                 paddingLeft: 62,
  //               }}
  //             >
  //               ບັດນັກສືກສາໃຊ້ເພືອຫຍັງ ?
  //             </Col>
  //           </div>
  //           <div
  //             style={{
  //               marginTop: 37,
  //             }}
  //           >
  //             <center>
  //               {" "}
  //               ໃຊ້ໄວ້ເພື່ອຮັບສ່ວນຫຼຸດພິເສດຈາກຮ້ານຄ້າທີ່ຮ່ວມລາຍການ{" "}
  //             </center>
  //           </div>
  //         </div>
  //       ) : detail === 2 ? (
  //         <div
  //           style={{
  //             height: 56,
  //             backgroundColor: "#057CAE",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               color: "white",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 marginTop: 18,
  //                 marginLeft: 15,
  //               }}
  //             >
  //               <FontAwesomeIcon
  //                 style={{ height: 20, width: 20 }}
  //                 icon={faArrowLeft}
  //                 onClick={() => history.push(FREQUENTLY_ASKED_QUESTIONS)}
  //               />
  //             </div>
  //             <Col
  //               style={{
  //                 marginTop: 15,
  //                 display: "flex",

  //                 fontSize: 20,
  //                 paddingLeft: 62,
  //               }}
  //             >
  //               ເເຈ້ງການເເລະລະບຽບເເມ່ນຫຍັງ ?
  //             </Col>
  //           </div>
  //           <div
  //             style={{
  //               marginTop: 37,
  //               marginLeft: 20,
  //               marginRight: 20,
  //             }}
  //           >
  //             <center>
  //               {" "}
  //               ເເມ່ນເບີ່ງແຈ້ງການ-ຂ່າວສານໄດ້ທ່ວງທັນເວລາ ຈາກ ມຊ ຫຼື ຄະນະຂອງຕົນເອງ
  //               ມີທັງໝົດສາມໝວດໝູູ່ຄື ເເຈ້ງການ, ຂໍ້ຕົກລົງ ເເລະ ລະບຽບ{" "}
  //             </center>
  //           </div>
  //         </div>
  //       ) : detail === 3 ? (
  //         <div
  //           style={{
  //             height: 56,
  //             backgroundColor: "#057CAE",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               color: "white",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 marginTop: 18,
  //                 marginLeft: 15,
  //               }}
  //             >
  //               <FontAwesomeIcon
  //                 style={{ height: 20, width: 20 }}
  //                 icon={faArrowLeft}
  //                 onClick={() => history.push(FREQUENTLY_ASKED_QUESTIONS)}
  //               />
  //             </div>
  //             <Col
  //               style={{
  //                 marginTop: 15,
  //                 display: "flex",

  //                 fontSize: 20,
  //                 paddingLeft: 62,
  //               }}
  //             >
  //               ລົງທະບຽນວິຊາຮຽນເເມ່ນເເນວໃດ ?
  //             </Col>
  //           </div>
  //           <div
  //             style={{
  //               marginTop: 37,
  //               marginLeft: 20,
  //               marginRight: 20,
  //             }}
  //           >
  //             <center> ເເມ່ນການລົງທະບຽນ ວິຊາທີ່ຕົນເອງໄດ້ຮຽນໃນປີນັ້ນ </center>
  //           </div>
  //         </div>
  //       ) : detail === 4 ? (
  //         <div
  //           style={{
  //             height: 56,
  //             backgroundColor: "#057CAE",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               color: "white",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 marginTop: 18,
  //                 marginLeft: 15,
  //               }}
  //             >
  //               <FontAwesomeIcon
  //                 style={{ height: 20, width: 20 }}
  //                 icon={faArrowLeft}
  //                 onClick={() => history.push(FREQUENTLY_ASKED_QUESTIONS)}
  //               />
  //             </div>
  //             <Col
  //               style={{
  //                 marginTop: 15,
  //                 display: "flex",

  //                 fontSize: 20,
  //                 paddingLeft: 62,
  //               }}
  //             >
  //               ວິຊາທີ່ລົງທະບຽນເເມ່ນຫຍັງ ?
  //             </Col>
  //           </div>
  //           <div
  //             style={{
  //               marginTop: 37,
  //               marginLeft: 20,
  //               marginRight: 20,
  //             }}
  //           >
  //             <center> ເເມ່ນວິຊາທີ່ໄດ້ລົງທະບຽນສໍາເລັດເເລ້ວ </center>
  //           </div>
  //         </div>
  //       ) : detail === 5 ? (
  //         <div
  //           style={{
  //             height: 56,
  //             backgroundColor: "#057CAE",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               color: "white",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 marginTop: 18,
  //                 marginLeft: 15,
  //               }}
  //             >
  //               <FontAwesomeIcon
  //                 style={{ height: 20, width: 20 }}
  //                 icon={faArrowLeft}
  //                 onClick={() => history.push(FREQUENTLY_ASKED_QUESTIONS)}
  //               />
  //             </div>
  //             <Col
  //               style={{
  //                 marginTop: 15,
  //                 display: "flex",

  //                 fontSize: 20,
  //                 paddingLeft: 62,
  //               }}
  //             >
  //               ລົງທະບຽນເເກ້ເກຣດເເນວໃດ ?
  //             </Col>
  //           </div>
  //           <div
  //             style={{
  //               marginTop: 37,
  //               marginLeft: 20,
  //               marginRight: 20,
  //             }}
  //           >
  //             <center>
  //               ເເມ່ນການລົງທະບຽນວິຊາຮຽນທີ່ີ່ ຄະເເນນ ຂອງຕົນເອງບໍ່ໄດ້ຕາມຄວາມຄາດໝາຍ{" "}
  //             </center>
  //           </div>
  //         </div>
  //       ) : detail === 6 ? (
  //         <div
  //           style={{
  //             height: 56,
  //             backgroundColor: "#057CAE",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               color: "white",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 marginTop: 18,
  //                 marginLeft: 15,
  //               }}
  //             >
  //               <FontAwesomeIcon
  //                 style={{ height: 20, width: 20 }}
  //                 icon={faArrowLeft}
  //                 onClick={() => history.push(FREQUENTLY_ASKED_QUESTIONS)}
  //               />
  //             </div>
  //             <Col
  //               style={{
  //                 marginTop: 15,
  //                 display: "flex",

  //                 fontSize: 20,
  //                 paddingLeft: 62,
  //               }}
  //             >
  //               ຕາຕະລາງຮຽນເເມ່ນຫຍັງ ?
  //             </Col>
  //           </div>
  //           <div
  //             style={{
  //               marginTop: 37,
  //               marginLeft: 20,
  //               marginRight: 20,
  //             }}
  //           >
  //             <center>
  //               {" "}
  //               ເເມ່ນການຕິດຕາມຕາຕະລາງຮຽນ, ຫ້ອງຮຽນ ເເລະ ເວລາຂື້ນຫ້ອງສອນຂອງອາຈານ
  //               ສາມາດດາວໂຫຼດເອກະສານບົດຮຽນໄດ້ຢ່າງສະດວກ{" "}
  //             </center>
  //           </div>
  //         </div>
  //       ) : detail === 7 ? (
  //         <div
  //           style={{
  //             height: 56,
  //             backgroundColor: "#057CAE",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               color: "white",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 marginTop: 18,
  //                 marginLeft: 15,
  //               }}
  //             >
  //               <FontAwesomeIcon
  //                 style={{ height: 20, width: 20 }}
  //                 icon={faArrowLeft}
  //                 onClick={() => history.push(FREQUENTLY_ASKED_QUESTIONS)}
  //               />
  //             </div>
  //             <Col
  //               style={{
  //                 marginTop: 15,
  //                 display: "flex",

  //                 fontSize: 20,
  //                 paddingLeft: 62,
  //               }}
  //             >
  //               ວິທີ່ເຂົ້າ App SLMS ?
  //             </Col>
  //           </div>
  //           <div
  //             style={{
  //               marginTop: 37,
  //               marginLeft: 20,
  //               marginRight: 20,
  //             }}
  //           >
  //             <center>
  //               {" "}
  //               ຫລັງຈາກທີ່ ນັກສຶກສາໄດ້ລົງທະບຽນເຂົ້າເປັນນັກສືກສາ ມຊ ສໍາເລັດ
  //               ຈະໄດ້ໄອດີ ເເລະ ລະຫັດ ໃນpreregistration ເພື່ອເຂົ້າ ໃຊ້ລະບົບ{" "}
  //             </center>
  //           </div>
  //         </div>
  //       ) : detail === 8 ? (
  //         <div
  //           style={{
  //             height: 56,
  //             backgroundColor: "#057CAE",
  //           }}
  //         >
  //           <div
  //             style={{
  //               display: "flex",
  //               flexDirection: "row",
  //               color: "white",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 marginTop: 18,
  //                 marginLeft: 15,
  //               }}
  //             >
  //               <FontAwesomeIcon
  //                 style={{ height: 20, width: 20 }}
  //                 icon={faArrowLeft}
  //                 onClick={() => history.push(FREQUENTLY_ASKED_QUESTIONS)}
  //               />
  //             </div>
  //             <Col
  //               style={{
  //                 marginTop: 15,
  //                 display: "flex",

  //                 fontSize: 20,
  //                 paddingLeft: 62,
  //               }}
  //             >
  //               ເຮົາຈະເບີ່ງຄະເເນນໄດ້ເເນວໃດ ?
  //             </Col>
  //           </div>
  //           <div
  //             style={{
  //               marginTop: 37,
  //               marginLeft: 20,
  //               marginRight: 20,
  //             }}
  //           >
  //             <center>
  //               {" "}
  //               ເຮົາສາມາດກົດເຂົ້າໃນຟັງຊັນຂອງ ຄະເເນນ
  //               ເພື່ອເບີ່ງຄະເເນນຕົນເອງຍ້ອນຫລັງໄດ້{" "}
  //             </center>
  //           </div>
  //         </div>
  //       ) : (
  //         ""
  //       )}
  //     </div>
  //   );
}
