import React, { useEffect, useState } from "react";
import { Col, Row, Container, Navbar, Image, Button } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { USER_KEY } from "../../consts/user";
import { STUDENT } from "../../apollo/user";
import { useLazyQuery } from "@apollo/react-hooks";
import { CustomButton } from "../../common/CustomButton"
import { setGenders } from "../../super";
import Consts from "../../consts"
import "../../index.css";
import DevelopingModal from "../../common/DevelopingModal";
import NavbarFooterApp from "../../common/NavbarFooterApp";
import consts from "../../consts";
import { MobileHeader } from "../../common";
const OldStudentContact = () => {
  /**
   *
   */
  const { history, location } = useReactRouter();

  const active = location.state;

  /**
   * use states
   */
  const [showDeveloping, setShowDeveloping] = useState(false);

  /**
   * apollo
   */
  const [loadStudent, { data }] = useLazyQuery(STUDENT);

  /**
   * use effect
   */
  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY);
    if (userData) {
      const user = JSON.parse(userData);
      loadStudent({ variables: { where: { id: user?.studentData?.id } } });
    }
  }, []);

  return (
    // style={{ height: "100vh", backgroundColor: "white" }}

    <div className="body">
      <MobileHeader
        title="ຊົມລົມສິດເກົ່າ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <Row>
        <Col xs={12}>
          <div style={{ marginTop: "20%", textAlign: "center" }}>
            <Image src="/assets/welcom.png" width="55%" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="10" style={{ marginTop: "5%", marginLeft: 28 }}>
          <h5 style={{ textAlign: "center", color: consts.PRIMARY_COLOR }}>
            <strong>ສະບາຍດີ, ຍິນດີຕອນຮັບເຂົ້າສູ່ຊົມລົມສິດເກົ່າ</strong>
          </h5>
          <div style={{ color: consts.PRIMARY_COLOR }}>
            <p style={{ marginTop: 20 }}><strong>ນັກສຶກສາທີ່ເຂົ້າມາໃນຟັງຊັນສິດເກົ່າ</strong></p>
            - ສາມາດ Contact ກັບລຸ້ນເອື້ອຍອ້າຍທີ່ເປັນສິດເກົ່າໄດ້<br />
            - ສາມາດເເຊັດກັບລຸ້ນເອື້ອຍອ້າຍຜ່ານ ເເອັບSLMS<br />
            - ຈະໄດ້ຮັບການໂຄສະນາ ແລະ ການເຜີຍແຜ່ຄວາມຮູ້ຕ່າງໆ
            ຈາກລຸ້ນເອື້ອຍອ້າຍ ແລະ ອື່ນໆອີກຫຼາກຫຼາຍ... <br />
            <p style={{ marginTop: 20 }}><strong >ຜູ້ທີ່ສະໝັກເປັນສິດເກົ່າ</strong></p>
            - ສາມາດ Contact ກັບລຸ້ນນ້ອງໄດ້<br />
            - ສາມາດເເຊັດກັບລຸ້ນນ້ອງຜ່ານ ເເອັບ SLMS<br />
            - ສາມາດສ້າງໂຄສະນາ ເເລະ ເຜີຍເເຜ່ຄວາມຮູ້ໃຫ້ກັບລຸ່ນນ້ອງ
            ໄດ້ຜ່ານແອັບ SLMS
          </div>
          <div className="mt-3">
            <button
              style={{ backgroundColor: Consts.PRIMARY_COLOR }}
              className="btn btn-primary btn-block"
              onClick={() => history.push('/graduated-student-club-list/')}
            >
              ເລີ່ມຕົ້ນໃຊ້ງານ
            </button>
          </div>
        </Col>
      </Row>

      {/* <NavbarFooterApp active={active} />
      <DevelopingModal
        show={showDeveloping}
        handleClose={() => setShowDeveloping(false)}
      /> */}
    </div>
  );
};
const styles = {
  title: {
    fontSize: 12,
  },
  iconStyle: {
    fontSize: "2.3em",
    height: 50,
    color: "#057CAE",
  },
};

const stylesFooter = {
  title: {
    fontSize: 12,
  },
  iconStyle: {
    fontSize: "2.3em",
    height: 50,
    color: "#C7C7C7",
  },
};
export default OldStudentContact;