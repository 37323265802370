import React, { useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import Consts from "../consts";
import { USER_KEY } from "../consts/user";

import "./sideNav.css";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";

function CustomSideNavWeb({ location, history }) {
  const [selectStatus, setSelectStatus] = useState(
    location.pathname.split("/")[1].split("-")[0]
  );
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (!user) {
      history.push("/");
    }
  }, []);
  return (
    <div>
      <SideNav
        onSelect={(selected) => {
          setSelectStatus(selected.split("-")[0]);
          const to = "/" + selected;
          if (location.pathname !== to) {
            history.push(to);
            if (to === "/timetable-list") {
              window.location.reload(true);
            }
          }
        }}
        style={{
          width: 70,
          backgroundColor: "#F5F5F5",
          height: "100vh",
          display: "block",
          position: "fixed",
          zIndex: 9999,
        }}
      >
        <SideNav.Toggle />
        <SideNav.Nav defaultSelected={location.pathname.split("/")[1]}>
          <NavItem
            eventKey="course-list"
            active={selectStatus === "course" ? true : false}
            style={{
              borderLeft:
                selectStatus === "course"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-plus-square"
                aria-hidden="true"
                style={{
                  fontSize: "1.75em",
                }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ລົງທະບຽນວິຊາຮຽນ
            </NavText>
          </NavItem>
          <NavItem
            eventKey="registered-course-list"
            active={selectStatus === "registered" ? true : false}
            style={{
              borderLeft:
                selectStatus === "registered"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-bookmark"
                style={{
                  fontSize: "1.75em",
                }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ວິຊາທີ່ລົງທະບຽນ
            </NavText>
          </NavItem>
          <NavItem
            eventKey="timetable-list"
            active={selectStatus === "timetable" ? true : false}
            style={{
              borderLeft:
                selectStatus === "timetable"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-table"
                style={{
                  fontSize: "1.75em",
                }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ຕາຕະລາງຮຽນ
            </NavText>
          </NavItem>
          <NavItem
            eventKey="doc-list"
            active={selectStatus === "doc" ? true : false}
            style={{
              borderLeft:
                selectStatus === "doc" ? "6px solid #7BB500" : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-folder"
                style={{
                  fontSize: "1.75em",
                }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>ເອກະສານ</NavText>
          </NavItem>
          {/* notice start */}
          
          {/* For emerald */}

          <NavItem
                eventKey="document-lists"
                active={selectStatus === "document" ? true : false}
                style={{
                  borderLeft:
                    selectStatus === "document" ? "6px solid #7BB500" : "6px solid #fff",
                }}
              >
                <NavIcon>
                  <i class="fa fa-book-reader" style={{ fontSize: "1.75em" }}/>
                </NavIcon>
                <NavText style={{ color: Consts.PRIMARY_COLOR }}>ຫ້ອງສະໝຸດທັນສະໄໝ</NavText>
          </NavItem> 

          {/* <NavItem
              eventKey="research"
              active={selectStatus === "document" ? true : false}
              style={{
                borderLeft:
                  selectStatus === "document" ? "6px solid #7BB500" : "6px solid #fff",
              }}
          > 
              <NavIcon>
                <i class="fa fa-book-reader" style={{ fontSize: "1.75em" }}/>
              </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>ຫ້ອງສະໝຸດທັນສະໄໝ</NavText>
          </NavItem>
          */}
          <NavItem
            eventKey="notice-list"
            active={selectStatus === "notice" ? true : false}
            style={{
              borderLeft:
                selectStatus === "notice"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-bullhorn"
                aria-hidden="true"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ແຈ້ງການ ແລະ ລະບຽບ
            </NavText>
          </NavItem>
          {/* end notice */}
          <NavItem
            eventKey="grade-list"
            active={
              selectStatus === "grade" || selectStatus === "upgrade"
                ? true
                : false
            }
            style={{
              borderLeft:
                selectStatus === "grade" || selectStatus === "upgrade"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-info"
                style={{ fontSize: "1.75em", FontWeights: "200!important" }}
              ></i>
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ຂໍ້ມູນຄະແນນ
            </NavText>
            <NavItem
              eventKey="grade-list"
              active={selectStatus === "grade" ? true : false}
              style={{
                marginLeft: -15,
                borderLeft:
                  selectStatus === "grade"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavText
                style={{
                  padding: 10,
                  borderLeft:
                    selectStatus === "grade" ? "6px solid #7BB500" : " ",
                }}
              >
                <i className="fas fa-clipboard-list fa-2x" /> &nbsp; ຄະແນນ
              </NavText>
            </NavItem>
            <NavItem
              eventKey="upgrade-list"
              active={selectStatus === "upgrade" ? true : false}
              style={{
                marginLeft: -15,
                borderLeft:
                  selectStatus === "upgrade"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavText
                style={{
                  padding: 10,
                  borderLeft:
                    selectStatus === "upgrade" ? "6px solid #7BB500" : " ",
                }}
              >
                <i className="fa fa-arrow-alt-circle-up fa-2x"></i> &nbsp;
                ການອັບເກຣດ
              </NavText>
            </NavItem>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </div>
  );
}

export default CustomSideNavWeb;
