import React, { useState } from 'react';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect';
import NoticeListBrowser from './NoticeListBrowser';
import NoticeListMobile from './NoticeListMobile';

const DocList = () => {

  return (
    <div>
      <BrowserView>
        <NoticeListBrowser />
      </BrowserView>
      <MobileView>
        <NoticeListMobile />
      </MobileView>
    </div>
  );
};

export default DocList;
