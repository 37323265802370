import React, { useEffect, useState } from "react";
import { Col, Row, Container, Navbar } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { USER_KEY } from "../../consts/user";
import { STUDENT } from "../../apollo/user";

import { useLazyQuery } from "@apollo/react-hooks";
import { setGenderE, setGenders } from "../../super";
import "../../index.css";
import DevelopingModal from "../../common/DevelopingModal";
import NavbarFooterApp from "../../common/NavbarFooterApp";
import Consts from "../../consts";
import "./header.css";
import { faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "antd";
import { WarningOutlined } from "@ant-design/icons";

const Dashboard = () => {
  const reloadPage = async () => {
    await history.push("/newresearch");
    // window.location.reload();
  };
  /**
   *
   */
  const { history, location } = useReactRouter();
  const active = location.state;
  const [loginUserData, setLoginUserData] = useState([]);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [imagesUrl, setImagesUrl] = useState("");
  const [onClickOldStudent, setOnClickOldStudent] = useState(false);
  const [countClickOldStudent, SetCountClickOldStudent] = useState(
    JSON.parse(localStorage.getItem("countClickOldStudent"))
  );
  /**
   * use states
   */
  const [showDeveloping, setShowDeveloping] = useState(false);

  /**
   * apollo
   */
  // const [loadGrade, { data, called, loading }] = useLazyQuery(STUDENTS_LOGIN);

  const [loadStudent, { data }] = useLazyQuery(STUDENT);

  /**
   * use effect
   */
  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY);
    if (userData) {
      const user = JSON.parse(userData);
      loadStudent({ variables: { where: { id: user?.studentData?.id } } });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "countClickOldStudent",
      JSON.stringify(countClickOldStudent)
    );
  }, [countClickOldStudent]);

  useEffect(() => {
    if (data) {
      // const images = data?.student?.image?.url.split('/').pop()
      const images = data?.student?.image?.url;
      setImagesUrl(images);
    }
    if (onClickOldStudent === true && countClickOldStudent === 1) {
      history.push("/graduated-student-contact");
    }
    if (onClickOldStudent === true && countClickOldStudent > 1) {
      history.push("/graduated-student-club-list");
    }
  }, [data, onClickOldStudent]);

  const _clickLogout = () => setShowConfirmLogout(true);
  const _clickCloseLogout = () => setShowConfirmLogout(false);
  const _onConfirmLogout = async () => {
    await localStorage.clear();
    history.push("/");
  };
  const _handleOldStudent = () => {
    setOnClickOldStudent(true);
    let newClickData = countClickOldStudent + 1;
    SetCountClickOldStudent(newClickData);
  };

  return (
    <div className="body">
      <Navbar
        expand="lg"
        fixed="top"
        variant="light"
        className="header pr-0 mr-0"
      >
        <Container className="">
          <Navbar.Brand>
            <img
              onClick={() => history.push("/profile")}
              src={
                data?.student?.image?.url
                  ? Consts.URL_IMAGE_STUDENT + imagesUrl
                  : "/assets/userpng.png"
              }
              style={{
                marginLeft: 15,
                width: 70,
                height: 70,
                borderRadius: 70,
                overflow: "hidden",
              }}
            />

            <span
              style={{
                float: "right",
                padding: 0,
                margin: 0,
                marginLeft: 10,
                marginTop: 15,
              }}
            >
              <p className="dashbord-name-ellipsis">
                {!data?.student?.firstNameL || data?.student?.firstNameL === ""
                  ? setGenderE(data?.student?.gender) +
                    " " +
                    data?.student?.firstNameE +
                    " " +
                    data?.student?.lastNameE
                  : setGenders(data?.student?.gender) +
                    " " +
                    data?.student?.firstNameL +
                    " " +
                    data?.student?.lastNameL}
                {/* {setGenders(data?.student?.gender) +
                  " " +
                  data?.student?.firstNameL +
                  "   " +
                  data?.student?.lastNameL} */}
              </p>
              <p className="dashbord-userid-ellipsis">
                ລະຫັດ: &nbsp;{data?.student?.studentNumber ?? ""}
              </p>
            </span>
          </Navbar.Brand>
          <Navbar
            className="justify-content-end"
            onClick={_clickLogout}
            style={{ padding: 0, marginRight: 4 }}
          >
            <Navbar.Text
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <i
                className="fa fa-power-off"
                aria-hidden="true"
                style={{
                  float: "center",
                  fontSize: "24px",
                  color: "#fff",
                  marginTop: "-25px",
                  marginRight: "5px",
                }}
              />
              <div
                className="text-truncate"
                style={{
                  marginBottom: -38,
                  fontSize: 8,
                  marginTop: 10,
                  position: "absolute",
                  top: "30px",
                  right: "10px",
                }}
              >
              </div>
            </Navbar.Text>
          </Navbar>
        </Container>
      </Navbar>

      <Row className="content-menu">
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/student-card")}
        >
          <i
            className="fa fa-address-card"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ບັດນັກສຶກສາ</p>
        </Col>
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/stores")}
        >
          <i
            className="fa fa-store"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ສ່ວນຫຼຸດຮ້ານຄ້າ</p>
        </Col>
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/notice-list")}
        >
          <i
            className="fa fa-bullhorn"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ແຈ້ງການ ແລະ ລະບຽບ</p>
        </Col>
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/course-list")}
        >
          <i
            className="fa fa-plus-square"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ລົງທະບຽນວິຊາຮຽນ</p>
        </Col>
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/registered-course-list")}
        >
          <i className="fa fa-bookmark" style={styles.iconStyle} />
          <p style={styles.title}>ວິຊາທີ່ລົງທະບຽນ</p>
        </Col>
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/timetable-list")}
        >
          <i
            className="fa fa-table"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ຕາຕະລາງຮຽນ</p>
        </Col>

        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/grade-list")}
        >
          <i
            className="fa fa-graduation-cap"
            aria-hidden="true"
            style={{
              width: 70,
              height: 70,
              color: "#057CAE",
              marginBottom: -6,
              padding: 0,
            }}
          />
          <p style={styles.title}>ຄະແນນ</p>
        </Col>

        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/upgrade-list")}
        >
          <i
            className="fa fa-arrow-alt-circle-up"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ການອັບເກຣດຄະແນນ</p>
        </Col>

        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/doc-list")}
        >
          <i
            className="fa fa-folder"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ເອກະສານ</p>
        </Col>

        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/job-vacancy-list")}
        >
          <i
            className="fa fa-briefcase"
            aria-hidden="true"
            style={styles.iconStyle}
            onClick={() => history.push("/job-vacancy-list")}
          />
          <p style={styles.title}>ສະຫມັກວຽກ</p>
        </Col>
        <Col xs={4} className="text-center mg-dev p-0" onClick={reloadPage}>
          <i
            className="fa fa-book-reader"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ຫ້ອງສະໝຸດທັນສະໄໝ</p>
        </Col>
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={_handleOldStudent}
        >
          <i
            className="fa fa-user-graduate"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ຊົມລົມສິດເກົ່າ</p>
        </Col>
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/scholarship-list")}
        >
          <i
            className="fa fa-hand-holding-usd"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ທຶນ​ການ​ສຶກ​ສາ</p>
        </Col>
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/activity-list")}
        >
          <i
            className="fa fa-users"
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>​ກິດ​ຈະ​ກຳ​ນອກຫຼັກ​ສ​ູດ</p>
        </Col>
        <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/media-page")}
        >
          <FontAwesomeIcon
            icon={faPhotoVideo}
            aria-hidden="true"
            style={styles.iconStyle}
          />
          <p style={styles.title}>ສື່ມີເດຍ</p>
        </Col>

        {/* <Col
          xs={4}
          className="text-center mg-dev p-0"
          onClick={() => history.push("/scopus")}
        >
          <img className="w-100 px-4" src="/scopus.jpeg" />
          <p style={styles.title}>Scopus</p>
        </Col> */}
      </Row>

      <NavbarFooterApp active={active} />
      <DevelopingModal
        show={showDeveloping}
        handleClose={() => setShowDeveloping(false)}
      />

      {/* <Modal show={showConfirmLogout} centered className="center">
        <Modal.Header>
          <Modal.Title>ອອກຈາກລະບົບ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col style={{ marginBottom: 30 }}>
            ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ ຫຼື ບໍ?
          </Col>
          <Col className="d-flex justify-content-center">
            <Button
              style={{
                fontSize: 12,
                marginRight: 10,
                border: `1px solid #057CAE`,
                backgroundColor: "#057CAE",
                color: "#fff",
              }}
              onClick={_onConfirmLogout}
            >
              ຢືນຢັນ
            </Button>
            <Button
              style={{
                fontSize: 12,
                border: `1px solid #057CAE`,
                backgroundColor: "#fff",
                color: "#057CAE",
              }}
              onClick={_clickCloseLogout}
            >
              ຍົກເລີກ
            </Button>

          </Col>
        </Modal.Body>
      </Modal> */}

      <Modal
        centered
        open={showConfirmLogout}
        onCancel={_clickCloseLogout}
        footer={null}
      >
        <div className="text-center">
          <p className="mb-3">
            <WarningOutlined
              style={{ fontSize: "60px", color: "#FF7F50" }}
              theme="outlined"
            />
          </p>
          <h5>
            <b>ຕ້ອງການອອກຈາກລະບົບແທ້ບໍ ?</b>
          </h5>
          <div className="mt-3">
            <hr />
            <Button
              type="primary"
              className="mr-3"
              size="middle"
              onClick={_onConfirmLogout}
            >
              ຕົກລົງ
            </Button>
            <Button
              type="primary"
              danger
              className="mr-3"
              size="middle"
              onClick={_clickCloseLogout}
            >
              ຍົກເລີກ
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
const styles = {
  title: {
    fontSize: 12,
  },
  iconStyle: {
    width: 60,
    height: 60,
    color: "#057CAE",
    marginBottom: 5,
    padding: 0,
  },
};

const stylesFooter = {
  title: {
    fontSize: 12,
  },
  iconStyle: {
    fontSize: "2.3em",
    height: 50,
    color: "#C7C7C7",
  },
};
export default Dashboard;
