import React, { useEffect, useState } from 'react'
import { MobilePDFReader } from 'react-read-pdf'
import { MobileHeader } from '../../common'
import useReactRouter from 'use-react-router'
import ControlPanel from "./ControlPanel"
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


export default function MobileDetailReader () {
  const { history, location, match } = useReactRouter()
  const [numPages, setNumPages] = useState(null)
  const [scale, setScale] = useState(1.0);
  const [pageNumber, setPageNumber] = useState(1)
  const [urlPDF, seturlPDF] = useState()

  useEffect(() => {
    if (location?.state) {
      seturlPDF(location?.state)
    }
    // console.log("location", location)
    console.log(
      'https://nuol.s3-ap-southeast-1.amazonaws.com/dev/' + location?.state
    )
  }, [location])

  function onDocumentLoadSuccess ({ numPages }) {
    setNumPages(numPages)
  }
  return (
    <div>
      <MobileHeader 
        showLeftArrow
        title={location?.state.split('/')[4]}
        leftButtonClick={() => history.goBack()}
      />
      <section
        style={{overflow:'scroll', marginTop: 70}}
        id="pdf-section"
        className="d-flex flex-column align-items-center w-100"
      >
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={urlPDF}
        />
        <div >
        <Document
          file={urlPDF}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
        </div>
      </section>
    </div>
  )
}
