import React, { useState, useEffect } from "react";

import useReactRouter from "use-react-router";
import { Formik } from "formik";
import "./index.css";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { MobileHeader } from "../../common";
import { GRADES, UPGRADE_GRADE, GRADES_FILTER } from "../../apollo/grade";
const RegisterToUpgradeMobileView = ({ student, courses }) => {
  const { history } = useReactRouter();
  const [studentId, setStudentId] = useState();
  const [gradeCourse, setGradeCourse] = useState();

  const [newData, setNewData] = useState([]);

  /**
   * apollo
   */
  const [loadGrade, { data, called, loading }] = useLazyQuery(GRADES);
  const [loadGradeFilter, { data: gradeFilter }] = useLazyQuery(GRADES_FILTER);
  const [upgradeGrade, { data: upgradeGradeData }] = useMutation(UPGRADE_GRADE);
  useEffect(() => {
    // setStudentId(student?.id);
    setStudentId(student?.userId);
    loadGrade();
    loadGradeFilter();
  }, [student]);
  useEffect(() => {
    setGradeCourse(data?.grades?.data);
    loadGrade({
      variables: {
        where: {
          student: studentId,
          // sendingGradeStatus_in: ["ADMIN_APPROVED"],
        },
      },
    });
    const loadGradeFilter = async () => {
      let _newData = [];
      for (var i = 0; i < data?.grades?.data?.length; i++) {
        for (var j = 0; j < _newData.length; j++) {
          if (data?.grades?.data[i]?.course?.id === _newData[j]?.course?.id) {
            delete _newData[j];
          }
        }
        _newData.push(data?.grades?.data[i]);
      }
      let _newData2 = [];
      for (var i = 0; i < _newData.length; i++) {
        if (_newData[i]) {
          _newData2.push(_newData[i]);
        }
      }
      setNewData(_newData2);
    };
    if (data?.grades?.data) {
      loadGradeFilter();
    }
  }, [data, student]);
  const getGrade = async (e) => {
    await upgradeGrade(e);
    history.push("upgrade-list");
    window.location.reload(true);
  };
  const _handleClick = () => {
    history.push("/upgrade-list");
  };
  console.log("newData", newData)
  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <MobileHeader
        title="ການອັບເກຣດຄະແນນ"
        showLeftArrow
        leftButtonClick={() => history.push("/upgrade-list")}
      />

      <br />
      {/* body div */}
      <div style={{ backgroundColor: "#eee" }}>
        <br />
        <br />
        <div className="row">
          <div className="container-fluid">
            <div className="col-sm-12">
              <Formik
                validate={(values) => {
                  const errors = {};
                  if (values.id === "") {
                    errors.id = "ກະລຸນາເລືອກວິຊາທີ່ຈະແກ້ເກຣດ";
                  }
                  return errors;
                }}
                onSubmit={async (values) => {
                  await getGrade({
                    variables: {
                      where: {
                        id: values.id,
                      },
                    },
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div>
                    <label htmlFor="fullname">ຊື່ ແລະ ນາມສະກຸນນັກສືກສາ</label>
                    <input
                      id="fullname"
                      type="text"
                      className="form-control"
                      value={`${student?.firstNameL} ${student?.lastNameL}`}
                      disabled
                    />
                    <br />
                    <label htmlFor="stdId">ລະຫັດນັກສືກສາ</label>
                    <input
                      id="stdId"
                      type="text"
                      className="form-control"
                      value={student?.userId}
                      disabled
                    />
                    <br />
                    <label htmlFor="id">ວິຊາທີ່ເປີດລົງທະບຽນແກ້ເກຣດ</label>
                    <select
                      className="form-control"
                      name="id"
                      onChange={handleChange}
                    >
                      <option>ເລືອກວິຊາແກ້ເກຣດ</option>
                      {newData &&
                        newData?.map((item, index) => (
                          <option
                            value={item?.id}
                            key={index}
                            style={{
                              display: item?.letterGrade === "A" || item?.course?.isUpgrade === false
                              ? "none" : "block"
                            }}
                            disabled={
                              // item?.course?.isUpgrade === false
                              //   ? true
                              //   : 
                                item?.numberTest > 1 &&
                                  item?.gredeStatus === "REQUESTING"
                                ? true
                                : item?.numberTest > 1 &&
                                  item?.gredeStatus === "APROVED"
                                ? true
                                : item?.numberTest > 1 &&
                                  item?.gredeStatus === "REJECTED"
                                ? false
                                : false
                            }
                          >
                            {
                            // item?.course?.isUpgrade === false
                            //   ? "ປິດລົງທະບຽນແກ້ເກຣດ"
                            //   : 
                              item?.numberTest > 1 &&
                                item?.gredeStatus === "REQUESTING"
                              ? item?.course?.title + " ລົງທະບຽນແລ້ວ"
                              : item?.numberTest > 1 &&
                                item?.gredeStatus === "APROVED"
                              ? item?.course?.title + " ລົງທະບຽນແລ້ວ"
                              : item?.numberTest > 1 &&
                                item?.gredeStatus === "REJECTED"
                              ? item?.course?.title
                              : item?.course?.title}
                          </option>
                        ))}
                    </select>
                    <p style={{ color: "red" }}>
                      {errors.courseId && touched.courseId && errors.courseId}
                    </p>
                    <p style={{ color: "red" }}>
                      {errors.yearLevel &&
                        touched.yearLevel &&
                        errors.yearLevel}
                    </p>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-6">
                        <button
                          className="btn-ouline-nuol"
                          onClick={_handleClick}
                        >
                          ຍົກເລີກ
                        </button>
                      </div>
                      <div className="col-6">
                        <button className="btn-default" onClick={handleSubmit}>
                          ບັນທຶກ
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
              <div style={{ height: 50 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterToUpgradeMobileView;
