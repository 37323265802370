import React, { useState, useEffect } from "react";
import { Breadcrumb, Row, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
// component --------------------------------------------------------->
import RegisterCourse from "./RegisterCourse";
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
import Loading from "../../common/Loading";
// constance --------------------------------------------------------->
import Consts from "../../consts";
import { USER_KEY } from "../../consts/user";
import { COURSE_FACULTY_DEPARTMENT, GET_COURSE } from "../../apollo/course";
import { GRADE_REGITRATIONS } from "../../apollo/registration";
// import { CREATE_REGISTRATION } from "../../apollo/registration";
import { CREATE_GRADE } from "../../apollo/grade";
import { STUDENT } from "../../apollo/user";
function CourseDetailBrowserView() {
  const { history, match } = useReactRouter();
  const { courseId } = match.params;
  // console.log("courseId", courseId);
  // states ------------------------------------------------------------>
  const [loginUserData, setLoginUserData] = useState("");
  const [registerData, setRegisterData] = useState("");
  const [showRegisterConfirm, setShowRegisterConfirm] = useState(false);
  const [facultyId, setFacultyId] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [majorId, setMajorId] = useState();
  const [classroomId, setClassroomId] = useState();
  let [createStatus, setCreateStatus] = useState("");
  const [isEnable, setIsEnable] = useState(true);

  const [displayStyle, setDisplayStyle] = useState(false)
  // apollo ------------------------------------------------------------>
  const [loadDataStudent, { data: dataStudent }] = useLazyQuery(STUDENT)
  const { loading, error, data } = useQuery(COURSE_FACULTY_DEPARTMENT, {
    variables: { where: { id: courseId ? courseId : "" } },
  });

  const {
    loading: registeredLoading,
    error: registeredError,
    data: registeredData,
  } = useQuery(GRADE_REGITRATIONS, {
    variables: {
      where: {
        course: courseId ? courseId : "",
        student: loginUserData?.userId ? loginUserData?.userId : ""
      },
    },
  });
  // const [registerCourse] = useMutation(CREATE_REGISTRATION);
  const [createGrade] = useMutation(CREATE_GRADE);
  // use --------------------------------------------------------------->
  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY);
    if (userData) {
      const user = JSON.parse(userData);
      setLoginUserData(user?.studentData);
      loadDataStudent({
        variables: {
          where: { id: user?.studentData?.id }
        }
      })
    }
  }, []);

  useEffect(() => {
    if(dataStudent) {
      setFacultyId(dataStudent?.student?.faculty?.id)
      setDepartmentId(dataStudent?.student?.department?.id)
      setMajorId(dataStudent?.student?.major?.id)
      setClassroomId(dataStudent?.student?.classRoom?.id)
    }
  }, [dataStudent])

  const DATENOW = moment(new Date()).format("YYYY-MM-DD");

  //faculty
  const STARTDATEREGISTER = moment(
    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.department?.faculty?.startDateRegister
  ).format("YYYY-MM-DD");
  const ENDDATEREGISTER = moment(
    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.department?.faculty?.endDateRegister
  ).format("YYYY-MM-DD");
  // DEPARTMENT
  const startDepartmentRegidter = moment(
    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.department?.startDateRegister
  ).format("YYYY-MM-DD");
  const endDepartmentRegidter = moment(
    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.department?.endDateRegister
  ).format("YYYY-MM-DD");
  useEffect(() => {
    if (STARTDATEREGISTER <= DATENOW && DATENOW <= ENDDATEREGISTER) {
      if (
        startDepartmentRegidter <= DATENOW &&
        DATENOW <= endDepartmentRegidter
      ) {
        if (data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.isRegistration === true) {
          setIsEnable(true);
        } else {
          setIsEnable(false);
        }
      } else {
      }
    } else {
    }
  }, [data]);

  const _handleRegisterConfirmViewClose = () => setShowRegisterConfirm(false);
  const _handleRegisterConfirmViewShow = () => setShowRegisterConfirm(true);

  const _docList = () => {
    history.push(`/course-doc-list/${ courseId }`);
  };

  const _registerCourse = (data) => {
    setRegisterData(data);
    _handleRegisterConfirmViewShow();
  };

  // console.log("registeredData:", registeredData?.grades?.data[0]?.gredeStatus);

  const _confirmRegister = async () => {
    let status = false;
    let count = 0;
    if (
      registeredData?.registrations &&
      registeredData?.registrations?.length > 0
    ) {
      for (var i = 0; i < registeredData.registrations.length; i++) {
        if (registeredData.registrations[i]?.course?.year === data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.year) {
          if (registeredData.registrations[i]?.course?.semester === data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.semester) {
            if (registeredData.registrations[i]?.course?.dayTimeIndexes?.length > 0 
              && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.dayTimeIndexes?.length > 0
            ) {
              let regisDayTimes =
                registeredData.registrations[i].course.dayTimeIndexes;
              let courseDayTimes = data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.dayTimeIndexes;
              if (_checkCourseDayTimes(regisDayTimes, courseDayTimes) === true) {
                count++;
              }
            }
          }
        }
      }
    }
    if (count > 0) {
      status = true;
    }
    if (status === false) {
      setCreateStatus("");
      let param = {
        data: {
          studentId: loginUserData?.id,
          courseId: courseId,
          faculty: facultyId,
          department: departmentId,
          major: majorId,
          classRoom: classroomId,
        },
      };
      // await registerCourse({ variables: paramQL });

      await createGrade({ variables: param });

      history.push("/registered-course-list");
      window.location.reload(true);
    } else {
      setCreateStatus("ມີຊົ່ວໂມງຮຽນທີ່ຕຳກັນ");
    }
  };
  const _checkCourseDayTimes = (regisDayTimes, courseDayTimes) => {
    let status = false;
    let count = 0;
    for (var i = 0; i < regisDayTimes.length; i++) {
      if (regisDayTimes[i].day) {
        for (var j = 0; j < courseDayTimes.length; j++) {
          if (courseDayTimes[j].day) {
            if (regisDayTimes[i].day === courseDayTimes[j].day) {
              regisDayTimes[i].timeIndexes.filter((times, index) => {
                if (courseDayTimes[j].timeIndexes.includes(times) === true) {
                  count++;
                }
              });
            }
          }
        }
      }
    }
    if (count > 0) {
      status = true;
    }
    return status;
  };
  const _checkCourse = () => {
    // if (isEnable === true)
    if (isEnable === true) {
      let result = false;
      for (var i = 0; i < registeredData?.registrations?.length; i++) {
        if (registeredData?.registrations[i]?.course?.id === courseId) {
          result = true;
        }
      }
      return result;
    }
    return true;
  };

  const _reconvertDay = (day) => {
    let result = "";
    switch (day) {
      case "MONDAY":
        result = "ຈັນ";
        break;
      case "TUESDAY":
        result = "ອັງຄານ";
        break;
      case "WEDNESDAY":
        result = "ພຸດ";
        break;
      case "THURSDAY":
        result = "ພະຫັດ";
        break;
      case "FRIDAY":
        result = "ສຸກ";
        break;
      case "SATURDAY":
        result = "ເສົາ";
        break;
      case "SUNDAY":
        result = "ອາທິດ";
        break;
      default:
        result = "ຈັນ";
    }
    return result;
  };

  if (loading && registeredLoading) return <Loading />;
  // if (error || registeredError) return <h1>GraphQL error</h1>;

  return (<></>)
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/course-list")}>
          ລົງທະບຽນວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/course-list")}>
          ວິຊາທັງຫມົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍລະອຽດວິຊາ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text="ລາຍລະອຽດວິຊາ" />

          {/* Button group */}

          {registeredData?.grades?.data[0]?.gredeStatus === "REQUESTING" ?
            <p className="mr-2" style={{ color: "orange", fontSize: 18 }}><b>ລໍຖ້າການຢືນຢັນ</b></p>
            : registeredData?.grades?.data[0]?.gredeStatus === "APROVED" ?
              <p className="mr-2" style={{ color: "green", fontSize: 18 }}><b>ລົງທະບຽນວິຊານີ້ແລ້ວ</b></p>
              : registeredData?.grades?.data[0]?.gredeStatus === "FINISHED" ?
                <p className="mr-2" style={{ color: "green", fontSize: 18 }}><b>ລົງທະບຽນວິຊານີ້ແລ້ວ</b></p>
                :
                isEnable === true
                  ?
                  <OverlayTrigger overlay={<Tooltip id="tooltip">ການລົງທະບຽນແມ່ນໄດ້ເປີດແລ້ວ</Tooltip>}>
                    <span className="d-inline-block">
                      <Button
                        className="mr-2"
                        disabled={false}
                        style={{
                          backgroundColor: Consts.PRIMARY_COLOR,
                          color: "#fff",
                          height: 40,
                          border: `1px solid ${ Consts.PRIMARY_COLOR }`,
                          outline: "none",
                        }}
                        onClick={() => _registerCourse(data && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse)}
                      >
                        ລົງທະບຽນວິຊາ
                    </Button>
                    </span>
                  </OverlayTrigger>
                  : isEnable === false ?
                    <OverlayTrigger overlay={<Tooltip id="tooltip">ການລົງທະບຽນແມ່ນໄດ້ປິດແລ້ວ</Tooltip>}>
                      <span className="d-inline-block">
                        <Button
                          style={{ pointerEvents: "none" }}
                          className="mr-2 btn btn-secondary"
                          disabled={_checkCourse}
                          onClick={() => _registerCourse(data && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse)}
                        >
                          ປິດການລົງທະບຽນແລ້ວ
                    </Button>
                      </span>
                    </OverlayTrigger >
                    : ""
          }


          {/* for disable register */}
          <div style={{ display: displayStyle ? "flex" : "none" }}>
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">ການລົງທະບຽນວິຊາແມ່ນປິດແລ້ວ</Tooltip>}>
              <span className="d-inline-block">
                <Button
                  disable
                  style={{
                    backgroundColor: Consts.SECONDARY_COLOR,
                    color: "white",
                    height: 40,
                    border: "1px solid " + Consts.SECONDARY_COLOR,
                    outline: "none",
                    marginRight: 15,
                    cursor: "none"
                  }}
                >
                  ປິດການລົງທະບຽນແລ້ວ
                </Button>
              </span>
            </OverlayTrigger>
          </div>
        </div>

        <div
          style={{
            width: 500,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            paddingBottom: 80,
          }}
        >
          {/* ------ detail box ------ */}
          <div
            style={{
              border: "1px solid #ddd",
              width: 500,
              padding: 20,
              fontSize: 14,
              paddingLeft: 80,
            }}
          >
            <Row>
              <Col>ຊື່ວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {data && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.title
                  ? data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.title
                  : ""}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ລະຫັດວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {data && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.courseCode
                  ? data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.courseCode
                  : ""}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ຈໍານວນຫນ່ວຍກິດ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {data && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.unit
                  ? data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.unit
                  : ""}
              </Col>
            </Row>
          </div>

          {/* -------- ຄະນະແລະສາຂາວິຊາ -------- */}
          {/* <div style={{ padding: 20, paddingBottom: 0 }}>
            <b>ຄະນະ ແລະ ສາຂາວິຊາ</b>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ຄະນະ</Col>
                <Col>{data?.courseAndFacultyAndDepartmentAndMajor?.dataFaculty[0]?.faculty?.name ?? "-"}</Col>
              </Row>
              <Row>
                <Col>ສາຂາວິຊາ</Col>
                <Col>
                  {data &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataDepartment &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataDepartment?.department &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataDepartment[0]?.department.name
                    ? data?.courseAndFacultyAndDepartmentAndMajor?.dataDepartment[0]?.department.name
                    : "-"}
                </Col>
              </Row>
            </div>
          </div> */}

          {/* -------- ປີຮຽນແລະພາກຮຽນ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <b>ປີຮຽນ ແລະ ພາກຮຽນ</b>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ປີຮຽນ</Col>
                <Col>
                  {data && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.year
                    ? data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.year
                    : "-"}
                </Col>
              </Row>
              <Row>
                <Col>ພາກຮຽນ</Col>
                <Col>
                  {data && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.semester
                    ? data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.semester
                    : "-"}
                </Col>
              </Row>
            </div>
          </div>

          {/* -------- ອາຈານສອນ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <b>ອາຈານສອນ</b>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ຊື່ອາຈານສອນ</th>
                  </TableHeader>
                </thead>
                <tbody>
                  {data &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.teacher &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.teacher?.length > 0 &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.teacher?.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {
                            <p> {item?.firstname + ' ' + item?.lastname}</p>
                          }
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* -------- ຕາຕະລາງມື້ສອນ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <b>ຕາຕະລາງມື້ສອນ</b>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ວັນ</th>
                    <th>ຊົ່ວໂມງ</th>
                  </TableHeader>
                </thead>
                <tbody>
                  {data &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.dayTimeIndexes &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.dayTimeIndexes.length > 0 &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.dayTimeIndexes.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {item.day ? _reconvertDay(item.day) : "-"}
                        </TableCell>
                        <TableCell>
                          {item.timeIndexes &&
                            item.timeIndexes.length > 0 &&
                            item.timeIndexes.map(
                              (t, ti) =>
                                parseInt(t) +
                                1 +
                                (ti + 1 >= item.timeIndexes.length ? "" : " - ")
                            )}
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div style={{ padding: 20, paddingBottom: 0 }}>
            <b>ສະຖານທີ່ຮຽນ</b>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>
                  {data && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.addressLearn
                    ? data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.addressLearn
                    : "-"}
                </Col>
              </Row>
            </div>
          </div>

          <div style={{ padding: 20, paddingBottom: 0 }}>
            <b>ຄໍາອະທິບາຍ</b>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>
                  {data && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse && data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.description
                    ? data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.description
                    : "-"}
                </Col>
              </Row>
            </div>
          </div>

          {/* -------- ອັບໂຫລດ Syllabus -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <b>ອັບໂຫລດ Syllabus</b>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ອັບໂຫລດໄຟລ</Col>
                <Col>
                  {data &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.syllabusFile &&
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.syllabusFile.file
                    ? data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.title +
                    "  ( " +
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.syllabusFile.file.split(".")[
                    data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse?.syllabusFile.file.split(".").length - 1
                    ] +
                    " )"
                    : ""}
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* ------- Delete Modal ------ */}
        {/* DeleteConfirm Modal */}
        <RegisterCourse
          showDeleteConfirmView={showRegisterConfirm}
          _handleDeleteConfirmViewClose={_handleRegisterConfirmViewClose}
          courseTitle={registerData}
          confirmRegister={() => _confirmRegister()}
          createStatus={createStatus}
        />
      </CustomContainer>
    </div >
  );
}

export default CourseDetailBrowserView;
