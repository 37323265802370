import React, { useState, useCallback } from 'react'
import BrowserRegisteredCourseDetail from './BrowserRegisteredCourseDetail'
import MobileRegisteredCourseDetail from './MobileRegisteredCourseDetail'
function RegisteredCourseDetail() {
  return (
    <div>
      <BrowserRegisteredCourseDetail/>
      <MobileRegisteredCourseDetail/>
    </div>
  )
}

export default RegisteredCourseDetail
