import React, { useState, useEffect } from "react";
import { USER_KEY } from "../../consts/user";

import axios from "axios";
import "./index.css"
import { API_KEY, NEW_API_URL } from "../../common/constraint";




const TimeListMobile = () => {

  const [studentId, setStudentId] = useState();
  const [faculty, setFaculty] = useState("")
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user) {
      setStudentId(user.studentData.id || "");
    }
  }, []);




  const [dataTable, setDataTable] = useState({})
  const [timeList, setTineList] = useState([])

  const [selectedYear, setSelectedYear] = useState(1); // State to store the selected option
  const [selectedSemester, setSelectedSemester] = useState(1); // State to store the selected option


  const fetchTimeTableData = async () => {
    if (studentId) {
      await axios.get(NEW_API_URL + "grade/" + studentId + "/" + selectedYear + "/" + selectedSemester, {
        headers: {
          api_key: API_KEY
        }
      }).then((res) => {
        // console.log(res);
        if (res.data.success) {
          const data = res.data.data
          // console.log(data);
          setDataTable(data)
        }
        else {
          setDataTable({})
        }
      }).catch((err) => {
        console.log("fetch students")
        console.log(err)
        setDataTable({})
      })
    }
  }




  const fetchStudentInfo = async () => {
    if (studentId) {
      await axios.get(NEW_API_URL + "/student/" + studentId + "/2023 - 2024", {
        headers: {
          api_key: API_KEY
        }
      }).then((res) => {
        if (res.data.success) {
          console.log("time data")
          console.log(res.data)
          setFaculty(res.data.data?.faculty)
        }
      }).catch((err) => {
        // console.log(err)

      })
    }
  }


  const fetchTimeList = async () => {


    if (faculty) {
      await axios.get(NEW_API_URL + "time/" + faculty, {
        headers: {
          api_key: API_KEY
        }
      }).then((res) => {
        if (res.data.success) {
          const data = res.data.data
          setTineList(data)
        }
      }).catch((err) => {
      })

    }
  }


  const [selectedDay, setSelectedDay] = useState("monday")




  useEffect(() => {
//    resetState();
    fetchTimeTableData()
    fetchStudentInfo()
    fetchTimeList()
     // console.log("fetch data again")
  }, [selectedYear, selectedSemester, selectedDay, studentId, faculty])


  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [wednsday, setWednesday] = useState([]);
  const [thursday, setThursday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);
  const [sunday, setSunday] = useState([]);



  const [mondayGrouped, setMondayGrouped] = useState();
  const [tuesdayGrouped, setTuesdayGrouped] = useState();
  const [wednesdayGrouped, setWednesdayGrouped] = useState();
  const [thursdayGrouped, setThursdayGrouped] = useState([]);
  const [fridayGrouped, setFridayGrouped] = useState([]);
  const [saturdayGrouped, setSaturdayGrouped] = useState([]);
  const [sundayGrouped, setSundayGrouped] = useState([]);





  const resetState = () => {
    setMonday([])
    setTuesday([])
    setWednesday([])
    setThursday([])
    setFriday([])
    setSaturday([])
    setSunday([])



    setMondayGrouped([])
    setTuesdayGrouped([])
    setWednesdayGrouped([])
    setThursdayGrouped([])
    setFridayGrouped([])
    setSaturdayGrouped([])
    setSundayGrouped([])
  }

  useEffect(() => {

    if (selectedDay === "" || selectedDay === "monday") {
      setSelectedDay("monday")
    }
    resetState()
    const updatedData = [];

    for (const day in dataTable) {
      if (dataTable.hasOwnProperty(day)) {
        const dayData = dataTable[day];
        const filteredData = dayData.filter(item => item !== null);
        updatedData[day] = filteredData;
      }
    }

    // console.log("-----------");
    // console.log(updatedData);
    // console.log("ppppppppppp");

    const teestData = updatedData

    if (teestData.monday)
      
      for (let h = 1; h <= 8; h++) {
        if (teestData.monday) {
          teestData.monday.map((subjects) => {
            subjects.map((subject) => {
              subject.timesStudy.map((time) => {
                time.times.map((t) => {
                  if (Number(t.hour) === h) {
                    const data = {
                      hour: h,
                      tome: t.time,
                      subject
                    }
                    setMonday(prevMonday => [...prevMonday, data]);
                  }
                })
              })
            })
          })
        }

        teestData.tuesday.map((subjects) => {
          if (subjects) {
            subjects.map((subject) => {
              subject.timesStudy.map((time) => {
                time.times.map((t) => {
                  if (Number(t.hour) === h) {
                    const data = {
                      hour: h,
                      tome: t.time,
                      subject
                    }
                    setTuesday(prevMonday => [...prevMonday, data]);
                  }
                })
              })
            })
          }
        })

        teestData.wednsday.map((subjects) => {
          if (subjects) {
            subjects.map((subject) => {
              subject.timesStudy.map((time) => {
                time.times.map((t) => {
                  if (Number(t.hour) === h) {
                    const data = {
                      hour: h,
                      tome: t.time,
                      subject
                    }
                    setWednesday(prevMonday => [...prevMonday, data]);
                  }
                })
              })
            })
          }
        })

        teestData.thursday.map((subjects) => {
          if (subjects) {
            subjects.map((subject) => {
              subject.timesStudy.map((time) => {
                time.times.map((t) => {
                  if (Number(t.hour) === h) {
                    const data = {
                      hour: h,
                      tome: t.time,
                      subject
                    }
                    setThursday(prevMonday => [...prevMonday, data]);
                  }
                })
              })
            })
          }
        })

        teestData.friday.map((subjects) => {
          if (subjects) {
            subjects.map((subject) => {
              subject.timesStudy.map((time) => {
                time.times.map((t) => {
                  if (Number(t.hour) === h) {
                    const data = {
                      hour: h,
                      tome: t.time,
                      subject
                    }
                    setFaculty(prevMonday => [...prevMonday, data]);
                  }
                })
              })
            })
          }
        })

        teestData.saturday.map((subjects) => {
          if (subjects) {
            subjects.map((subject) => {
              subject.timesStudy.map((time) => {
                time.times.map((t) => {
                  if (Number(t.hour) === h) {
                    const data = {
                      hour: h,
                      tome: t.time,
                      subject
                    }
                    setSaturday(prevMonday => [...prevMonday, data]);
                  }
                })
              })
            })
          }
        })

        teestData.sunday.map((subjects) => {
          if (subjects) {
            subjects.map((subject) => {
              subject.timesStudy.map((time) => {
                time.times.map((t) => {
                  if (Number(t.hour) === h) {
                    const data = {
                      hour: h,
                      tome: t.time,
                      subject
                    }
                    setSunday(prevMonday => [...prevMonday, data]);
                  }
                })
              })
            })
          }
        })
      }


      // console.log("selected year ok :  " + selectedYear)
      // console.log("selected semester ok : " + selectedSemester)
  
  

  }, [dataTable, selectedDay, selectedSemester, selectedYear])


  useEffect(() => {
    // console.log("selected year : " + selectedYear)
    // console.log("selected semester : " + selectedSemester)
    groupByHour()
  }, [monday, tuesday, wednsday, thursday, friday, saturday, sunday])


  const GetContent = ({ days }) => {
    console.log("days : ", days)
    return (
      <div>
        {timeList.map((time, timeIndex) => { // Add an index for the map loop
          const Subject = () => {
            let Components = [];
            for (let i = 0; i < days.length; i++) {
              if (Number(days[i].hour) === Number(time.hour)) {
                const component = (
                  <div className="container p-0 m-0" key={i}>
                    <div className="contanier m-0 p-0">
                      <div className="row w-100">
                        {days[i].subjects.map((s, index) => {
                          if (s) {
                            return (
                              <div key={index} className="container col-12 border-bottom py-2">
                                <div className="col-12">
                                  <h4>{s.title}</h4>
                                </div>
                                <div className="col-12">
                                  <p>{"ສະຖານທິຮຽນ : " + s.addressLearn}</p>
                                </div>
                                <div className="col-12"><p>ອາຈານສອນ : {s.teacher.map((t, index) => <span key={index}>{" " + t.firstname}</span>)}</p></div>
                                <div className="col-12">
                                  <p>{"ຫນ່ວຍກິດ : " + s.unit}</p>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                );
                Components.push(
                  <div key={i}>
                    {component}
                  </div>
                );

                break;
              } else {
                let component = (
                  <div key={`empty-${i}`}>
                    {/* Empty component */}
                  </div>
                );

                Components.push(
                  <div key={i}>
                    {component}
                  </div>
                );
              }
            }

            return (
              <div>
                {Components}
              </div>
            );
          };


          return (
            <div className=" w-100 bg-white my-2 px-2 pt-2" key={`time-${timeIndex}`}>
              <div className="row">
                <div className="col-3  border-bottom w-100">
                  <h3>{time.hour}</h3>
                </div>
                <div className="col-8 border-bottom">
                  <h3>{time.time}</h3>
                </div>
                <Subject />
              </div>
            </div>
          );



        })}
      </div>
    )

  }



  const groupByHour = async () => {


    const groupMonday = monday.reduce((groups, item) => {
      const { hour, tome, subject } = item;

      const key = `${hour}${tome}`;

      if (!groups[key]) {
        groups[key] = { hour, time: tome, subjects: [] };
      }
      groups[key].subjects.push(subject);
      return groups;

    }, {});

    const groupTuesday = tuesday.reduce((groups, item) => {
      const { hour, tome, subject } = item;

      const key = `${hour}${tome}`;

      if (!groups[key]) {
        groups[key] = { hour, time: tome, subjects: [] };
      }

      groups[key].subjects.push(subject);

      return groups;

    }, {});

    const groupWednsday = wednsday.reduce((groups, item) => {
      const { hour, tome, subject } = item;

      const key = `${hour}${tome}`;

      if (!groups[key]) {
        groups[key] = { hour, time: tome, subjects: [] };
      }

      groups[key].subjects.push(subject);

      return groups;

    }, {});

    const groupThursday = thursday.reduce((groups, item) => {
      const { hour, tome, subject } = item;

      const key = `${hour}${tome}`;

      if (!groups[key]) {
        groups[key] = { hour, time: tome, subjects: [] };
      }

      groups[key].subjects.push(subject);

      return groups;

    }, {});

    const groupFiday = friday.reduce((groups, item) => {
      const { hour, tome, subject } = item;

      const key = `${hour}${tome}`;

      if (!groups[key]) {
        groups[key] = { hour, time: tome, subjects: [] };
      }

      groups[key].subjects.push(subject);

      return groups;

    }, {});

    const groupSaturday = saturday.reduce((groups, item) => {
      const { hour, tome, subject } = item;

      const key = `${hour}${tome}`;

      if (!groups[key]) {
        groups[key] = { hour, time: tome, subjects: [] };
      }

      groups[key].subjects.push(subject);

      return groups;

    }, {});

    const groupSunday = sunday.reduce((groups, item) => {
      const { hour, tome, subject } = item;

      const key = `${hour}${tome}`;

      if (!groups[key]) {
        groups[key] = { hour, time: tome, subjects: [] };
      }

      groups[key].subjects.push(subject);

      return groups;

    }, {});



    // Convert the groupedData object into an array of group objects


    setMondayGrouped(Object.values(groupMonday))
    setTuesdayGrouped(Object.values(groupTuesday))
    setWednesdayGrouped(Object.values(groupWednsday))
    setThursdayGrouped(Object.values(groupThursday))
    setFridayGrouped(Object.values(groupFiday))
    setSaturdayGrouped(Object.values(groupSaturday))
    setSundayGrouped(Object.values(groupSunday))

  }



  const onDaySelected = (e) => {

    setDataTable({})
    setSelectedDay(e.target.name)

  }

  const handleYearChange = (event) => {

    console.log("year value")
    console.log(event.target.value)
     setSelectedYear(event.target.value);
  };

  const handleSemesterChange = (event) => {
    setSelectedSemester(event.target.value);
  };





  return (
    <div className="container pt-5 mt-5 mx-0">
      <div className="row">
        <div className="col">
          <select
            value={selectedYear}
            onChange={handleYearChange}
            className="form-select form-select-sm py-2 px-3"
            aria-label="Select Year">
            <option defaultValue>ເລືອກປີຮຽນ</option>
            <option value="1"> 1</option>
            <option value="2"> 2</option>
            <option value="3"> 3</option>
            <option value="4"> 4</option>
            <option value="5"> 5</option>
            <option value="6"> 6</option>
          </select>
        </div>
        <div className="col">
          <select
            value={selectedSemester}
            onChange={handleSemesterChange}
            className="form-select form-select-sm py-2 px-3"
            aria-label="Select Semester">
            <option defaultValue>ເລືອກພາກຮຽນ</option>
            <option value="1"> 1</option>
            <option value="2"> 2</option>
          </select>
        </div>
      </div>


      <div className="row mx-0 px-0 mt-4 mb-3 ">
        <div className="col p-0">
          <button onClick={onDaySelected} name="monday" className={`bt-color ${selectedDay === "monday" ? "bt-active-day" : ""}`}>ຈັນ</button>
        </div>
        <div className="col p-0">
          <button onClick={onDaySelected} name="tuesday" className={`bt-color ${selectedDay === "tuesday" ? "bt-active-day" : ""}`}>ອັງຄານ</button>
        </div>
        <div className="col p-0">
          <button onClick={onDaySelected} name="wednsday" className={`bt-color ${selectedDay === "wednsday" ? "bt-active-day" : ""}`}>ພຸດ</button>
        </div>
        <div className="col p-0">
          <button onClick={onDaySelected} name="thursday" className={`bt-color ${selectedDay === "thursday" ? "bt-active-day" : ""}`}>ພະຫັດ</button>
        </div>
        <div className="col p-0">
          <button onClick={onDaySelected} name="friday" className={`bt-color ${selectedDay === "friday" ? "bt-active-day" : ""}`}>ສຸກ</button>
        </div>
        <div className="col p-0">
          <button onClick={onDaySelected} name="saturday" className={`bt-color ${selectedDay === "saturday" ? "bt-active-day" : ""}`}>ເສົາ</button>
        </div>
        <div className="col p-0">
          <button onClick={onDaySelected} name="sunday" className={`bt-color ${selectedDay === "sunday" ? "bt-active-day" : ""}`}>ອາທິດ</button>
        </div>
      </div>


      {selectedDay === "monday" &&
        <GetContent days={mondayGrouped} />
      }

      {selectedDay === "tuesday" &&
        <GetContent days={tuesdayGrouped} />
      }

      {selectedDay === "wednsday" &&
        <GetContent days={wednesdayGrouped} />
      }

      {selectedDay === "thursday" &&
        <GetContent days={thursdayGrouped} />
      }

      {selectedDay === "friday" &&
        <GetContent days={fridayGrouped} />
      }

      {selectedDay === "saturday" &&
        <GetContent days={saturdayGrouped} />
      }

      {selectedDay === "sunday" &&
        <GetContent days={sundayGrouped} />
      }

    </div>
  );

}


export default TimeListMobile;
