import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { MobileView } from "react-device-detect";
import Moment from "moment";
import useReactRouter from "use-react-router";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { TableHeader, TableCell } from "../../common";

import Consts from "../../consts";
import { GET_COURSE } from "../../apollo/course";
import { MobileHeader } from "../../common/MobileHeader";
import { GRADES, UPGRADE_GRADE, DELETE_GRADE } from "../../apollo/grade";
import { USER_KEY } from "../../consts/user";
import { reconvertDay } from "../../super";
import ConfirmModal from "../../common/ConfirmModal";
import { FILES } from "../../apollo/file";
function MobileRegisteredCourseDetail() {
  const { history, location, match } = useReactRouter();
  const [gradeStatusRQ, setGradeStatusRQ] = useState("");
  const [gradeStatusRJ, setGradeStatusRJ] = useState("");

  const regisid = match.params.regisid;
  const ID = match.params.id;
  const [showRegisterConfirm, setShowRegisterConfirm] = useState(false);
  const [isTrue, setIsTrue] = useState(1);
  const [user, setUser] = useState();
  const [dataFile, setDataFile] = useState([]);

  /**
   * apollo
   */
  const [loadGrades, { data: gradeData }] = useLazyQuery(GRADES);
  // console.log("dk/d/", gradeData)
  const GRADE_STATUS = gradeData?.grades?.data?.map((time) => time.gredeStatus);
  const GRADE_STATUS1 = gradeData?.grades?.data[0]?.gredeStatus;
  const [upgradeGrade] = useMutation(UPGRADE_GRADE);
  const [deleteGrade] = useMutation(DELETE_GRADE);

  const { data } = useQuery(GET_COURSE, {
    variables: { where: { id: ID } },
  });
  const [loadFile, { data: courseFileData }] = useLazyQuery(FILES);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user.studentData.id) {
      setUser(user.studentData);
    }
  }, []);

  useEffect(() => {
    if (ID) {
      loadFile({
        variables: {
          where: { course: ID, isDeleted: false },
        },
      });
    }
  }, [ID]);

  useEffect(() => {
    if (courseFileData) setDataFile(courseFileData?.files?.data);
  }, [courseFileData]);

  useEffect(() => {
    if (user) {
      loadGrades({
        variables: {
          // where: { course: ID, student: user && user.id },
          where: { course: ID, student: user && user.userId },
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (GRADE_STATUS) {
      for (var i = 0; i < GRADE_STATUS.length; i++) {
        if (GRADE_STATUS[i] === "REQUESTING") {
          setGradeStatusRQ("REQUESTING");
        }
        if (GRADE_STATUS[i] === "REJECTED") {
          setGradeStatusRJ("REJECTED");
        }
      }
    }
  }, [gradeData]);
  // console.log(gradeData?.grades?.data[0]?.numberTest)
  // console.log(gradeStatusRQ)
  const _upGrade = async (id) => {
    await upgradeGrade({ variables: { where: { id } } });
    history.push("/upgrade-list");
    window.location.reload();
  };
  const _deleteGrade = async (id) => {
    await deleteGrade({ variables: { where: { id } } });
    history.push("/registered-course-list");
    // window.location.reload();
  };
  const _onTabs = (status) => {
    setIsTrue(status);
    if (status === 1) {
      //   setWhereFile({ where: { type: "PUBLIC_FILE", cate: "RESEARCH" } });
    } else if (status === 2) {
      //   setWhereFile({ where: { type: "PUBLIC_FILE", cate: "GENERAL" } });
    }
  };


  return (<></>)
  return (
    <MobileView>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginTop: 60,
          padding: 20,
          backgroundColor: "white",
          paddingBottom: 60,
        }}
      >
        <MobileHeader
          title={"ຂໍ້ມູນວິຊາ"}
          showLeftArrow={true}
          leftButtonClick={() => history.push("/registered-course-list")}
        />
        {/* ------ detail box ------ */}
        <div
          style={{
            //   border: '1px solid #ddd',
            padding: 20,
            fontSize: 14,
            backgroundColor: "white",
            fontWeight: "bolder",
            boxShadow: "0px 0px 5px lightGray",
          }}
        >
          <Row>
            <Col style={{ fontSize: 16 }}>ຊື່ວິຊາ</Col>
            <Col style={{ color: Consts.FONT_COLOR_PRIMARY, fontSize: 14 }}>
              {data?.course?.title}
            </Col>
          </Row>
          <h1>Hello</h1>
          <div style={{ height: 10 }} />
          <Row>
            <Col style={{ fontSize: 16 }}>ລະຫັດວິຊາ</Col>
            <Col style={{ color: Consts.FONT_COLOR_PRIMARY, fontSize: 14 }}>
              {data?.course?.courseCode}
            </Col>
          </Row>
          <div style={{ height: 10 }} />
          <Row>
            <Col style={{ fontSize: 16 }}>ຈໍານວນຫນ່ວຍກິດ</Col>
            <Col style={{ color: Consts.FONT_COLOR_PRIMARY, fontSize: 14 }}>
              {data?.course?.unit}
            </Col>
          </Row>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <div
            style={{
              cursor: "pointer",
              width: 100,
              marginRight: 40,
              textAlign: "center",
              borderBottom:
                isTrue && isTrue === 1
                  ? `5px solid ${Consts.COLOR_EDIT}`
                  : "none",
            }}
            onClick={() => _onTabs(1)}
          >
            ລາຍລະອຽດວິຊາ
          </div>
          <div
            style={{
              cursor: "pointer",
              width: 100,
              marginRight: 40,
              textAlign: "center",
              borderBottom:
                isTrue && isTrue === 2
                  ? `5px solid ${Consts.COLOR_EDIT}`
                  : "none",
            }}
            onClick={() => _onTabs(2)}
          >
            ເອກະສານວິຊາ
          </div>
        </div>
        {isTrue === 1 ? (
          <div>
            {/* -------- ປີຮຽນແລະພາກຮຽນ -------- */}
            <div style={{ paddingBottom: 20 }}>
              <div style={{ fontWeight: "bolder" }}>ປີຮຽນ ແລະ ພາກຮຽນ</div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                <Row>
                  <Col>ປີຮຽນ</Col>
                  <Col>{data?.course?.year}</Col>
                </Row>
                <Row>
                  <Col>ພາກຮຽນ</Col>
                  <Col>{data?.course?.semester}</Col>
                </Row>
              </div>
            </div>
            {/* -------- ອາຈານສອນ -------- */}
            <div style={{ paddingBottom: 20 }}>
              <b>ອາຈານສອນ</b>
              <div style={{ fontSize: 14 }}>
                <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                  <thead>
                    <TableHeader>
                      <th style={{ width: 60 }}>ລຳດັບ</th>
                      <th>ຊື່ອາຈານສອນ</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {data?.course?.teacher?.length > 0 &&
                      data?.course?.teacher?.map((item, index) => (
                        <tr
                          style={{
                            borderBottom: "2px solid #ffff",
                            textAlign: "center",
                          }}
                          key={index}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {
                              <span>
                                {item.firstname +
                                  " " +
                                  (item.lastname ? item.lastname : "")}
                              </span>
                            }
                          </TableCell>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* -------- ຕາຕະລາງມື້ສອນ -------- */}
            <div>
              <div style={{ fontWeight: "bolder" }}>ຕາຕະລາງມື້ສອນ</div>
              <div>
                <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                  <thead>
                    <TableHeader>
                      <th style={{ width: 60 }}>ລຳດັບ</th>
                      <th>ວັນ</th>
                      <th>ຊົ່ວໂມງ</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {data?.course?.dayTimeIndexes?.length > 0 &&
                      data?.course?.dayTimeIndexes?.map((item, index) => (
                        <tr
                          style={{
                            borderBottom: "2px solid #ffff",
                            textAlign: "center",
                          }}
                          key={index}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {item?.day ? reconvertDay(item.day) : "-"}
                          </TableCell>
                          <TableCell>
                            {item?.timeIndexes?.length > 0 &&
                              item?.timeIndexes?.map(
                                (t, ti) =>
                                  parseInt(t) +
                                  1 +
                                  (ti + 1 >= item?.timeIndexes?.length
                                    ? ""
                                    : " - ")
                              )}
                          </TableCell>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ paddingTop: 20 }}>
              <div style={{ fontWeight: "bolder" }}>ສະຖານທີ່ຮຽນ</div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                <Row>
                  <Col>{data?.course?.addressLearn}</Col>
                </Row>
              </div>
            </div>

            <div style={{ paddingTop: 20 }}>
              <div style={{ fontWeight: "bolder" }}>ຄໍາອະທິບາຍ</div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                <Row>
                  <Col>{data?.course?.description}</Col>
                </Row>
              </div>
            </div>
            {/* -------- ອັບໂຫລດ Syllabus -------- */}
            <div style={{ paddingTop: 20, paddingBottom: 80 }}>
              <div style={{ fontWeight: "bolder" }}>ອັບໂຫລດ Syllabus</div>
              <div style={{ fontSize: 14 }}>
                <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                  <thead>
                    <TableHeader>
                      <th style={{ width: 60 }}>ລຳດັບ</th>
                      <th>ຊື່ໄຟສ</th>
                      <th>ດາວໂຫລດ</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                    >
                      <TableCell>{1}</TableCell>
                      <TableCell>
                        {data?.course?.syllabusFile?.title ?? "-"}
                      </TableCell>
                      <TableCell>
                        {data?.course?.syllabusFile?.file.split(".")[4] !==
                        "pdf" ? (
                          <a href={data?.course?.syllabusFile?.file} download>
                            {data?.course?.syllabusFile?.title ?? "-"}
                          </a>
                        ) : (
                          <u
                            onClick={() =>
                              history.push(
                                `/registered-course-detail/${data?.course?.id}/${regisid}/pdf`,
                                data?.course?.syllabusFile?.file
                              )
                            }
                          >
                            {data?.course?.syllabusFile?.title ?? "ບໍ່ມີຊື່"}
                          </u>
                        )}
                      </TableCell>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: 10 }}>
            {dataFile?.map((x, index) => {
              return (
                <div
                  style={{
                    flex: 1,
                    color: Consts.FONT_COLOR_SECONDARY,
                    padding: 10,
                    marginBottom: 10,
                    borderTop: "1px solid gray",
                  }}
                  key={index}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      <p>{Moment.utc(data?.createdAt).format("YYYY/MM/DD")}</p>
                    </div>
                    <div>
                      <a href={x?.file}>
                        <p>
                          {x?.title} ({" "}
                          {x?.file?.split(".")[x?.file?.split(".").length - 1]}{" "}
                          )
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {/* ------- Register Modal ------ */}
      {/* RegisterConfirm Modal */}
      <ConfirmModal
        title="ຢືນຢັນການລົງທະບຽນແກ້ເກຣດ"
        courseTitle={data?.course?.title}
        handleConfirm={() => _upGrade(gradeData?.grades?.data[0]?.id)}
        show={showRegisterConfirm}
        handleClose={() => setShowRegisterConfirm(false)}
      />
      {/* _upGrade(gradeData?.grades?.data[0]?.id) */}
      {GRADE_STATUS1 === "REQUESTING" &&
      gradeData?.grades?.data[0]?.numberTest === 1 ? (
        <span>
          <Button
            onClick={() => {
              _deleteGrade(gradeData.grades.data[0].id);
            }}
            style={{
              backgroundColor: Consts.COLOR_DELETE,
              width: "100%",
              position: "fixed",
              bottom: 0,
              border: 0,
              borderRadius: 0,
              height: 50,
            }}
          >
            ຍົກເລີກການລົງທະບຽນ
          </Button>
        </span>
      ) : data?.course?.isUpgrade === false ? (
        <Button
          disabled
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ປິດການລົງທະບຽນແກ້ເກຣດ
        </Button>
      ) : data?.course?.isUpgrade === true && gradeStatusRQ === "REQUESTING" ? (
        <Button
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລົງທະບຽນແກ້ເກຣດແລ້ວ
        </Button>
      ) : data?.course?.isUpgrade === true && gradeStatusRJ === "REJECTED" ? (
        <Button
          onClick={() => setShowRegisterConfirm(true)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລົງທະບຽນແກ້ເກຣດ
        </Button>
      ) : (
        <Button
          onClick={() => setShowRegisterConfirm(true)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລົງທະບຽນແກ້ເກຣດ
        </Button>
      )}
    </MobileView>
  );
}

export default MobileRegisteredCourseDetail;
