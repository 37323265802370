import React, { useState } from "react";
import { MobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import Consts from "../../consts";
import { OLD_STUDENT } from "./const";
import { convertName, convertNameE } from "./common";
import "./index.css";
const OldStudentClubDetail = () => {
  const { history, match } = useReactRouter();
  const id = match.params.id;
  const [key, setkey] = useState();

  return (
    <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
      {OLD_STUDENT.filter((_data) => _data?.id === id).map((item, index) => (
        <div key={index}>
          <MobileHeader
            style={{ backgroundColor: "transparent", boxShadow: 0 }}
            showLeftArrow
            leftButtonClick={() => history.push("/graduated-student-club-list")}
          />
          <div
            style={{ backgroundColor: Consts.PRIMARY_COLOR, minHeight: 279 }}
            className="text-center pl-3 pr-3 pb-3"
          >
            <img
              src={item.img ? item.img : "assets/wallpaper-image.png"}
              alt=""
              className="imageDetail"
            />
            <h5 className="mt-2" style={{ color: Consts.FONT_COLOR_WHITE }}>
              {convertName(item.gender, item.firstnameL, item.lastnameL)}
            </h5>
            <h5 className="mt-2" style={{ color: Consts.FONT_COLOR_WHITE }}>
              {convertNameE(item.gender, item.firstnameE, item.lastnameE)}
            </h5>
          </div>
          <div className="pt-4 pb-3 pl-3 pr-3">
            <label>
              <b>ຂໍ້ມູນສ່ວນຕົວ</b>
            </label>
            <p>
              {convertName(item.gender, item.firstnameL, item.lastnameL)}
            </p>
            <p>ສິດເກົ່າຈາກ ຄະນະ{item.faculty}</p>
            <p>ສາຂາວິຊາ {item.department}</p>
            <p>ນັກສຶກສາຮຸ່ນທີ {item.general}</p>
            <p>ປັດຈຸບັນເຮັດວຽກທີ່: {item.workAt}</p>
            <label className="pt-3">
              <b>ຂໍ້ມູນຕິດຕໍ່</b>
            </label>
            <p>ເບີໂທຕິດຕໍ່: {item.phonemobile}</p>
            <p>WhatsApp: {item.whatsapp}</p>
            <p>Email: {item.email}</p>
            <p>Facebook: {item.facebook}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default OldStudentClubDetail;
