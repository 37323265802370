import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import DocSearch from "./DocSearch";
import Loading from "../../common/Loading";
import Consts from "../../consts";
import Router from "../../Routes";
import {
  CustomContainer,
  SearchBar,
  Title,
  TableHeader,
  TableCell,
} from "../../common";
import { FILES } from "../../apollo/file";
import { FILE_BY_KEYWORD } from "../../apollo/fileByKeyword";
import { formatDateTime, noticeStatus } from "../../super";
import { NOTICS } from "../../apollo/notic";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";

const DocListBrowser = () => {
  // router ------------------------------------------------------------->
  const { history } = useReactRouter();

  // Varible ------------------------------------------------------------->
  let DATENOW =
    new Date().getFullYear() +
    "-" +
    (new Date().getMonth() + 1) +
    "-" +
    new Date().getDate();

  // states ------------------------------------------------------------->
  const [showSearchView, setShowSearchView] = useState(false);
  const [startDay, setStartDay] = useState(false);
  const [endDay, setEndDay] = useState(false);
  const [createdName, setCreatedName] = useState("");
  const [isTrue, setIsTrue] = useState(1);
  const [title, setTitle] = useState("ເອກະສານທັງຫມົດ");
  const [whereFile, setWhereFile] = useState({
    type: "PUBLIC_FILE",
    cate: "RESEARCH",
    isDeleted: false
  });
  const [fileDatas, setFileDatas] = useState([]);
  const [keywordInput, setKeywordInput] = useState("");
  const [dataNotics, setDataNotics] = useState([])

  // apollo ------------------------------------------------------------->
  const [loadData, { loading, called, data }] = useLazyQuery(FILES);

  const [getNotics, { loading: noticLoading, data: noticData }] = useLazyQuery(
    NOTICS,
    { fetchPolicy: "network-only" }
  );

  // use ---------------------------------------------------------------->
  useEffect(() => {
    loadData({
      variables: {
        where: { type: "PUBLIC_FILE", cate: "RESEARCH", isDeleted: false },
      },
    });
  }, []);

  useEffect(() => {
    if(noticData) {
      setDataNotics(noticData?.notics?.data)
    }
  }, [noticData])

  useEffect(() => {
    if (data?.files?.data) {
      setFileDatas(data?.files?.data);
    }
  }, [data]);

  useEffect(() => {
    loadData({
      variables: { where: whereFile },
    });
  }, [whereFile]);

  // clear search
  const _clearSearch = () => {
    history.push("/doc-list");
  };
  // Search
  const _onKeyCreatedName = (e) => {
    if(e.key === "Enter") {
      setCreatedName(e.target.value)
      setStartDay(null)
      setEndDay(null)
    }
  }
  const _selectStartDate = (e) => {
    const date = new Date(e.target.value);
    setStartDay(date)
  }

  const _selectEndDate = (e) => {
    const date = new Date(e.target.value);
    setEndDay(date)
  }
  useEffect(() => {
    let qlWhere = whereFile
    
    if(createdName) {
      qlWhere = { ...qlWhere, createdBy: {firstname: createdName} }
    }
    if(startDay && endDay) {
      setCreatedName("")
      qlWhere = { ...qlWhere, createdAt_gte: startDay, createdAt_lt: endDay }
    }
    
    loadData({
      variables: {
        where: qlWhere
      }
    })
  }, [createdName, startDay, endDay])

  // go to detail
  const _onDocDetail = (id) => {
    history.push(`/documents/${id}`);
  };

  // Filter
  const filterData = () => {
    if (fileDatas?.length > 0) {
      if (whereFile.cate) {
        return fileDatas.filter(
          (i) =>
            i.type.toLowerCase().includes(whereFile.type.toLowerCase()) &&
            i.cate.toLowerCase().includes(whereFile.cate.toLowerCase())
        );
      } else {
        return fileDatas;
      }
    } else {
      return fileDatas;
    }
  };
  const _onTabs = (status) => {
    setIsTrue(status);
    if (status === 1) {
      setDataNotics([]);
      setWhereFile({ type: "PUBLIC_FILE", cate: "RESEARCH", isDeleted: false });
    } else if (status === 2) {
      setDataNotics([]);
      setWhereFile({ type: "PUBLIC_FILE", cate: "GENERAL", isDeleted: false });
    } else if (status === 3) {
      setDataNotics([]);
      setWhereFile({ type: "PUBLIC_FILE", cate: "SPECIFIC", isDeleted: false });
    } else if (status === 4) {
      getNotics({
        variables: {
          where: {
            type: "NEWS", isPublish: true,
          },
          orderBy: "createdAt_DESC",
        }
      });
    } else {
      setWhereFile({ type: "PUBLIC_FILE", cate: "RESEARCH", isDeleted: false });
    }
  };
  if (loading) return <Loading />;
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/doc-list")}>
          ເອກະສານ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ເອກະສານທັງຫມົດ</Breadcrumb.Item>
      </Breadcrumb>
      {/* <div style={{ display: "flex"}}>
        <div className="menuItemTap active-menuTap" onClick={() => history.push("/doc-list")}> ເອກະສານທັງໝົດ </div>
        <div className="menuItemTap not-active-menuTap" 
        onClick={() => history.push("/document-lists")}> ບົດຄົ້ນຄວ້າຕ່າງປະເທດ </div>
      </div> */}
      <CustomContainer>
        <Title text={title} />
        {keywordInput && <span style={{ fontSize: 18 }}>{keywordInput}</span>}

        {/* custom search button */}
        <div className="row">
          <div className="col-4">
            <div style={{ color: Consts.PRIMARY_COLOR, paddingTop: 20, paddingBottom: 10, fontWeight:"bold" }}>
              ຄົ້ນຫາຊື່ຜູ້ສ້າງ
            </div>
            <input
              type="text"
              name="createdName"
              placeholder="ປ້ອນຊື່ຜູ້ສ້າງແລ້ວ Enter"
              className="form-control"
              defaultValue={createdName}
              onKeyDown={(e) => _onKeyCreatedName(e)}
              style={{
                width: "100%",
                height: 35,
                borderRadius: 15,
                paddingLeft: 15,
                outline: "none",
                border: "none",
                backgroundColor: "#f1f1f1",
                color: Consts.FONT_COLOR_SECONDARY,
              }}
            />
          </div>
          <div className="col-4">
            <div style={{ color: Consts.PRIMARY_COLOR, paddingTop: 20, paddingBottom: 10, fontWeight:"bold" }}>
              ວັນທີ
            </div>
            <InputBase
              id="date"
              type="date"
              name="startDate"
              defaultValue={moment(startDay).format("YYYY-MM-DD")}
              placeholder="ປ້ອນຊື່ຜູ້ສ້າງ"
              className="form-control"
              onChange={(e) => _selectStartDate(e)}
              style={{
                width: "100%",
                height: 35,
                borderRadius: 15,
                paddingLeft: 15,
                outline: "none",
                border: "none",
                backgroundColor: "#f1f1f1",
                color: Consts.FONT_COLOR_SECONDARY,
              }}
            />
          </div>
          <div className="col-4">
            <div style={{ color: Consts.PRIMARY_COLOR, paddingTop: 20, paddingBottom: 10, fontWeight:"bold" }}>
              ວັນທີ
            </div>
            <InputBase
              id="date"
              type="date"
              name="endDate"
              defaultValue={moment(endDay).format("YYYY-MM-DD")}
              placeholder="ປ້ອນຊື່ຜູ້ສ້າງ"
              className="form-control"
              onChange={(e) => _selectEndDate(e)}
              style={{
                width: "100%",
                height: 35,
                borderRadius: 15,
                paddingLeft: 15,
                outline: "none",
                border: "none",
                backgroundColor: "#f1f1f1",
                color: Consts.FONT_COLOR_SECONDARY,
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: 20, textAlign: "right" }}>
          <button
            className="btn btn-outline-primary"
            onClick={() => _clearSearch()}
            // style={{float: "right"}}
          >
            {/* <FontAwesomeIcon icon="close" size="1x" /> */}
             X ລ້າງການຄົ້ນຫາ
          </button>
        </div>
        <hr />

        {/* muad */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 30,
          }}
        >
          <div style={{ width: 80, color: Consts.PRIMARY_COLOR }}>ຫມວດ</div>
          <div
            className="menuItem"
            style={{
              cursor: "pointer",
              width: 150,
              marginRight: 40,
              textAlign: "center",
              borderBottom:
                isTrue && isTrue === 1 ? "5px solid #7BB500" : "none",
            }}
            onClick={() => _onTabs(1)}
          >
            ບົດຈົບຊັ້ນ / ບົດຄົ້ນຄ້ວາ
          </div>
          <div
            className="menuItem"
            style={{
              cursor: "pointer",
              width: 100,
              marginRight: 40,
              textAlign: "center",
              borderBottom:
                isTrue && isTrue === 2 ? "5px solid #7BB500" : "none",
            }}
            onClick={() => _onTabs(2)}
          >
            ຄວາມຮູ້ທົ່ວໄປ
          </div>
          <div
            style={{
              cursor: "pointer",
              width: 140,
              borderBottom:
                isTrue && isTrue === 3 ? "5px solid #7BB500" : "none",
            }}
            onClick={() => _onTabs(3)}
          >
            ເອກະສານວິຊາສະເພາະ
          </div>
          <div
            style={{
              cursor: "pointer",
              width: 90,
              textAlign: "center",
              borderBottom:
                isTrue && isTrue === 4 ? "5px solid #7BB500" : "none",
            }}
            onClick={() => _onTabs(4)}
          >
            ໜັງສືພີມ
          </div>
        </div>

        {/* ເອກະສານທັງຫມົດ */}
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          ທັງຫມົດ {dataNotics?.length ? dataNotics?.length : filterData()?.length ?? "0"}
        </div>

        {/* Table list */}
        {isTrue && isTrue !== 4 ?
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader style={{ backgroundColor: "#057CAE" }}>
                <th style={{ width: 80 }}>ລຳດັບ</th>
                <th>ຊື່ເອກະສານ</th>
                <th>ສ້າງ</th>
                <th>ອັບເດດ</th>
                <th style={{ width: 220 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {filterData()?.length > 0 &&
                filterData()?.map((file, index) => {
                  return (
                    <tr
                      style={{
                        border: "2px solid white",
                      }}
                      key={index}
                    >
                      <TableCell
                        style={{
                          backgroundColor: "#EEEEEE",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#EEEEEE" }}>
                        {file?.title}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#EEEEEE",
                          textAlign: "center",
                        }}
                      >
                        <span>{formatDateTime(file?.createdAt)}</span>
                        <p>
                          (ໂດຍ:{" "}
                          {
                            (file?.createdBy?.firstname ?? "") +
                            " " +
                            (file?.createdBy?.lastname ?? "")
                            }
                          )
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#EEEEEE",
                          textAlign: "center",
                        }}
                      >
                        <span>{formatDateTime(file?.updatedAt)}</span>
                        <p>
                          (ໂດຍ:{" "}
                          {(file?.updatedBy?.firstname ?? "") +
                            " " +
                            (file?.updatedBy?.lastname ?? "")}
                          )
                        </p>
                      </TableCell>
                      <TableCell
                        style={{ width: 200, backgroundColor: "#EEEEEE" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: 200,
                          }}
                        >
                          <a href={file?.file}>
                            {["top"].map((placement) => (
                              <OverlayTrigger
                                placement={placement}
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    ດາວໂຫລດເອກະສານ
                                  </Tooltip>
                                }
                              >
                                <span className="d-inline-block">
                                  <button
                                    style={{
                                      width: 80,
                                      height: 30,
                                      borderRadius: 3,
                                      border: "1px solid #ddd",
                                      outline: "none",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <i
                                      style={{ color: Consts.COLOR_EDIT }}
                                      className="fa fa-download"
                                    />
                                  </button>
                                </span>
                              </OverlayTrigger>
                            ))}
                          </a>
                          {["top"].map((placement) => (
                            <OverlayTrigger
                              placement={placement}
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  ເບິ່ງລາຍລະອຽດ
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <button
                                  onClick={() => _onDocDetail(file?.id)}
                                  style={{
                                    width: 60,
                                    height: 30,
                                    borderRadius: 3,
                                    border: "1px solid #ddd",
                                    outline: "none",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={["fa", "eye"]}
                                    color={Consts.COLOR_EDIT}
                                  />
                                </button>
                              </span>
                            </OverlayTrigger>
                          ))}
                        </div>
                      </TableCell>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          :
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader>
                <th style={{ width: 80 }}>ລຳດັບ</th>
                <th style={{ width: 110, alignContent: "center" }}>ຮູບພາບ</th>
                <th>ຫົວຂໍ້</th>
                <th style={{ width: 150 }}>ປະເພດ</th>
                <th>ສ້າງ</th>
                <th>ອັບເດດ</th>
                <th style={{ width: 200 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {dataNotics?.length > 0 &&
                dataNotics.map((x, index) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <TableCell style={{ width: 70 }}>{index + 1}</TableCell>
                      <TableCell>
                        <img
                          src={
                            x.coverImage?.url
                              ? x.coverImage?.url
                              : "/assets/wallpaper-image.png"
                          }
                          // src="assets/wallpaper-image.png"
                          className="box-image"
                        />
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", paddingLeft: 10 }}
                      >
                        <a
                          // className="title"
                          style={{
                            overflowX: "hidden",
                            overflowY: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontWeight: "bold",
                          }}
                        >
                          {x?.title}
                        </a>
                      </TableCell>
                      <TableCell> {noticeStatus(x.type)}</TableCell>
                      <TableCell style={{ width: 200 }}>
                        {formatDateTime(x.createdAt)}
                        <br />( ໂດຍ: {x?.createdBy?.firstname}{" "}
                        {x?.createdBy?.lastname ? x?.createdBy?.lastname : ""} )
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        {formatDateTime(x.updatedAt)}
                        <br />( ໂດຍ: {x?.updatedBy?.firstname}
                        {x?.updatedBy?.lastname ? x?.updatedBy?.lastname : ""} )
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: 200,
                          }}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip">ເບິ່ງລາຍລະອຽດ</Tooltip>
                            }
                          >
                            <span className="d-inline-block">
                              <div
                                onClick={() =>
                                  history.push(
                                    "./notice-detail/" + x.id + "/" + x.type
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#FFFFFF",
                                  padding: 3,
                                  width: 64,
                                  borderRadius: 4,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={["fas", "eye"]}
                                  color="#7BB500"
                                />
                              </div>
                            </span>
                          </OverlayTrigger>
                        </div>
                      </TableCell>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        }
      </CustomContainer>
      
    </div>
  );
};

export default DocListBrowser;
