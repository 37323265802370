import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import RegisterCourseMobileView from "./RegisterCourseMobileView";
import { CustomContainer, TableHeader, TableCell } from "../../common";
import Loading from "../../common/Loading";
import CourseDocListMobileView from "./CourseDocListMobileView";
import Consts from "../../consts";
import { USER_KEY } from "../../consts/user";
import { GET_COURSE } from "../../apollo/course";
import { GRADE_REGITRATIONS } from "../../apollo/registration";
import { CREATE_GRADE } from "../../apollo/grade";
import { STUDENT } from "../../apollo/user";
import CourseDetailV2 from "../registeredCourseDetail/CourseDetailV2";
function CourseDetail() {
  const { history, match } = useReactRouter();
  const { courseId } = match.params;

  // states ------------------------------------------------------------>
  const [loginUserData, setLoginUserData] = useState();
  const [registerData, setRegisterData] = useState("");
  const [showRegisterConfirm, setShowRegisterConfirm] = useState(false);
  const [number, setNumber] = useState(1);
  const [isEnable, setIsEnable] = useState(false);
  const [facultyId, setFacultyId] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [majorId, setMajorId] = useState();
  const [classroomId, setClassroomId] = useState();
  let [createStatus, setCreateStatus] = useState("");

  // apollo ------------------------------------------------------------>
  const [loadDataStudent, { data: dataStudent }] = useLazyQuery(STUDENT);
  const { loading, data } = useQuery(GET_COURSE, {
    variables: { where: { id: courseId ? courseId : "" } },
  });
  const {
    loading: registeredLoading,
    error: registeredError,
    data: registeredData,
  } = useQuery(GRADE_REGITRATIONS, {
    variables: {
      where: {
        course: courseId ? courseId : "",
        student: loginUserData?.userId ? loginUserData?.userId : "",
      },
    },
  });

  // const [registerCourse] = useMutation(CREATE_REGISTRATION);
  const [createGrade] = useMutation(CREATE_GRADE);
  // use --------------------------------------------------------------->
  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY);
    if (userData) {
      const user = JSON.parse(userData);
      setLoginUserData(user?.studentData);
      loadDataStudent({
        variables: {
          where: { id: user?.studentData?.id },
        },
      });
    }
  }, []);
  // console.log("USER_KEY", registeredData);

  useEffect(() => {
    if (dataStudent) {
      setFacultyId(dataStudent?.student?.faculty?.id);
      setDepartmentId(dataStudent?.student?.department?.id);
      setMajorId(dataStudent?.student?.major?.id);
      setClassroomId(dataStudent?.student?.classRoom?.id);
    }
  }, [dataStudent]);

  const DATENOW = moment(new Date()).format("YYYY-MM-DD");

  //faculty
  const STARTDATEREGISTER = moment(
    data?.course?.department?.faculty?.startDateRegister
  ).format("YYYY-MM-DD");
  const ENDDATEREGISTER = moment(
    data?.course?.department?.faculty?.endDateRegister
  ).format("YYYY-MM-DD");
  // DEPARTMENT
  const startDepartmentRegidter = moment(
    data?.course?.department?.startDateRegister
  ).format("YYYY-MM-DD");
  const endDepartmentRegidter = moment(
    data?.course?.department?.endDateRegister
  ).format("YYYY-MM-DD");

  useEffect(() => {
    if (STARTDATEREGISTER <= DATENOW && DATENOW <= ENDDATEREGISTER) {
      if (
        startDepartmentRegidter <= DATENOW &&
        DATENOW <= endDepartmentRegidter
      ) {
        if (data?.course?.isRegistration === true) {
          setIsEnable(true);
        } else {
        }
      } else {
      }
    } else {
    }
  }, [data]);

  // Set states
  const _handleRegisterConfirmViewClose = () => setShowRegisterConfirm(false);
  const _handleRegisterConfirmViewShow = () => setShowRegisterConfirm(true);

  const _courseDetail = () => {
    setNumber(1);
  };
  const _courseDocument = () => {
    setNumber(2);
  };
  const _registerCourse = (data) => {
    setRegisterData(data);
    _handleRegisterConfirmViewShow();
  };
  const _confirmRegister = async () => {
    let status = false;
    let count = 0;
    if (
      registeredData?.registrations &&
      registeredData?.registrations?.length > 0
    ) {
      for (var i = 0; i < registeredData.registrations.length; i++) {
        if (
          registeredData.registrations[i]?.course?.year === data?.course?.year
        ) {
          if (
            registeredData.registrations[i]?.course?.semester ==
            data?.course?.semester
          ) {
            if (
              registeredData.registrations[i]?.course?.dayTimeIndexes?.length >
                0 &&
              data?.course?.dayTimeIndexes?.length > 0
            ) {
              let regisDayTimes =
                registeredData.registrations[i].course.dayTimeIndexes;
              let courseDayTimes = data.course.dayTimeIndexes;
              if (
                _checkCourseDayTimes(regisDayTimes, courseDayTimes) === true
              ) {
                count++;
              }
            }
          }
        }
      }
    }
    if (count > 0) {
      status = true;
    }
    if (status === false) {
      setCreateStatus("");
      let param = {
        data: {
          studentId: loginUserData?.id,
          courseId: courseId,
          faculty: facultyId,
          department: departmentId,
          major: majorId,
          classRoom: classroomId,
        },
      };
      await createGrade({ variables: param });
      history.push("/registered-course-list");
      window.location.reload(true);
    } else {
      setCreateStatus("ມີຊົ່ວໂມງຮຽນທີ່ຕຳກັນ");
    }
  };
  const _checkCourseDayTimes = (regisDayTimes, courseDayTimes) => {
    let status = false;
    let count = 0;
    for (var i = 0; i < regisDayTimes.length; i++) {
      if (regisDayTimes[i].day) {
        for (var j = 0; j < courseDayTimes.length; j++) {
          if (courseDayTimes[j].day) {
            if (regisDayTimes[i].day === courseDayTimes[j].day) {
              regisDayTimes[i].timeIndexes.filter((times, index) => {
                if (courseDayTimes[j].timeIndexes.includes(times) === true) {
                  count++;
                }
              });
            }
          }
        }
      }
    }
    if (count > 0) {
      status = true;
    }
    return status;
  };

  const _reconvertDay = (day) => {
    let result = "";
    switch (day) {
      case "MONDAY":
        result = "ຈັນ";
        break;
      case "TUESDAY":
        result = "ອັງຄານ";
        break;
      case "WEDNESDAY":
        result = "ພຸດ";
        break;
      case "THURSDAY":
        result = "ພະຫັດ";
        break;
      case "FRIDAY":
        result = "ສຸກ";
        break;
      case "SATURDAY":
        result = "ເສົາ";
        break;
      case "SUNDAY":
        result = "ອາທິດ";
        break;
      default:
        result = "ຈັນ";
    }
    return result;
  };

  if (loading || registeredLoading) return <Loading />;
  // if (error || registeredError) return <h1>GraphQL error</h1>;

  return (
    <div>
      <CourseDetailV2 courseId={courseId} />
      {registeredData?.grades?.data[0]?.gredeStatus === "REQUESTING" ? (
        <Button
          disabled
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລໍຖ້າການຢືນຢັນ
        </Button>
      ) : registeredData?.grades?.data[0]?.gredeStatus === "APROVED" ? (
        <Button
          disabled
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລົງທະບຽນວິຊາແລ້ວ
        </Button>
      ) : registeredData?.grades?.data[0]?.gredeStatus === "FINISHED" ? (
        <Button
          disabled
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລົງທະບຽນວິຊາແລ້ວ
        </Button>
      ) : isEnable === true ? (
        <Button
          disabled={false}
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລົງທະບຽນວິຊາ
        </Button>
      ) : isEnable === false ? (
        <Button
          disabled
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ປິດການລົງທະບຽນແລ້ວ
        </Button>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div style={{ padding: 30, marginTop: 50 }}>
        <div
          style={{
            padding: 20,
            fontSize: 14,
            backgroundColor: "white",
            fontWeight: "bolder",
            boxShadow: "0px 0px 5px lightGray",
          }}
        >
          <Row>
            <Col style={{ fontSize: 16 }}>ຊື່ວິຊາ</Col>
            <Col style={{ color: Consts.FONT_COLOR_PRIMARY, fontSize: 14 }}>
              {data?.course?.title ?? "-"}
            </Col>
          </Row>
          <div style={{ height: 10 }} />
          <Row>
            <Col style={{ fontSize: 16 }}>ລະຫັດວິຊາ</Col>
            <Col style={{ color: Consts.FONT_COLOR_PRIMARY, fontSize: 14 }}>
              {data?.course?.courseCode ?? "-"}
            </Col>
          </Row>
          <div style={{ height: 10 }} />
          <Row>
            <Col style={{ fontSize: 16 }}>ຈໍານວນຫນ່ວຍກິດ</Col>
            <Col style={{ color: Consts.FONT_COLOR_PRIMARY, fontSize: 14 }}>
              {data?.course?.unit ?? "-"}
            </Col>
          </Row>
        </div>
      </div>
      <CustomContainer>
        <div>
          {/* -------- ຄະນະແລະສາຂາວິຊາ -------- */}
          {number && number === 1 ? (
            <div style={{ backgroundColor: "transparent", marginTop: -20 }}>
              {/* <div style={{ fontWeight: "bolder" }}>ຄະນະ ແລະ ສາຂາວິຊາ</div>
              <div style={{ fontSize: 14, marginLeft: 10 }}>
                <Row>
                  <Col>ຄະນະ</Col>
                  <Col>{data?.course?.faculty?.name ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ສາຂາວິຊາ</Col>
                  <Col>{data?.course?.department?.name ?? "-"}</Col>
                </Row>
              </div> */}

              {/* -------- ປີຮຽນແລະພາກຮຽນ -------- */}
              <div style={{ paddingTop: 14 }}>
                <div style={{ fontWeight: "bolder" }}>ປີຮຽນ ແລະ ພາກຮຽນ</div>
                <div style={{ fontSize: 14, marginLeft: 10 }}>
                  <Row>
                    <Col>ປີຮຽນ</Col>
                    <Col>{data?.course?.year ?? "-"}</Col>
                  </Row>
                  <Row>
                    <Col>ພາກຮຽນ</Col>
                    <Col>{data?.course?.semester ?? "-"}</Col>
                  </Row>
                </div>
              </div>

              {/* -------- ອາຈານສອນ -------- */}
              <div style={{ paddingBottom: 14, paddingTop: 14 }}>
                <b>ອາຈານສອນ</b>
                <div style={{ fontSize: 14 }}>
                  <table
                    border="1"
                    bordercolor="#fff"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <TableHeader>
                        <th style={{ width: 60 }}>ລຳດັບ</th>
                        <th>ຊື່ອາຈານສອນ</th>
                      </TableHeader>
                    </thead>
                    <tbody>
                      {data &&
                        data?.course &&
                        data?.course?.teacher &&
                        data?.course?.teacher?.length > 0 &&
                        data?.course?.teacher?.map((item, index) => (
                          <tr
                            style={{
                              borderBottom: "2px solid #ffff",
                              textAlign: "center",
                            }}
                            key={index}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {<p> {item?.firstname + " " + item?.lastname}</p>}
                            </TableCell>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* -------- ຕາຕະລາງມື້ສອນ -------- */}
              <div>
                <div style={{ fontWeight: "bolder" }}>ຕາຕະລາງມື້ສອນ</div>
                <div>
                  <table
                    border="1"
                    bordercolor="#fff"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <TableHeader>
                        <th style={{ width: 60 }}>ລຳດັບ</th>
                        <th>ວັນ</th>
                        <th>ຊົ່ວໂມງ</th>
                      </TableHeader>
                    </thead>
                    <tbody>
                      {data?.course?.dayTimeIndexes?.length > 0 &&
                        data?.course?.dayTimeIndexes?.map((item, index) => (
                          <tr
                            style={{
                              borderBottom: "2px solid #ffff",
                              textAlign: "center",
                            }}
                            key={index}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {item?.day ? _reconvertDay(item.day) : "-"}
                            </TableCell>
                            <TableCell>
                              {item?.timeIndexes?.length > 0 &&
                                item?.timeIndexes?.map(
                                  (t, ti) =>
                                    parseInt(t) +
                                    1 +
                                    (ti + 1 >= item?.timeIndexes?.length
                                      ? ""
                                      : " - ")
                                )}
                            </TableCell>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div style={{ paddingTop: 14 }}>
                <div style={{ fontWeight: "bolder" }}>ສະຖານທີ່ຮຽນ</div>
                <div style={{ fontSize: 14, marginLeft: 10 }}>
                  <Row>
                    <Col>{data?.course?.addressLearn ?? "-"}</Col>
                  </Row>
                </div>
              </div>

              <div style={{ paddingTop: 14 }}>
                <div style={{ fontWeight: "bolder" }}>ຄໍາອະທິບາຍ</div>
                <div style={{ fontSize: 14, marginLeft: 10 }}>
                  <Row>
                    <Col>{data?.course?.description ?? "-"}</Col>
                  </Row>
                </div>
              </div>

              {/* -------- ອັບໂຫລດ Syllabus -------- */}
              <div style={{ paddingTop: 14, paddingBottom: 90 }}>
                <div style={{ fontWeight: "bolder" }}>ອັບໂຫລດ Syllabus</div>
                <div style={{ fontSize: 14 }}>
                  <table
                    border="1"
                    bordercolor="#fff"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <TableHeader>
                        <th style={{ width: 60 }}>ລຳດັບ</th>
                        <th>ຊື່ໄຟສ</th>
                        <th>ດາວໂຫລດ</th>
                      </TableHeader>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                      >
                        <TableCell>{1}</TableCell>
                        <TableCell>
                          {data?.course?.syllabusFile?.title ?? "-"}
                        </TableCell>
                        <TableCell>
                          {data?.course?.syllabusFile?.file.split(".")[4] !==
                          "pdf" ? (
                            <a href={data?.course?.syllabusFile?.file} download>
                              {data?.course?.syllabusFile?.title ?? "-"}
                            </a>
                          ) : (
                            <u
                              onClick={() =>
                                history.push(
                                  `/course-detail/${data?.course?.id}/pdf`,
                                  data?.course?.syllabusFile?.file
                                )
                              }
                            >
                              {data?.course?.syllabusFile?.title ?? "ບໍ່ມີຊື່"}
                            </u>
                          )}
                        </TableCell>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : number && number === 2 ? (
            <div>
              <CourseDocListMobileView />
            </div>
          ) : (
            ""
          )}
        </div>

        {/* ------- Delete Modal ------ */}
        {/* DeleteConfirm Modal */}
        <RegisterCourseMobileView
          showDeleteConfirmView={showRegisterConfirm}
          _handleDeleteConfirmViewClose={_handleRegisterConfirmViewClose}
          courseTitle={registerData?.title}
          confirmRegister={() => _confirmRegister()}
          createStatus={createStatus}
        />
      </CustomContainer>
      {registeredData?.grades?.data[0]?.gredeStatus === "REQUESTING" ? (
        <Button
          disabled
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລໍຖ້າການຢືນຢັນ
        </Button>
      ) : registeredData?.grades?.data[0]?.gredeStatus === "APROVED" ? (
        <Button
          disabled
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລົງທະບຽນວິຊາແລ້ວ
        </Button>
      ) : registeredData?.grades?.data[0]?.gredeStatus === "FINISHED" ? (
        <Button
          disabled
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລົງທະບຽນວິຊາແລ້ວ
        </Button>
      ) : isEnable === true ? (
        <Button
          disabled={false}
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ລົງທະບຽນວິຊາ
        </Button>
      ) : isEnable === false ? (
        <Button
          disabled
          onClick={() => _registerCourse(data?.course)}
          style={{
            backgroundColor: Consts.PRIMARY_COLOR,
            width: "100%",
            position: "fixed",
            bottom: 0,
            border: 0,
            borderRadius: 0,
            height: 50,
          }}
        >
          ປິດການລົງທະບຽນແລ້ວ
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}

export default CourseDetail;
