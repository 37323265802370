import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

const FILES = gql`
  query Files {
    files {
      total
      data {
        id
        title
        description
        enName
        enDescription
        file
        type
        cate
        createdAt
        updatedAt
        createdBy {
          firstname
          lastname
        }
        updatedBy {
          firstname
          lastname
        }
      }
    }
  }
`;

function Testt1() {
  const { data } = useQuery(FILES);
  const [files, setFiles] = useState({});

  console.log(data);

  useEffect(() => {
    setFiles(data);
  }, []);
  console.log("fies------ ", files);

  return (
    <div>
      <div>
        {/* {files?.data?.map((item, index) => (
          <div>
            <h1>{item.title}</h1>
          </div>
        ))} */}
        files:
        {files}
        <a href="" target="_blank"></a>
      </div>
    </div>
  );
}

export default Testt1;
