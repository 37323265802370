import React, { useEffect, useState } from "react";
import { Col, Row, Container, Navbar, Modal, Image } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import { USER_KEY } from "../../consts/user";
import { setGenders } from "../../super";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../index.css";
import DevelopingModal from "../../common/DevelopingModal";
import NavbarFooterApp from "../../common/NavbarFooterApp";
import EditPasswordModal from "../courseList/EditPasswordModal";
import { UPDATE_USER_PASSWORD, STUDENT } from "../../apollo/user";
// import { STUDENT } from "../../apollo/user";
import { FREQUENTLY_ASKED_QUESTIONS } from "../../consts/router";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const Setting = () => {
  /**
   *
   */
  const { history, match } = useReactRouter();
  /**
   * use states
   */
  const [showDeveloping, setShowDeveloping] = useState(false);
  const [loginUserData, setLoginUserData] = useState([]);
  const [userLoginData, setUserLoginData] = useState({});
  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [showHelper, setShowHelper] = useState(false);

  const _clickLogout = () => setShowConfirmLogout(true);
  const _clickCloseLogout = () => setShowConfirmLogout(false);

  const [stId, setStId] = useState();
  const [loadGrade, { data, called, loading }] = useLazyQuery(STUDENT);

  const _handleShowEditPasswordModal = () => setShowEditPasswordModal(true);
  const _onClickChengePasssword = () => {
    _handleShowEditPasswordModal();
  };
  const _onEdit = async (graphQL) => {
    const res = await updateUserPassword({ variables: graphQL });
    if (res?.data) {
      localStorage.removeItem("editPassword");
      setShowEditPasswordModal(false);
    }
  };
  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY);
    if (userData) {
      const user = JSON.parse(userData);
      setStId(
        user?.studentData?.userId.split("/")[0] +
          user?.studentData?.userId.split("/")[1]
      );
      setLoginUserData(user);
    }
  }, []);

  const _onConfirmLogout = async () => {
    await localStorage.clear();
    history.push("/");
  };
  const studentId = loginUserData?.studentData?.id;
  useEffect(() => {
    loadGrade({
      variables: {
        where: {
          id: studentId,
        },
      },
    });
  }, [studentId]);

  return (
    // style={{ height: "100vh", backgroundColor: "white" }}

    <div className="body">
      <Navbar expand="lg" fixed="top" variant="light" className="header">
        <Container className="justify-content-center">
          <p
            style={{
              fontSize: "20px",
              color: "#FFFFFF",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            ຕັ້ງຄ່າ
          </p>
        </Container>
      </Navbar>

      {/* <Container className="bodyContent"> */}
      <Row className="bodyContent">
        <Col
          className="setpassword"
          xs={12}
          onClick={() => _onClickChengePasssword()}
        >
          <i
            style={{ color: "#057CAE", fontSize: "40px", textAlign: "left" }}
            className="fa fa-key icon"
            aria-hidden="true"
          />
          <p className="title">ປ່ຽນລະຫັດຜ່ານ</p>
          <p className="subtitle">ໃຊ້ໃນການປ່ຽນລະຫັດຜ່ານໃໝ່</p>
        </Col>

        <Col
          className="setcontact"
          xs={12}
          onClick={() => history.push("/contact")}
        >
          <i
            style={{ color: "#057CAE", fontSize: "40px", textAlign: "left" }}
            className="fa fa-address-card icon"
            aria-hidden="true"
          />
          <p className="title">ຕິດຕໍ່ພວກເຮົາ</p>
          <p className="subtitle">ໃຊ້ໃນການຕິດຕໍ່ຫາພວກເຮົາ</p>
        </Col>

        <Col className="setbook" xs={12}>
          <div onClick={() => history.push('/setting-pdf-manual')}>
            <i
              style={{ color: "#057CAE", fontSize: "40px", textAlign: "left" }}
              className="fa fa-book-open icon"
              aria-hidden="true"
            />

            <p className="title">ປື້ມຄູ່ມື</p>
            <p className="subtitle">ຄູ່ມືການນຳໃຊ້ແອັບ</p>
          </div>
        </Col>
        <Col className="setbook" xs={12}>
          <div onClick={() => history.push(FREQUENTLY_ASKED_QUESTIONS)}>
            {/* <i
              style={{ color: "#057CAE", fontSize: "40px", textAlign: "left" }}
              className="fa fa-circle-questions icon"
             
              aria-hidden="true"
            />  */}
            <FontAwesomeIcon icon={faQuestionCircle} aria-hidden="true"
              style={{ color: "#057CAE", fontSize: "40px", textAlign: "left",marginLeft:42 }}/>

            <p className="title">FAQ</p>
            <p className="subtitle">ຄຳຖາມທີ່ຖຶກຖາມເລື້ອຍໆ</p>
          </div>
        </Col>
      </Row>

      <NavbarFooterApp />
      <DevelopingModal
        show={showDeveloping}
        handleClose={() => setShowDeveloping(false)}
      />

      <EditPasswordModal
        showEditPassword={showEditPasswordModal}
        _handleEditPasswordClose={() => setShowEditPasswordModal(false)}
        onEdit={(graphQL) => _onEdit(graphQL)}
        userId={userLoginData?.userId}
      />

      <Modal
        show={showHelper}
        centered
        className="center"
        onHide={() => setShowHelper(false)}
      >
        <Modal.Header className="borderHelperMobile">
          <div>ຕິດ​ຕໍ່​ພວກ​ເຮົາ</div>
        </Modal.Header>
        <Modal.Body>
          <Col xs="12">ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ​</Col>
          <Row style={{ fontSize: 12, textAlign: "left", paddingTop: 10 }}>
            <Col xs="6">ເບີ​ໂທ​ລະ​ສັບ:</Col>
            <Col xs="6">021 771 989</Col>
            <Col xs="6">ອີ​ເມວ:</Col>
            <Col xs="6">info.01slms@gmail.com</Col>
          </Row>
          <Image
            src="/assets/png_call.png"
            style={{ width: "40%", height: "40%", paddingTop: 10 }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Setting;
