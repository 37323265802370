import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DockListBrowser from "./DockListBrowser";
import DockListMobile from "./DockListMobile";

const DocList = () => {
  return (
    <div>
      <BrowserView>
        <DockListBrowser />
      </BrowserView>
      <MobileView>
        <DockListMobile />
      </MobileView>
    </div>
  );
};

export default DocList;
