import React from "react";
import { MobileHeader } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useReactRouter from "use-react-router";
import { Row, Col } from "react-bootstrap";
import { OLD_STUDENT } from "./const";
import { convertName } from "./common";
import Consts from "../../consts";
import "./index.css";
const OldStudentClubList = () => {
  const { history } = useReactRouter();

  const _handleDetail = (id) => {
    history.push(`/graduated-student-club-detail/${id}`);
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
      <MobileHeader
        title="ຊົມລົມສິດເກົ່າ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <Row className="content">
        {OLD_STUDENT.map((item, index) => (
          <Col xs={6} md={3} key={index} onClick={() => _handleDetail(item.id)}>
            <div className="detail">
              <img
                src={item.img ? item.img : "assets/wallpaper-image.png"}
                alt=""
                className="imageList"
              />
              <div className="p-2">
                <p className="old-studentlist-ellipsis-name">
                  {convertName(item.gender, item.firstnameL, item.lastnameL)}
                </p>
                <p className="old-studentlist-ellipsis-faculty">ຄະນະ {item.faculty}</p>
                <p className="old-studentlist-ellipsis-faculty">ສາຂາວິຊາ {item.department}
                </p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default OldStudentClubList;
