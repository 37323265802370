import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import useReactRouter from "use-react-router";
import moment from "moment";

// component import
import Loading from "../../../common/Loading";

// api import
import { API_KEY, NEW_API_URL } from "../../../common/constraint";
import axios from "axios";

function Legislation() {
  const { history } = useReactRouter();
  const [loading, setLoading] = useState(true);
  const [governmentFiles, setGovernmentFiles] = useState([]);

  // Function to set generalFiles
  const setGovernmentFilesFromdata = (data) => {
    const generalFilesData = data.filter((item) => item.type === "legislation");
    setGovernmentFiles(generalFilesData);
  };

  // function for get new api
  const getLaoResearch = async () => {
    setLoading(true);
    await axios
      .get(`${NEW_API_URL}laodoc`, {
        headers: {
          api_key: API_KEY,
        },
        params: {
          limit: 100,
        },
      })
      .then((res) => {
        if (res.data) {
          setGovernmentFilesFromdata(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  // call the function
  useEffect(() => {
    getLaoResearch();
  }, []);

  // Navigate function
  const navigate = async (item) => {
    setLoading(true);
    await axios
      .get(`${NEW_API_URL}laodoc/view`, {
        headers: {
          api_key: API_KEY,
        },
        params: {
          documentId: item._id,
        },
      })
      .then((res) => {
        console.log("view counted", res.data.viewCount);
      })
      .catch((err) => {
        console.log(err);
      });

    history.push(`/researchdetail/${item._id}`, { item: item });
    setLoading(false);
  };

  if (loading) return <Loading />;

  return (
    <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <header
        id="dynamic-header"
        className="py-2 bg-white shadow-sm px-2"
        style={{ position: "fixed", top: "0", left: "0", right: "0" }}
      >
        <div className="row mb-4">
          <div className="col col-1 pt-2">
            <Icon.ChevronLeft onClick={() => history.goBack()} />
          </div>
          <div className="col col-10">
            <h2 className="h5 fw-bold text-truncate pt-2">
              ບົດຄົ້ນຄວ້ານິຕິກຳຕ່າງໆ
            </h2>
          </div>
        </div>
      </header>
      <main
        className="container mx-auto pb-2"
        style={{
          marginTop: `100px`,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div id="general" style={{ maxWidth: "425px", width: "100%" }}>
          <h4 className="fw-bold h5 text-truncate mb-3">
            {governmentFiles.length} ລາຍການ
          </h4>
          <div id="domestics-list">
            {governmentFiles && governmentFiles.length ? (
              <div>
                {governmentFiles.map((item, index) => (
                  <div key={index} onClick={() => navigate(item)}>
                    <div
                      id="domestic-item"
                      className="px-3 pt-1 rounded bg-white shadow-sm my-2 d-flex align-content-center border border-1"
                      style={{ fontSize: "small" }}
                    >
                      <div
                        id="img"
                        className="me-3"
                        style={{
                          width: "35%",
                          minWidth: "100px",
                          maxWidth: "250px",
                          height: "100px",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          src={item.image}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </div>
                      <div className="pt-1" style={{ width: "65%" }}>
                        <div className="mb-3">
                          <div className="text-truncate fw-bold mb-2">
                            {item.title}
                          </div>
                          <div className="text-truncate">
                            ສ້າງໂດຍ:
                            <span className="text-truncate">
                              &nbsp;{item.author}
                            </span>
                          </div>
                          <div className="text-truncate">
                            ປະເພດບົດ:
                            <span className="text-truncate">
                              &nbsp;{item.type}
                            </span>
                          </div>
                        </div>
                        <div className="text-truncate">
                          ອັບເດດວັນທີ່:
                          <span className="text-danger text-truncate">
                            &nbsp;
                            {moment(item.createdAt).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <h1 style={{ fontWeight: "bold" }}>ບໍມີລາຍການທີ່ຈະສະເເດງ</h1>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Legislation;
