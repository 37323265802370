/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import useReactRouter from "use-react-router";
import { Row, Col, Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const StoreDetail = () => {
  /**
   * routes
   */
  const { history, location } = useReactRouter();
  const { state } = location;
  /**
   * states
   */
  /**
   * functional
   */

  return (
    <div style={{ paddingBlockEnd: 50, backgroundColor: "white" }}>
      <div
        onClick={() => history.goBack()}
        className="d-flex justify-content-center"
        style={{
          backgroundColor: "white",
          position: "absolute",
          cursor: "pointer",
          top: 10,
          left: 10,
          width: 30,
          height: 30,
          borderRadius: 50,
          zIndex: 999999999,
          textAlign: "center",
          padding: "6px 0px",
        }}
      >
        <i
          className="fa fa-arrow-left"
          aria-hidden="true"
          style={{ fontSize: "1.1em", color: "#057CAE" }}
        />
      </div>
      <div style={{ display: "block" }}>
        <Carousel >
          {state &&
            state?.slide.map((image, index) => (
              <Carousel.Item >
        <img
        effect="blur"
          style={{ width: "100%", height: 224 }}
          className="d-block w-100"
          src={image ? image : state?.logo}
          alt="ຮູບສະລາຍ"
        />
        </Carousel.Item>
             ))} 
        </Carousel>
      </div>
      <div
        style={{
          // position: "absolute",
          width: "100%",
          height: 655,
          // top: 180,
          background: "#FFFFFF",
          borderRadius: "4px 4px 4px 4px",
          boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="p-4">
          <Row>
            <Col xs={12}>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  width: "100%",
                  marginBottom: 15,
                }}
              >
                {state?.name}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ fontSize: "1.1em", color: "#2085B0" }}
              />
            </Col>
            <Col xs={10}>
              <p style={{ fontSize: 14 }}>
                {state?.address}
                <a href={state?.map ?? ""}>(link google map)</a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <FontAwesomeIcon
                icon={faPhoneAlt}
                style={{ fontSize: "1.1em", color: "#2085B0" }}
              />
            </Col>
            <Col xs={10}>
              <p style={{ fontSize: 14 }}>{state?.phone}</p>
            </Col>
          </Row>
          <div
            style={{
              width: "100%",
              height: 2,
              marginBottom: 15,
              background: "#2085B0",
              borderRadius: "1.5px",
            }}
          ></div>
          <p style={{ fontSize: 12, marginTop: 20, color: "#717171" }}>
            ລາຍລະອຽດກ່ຽວກັບສ່ວນຫຼຸດ
          </p>
          <p style={{ paddingLeft: 47, fontWeight: 400, fontSize: 14 }}
          dangerouslySetInnerHTML={{ __html: state?.detail }}
          >
            {/* {} */}
          </p>
          <p style={{ fontSize: 12, marginTop: 20, color: "#717171" }}>
            ກ່ຽວກັບ
          </p>
          <p style={{ paddingLeft: 47, fontWeight: 400, fontSize: 14 }}>
            {state?.about}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StoreDetail;
