import React from 'react'
import { MobileHeader } from "../../common";
import useReactRouter from "use-react-router";

export default function PlayMedia() {
    const { history, location } = useReactRouter();
    const { state } = location;
    console.log('history', history)
    return (
        <div>
            <MobileHeader
                title={state?.name}
                showLeftArrow
                leftButtonClick={() => history.goBack()}
            />
            <iframe width="100%" height="315" src={state?.video}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
            </iframe>
            <div style={{ padding: 10 }}>
                <div style={{ fontWeight: "bold", marginBottom: 10 }}>{state?.name}</div>
                <div>
                &emsp;{state?.description}
                </div>
            </div>
        </div>
    )
}
