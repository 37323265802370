import Consts from "../../consts";

var noneSelect = {
    backgroundColor: Consts.FONT_COLOR_WHITE,
    color: Consts.PRIMARY_COLOR,
    border: `2px solid ${ Consts.PRIMARY_COLOR }`,
    flexShrink: 0,
    width: 50,
    height: 40,
    fontWeight: "bold",
    marginLeft: 5,
    marginTop: 10,
    marginBottom: 15,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}
var noneSelectAll = {
    backgroundColor: Consts.FONT_COLOR_WHITE,
    color: Consts.PRIMARY_COLOR,
    border: `2px solid ${ Consts.PRIMARY_COLOR }`,
    flexShrink: 0,
    width: 90,
    height: 40,
    fontWeight: "bold",
    marginLeft: 5,
    marginTop: 10,
    marginBottom: 15,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}
var Selected = {
    flexShrink: 0,
    width: 50,
    height: 40,
    fontWeight: "bold",
    marginLeft: 5,
    marginTop: 10,
    marginBottom: 15,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Consts.PRIMARY_COLOR,
    color: Consts.FONT_COLOR_WHITE,
    border: `2px solid ${ Consts.PRIMARY_COLOR }`,
}
var SelectedAll = {
    flexShrink: 0,
    width: 90,
    height: 40,
    fontWeight: "bold",
    marginLeft: 5,
    marginTop: 10,
    marginBottom: 15,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Consts.PRIMARY_COLOR,
    color: Consts.FONT_COLOR_WHITE,
    border: `2px solid ${ Consts.PRIMARY_COLOR }`,
}
var buttonClicked = {
    color: '#000',
    borderBottom: `4px solid ${Consts.COLOR_EDIT}`,
    borderTop: '0px solid #fff',
    borderLeft: '0px solid #fff',
    borderRight: '0px solid #fff',
    fontSize: 16,
    fontWeight: 'bold'
}
var buttonClick = {
    border: '0px solid #fff',
    color: '#C7C7C7',
    fontSize: 16,
    fontWeight: 'bold'
}
const Styles = {
    noneSelect: noneSelect,
    selected: Selected,
    noneSelectAll: noneSelectAll,
    selectedAll: SelectedAll,
    buttonClicked: buttonClicked,
    buttonClick: buttonClick
}


export default Styles;