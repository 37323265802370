import React, { useState } from "react";
import { Breadcrumb, Row, Col, Button } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@apollo/react-hooks";
import Loading from "../../common/Loading";
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
import CourseDocEdit from "./CourseDocEdit";
import CourseDocDeleteConfirm from "./CourseDocDeleteConfirm";
import Consts from "../../consts";
import { FILES } from "../../apollo/file";
import { GET_COURSE } from "../../apollo/course";
const CourseDocList = () => {
  const { history, match } = useReactRouter();
  const { courseId } = match.params;

  const {
    loading: courseLoading,
    error: corseError,
    data: courseData,
  } = useQuery(GET_COURSE, {
    variables: { where: { id: courseId ? courseId : "" } },
  });

  const [courseDocEditModal, setCourseDocEditModal] = useState(false);
  const [courseDocDeleteConfirmModal, setCourseDocDeleteConfirmModal] =
    useState(false);

  // apollo --------------------------------------------------------------------->
  const { loading, error, data } = useQuery(FILES, {
    variables: { where: { course: { id: courseId } } },
  });
  // Set states
  const _handlCourseDocEditModalClose = () => setCourseDocEditModal(false);
  const _handlCourseDocDeleteConfirmModalClose = () =>
    setCourseDocDeleteConfirmModal(false);

  const _courseDetail = (courseId) => {
    history.push(`/course-detail/${courseId}`);
  };
  if (loading || courseLoading) return <Loading />;
  if (error || corseError) return <h1>GraphQL error</h1>;
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="/course-list">ຈັດການວິຊາ</Breadcrumb.Item>
        <Breadcrumb.Item href="/course-list">ວິຊາທັງຫມົດ</Breadcrumb.Item>
        <Breadcrumb.Item active>ເອກະສານບົດສອນ</Breadcrumb.Item>
      </Breadcrumb>

      {/* Container */}
      <CustomContainer>
        {/* --------- Title and Button groups ----------- */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text="ເອກະສານບົດສອນ" />

          {/* Button group */}
          <div>
            {/* ລາຍລະອຽດວິຊາ */}
            <button
              style={{
                backgroundColor: "#fff",
                color: Consts.FONT_COLOR_SECONDARY,
                width: 160,
                height: 40,
                border: `1px solid ${Consts.FONT_COLOR_SECONDARY}`,
                outline: "none",
                borderRadius: 0,
                marginRight: 20,
              }}
              onClick={() => _courseDetail(courseId)}
            >
              ລາຍລະອຽດວິຊາ
            </button>
          </div>
        </div>

        {/* -------- ຂໍ້ມູນວິຊາ ----------- */}
        <div style={{ marginTop: 10 }}>
          <div>ຂໍ້ມູນວິຊາ</div>
          {/* ------ detail box ------ */}
          <div
            style={{
              border: "1px solid #ddd",
              width: "60%",
              padding: 20,
              fontSize: 14,
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: -10,
              paddingLeft: 80,
            }}
          >
            <Row>
              <Col>ຊື່ວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData.course && courseData.course.title
                  ? courseData.course.title
                  : "-"}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ລະຫັດວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData.course && courseData.course.courseCode
                  ? courseData.course.courseCode
                  : "-"}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ຈໍານວນຫນ່ວຍກິດ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData.course && courseData.course.unit
                  ? courseData.course.unit
                  : "-"}
              </Col>
            </Row>
          </div>
        </div>

        {/* ------ ເອກະສານທີ່ຖືກອັບໂຫລດ -------- */}
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          ເອກະສານທີ່ຖືກອັບໂຫລດ
        </div>

        {/* ---------- table --------- */}
        <div>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader>
                <th>ລຳດັບ</th>
                <th style={{ width: 250 }}>ຫົວຂໍ້</th>
                <th style={{ width: 300 }}>ໄຟລ</th>
                <th>ອັບເດດ</th>
                <th>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {data?.files?.data?.length > 0 &&
                data?.files?.data?.map((file, index) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{file?.title ?? ""}</TableCell>
                      <TableCell>
                        {decodeURI(file?.file?.split("/")[4])}
                        {/* {file?.file ? file.file.split("/")[4] : ""} */}
                      </TableCell>
                      <TableCell>
                        {/* {file && file.createdAt
                          ? moment(file.createdAt).format("YYYY/MM/DD H:mm")
                          : ""} */}
                        {new Date(file?.updatedAt).toLocaleString("la-LA", {
                          hour12: false,
                        })}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <div>
                            <a href={file && file.file}>
                              <Button
                                style={{
                                  backgroundColor: Consts.FONT_COLOR_WHITE,
                                  border: `1px solid #f1f1f1`,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={["fas", "download"]}
                                  color={Consts.PRIMARY_COLOR}
                                />{" "}
                              </Button>
                            </a>
                          </div>
                        </div>
                      </TableCell>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {/* -------- Course doc edit modal ---------- */}
        <CourseDocEdit
          courseDocEditModal={courseDocEditModal}
          _handlCourseDocEditModalClose={_handlCourseDocEditModalClose}
        />

        {/* -------- Course delete confirm modal ---------- */}
        <CourseDocDeleteConfirm
          courseDocDeleteConfirmModal={courseDocDeleteConfirmModal}
          _handlCourseDocDeleteConfirmModalClose={
            _handlCourseDocDeleteConfirmModalClose
          }
        />
      </CustomContainer>
    </div>
  );
};

export default CourseDocList;
