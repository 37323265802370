import React from "react";
import { Modal, Button } from "react-bootstrap";
import Consts from "../../consts";

const CourseDeleteConfirm = ({
  showDeleteConfirmView,
  _handleDeleteConfirmViewClose,
  courseTitle,
  confirmRegister,
  createStatus,
}) => {
  return (
    <Modal
      show={showDeleteConfirmView}
      onHide={_handleDeleteConfirmViewClose}
      size="lg"
    >
      <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
        <p className="text-center" style={{ fontWeight: "bold" }}>
          ຢືນຢັນການລົງທະບຽນ
        </p>

        <p className="text-center">{courseTitle?.title ?? ""}</p>
        {createStatus && (
          <p className="text-center" style={{ color: "red", fontSize: 14 }}>
            {createStatus}
          </p>
        )}

        <div style={{ height: 20 }} />
        <div className="row">
          <div style={{ padding: 15 }} className="col">
            <Button
              onClick={_handleDeleteConfirmViewClose}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                color: Consts.PRIMARY_COLOR,
                borderColor: Consts.PRIMARY_COLOR,
              }}
            >
              ຍົກເລີກ
            </Button>
          </div>
          <div style={{ padding: 15 }} className="col">
            <Button
              style={{
                width: "100%",
                backgroundColor: Consts.PRIMARY_COLOR,
                color: "#fff",
                borderColor: Consts.PRIMARY_COLOR,
              }}
              onClick={() => confirmRegister()}
            >
              ລົງທະບຽນ
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CourseDeleteConfirm;
