// production
const MOODLE_URL = "http://54.255.147.171:888"

// staging
// const MOODLE_URL = "http://54.254.4.191:888"

const URL_IMAGE_STUDENT = "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student-image/"

const VERSIONONLOGIN = "V 1.0.32"
const VERSION = "Student Pro 1.0.32"

module.exports = {
  PRIMARY_COLOR: "#057CAE",
  SECONDARY_COLOR: "#6f6f6f",
  FONT_COLOR_PRIMARY: "#383838",
  FONT_COLOR_SECONDARY: "#6f6f6f",
  CONTAINER_PADDING: 24,
  CONTAINER_MARGIN_TOP: -10,
  FONT_COLOR_WHITE: "#fff",
  FONT_COLOR_BLACK: "#000000",
  TAB_COLOR: "#057CAE",
  COLOR_EDIT: "#7bb500",
  COLOR_DELETE: "#B80000",
  FONT_SIZE: 12,
  MOODLE_URL,
  VERSIONONLOGIN,
  VERSION,
  URL_IMAGE_STUDENT
};
