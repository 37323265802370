import React from "react";
import { MobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import consts from "../../consts";
import { SCHOLARSHIPS } from "./const";
const ScholarShipList = () => {
  const { history } = useReactRouter();
  return (
    <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
      <MobileHeader
        title="ທຶນການສຶກສາ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <div className="container content mx-auto">
        {SCHOLARSHIPS.map((item, index) => (
          <div
            className="job-card my-1 row"
            key={index}
            onClick={() =>
              history.push({
                pathname: `/scholarship-detail/${item?.id}`,
                state: item,
              })
            }
          >
            <div className="body-flex p-1">
              <div className="flex-left mr-2">
                <img style={{ width: 95, height: 66 }} src={item?.logo} />
              </div>
              <div className="flex-right">
                <p className="ellipsis-text">{item?.title}</p>
                <p className="ellipsis-text">
                  <b>{item?.entitle}</b>
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: consts.PRIMARY_COLOR,
                  }}
                  onClick={() =>
                    history.push({
                      pathname: `/scholarship-detail/${item?.id}`,
                      state: item,
                    })
                  }
                >
                  ລາຍລະອຽດເພິ່ມເຕີມ
                  <FontAwesomeIcon
                    icon={["fas", "arrow-right"]}
                    color={consts.PRIMARY_COLOR}
                    size="xs"
                    style={{ marginLeft: 5 }}
                  />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ScholarShipList;
