import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";

import { Navbar, Nav, NavDropdown, Modal, Button } from "react-bootstrap";
import { Col, Row, Container } from "react-bootstrap";
import DevelopingModal from "./DevelopingModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import "../index.css";
import { MESSENGER, NOTIFICATION, SETTING } from "../consts/router";

import "./navbarFooter.css";

function NavbarFooterApp() {
const { history,match, location } = useReactRouter();
  const active = location.state ? location.state : 1;

  const [showDeveloping, setShowDeveloping] = useState(false);

  return (
    <div className="footer">
      <Row className="footerRow">
        <Col xs={3} className="footerCol" style={{ marginLeft: 10 }}>
          <div
            className="footerCol"
            style={{
              color: active === 1 ? "#71BB14" : "#C7C7C7",
            }}
            onClick={(e) => history.push("/dashboard/", 1)}
          >
            <i
              className="fa fa-home iconApp"
              aria-hidden="true"
              style={{
                fontSize: "2.3em",
                height: 25,
                padding: "10px 10px",
              }}
            />
            <p className="textApp">ໜ້າຫຼັກ</p>
          </div>
        </Col>
        <Col xs={3} className="footerCol" style={{ marginLeft: -10 }}>
          <div
            xs={3}
            className="footerCol"
            style={{
              color: active === 2 ? "#71BB14" : "#C7C7C7",
            }}
            onClick={(e) => history.push(MESSENGER, 2)}
          >
            <i
              className="fa fa-envelope iconApp"
              aria-hidden="true"
              style={{
                fontSize: "2.3em",
                height: 25,
                padding: "10px 10px",
              }}
            />

            <p className="textApp">ຂໍ້ຄວາມ</p>
          </div>
        </Col>
        <Col xs={3} className="footerCol" style={{ marginLeft: -10 }}>
          <div
            className="footerCol"
            style={{
              color: active === 3 ? "#71BB14" : "#C7C7C7",
            }}
            onClick={(e) => history.push(NOTIFICATION, 3)}
          >
            <i
              className="fa fa-bell iconApp"
              aria-hidden="true"
              style={{
                fontSize: "2.3em",
                height: 25,
                padding: "10px 10px",
              }}
            />
            <p className="textApp">ແຈ້ງເຕືອນ</p>
          </div>
        </Col>
        <Col xs={3} className="footerCol" style={{ marginLeft: -10 }}>
          <div
            className="footerCol"
            style={{
              color: active === 4 ? "#71BB14" : "#C7C7C7",
            }}
            onClick={(e) => history.push(SETTING, 4)}
          >
            <i
              className="fa fa-cogs iconApp"
              aria-hidden="true"
              style={{
                fontSize: "2.3em",
                height: 25,
                padding: "10px 10px",
              }}
            />
            <p className="textApp">ຕັ້ງຄ່າ</p>
          </div>
        </Col>
      </Row>
      <DevelopingModal
        show={showDeveloping}
        handleClose={() => setShowDeveloping(false)}
      />
    </div>
  );
}
export default NavbarFooterApp;
