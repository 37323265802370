import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import NoticeDetailBrowser from "./NoticeDetailBrowser";
import NoticeDetailMobile from "./NoticeDetailMobile";

const DocList = () => {
  return (
    <div>
      <BrowserView>
        <NoticeDetailBrowser />
      </BrowserView>
      <MobileView>
        <NoticeDetailMobile />
      </MobileView>
    </div>
  );
};

export default DocList;
