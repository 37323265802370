import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as _ from "lodash";
import Consts from "../../consts";
import CustomSideNavMobile from "../../common/CustomSideNavMobile";
import { MobileHeader } from "../../common";
import { CONTAINER_PADDING } from "../../consts";
import { formatDateTime, formatDate, noticeStatus } from "../../super";
import { useQuery } from "@apollo/react-hooks";
import { NOTICS } from "../../apollo/notic";

function NoticeRelation() {
  const { history, location, match } = useReactRouter();
  const [typeNotice, setTypeNotice] = useState(match.params.type);
  const [isGrid, setIsGrid] = useState(true);

  //  SideNav
  const [showNav, setShowNav] = useState(false);
  const closeNav = () => {
    setShowNav(false);
  };

  const {
    error: noticError,
    loading: noticLoading,
    data: noticData,
  } = useQuery(NOTICS, {
    variables: {
      where: { type: typeNotice },
      orderBy: "createdAt_DESC",
    },
  });

  const _displayStyle = () => {
    setIsGrid(!isGrid);
  };

  return (
    <div>
      {/* Breadcrumb */}
      <CustomSideNavMobile
        showNav={showNav}
        closeNav={closeNav}
        history={history}
        location={location}
      />
      <MobileHeader
        // showHamburger
        leftButtonClick={() => history.push("/notice-list")}
        title="ແຈ້ງການ ແລະ ລະບຽບການ"
        showLeftArrow={true}
      />
      <div
        style={{
          paddingLeft: CONTAINER_PADDING,
          paddingRight: CONTAINER_PADDING,
          backgroundColor: "white",
          paddingTop: 80,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold", fontSize: 16 }}>
            ທັງໝົດ {noticData?.notics?.data?.length}
          </span>
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            onClick={_displayStyle}
            icon={["fas", isGrid === true ? "list" : "th-large"]}
          />
        </div>

        <div
          style={{
            display: isGrid === true ? "block" : "none",
            paddingBottom: 20,
          }}
        >
          {noticData?.notics?.data?.length > 0 &&
            noticData?.notics?.data?.map((x, index) => {
              return (
                <div
                  onClick={() =>
                    history.push(`/notice-detail/${x.id}/${x.type}`)
                  }
                  key={index}
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    border: "1px solid #ddd",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      height: 171,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={
                        x?.coverImage?.url ||
                        "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                      }
                    />
                  </div>

                  <div style={{ padding: 10 }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a style={{ fontSize: 14 }}>
                        ສ້າງໂດຍ:{x?.createdBy?.firstname}{" "}
                        {x?.createdBy?.lastname ? x?.createdBy?.lastname : ""}
                      </a>
                      <a style={{ fontSize: 14 }}>{noticeStatus(x.type)}</a>
                    </div>
                    <a
                      className="title"
                      style={{
                        fontSize: 16,
                        marginLeft: -1,
                        color: Consts.FONT_COLOR_PRIMARY,
                        fontWeight: "bolder",
                      }}
                    >
                      {x?.title}
                    </a>{" "}
                    {/* <br /> */}
                    <a style={{ fontSize: 14 }}>
                      {formatDateTime(x.createdAt)}
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{
            display: isGrid === true ? "none" : "block",
            paddingBottom: 20,
          }}
        >
          {noticData?.notics?.data?.length > 0 &&
            noticData?.notics?.data?.map((item, index) => {
              return (
                <div
                  className="list-item"
                  key={index}
                  onClick={() =>
                    history.push(`/notice-detail/${item.id}/${item.type}`)
                  }
                >
                  <table border="0" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: 80, border: "none" }}>
                          <img
                            src={
                              item?.coverImage?.url ||
                              "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                            }
                            className="box-image"
                          />
                        </th>
                        {/* <th style={{ border: "none", width: "20%" }}>
                          <p>ຫົວຂໍ້: </p>
                          <p>ສ້າງ: </p>
                        </th> */}
                        <td
                          style={{
                            border: "none",
                            width: "80%",
                            paddingRight: 10,
                            paddingBottom: 5,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <a style={{ fontSize: 14, marginLeft: 5 }}>
                              {noticeStatus(item?.type)}
                            </a>
                            <a style={{ fontSize: 14 }}>
                              {formatDate(item?.createdAt) || "-"}
                            </a>
                          </div>
                          <a
                            className="title"
                            style={{
                              width: "100%",
                              fontSize: 16,
                              fontWeight: "bold",
                              overflowX: "hidden",
                              overflowY: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item?.title}
                          </a>
                          {/* <br /> */}
                          <a style={{ fontSize: 14, marginLeft: 5 }}>
                            ສ້າງໂດຍ:{item?.createdBy?.firstname}{" "}
                            {item?.createdBy?.lastname
                              ? item?.createdBy?.lastname
                              : ""}
                          </a>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default NoticeRelation;
