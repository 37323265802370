import React, { useState, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MobileView } from 'react-device-detect'
import { Row, Col, Button, Image, Card, Modal } from 'react-bootstrap'
import { USER_KEY } from '../../consts/user'
import { URL_IMAGE_STUDENT } from '../../consts'
import { MobileHeader } from '../../common'
import { setGenders, setGenderE } from './../../super'
import EditPasswordModal from '../courseList/EditPasswordModal'
import { UPDATE_USER_PASSWORD } from '../../apollo/user'
import { STUDENT } from '../../apollo/user'


function StudentCard () {
  const { history } = useReactRouter()
  const [loginUserData, setLoginUserData] = useState([])
  const [userLoginData, setUserLoginData] = useState({})
  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD)
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false)
  const [showConfirmLogout, setShowConfirmLogout] = useState(false)

  const _clickLogout = () => setShowConfirmLogout(true)
  const _clickCloseLogout = () => setShowConfirmLogout(false)

  const [stId, setStId] = useState()
  const [imagesUrl, setImagesUrl] = useState()
  const [loadGrade, { data, called, loading }] = useLazyQuery(STUDENT)

  const _handleShowEditPasswordModal = () => setShowEditPasswordModal(true)
  const _onClickChengePasssword = () => {
    _handleShowEditPasswordModal()
  }
  const _onEdit = async graphQL => {
    const res = await updateUserPassword({ variables: graphQL })
    if (res?.data) {
      localStorage.removeItem('editPassword')
      setShowEditPasswordModal(false)
    }
  }
  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY)
    if (userData) {
      const user = JSON.parse(userData)
      setStId(
        user?.studentData?.userId.split('/')[0] +
          user?.studentData?.userId.split('/')[1]
      )
      setLoginUserData(user)
    }
  }, [])

  useEffect(() => {
    // const images = data?.student?.image?.url.split('/').pop()
    const images = data?.student?.image?.url
    setImagesUrl(images)
  }, [data]);

  const _onConfirmLogout = async () => {
    await localStorage.clear()
    history.push('/')
  }
  const studentId = loginUserData?.studentData?.id
  useEffect(() => {
    loadGrade({
      variables: {
        where: {
          id: studentId
        }
      }
    })
  }, [studentId])

  // console.log(studentId)
  return (
    <MobileView>
      <div style={{ backgroundColor: '#fff', paddingBottom: 80 }}>
        <MobileHeader
          showLeftArrow={true}
          leftButtonClick={() => history.goBack()}
          title='ບັດປະຈຳຕົວນັກສືກສາ'
        />
        <div
          style={{
            padding: 12,
            paddingTop: 90,
            background: "url('./assets/wallpaper-card.png')",
            backgroundSize: 'cover'
          }}
        >
          <Row>
            <Col className='text-center'>
              {data?.student?.image?.url ? (
                <Image
                  src={URL_IMAGE_STUDENT + imagesUrl}
                  style={{
                    width: '140px',
                    height: '140px',
                    boxShadow: '#f1f1f1 1px 1px 5px 5px',
                    backgroundSize: 'corver'
                  }}
                  roundedCircle
                />
              ) : (
                <Image
                  src='./assets/userpng.png'
                  style={{
                    width: '140px',
                    height: '140px',
                    boxShadow: '#f1f1f1 1px 1px 5px 5px'
                  }}
                  roundedCircle
                />
              )}
              <p className='mt-3' style={{ fontWeight: 'bolder' }}>
                <b>
                  {setGenders(loginUserData?.studentData?.gender) +
                    ' ' +
                    loginUserData?.studentData?.firstNameL +
                    ' ' +
                    loginUserData?.studentData?.lastNameL}
                </b>
              </p>
            </Col>
          </Row>

          {/* Card */}
          <div>
            <Row>
              <Col>
                <Card
                  style={{
                    borderRadius: '20px',
                    color: 'white',
                    background: "url('./assets/background card.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    border: 'none'
                  }}
                >
                  <Card.Body>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <Row style={{ marginTop: '-15px' }}>
                          <Col xs={1}>
                            <Image
                              src='assets/SLMS-white.png'
                              style={{ width: '70px', marginTop: '-15px' }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={2} className='mr-4'>
                            {data?.student?.image?.url ? (
                              <Image
                                src={URL_IMAGE_STUDENT + imagesUrl}
                                style={{ width: '65px', height: '80px' }}
                              />
                            ) : (
                              <Image
                                src='./assets/userpng.png'
                                style={{ width: '65px', height: '70px' }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row style={{ paddingLeft: 30, paddingTop: 5 }}>
                          <i
                            style={{ fontSize: '18px' }}
                            class='fas fa-barcode'
                          ></i>
                          <i
                            style={{ fontSize: '18px' }}
                            class='fas fa-barcode'
                          ></i>
                        </Row>
                        <Row style={{ paddingLeft: 3 }} >
                          <small className='text-muted' style={{position: "absolute"}}>
                            Exp. Date 02/08/2026
                          </small>
                        </Row>
                      </div>
                      <div>
                        <Row style={{marginTop: -14}}>
                          <Col className='text-left'>
                            <small style={{paddingLeft:12,fontSize: '10px' }}>
                              ມະຫາວິທະຍາໄລແຫ່ງຊາດ
                            </small>
                            <p style={{ fontWeight: 'bold', fontSize: '15px' }}>
                              ບັດປະຈຳຕົວນັກສຶກສາ
                            </p>
                          </Col>
                        </Row>
                        <Row>
                        <Col className="text-left p-0 pl-1">
                          <p
                            style={{
                              fontWeight: 'bold',
                              letterSpacing: '2px',
                              fontSize: 16
                            }}
                          >
                            {loginUserData?.studentData?.userId}
                          </p>
                          <p style={{fontSize:14 }}>
                            {setGenders(loginUserData?.studentData?.gender) +
                              ' ' +
                              loginUserData?.studentData?.firstNameL +
                              '   ' +
                              loginUserData?.studentData?.lastNameL}
                          </p>
                          <p style={{fontSize:14  }}>
                            {setGenderE(loginUserData?.studentData?.gender) +
                              ' ' +
                              loginUserData?.studentData?.firstNameE +
                              ' ' +
                              loginUserData?.studentData?.lastNameE}
                          </p>
                        </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div style={{ paddingLeft: 10 }}>
            <div sm='12'>
              <strong>ຄະນະ, ພາກວິຊາ ແລະ ສາຂາວິຊາ</strong>
            </div>
            <div style={{ display: 'flex' }}>
              <Col sm='4' style={{ fontSize: 12 }}>
                ຄະນະ{' '}
              </Col>
              <Col sm='6' style={{ fontSize: 12 }}>
                {data?.student?.faculty?.name}{' '}
              </Col>
            </div>
            <div style={{ display: 'flex' }}>
              <Col sm='6' style={{ fontSize: 12 }}>
                ພາກວິຊາ
              </Col>
              <Col sm='6' style={{ fontSize: 12 }}>
                {' '}
                {data?.student?.department?.name}
              </Col>
            </div>
            <div style={{ display: 'flex' }}>
              <Col sm='6' style={{ fontSize: 12 }}>
                ສາຂາວິຊາ
              </Col>
              <Col sm='6' style={{ fontSize: 12 }}>
                {' '}
                {data?.student?.major?.name}
              </Col>
            </div>
            <br />
          </div>
        </div>
        <div>
          <EditPasswordModal
            showEditPassword={showEditPasswordModal}
            _handleEditPasswordClose={() => setShowEditPasswordModal(false)}
            onEdit={graphQL => _onEdit(graphQL)}
            userId={userLoginData?.userId}
          />
        </div>
      </div>
    </MobileView>
  )
}

export default StudentCard
