export const OLD_STUDENT = [
    {
        id: '1',
        firstnameL: 'ໂກ່',
        firstnameE: 'Go',
        lastnameL: 'ໄຊຍະແສງ',
        lastnameE: 'SAYYASAENG',
        gender: 'MALE',
        faculty: 'ວິທະຍາສາດທຳມະຊາດ',
        department: 'ການພັດທະນາໂປຣແກຣມຄອມພີວເຕີ',
        general: 'III',
        workAt: 'ບໍລິສັດ ລາວໂທລະຄົມມະນາຄົມ ມະຫາຊົນ',
        phonemobile: '020 55171065',
        whatsapp: '020 55171065',
        email: 'goxayyasang@gmail.com',
        facebook: 'Go Xayyasang',
        img: 'https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/%E0%BA%97%E0%BB%89%E0%BA%B2%E0%BA%A7+%E0%BB%82%E0%BA%81%E0%BB%88+%E0%BB%84%E0%BA%8A%E0%BA%8D%E0%BA%B0%E0%BB%81%E0%BA%AA%E0%BA%87.JPG',
    },
    {
        id: '2',
        firstnameL: 'ດອກຄູນຄຳ',
        firstnameE: 'Dorkkhounkham',
        lastnameL: 'ຂຸນພົມ',
        lastnameE: 'KHOUNPHOM',
        gender: 'FEMALE',
        faculty: 'ວິທະຍາສາດທຳມະຊາດ',
        department: 'ການພັດທະນາໂປຣແກຣມຄອມພີວເຕີ',
        general: 'III',
        workAt: 'ບໍລິສັດ ຮັບພັດທະນາລາວ ແລະ ທີ່ປືກສາ ຈຳກັດຜູ້ດຽວ',
        phonemobile: '020 93830107',
        whatsapp: '020 93830107',
        email: 'koon7819@gmail.com',
        facebook: 'Devant Koon',
        img: 'https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/%E0%BA%99%E0%BA%B2%E0%BA%87+%E0%BA%94%E0%BA%AD%E0%BA%81%E0%BA%84%E0%BA%B9%E0%BA%99%E0%BA%84%E0%BA%B3+%E0%BA%82%E0%BA%B8%E0%BA%99%E0%BA%9E%E0%BA%BB%E0%BA%A1.JPG',
    },
    {
        id: '3',
        firstnameL: 'ກຸ້ງນາງ',
        firstnameE: 'Koungnang',
        lastnameL: 'ຄຳສິງສະຫວັດ',
        lastnameE: 'KHAMSINGSAVATH',
        gender: 'FEMALE',
        faculty: 'ວິທະຍາສາດທຳມະຊາດ',
        department: 'ການພັດທະນາໂປຣແກຣມຄອມພີວເຕີ',
        general: 'III',
        workAt: 'ບໍລິສັດ ຮັບພັດທະນາລາວ ແລະ ທີ່ປືກສາ ຈຳກັດຜູ້ດຽວ',
        phonemobile: '020 91902385',
        whatsapp: '020 78358332',
        email: 'kssv8332@gmail.com',
        facebook: 'Koung Nang',
        img: 'https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/%E0%BA%99%E0%BA%B2%E0%BA%87+%E0%BA%81%E0%BA%B8%E0%BB%89%E0%BA%87%E0%BA%99%E0%BA%B2%E0%BA%87+%E0%BA%84%E0%BA%B3%E0%BA%AA%E0%BA%B4%E0%BA%87%E0%BA%AA%E0%BA%B0%E0%BA%AB%E0%BA%A7%E0%BA%B1%E0%BA%94.JPG',
    },
    {
        id: '4',
        firstnameL: 'ລີທໍ່',
        firstnameE: 'leethor',
        lastnameL: 'ເຊ່ຍເຢ້',
        lastnameE: 'XIAYE',
        gender: 'MALE',
        faculty: 'ວິທະຍາສາດທຳມະຊາດ',
        department: 'ການພັດທະນາໂປຣແກຣມຄອມພີວເຕີ',
        general: 'III',
        workAt: 'IT Capital',
        phonemobile: '020 77929602',
        whatsapp: '020 77929602',
        email: 'lithor99@gmail.com',
        facebook: 'Limit Thor',
        img: 'https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/%E0%BA%97%E0%BB%89%E0%BA%B2%E0%BA%A7+%E0%BA%A5%E0%BA%B5%E0%BA%97%E0%BB%8D.JPG',
    },
    {
        id: '5',
        firstnameL: 'ພິມພິກາ',
        firstnameE: 'phimpheeka',
        lastnameL: 'ອິນທະເສີດ',
        lastnameE: 'INTHASERD',
        gender: 'FEMALE',
        faculty: 'ວິທະຍາສາດທຳມະຊາດ',
        department: 'ການພັດທະນາໂປຣແກຣມຄອມພີວເຕີ',
        general: 'III',
        workAt: 'ບໍລິສັດ ຮັບພັດທະນາລາວ ແລະ ທີ່ປືກສາ ຈຳກັດຜູ້ດຽວ',
        phonemobile: '020 91524266',
        whatsapp: '020 91524266',
        email: 'tainthaserd@gmail.com',
        facebook: 'Ta Intaserd',
        img: 'https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/%E0%BA%99%E0%BA%B2%E0%BA%87+%E0%BA%9E%E0%BA%B4%E0%BA%A1%E0%BA%9E%E0%BA%B4%E0%BA%81%E0%BA%B2+%E0%BA%AD%E0%BA%B4%E0%BA%99%E0%BA%97%E0%BA%B0%E0%BB%80%E0%BA%AA%E0%BA%B5%E0%BA%94.JPG',
    },
    {
        id: '6',
        firstnameL: 'ຄຳຫຼ້າ',
        firstnameE: 'Khamla',
        lastnameL: 'ຊີຢີຫວ່າງ',
        lastnameE: 'SIYIVANG',
        gender: 'MALE',
        faculty: 'ວິທະຍາສາດທຳມະຊາດ',
        department: 'ການພັດທະນາໂປຣແກຣມຄອມພີວເຕີ',
        general: 'III',
        workAt: 'Huawei Technologies (Lao) Sole Co., Ltd.',
        phonemobile: '020 55742147',
        whatsapp: '020 55742147',
        email: 'ksiyivang@gmail.com',
        facebook: 'Khamla Zyv',
        img: 'https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/%E0%BA%97%E0%BB%89%E0%BA%B2%E0%BA%A7+%E0%BA%84%E0%BA%B3%E0%BA%AB%E0%BA%BC%E0%BB%89%E0%BA%B2.JPG',
    },
]