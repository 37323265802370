import React, { useEffect, useState } from "react";
export const EmeraldIfram = () => {
  const [isInsideIframe, setIsInsideIframe] = useState(false);

  useEffect(() => {
    // Check if the component is inside an iframe
    setIsInsideIframe(window.self !== window.top);
  }, []);

  return (
    <div>
      {isInsideIframe && (
        <a
          href="https://www.emerald.com/insight/"
          referrerPolicy="no-referrer-when-downgrade"
          target="_blank"
        >
          <button className="buttonEmerald">ຄົ້ນຫາບົດຄົ້ນຄວ້າ</button>
        </a>
      )}
    </div>
  );
};

// import React, { useEffect, useState } from 'react';

// export const EmeraldIfram = () => {
//   const [isInsideIframe, setIsInsideIframe] = useState(false);

//   useEffect(() => {
//     // Check if the component is inside an iframe
//     setIsInsideIframe(window.self !== window.top);

//     // Check if the referrer matches a specific domain
//     const referrer = document.referrer;
//     if (referrer.indexOf('https://www.daohoung.com/') === 0) {
//       // Replace 'https://www.example.com/' with your specific domain
//       setIsInsideIframe(false); // Allow access
//     }
//   }, []);

//   return (
//     <div>
//       {isInsideIframe && (
//         <a href="https://www.emerald.com/insight/" referrerPolicy="no-referrer-when-downgrade" target="_blank">
//           <button className="buttonEmerald">ຄົ້ນຫາບົດຄົ້ນຄວ້າ</button>
//         </a>
//       )}
//     </div>
//   );
// };
