import React, { useState, useRef } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { FormControl } from "@material-ui/core";
import useReactRouter from "use-react-router";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { isBrowser, isMobile } from "react-device-detect";

// const ----------------------------------------------------------------->
import { USER_KEY } from "../../consts/user";
import { LOGIN_USER } from "../../apollo/user";

function Login() {
  const { history } = useReactRouter();

  // apollo -------------------------------------------------------------->
  const [loginUser] = useMutation(LOGIN_USER);

  // use ----------------------------------------------------------------->
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user) {
      history.push("/dashboard");
    }
  }, []);

  // state ----------------------------------------------------------------->
  const [errStatus, setErrStatus] = useState(false);
  const [userIdLogin, setUserIdLogin] = useState(null);
  const [passLogin, setPassLogin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [helper, setHelper] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageError, setMessageError] = useState("");

  const showHelper = () => setHelper(true);

  const _onEnterKey = (e) => {
    if (e.key === "Enter") {
      _onLoginUser();
    }
  };

  const _onLoginUser = async () => {
    const url = "http://localhost:8000/api/v1/sts/" + userIdLogin;

    try {
      setIsLoading(true);
      setErrStatus(false);
      try {
        const data = await loginUser({
          variables: {
            data: {
              userId: userIdLogin,
              password: passLogin,
            },
          },
        });
        const user = JSON.stringify(data.data.loginUser);
        localStorage.setItem(USER_KEY, user);

        setIsLoading(false);

        if (passLogin === "123456") {
          localStorage.setItem("editPassword", true);
          history.push("/");
        } else if (isMobile) {
          history.push("/dashboard");
        } else if (isBrowser) {
          history.push("/course-list");
        }
      } catch (err) {
        setErrStatus(true);
        setIsLoading(false);
      }
    } catch (err) {
      setMessageError("ລະບົບມີບັນຫາ");
      setShowAlert(true);
    }
  };

  const AlterDialog = () => (
    <div className="my-modal">
      <div className="modal-content">
        <h2>ຂໍອະໄພ</h2>
        <p>{messageError}</p>
        <button onClick={() => setShowAlert(false)} className="newbt w-25 mt-4">
          ຕົກລົງ
        </button>
      </div>
    </div>
  );

  return (
    <div className="main-bg">
      {showAlert && <AlterDialog />}
      <div className="row">
        <div className="px-5 text-center">
          <div>
            <img
              style={{ marginTop: 20, width: 150, height: "150px" }}
              src="/assets/new_logo_nuol.png"
            />
          </div>
          <div>ມະຫາວິທະຍາໄລແຫ່ງຊາດ</div>

          <Formik
            initialValues={{ userId: "", password: "" }}
            validate={(values) => {
              const errors = {};
              setUserIdLogin(values.userId);
              setPassLogin(values.password);
              if (!values.userId) {
                errors.userId = "ກະລຸນາປ້ອນໄອດີ";
              }
              if (!values.password) {
                errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານ";
              }
              return errors;
            }}
            onSubmit={async (values, { onSubmitting }) => {
              _onLoginUser();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div className="mt-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <FormControl>
                      <input
                        className="form-control p-2 mb-2"
                        type="text"
                        name="userId"
                        placeholder="ໄອດີ"
                        value={values.userId}
                        error={!!errors.userId}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <Form.Control.Feedback type="invalid">
                      {errors.userId}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <FormControl className="mt-2">
                      <input
                        className="form-control p-2 mb-2"
                        type="password"
                        name="password"
                        placeholder="ລະຫັດຜ່ານ"
                        value={values.password}
                        isInvalid={!!errors.password}
                        onChange={handleChange}
                        onKeyDown={(e) => _onEnterKey(e)}
                        tabIndex="0"
                      />
                    </FormControl>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <Form.Group as={Row} controlId="formPlaintextPassword">
                  <FormControl className="mt-2" fullWidth>
                    <button
                      style={{
                        backgroundColor: "#1982c4",
                        color: "white",
                        padding: "8px",
                        width: "100%",
                        borderRadius: "7px",
                      }}
                      onClick={() => handleSubmit()}
                      type="submit"
                      className="btn"
                    >
                      ເຂົ້າສູ່ລະບົບ
                    </button>
                  </FormControl>
                </Form.Group>

                <div className="text-dark text-center mt-3">
                  ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?
                  <span
                    style={{
                      color: "#057CAE",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => showHelper(true)}
                  >
                    {" "}
                    ຊ່ວຍ​ເຫຼືອ
                  </span>
                  <div className="mt-2">
                    ນະໂຍບາຍ ແລະ ຄວາມເປັນສ່ວນຕົວ
                    <span
                      style={{
                        color: "#057CAE",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push("/policy")}
                    >
                      {" "}
                      {"  "} Policy
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
      <Modal show={helper} onHide={() => setHelper(false)} animation={false}>
        <Modal.Header
          style={{
            backgroundColor: "#057CAE",
            color: "white",
            justifyContent: "center",
            fontSize: 40,
            fontWeight: "bold",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <div>ຕິດ​ຕໍ່​ພວກ​ເຮົາ</div>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "#707070",
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          <p>ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ​</p>
          <p>ເບີ​ໂທ​ລະ​ສັບ:</p>
          <p>+85620 2222 2222</p>
          <p>+85620 3333 3333</p>
          <p>ອີ​ເມວ:</p>
          <p>support@domain.com</p>
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "center",
            borderTop: 0,
          }}
        >
          <Button
            variant="secondary"
            onClick={() => setHelper(false)}
            className="newbt"
            style={{ width: "200px", fontSize: "20px", height: "50px" }}
          >
            ປິດ
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
