import { gql } from "apollo-boost";

export const PRE_SIGNED_URL = gql`
  query PreSignedUrl($mimeType: String) {
    preSignedUrl(mimeType: $mimeType) {
      url
    }
  }
`;

export const FILES = gql`
  query Files(
    $where: CustomFileWhereInput
    $orderBy: FileOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    files(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      data{
        id
        title
        description
        file
        keyword
        type
        cate
        course {
          id
          courseCode
          title
          unit
        }
        department {
          id
          name
        }
        faculty {
          id
          name
          description
        }
        createdBy {
          id
          firstname
          lastname
          userId
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const FILE = gql`
  query File($where: FileWhereUniqueInput!) {
    file(where: $where) {
      id
      title
      description
      file
      keyword
      type
      cate
      course {
        id
        courseCode
        title
      }
      department {
        id
        name
      }
      faculty {
        id
        name
        description
      }
      createdBy {
        id
        firstname
        lastname
        userId
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_FILE = gql`
  mutation CreateFile($data: FileCreateInput!) {
    createFile(data: $data) {
      id
      title
    }
  }
`;

export const UPDATE_FILE = gql`
  mutation UpdateFile($data: FileUpdateInput!, $where: FileWhereUniqueInput!) {
    updateFile(data: $data, where: $where) {
      id
      title
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($where: FileWhereUniqueInput!) {
    deleteFile(where: $where) {
      id
      title
    }
  }
`;
