export const SHOPS = [
  {
    id: "1",
    name: "ຮ້ານ ໃຕ້ຟ້າການແວ່ນ ( TAIFA OPTICAL CENTER )",
    type: "store",
    logo: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/tai_fai-100.jpg",
    phone: "020 5937 4499",
    address: "ສາຂາສຳນັກງານໃຫຍ່ ບ້ານ ໜອງບອນ, ທາງເຂົ້າຕະຫຼາດຂົວດິນ",
    about: "ຮ້ານ ໃຕ້ຟ້າການແວ່ນ ເປັນຮ້ານຂາຍແວ່ນ ແລະ ຕັດແວ່ນຕາ",
    discountImage:"https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster+tainfar-100.jpg?fbclid=IwAR0SC5QF_a3Hou25lo4IXXO-l2ahlVEUemY0d_xejGaLa4MKURrxcnr8YFA",
    map: "https://www.google.com/maps/place/Taifa+Optical+Center/@17.9647617,102.615639,19.08z/data=!4m5!3m4!1s0x31246880aae06797:0xdb6a006eca5ac0fe!8m2!3d17.9649703!4d102.6160087",
    facebook: "TAIFA OPTICAL CENTER",
    detail:
      "ນັກສຶກສາ ຫຼຸດ 30% : <br /> - ສຳລັບຂອບແວ່ນສາຍຕາ <br /> - ເລນສາຍຕາ <br /> - ແວ່ນຕາກັນແດດ ( ບໍ່ນັບສິນຄ້າລາຄາພິເສດ ຫຼື ສິນຄ້າ on sale -50%,-70% )",
    slide: [
      "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster+tainfar-100.jpg?fbclid=IwAR0SC5QF_a3Hou25lo4IXXO-l2ahlVEUemY0d_xejGaLa4MKURrxcnr8YFA",
      "https://drive.google.com/uc?id=117axf7eaM0c8lh_1xXNRapK_5cXQfnbJ",
      "https://drive.google.com/uc?id=10zeVxEBKu31zcIeM9iMN0w1-9hrc16gn",
      "https://drive.google.com/uc?id=10l_wGQB-d94LY-vYob4yJRH6-n2Hp7QN",
      
    ],
  },
  // {
  //   id: "2",
  //   name: "Jເຈນເຝີເຮືອຕົ້ນຕຳລັບ ສາຂາ02",
  //   type: "food",
  //   logo: "https://drive.google.com/uc?id=12qKDlRDp8RodGzdnOiAzJxGVh_7DQ5fW",
  //   phone: "020 5995 9946",
  //   address: "ບ້ານສາຍນ້ຳເງິນ, ເມືອງໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ",
  //   map:"",
  //   facebook: "",
  //   detail: "ນັກສຶກສາມາກິນນຳກັນ 02 ຄົນຂື້ນໄປ ຫຼຸດ 15% ຂອງລາຄາອາຫານ",
  //   slide: ["https://drive.google.com/uc?id=139fhiv7oo0gyIZ66-_2Gwr3Y8ef0PV5g"],
  // },
  {
    id: "3",
    name: "ຮ້ານນາງຕຸ້ຍເຂົ້າຊອຍແຊບຕົ້ນຕຳລັບເມືອງເໜືອ",
    type: "food",
    logo: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/touy_khao_zoy_zap-100.jpg",
    phone: "020 5585 2697",
    address: "ຮ່ອມຫຼັງຫໍພັກ ມຊ, ບ້ານ ດົງໂດກ, ເມືອງ ໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ",
    about: "ຮ້ານນາງຕຸ້ຍເຂົ້າຊອຍແຊບຕົ້ນຕຳລັບເມືອງເໜືອ",
    discountImage:"https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster-100.jpg",
    map: "https://www.google.com/maps/place/%E0%BA%AE%E0%BB%89%E0%BA%B2%E0%BA%99%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%8A%E0%BA%AD%E0%BA%8D/@18.0452801,102.6379445,20.65z/data=!4m13!1m7!3m6!1s0x0:0xacdb5da02ddeaa44!2zMTjCsDAyJzQ0LjUiTiAxMDLCsDM4JzE3LjYiRQ!3b1!8m2!3d18.0456924!4d102.6382217!3m4!1s0x31245de81e52dc0d:0x1cd2f0dde801619b!8m2!3d18.0453155!4d102.6379825?hl=th",
    facebook: "",
    detail: "ອາຫານ + ເຄື່ອງດື່ມໜຶ່ງຢ່າງຫຼຸດ 1000 ກີບ",
    slide: [
      "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster-100.jpg",
    ],
  },
  {
    id: "4",
    name: "ຮ້ານຕຳແຊບຕູບນ້ອຍອິນເຕີ",
    type: "food",
    logo: "https://drive.google.com/uc?id=11kPRqYGvIHq9tWA9D-Kkh3waIRGH4NQu",
    phone: "030 5346 252",
    address: "ບ້ານໜອງວຽງຄຳ, ເມືອງ ໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ",
    about: "ຮ້ານຕຳແຊບຕູບນ້ອຍອິນເຕີ",
    discountImage: "https://drive.google.com/uc?id=11kPRqYGvIHq9tWA9D-Kkh3waIRGH4NQu",
    map: "",
    facebook: "",
    detail: "ສຳລັບນັກສຶກສາແມ່ນ ຫຼຸດ 5% ຂອງມູນຄ່າປະເພດອາຫານ",
    slide: [
      "https://drive.google.com/uc?id=11dCwZ_YPyg9EgPGPX-eM7O4IqpTm-9Sd",
      "https://drive.google.com/uc?id=11eJE4ctjsEI5XfJNSXlTI6fN__KbUD4B",
      "https://drive.google.com/uc?id=11fSlyPrG-rZy1Uc4IKjR5avV2LK6G35r",
      "https://drive.google.com/uc?id=12o7R44zEhNhZuh594Zj0sniPbQrq66KP",
    ],
  },
  {
    id: "5",
    name: "ຮ້ານໝີ່ກ້ຽວຄຳຮຸ່ງ ສາຂາ 03",
    type: "food",
    logo: "https://drive.google.com/uc?id=12XoLaPwfqWh6KfhiH1IAhbMVi7PM4kEj",
    phone: "020 2220 7733",
    address: "ບ້ານ ຕານມີໄຊ, ເມືອງ ໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ",
    about: "ຮ້ານໝີ່ກ້ຽວຄຳຮຸ່ງ ສາຂາ 03",
    discountImage: "https://drive.google.com/uc?id=12XoLaPwfqWh6KfhiH1IAhbMVi7PM4kEj",
    map: "",
    facebook: "",
    detail:
      "ສຳລັບນັກສຶກສາ ແມ່ນຫຼຸດ 15% ຂອງມູນຄ່າອາຫານ ແລະ ພິເສດສຳລັບຜູ້ທີ່ຕິດຕາມເພກຂອງທາງຮ້ານຈະໄດ້ຮັບໂປຣພິເສດເພີ່ມ ( ແຕ່ຕ້ອງແຈ້ງທາງຮ້ານກ່ອນ )",
    slide: [
      "https://drive.google.com/uc?id=12ZZ23yr2uYB7ulqSgdcMvUaO7u_EmNI6",
      "https://drive.google.com/uc?id=12ZDot8BRogeLm8R2vPYdTabV1UTcuCrq",
      "https://drive.google.com/uc?id=137pMXRwzcn6kPbZnQg9mMqkmjQR6lV3v",
    ],
  },
  {
    id: "6",
    name: "ຮ້ານຕັດຜົມຊົງຊາຍ",
    type: "store",
    logo: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/tud_phom-100.jpg",
    phone: "020 2244 4279",
    address: "ສີແຍກນາທົ່ມ ( ນວນຕາ ), ເມືອງໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ.",
    about: "ຮ້ານຕັດຜົມຊົງຊາຍ",
    discountImage: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster1-100.jpg",
    map: "https://www.google.com/maps/place/18%C2%B003'16.7%22N+102%C2%B037'50.8%22E/@18.0546474,102.6285944,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xbb4d69a66ed7d5d!8m2!3d18.0546474!4d102.6307831?hl=th",
    facebook: "",
    detail:
      "- ມາຕັດຜົມຄົບ 3 ຄົນໄດ້ຮັບສ່ວນຫຼຸດ 6000 ກີບ <br />- ມາຕັກຜົມຄົບ 3 ເທື່ອໄດ້ຕັດຟຣີອີກ 1 ເທື່ອ",
    slide: [
      "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster1-100.jpg",
    ],
  },
  {
    id: "7",
    name: "ສະບາຍດີຕານມີໄຊ",
    type: "food",
    logo: "https://drive.google.com/uc?id=1WXMb0jDljyvZ98qh0xOiVO3kE1QZ6zGm",
    phone: "020 9110 4879",
    address: "ບ. ຕານມີໄຊ ຂ້າງຄະນະປ່າໄມ້",
    about: "ສະບາຍດີຕານມີໄຊ",
    discountImage: "https://drive.google.com/uc?id=1WXMb0jDljyvZ98qh0xOiVO3kE1QZ6zGm",
    map: "",
    facebook: "",
    detail: "ເຊັກບິນຄົບ 40,000ກີບຂື້ນໄປ ແຖມ Free ແປັບຊີ່ 01 ແກ້ວ",
    slide: [
      "https://drive.google.com/uc?id=18LYZPkGfqUNKy1J97-u4M5lPgA96LeBn",
      "https://drive.google.com/uc?id=1a2KKug0p5mz3g99XOluVNwx2LnDoTZ2t",
      "https://drive.google.com/uc?id=1Sj0sI7zLrMGetevQQlkyVBbOxqZ--xl_",
    ],
  },
  {
    id: "8",
    name: "ທຽນຫອມພັນລະນາ",
    type: "store",
    logo: "https://drive.google.com/uc?id=17NMx1iJoqpGkwhgYithIVp07G-jJ8eYN",
    phone: "020 5553 6978",
    address: "ບ. ເມືອງວາທົ່ງ, ມ. ສີໂຄດຕະບອງ",
    about: "ທຽນຫອມພັນລະນາ",
    discountImage: "https://drive.google.com/uc?id=17NMx1iJoqpGkwhgYithIVp07G-jJ8eYN",
    map: "",
    facebook: "",
    detail:
      "ສັ່ງຊື້ຜ່ານຊ່ອງທາງອອນໄລນ໌ຂອງຮ້ານ ຫຼຸດ 10% ( ທຸກໆກິ່ນ, ບໍ່ມີຂັ້ນຕ່ຳ )",
    slide: [
      "https://drive.google.com/uc?id=1G50RfN1pAzhJPa6jk1VoGzzVEs6aJioh",
      "https://drive.google.com/uc?id=1df0QWOXVNOCOSbqQP0-EgV32Sg0iMdMQ",
      "https://drive.google.com/uc?id=10lXJqyXu7QDpNCSoUiFUtZIBQVjMY3em",
    ],
  },
  {
    id: "9",
    name: "The Tamper Coffee & Studio",
    type: "food",
    logo: "https://drive.google.com/uc?id=1M_cmzjDWIyKAqwDpPAkgsjf80FXY1jVm",
    phone: "030 5513 903",
    address: "ບ. ສະພານທອງເໜືອ, ມ ສີສັດຕະນາກ",
    about: "The Tamper Coffee & Studio",
    discountImage: "https://drive.google.com/uc?id=1M_cmzjDWIyKAqwDpPAkgsjf80FXY1jVm",
    map: "",
    facebook: "",
    detail:
      "1. ຫ້ອງ Learning Space ຫຼຸດ 30% ແລະ ກໍລະນີເຊົ່າເຄີ່ງມື້ ຟຣີເຄື່ອງດື່ມ 02 ຈອກ ຮ້ອນ ຫຼື ເຢັນ ( ເມນູໃດກໍ່ໄດ້ ) <br /> 2. ເຄື່ອງດື່ມ ແລະ ເຂົ້າໜົມຫຼຸດ 10% ( ເມນູໃດກໍ່ໄດ້ ).<br /> 3. ຊື້ເຄື່ອງດື່ມ 05 ຈອກຂື້ນໄປ ແຖມຟຣີ 01 ຈອກ ( ສະເພາະເມນູເຢັນເຄື່ອງດື່ມໃດກໍ່ໄດ້ ).",
    slide: [
      "https://drive.google.com/uc?id=1o3YXmqV7NoREqlWaXVziP4-lIHUHtE0v",
      "https://drive.google.com/uc?id=12NfttCy5_0jKFHEroUmyMT-vEub2kCFk",
      "https://drive.google.com/uc?id=1OW-r0ZD62_kDdXsq3XORaNVseNn5eySy",
    ],
  },
  {
    id: "10",
    name: "ລັດສະໝີຊີ້ນດາດຈານໃຫຍ່",
    type: "food",
    logo: "https://drive.google.com/uc?id=1Qx_B5Eyc9GH63Wr7vCYLcFmFFN9boUT8",
    phone: "020 5433 5355",
    address: "ບ. ຕານມີໄຊ, ມ. ໄຊທານີ, ນວຈ",
    about: "ລັດສະໝີຊີ້ນດາດຈານໃຫຍ່",
    discountImage: "https://drive.google.com/uc?id=1Qx_B5Eyc9GH63Wr7vCYLcFmFFN9boUT8",
    map: "",
    facebook: "",
    detail:
      "ສຳລັບນັກສຶກສາຫຼຸດ 10%( ສະເພາະອາຫານບໍ່ລວມເຄື່ອງດື່ມ ), ພິເສດມາໃຊ້ບໍລິການທາງຮ້ານຄົບ 05ຄັ້ງ ຮັບຟຣີຊີ້ນດາດຊຸດໃຫຍ່ 01ຊຸດ.",
    slide: [
      "https://drive.google.com/uc?id=1euHY1P0zIIF7kzsl1inYv2RDQ2pFk7PI",
      "https://drive.google.com/uc?id=1s6ruWPIvof_BNvxIAR3EBbRo27YsPNBC",
      "https://drive.google.com/uc?id=1zfesAHPa4CsQWTynNVcin3CalKdJ1UDO",
    ],
  },
  {
    id: "11",
    name: "AEC Eyewear",
    type: "store",
    logo: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/aec+eyewear-100.jpg",
    phone: "020 9766 7713",
    address: "ບ. ຊ້າງຄູ່ ( ຕິດກັບຮ້ານດອຍຊ້າງຄ໊ອບຟີ )",
    about: "AEC Eyewear",
    discountImage: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/aec+eyewear-100.jpg",
    map: "https://www.google.com/maps/place/18%C2%B001'59.4%22N+102%C2%B038'17.4%22E/@18.0327734,102.6367645,17.78z/data=!4m5!3m4!1s0x0:0xc9e9a32534994459!8m2!3d18.0331789!4d102.6381658?hl=th",
    facebook: "",
    detail:
      "1 ຊື້ເລນ Essilor ຮັບສ່ວນຫຼຸດທັນທີ 30% <br /> 2 ແວ່ນສາຍຕາປົກກະຕິຂາຍ 1,500 ບາດ ນັກສຶກສາມາຊື້ຫຼຸດເຫລືອ1,000 ບາດ <br /> 3 ແວ່ນກັນແດດປົກກະຕິລາຄາ 5,000 ບາດ   ຫຼຸດເຫລືອ 2,500 ບາດ <br /> 4 ຊື້ແວ່ນຕາມູນຄ່າ 1,000,000ກີບຂື້ນໄປ ( ລຸ້ນໃດກໍ່ໄດ້ ) ມີບັດສ່ວນຫຼຸດໃຫ້ ມູນຄ່າ 300,000 ກີບເພື່ອຊື້ສິນຄ້າຕັ້ງຕໍ່ໄປ. <br /> 5 ຕັດແວ່ນ ( ຂອບ+ເລນກັນແສງ ) ຈາກລາຄາປົກກະຕິ 900,000 ກີບ ຫຼຸດເຫຼືອ 500,000 ກີບ ເທົ່ານັ້ນ.",

    slide: [
      "https://drive.google.com/uc?id=11Vbp7cframyV1IlKn1YChAFPgVtc5Swj",
      "https://drive.google.com/uc?id=1ijcodwYQjmAVIAlwqufa6jVs7hi4_tAD",
      "https://drive.google.com/uc?id=1OrbzGBn1sdRFcFXwaE0PpNB4XcMu4Y01",
    ],
  },
  {
    id: "12",
    name: "The Hacker Coffee",
    type: "food",
    logo: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/hacker-100.jpg",
    phone: "020 7603 0060",
    address: "ບ. ຕານມີໄຊ, ມ ໄຊທານີ",
    about: "The Hacker Coffee",
    discountImage:"https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster-the-hacker-100.jpg",
    map: "https://www.google.com/maps/place/HackerCoffee/@18.0274053,102.6203099,20.91z/data=!4m13!1m7!3m6!1s0x0:0xddd2b09ea2591f86!2zMTjCsDAxJzM4LjciTiAxMDLCsDM3JzEzLjMiRQ!3b1!8m2!3d18.0274086!4d102.620369!3m4!1s0x312442b33f678d5b:0x70343b01adb14167!8m2!3d18.0273774!4d102.6204873?hl=th",
    facebook: "",
    detail: "ເຊັກບິນມູນຄ່າຄົບ 50,000 ກີບ ຂື້ນໄປ ຮັບສ່ວນຫຼຸດທັນທີ 10% ",

    slide: [
      "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster-the-hacker-100.jpg",
      "https://drive.google.com/uc?id=1nZM2KyHxCLqm8QCuVEX-63ppbMZcd80r",
      "https://drive.google.com/uc?id=1UN-GesOdLFb3gkKprx0jmL9hUKPl0XLY",
      "https://drive.google.com/uc?id=1pELGZT6U2HZykGsjAr6FNuoNIzYx1fRy",
    ],
  },
  {
    id: "13",
    name: "Kai Tea Shop",
    type: "food",
    logo: "https://drive.google.com/uc?id=1FXW8CpiIC1x8bxL99_IN1FCSsqbs0N7n",
    phone: "020 5963 3523",
    address: "ບ. ໂພສີ, ມ ສີໂຄດຕະບອງ",
    about: "Kai Tea Shop",
    discountImage: "https://drive.google.com/uc?id=1FXW8CpiIC1x8bxL99_IN1FCSsqbs0N7n",
    map: "",
    facebook: "",
    detail: "ຫຼຸດ 15% ທຸກໆລາຍການ ( ບໍ່ລວມລາຍການທີ່ມີໂປຣຢູ່ແລ້ວ ) ທຸກໆສາຂາ",

    slide: [
      "https://drive.google.com/uc?id=1cYfoIHaatNsI5AJ72RpJWrPgfxQs-rb5",
      "https://drive.google.com/uc?id=1azn6iR_Fu2s04JVjAMrFmr30X68cFO6E",
      "https://drive.google.com/uc?id=1_-eNh7iecZBEgtg9GxB7eBLV5J4DcxKT",
    ],
  },
  {
    id: "14",
    name: "Just My Dish",
    type: "food",
    logo: "https://drive.google.com/uc?id=1cHDXyxtshifX1hy5UrbWYc_Q-0PhiR7-",
    phone: "020 7696 9696",
    address: "ບ. ຊຽງຍືນ, ມ. ຈັນທະບູລີ",
    about: "Just My Dish",
    discountImage: "https://drive.google.com/uc?id=1cHDXyxtshifX1hy5UrbWYc_Q-0PhiR7-",
    map: "",
    facebook: "",
    detail:
      "ສະເພາະເມນູກະເພົາໄກ່ໄຂ່ດາວຫຼຸດເຫຼືອ 17,000ກີບ/ຈານ ( ຈາກລາຄາປົກະຕິ 21,000ກີບ ) ພິເສດແຖມນ້ຳດື່ມຕຸກນ້ອຍ 01ຕຸກ/ຄົນ/ມື້.",

    slide: [
      "https://drive.google.com/uc?id=1wJoppzhFJbmco87NwuLiHjhJVwu353R_",
      "https://drive.google.com/uc?id=1izaA5HRwz-YDtJFQqpkmLoe5LMBNPMU9",
      "https://drive.google.com/uc?id=14M4WQKAEEmTVhHXozmYf0H3dkKD5f-T5",
    ],
  },
  {
    id: "15",
    name: "Swan Wardrobe",
    type: "store",
    logo: "https://drive.google.com/uc?id=1lb2f762ofpHjbuR5c8ow6QlXoRhnob7a",
    phone: "020 5454 2978",
    address: "ບ. ທົ່ງສ້າງນາງ, ມ ຈັນທະບູລີ",
    about: "Swan Wardrobe",
    discountImage: "https://drive.google.com/uc?id=1lb2f762ofpHjbuR5c8ow6QlXoRhnob7a",
    map: "",
    facebook: "",
    detail:
      "ຮັບສ່ວນຫຼຸດ 15% ຂອງທຸກໆລາຍການນັບແຕ່ວັນທີ່ 01-10 ຂອງທຸກໆເດືອນ (ຍົກເວັ້ນລາຍການທີ່ທາງຮ້ານມີໂປຣສ່ວນຫຼຸດໄວ້ແລ້ວ).",

    slide: [
      "https://drive.google.com/uc?id=1NEtpvBnkMl8C7EJ9AuPVxFYT_FEga2B5",
      "https://drive.google.com/uc?id=1QUVNNL5bN8HPSuUVe_nHtPuZjb7guX3F",
      "https://drive.google.com/uc?id=1BQ1ceYoiqi57UZJs3nBEOvk0qJ1SfKt4",
    ],
  },
  {
    id: "16",
    name: "ຮ້ານອາຫານ ສາຍໃຈ",
    type: "food",
    logo: "https://drive.google.com/uc?id=1JuFsr2Jk9usL4Vp5sf4tuY1EN-tJ05OZ",
    phone: "020 5555 5132",
    address: "ບ. ໂພນຕ້ອງສະຫວາດ, ມ ຈັນທະບູລີ",
    about: "ຮ້ານອາຫານ ສາຍໃຈ",
    discountImage: "https://drive.google.com/uc?id=1JuFsr2Jk9usL4Vp5sf4tuY1EN-tJ05OZ",
    map: "",
    facebook: "",
    detail:
      "(1) ເຊັກບິນມູູນຄ່າ 100,000ກີບ ຂື້ນໄປ ຫຼຸດ 5% ( ສະເພາະປະເພດອາຫານ ). <br /> (2) ເຊັກບິນມູູນຄ່າ 500,000ກີບ ຂື້ນໄປ ຫຼຸດ 10% ( ສະເພາະປະເພດອາຫານ ).",

    slide: [
      "https://drive.google.com/uc?id=1JuFsr2Jk9usL4Vp5sf4tuY1EN-tJ05OZ",
      "https://drive.google.com/uc?id=1y1B9psj3Gwx1MLhvt9VqGt-EU_qIidHF",
      "https://drive.google.com/uc?id=14OfZX--png2CVSQA6ZZTKAyhl8gCssOc",
    ],
  },
  {
    id: "17",
    name: "DongDok Smile Dental Clinic",
    type: "store",
    logo: "https://drive.google.com/uc?id=1kgjqtkG5NP9M26kTLXZ8jwZ2SVar2-tt",
    phone: "020 5222 2069",
    address: "ບ. ດົງໂດກ, ມ ໄຊທານີ",
    about: "DongDok Smile Dental Clinic",
    discountImage: "https://drive.google.com/uc?id=1kgjqtkG5NP9M26kTLXZ8jwZ2SVar2-tt",
    map: "https://www.google.com/maps?q=18.041765213012695,102.6342544555664&z=17&hl=th",
    facebook: "",
    detail:
      "- ດັດແຂ້ວຖາວອນ ຈ່າຍກ່ອນ 700,000ກີບ ຮັບຟຣີຣີເທັນເນີ້ແບບເຫຼັກ ແລະ ແບບຢາງ, ໃສ່ດອກແຂ້ວຟຣີ, ຂູດຫີນປູນຟຣີ",

    slide: [
      "https://drive.google.com/uc?id=1XhJ-uJEUFJu4WO-dxb6VG2mC7D8wjF2q",
      "https://drive.google.com/uc?id=13VMGm3sDQ-yE-V2UCq5ZncAtq3jNAHwh",
    ],
  },
  {
    id: "18",
    name: "SweetHeart Shop",
    type: "store",
    logo: "https://drive.google.com/uc?id=1G-rI2LvSAb00VxZEIooM2dcOa12nUpzN",
    phone: "020 5933 3688",
    address: "ບ. ດົງໂດກ, ມ ໄຊທານີ",
    about: "SweetHeart Shop",
    discountImage: "https://drive.google.com/uc?id=1G-rI2LvSAb00VxZEIooM2dcOa12nUpzN",
    map: "",
    facebook: "",
    detail: "ໂປຣໂມຊັ່ນຊື້ 01ຊຸດ ແຖມ 01ຊຸດ (ສະເພາະສິນຄ້າທີ່ຮ້ານຈັດໄວ້ໃຫ້)",

    slide: [
      "https://drive.google.com/uc?id=13vDBtvscdTAnzP1cCMwSZ8c9AzlNRGUP",
      "https://drive.google.com/uc?id=1COuvInmDXw4DuihDc0atVE5OGpQVs8mE",
      "https://drive.google.com/uc?id=1rt_gdFvDeBg6HBI15Ki1s2KVm2fx-el0",
    ],
  },
  {
    id: "19",
    name: "ເອື້ອຍຫລ້າ ຊາຍສີ່ມໝີ່ກ້ຽວ",
    type: "food",
    logo: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/auey_lar_zay_4_mee_kiew.jpg",
    phone: "020 5483 4271",
    address: "ບ້ານ ຫ້ວຍຫົງ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ",
    about: "ເອື້ອຍຫລ້າ ຊາຍສີ່ມໝີ່ກ້ຽວ",
    discountImage:"https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster100.jpg",
    map: "https://www.google.com/maps/place/18%C2%B000'49.5%22N+102%C2%B036'54.7%22E/@18.013735,102.6129935,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x67e172096a53f8e!8m2!3d18.013735!4d102.6151822?hl=th",
    facebook: "",
    detail: "ມາຄົບ 5 ຄົນຫຼຸດ 10 ເປີເຊັນ ແຖມນ້ຳດື່ມຕຸກນ້ອຍ/ຄົນ/ຕຸກ",

    slide: [
      "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster100.jpg",
    ],
  },
  {
    id: "20",
    name: "ຮ້ານເສື້ອຜ້າ ລູກຫລ້າ",
    type: "store",
    logo: "https://drive.google.com/uc?id=1H6UwE7AhC-ts35jCS_RQyZAl57Blc0zm",
    phone: "020 56445594",
    address: "ບ. ວັດນາກ, ມ ສີສັດຕະນາກ",
    about: "ຮ້ານເສື້ອຜ້າ ລູກຫລ້າ",
    discountImage: "https://drive.google.com/uc?id=1H6UwE7AhC-ts35jCS_RQyZAl57Blc0zm",
    map: "",
    facebook: "",
    detail: "ຫຼຸດ 10% ທຸກລາຍການ (ຍົກເວັ້ນສິນຄ້າທີ່ຖືກຈັດໂປຣຢູ່ແລ້ວ)",

    slide: [
      "https://drive.google.com/uc?id=1cOuhKcuS58ekI-wcEJfjL2EoV3llODUR",
      "https://drive.google.com/uc?id=1eVnhcmxRs0snGcttEJXN2y7VUn1rzg7f",
      "https://drive.google.com/uc?id=1Bj7yEN3l3tiDFk_SqEIIVX-ZneonX-A9",
    ],
  },
  {
    id: "21",
    name: "NN Convenient Store",
    type: "store",
    logo: "https://drive.google.com/uc?id=1zW5TpSiIPYfgOuLutgBzb8Z5cddn54tM",
    phone: "020 5545 4515, 030 453 8393",
    address: "ບ ໜອງຈັນ, ມ ສີສັດຕະນາກ",
    about: "NN Convenient Store",
    discountImage: "https://drive.google.com/uc?id=1zW5TpSiIPYfgOuLutgBzb8Z5cddn54tM",
    map: "",
    facebook: "",
    detail: "ຊື້ສິນຄ້າຄົບ 100,000 ກີບຂຶ້ນໄປ ຫຼຸດ 5% ",

    slide: [
      "https://drive.google.com/uc?id=1QfxT6Dfw6B2UH8nASn2489WL1r7OGllj",
      "https://drive.google.com/uc?id=13DCFvBF-OrOcShRmPX-y2TDjUn7C6eiQ",
      "https://drive.google.com/uc?id=1rXEPEKYPyHySlvaArLD7kBrbfXmQdgSz",
    ],
  },
  {
    id: "22",
    name: "My Man Shop (online)",
    type: "store",
    logo: "https://drive.google.com/uc?id=1L-4P87fmC_NdTY3On6LvjS0TbdtKCBHf",
    phone: "020 2262 4563",
    address: "ບ ຫ້ວຍຫົງ, ມ ຈັນທະບູລີ",
    about: "My Man Shop (online)",
    discountImage: "https://drive.google.com/uc?id=1L-4P87fmC_NdTY3On6LvjS0TbdtKCBHf",
    map: "",
    facebook: "",
    detail:
      "ຫຼຸດ 15% ທຸກລາຍການ (ສະເພາະສິນຄ້າທີ່ຮ່ວມລາຍການ ແລະ ຍົກເວັ້ນສິນຄ້າທີ່ຖືກຈັດໂປຣຢູ່ແລ້ວ)",

    slide: [
      "https://drive.google.com/uc?id=12dKPc9ZYfmU5A95VDb9iuxMDsFYj2EGM",
      "https://drive.google.com/uc?id=121DdqjgkOZv4w5eMVhJgfe1OAZthHPA0",
      "https://drive.google.com/uc?id=1ZcxTfzyDxDJT7smZHk2ADwJcwUJMMea5",
    ],
  },
  {
    id: "23",
    name: "ຮ້ານອາຫານ ສອງແມ່ລູກ",
    type: "food",
    logo: "https://drive.google.com/uc?id=1VZ9ew91yr4_d_NhKBdj4ixVop6R1WYhO",
    phone: "020 5478 6888, 030 555 0993",
    address: "ບ. ໂພນຕ້ອງສະຫວາດ, ມ ຈັນທະບູລີ",
    about: "ຮ້ານອາຫານ ສອງແມ່ລູກ",
    logo: "https://drive.google.com/uc?id=1VZ9ew91yr4_d_NhKBdj4ixVop6R1WYhO",
    map: "",
    facebook: "",
    detail:
      "ສ່ວນຫລຸດ 5% (ບໍ່ມີຂັ້ນຕໍ່າ) ແລະ ແຖມນໍ້າດື່ມນ້ອຍ (350ml) 1 ຕຸກ/ຄົນ/ມື້",

    slide: [
      "https://drive.google.com/uc?id=1u35KN2uQ0ELMMd_vqNbrUwE4LkFPdIj6",
      "https://drive.google.com/uc?id=1zmbihhxTbcgI6eVUyeIMMRI16iRC58VM",
      "https://drive.google.com/uc?id=1bc3G7NEaZFr_xp_SOv526Gi7lUzNp7-5",
    ],
  },
  // {
  //   id: "24",
  //   name: "ຮ້ານຊາໄຂ່ມຸກ ເຊັນຊູ",
  //   type: "food",
  //   logo: "https://drive.google.com/uc?id=1yCwk_-03H4LLLgZWzBi8aKnqqnCWsKFm",
  //   phone: "020 22949495",
  //   address: "ບ. ໂພນຕ້ອງສະຫວາດ, ມ ຈັນທະບູລີ",
  //   about: "ຮ້ານຊາໄຂ່ມຸກ ເຊັນຊູ",
  //   map:"",
  //   facebook: "",
  //   detail:
  //     "1) ຊື້ເຄື່ອງດື່ມ 3 ຈອກຂຶ້ນໄປ, ຟຣີ 1 ຈອກ (ສະເພາະມນູທີ່ຮ້ານຈັດໄວ້ໃຫ້); 2) ຊື້ 1 ຈອກ ຟຣີທ໋ອບປິ້ງໄຂ່ມຸກ",

  //   slide: [
  //     "https://drive.google.com/uc?id=1uhWaA6rL_kP-V-j8RJD6PZ1K3tOogMZe",
  //     "https://drive.google.com/uc?id=1R-LGczUA0yx4o8VbyMgzedNmYU9AuH0-",
  //     "https://drive.google.com/uc?id=1vwDcmhJdLf8SdIPTmurUsPelo0JB7l5I",
  //   ],
  // },
  // {
  //   id: "25",
  //   name: "NN Closet",
  //   type: "store",
  //   logo: "https://drive.google.com/uc?id=1lQw4SBJpsD8ybL5oZWROWNrWMSY2WKS5",
  //   phone: "020 55446664",
  //   address: "ບ. ໂພນຕ້ອງສະຫວາດ, ມ ຈັນທະບູລີ",
  //   about: "NN Closet",
  //   map:"",
  //   facebook: "",
  //   detail:
  //     "ຫຼຸດ 10% ທຸກລາຍການ (ຍົກເວັ້ນສິນຄ້າທີ່ຖືກຈັດໂປຣຢູ່ແລ້ວ)",

  //   slide: [
  //     "https://drive.google.com/uc?id=1_Bbuo1YeLCwpjn7zOSTO2ERyKTLWz6lg",
  //     "https://drive.google.com/uc?id=1jW0Ye4yp4aqoHt9oSVnJIlU5MtuisJyu",
  //     "https://drive.google.com/uc?id=113raRWTy1NcMMpcwRaQsgflNIhJ-Jy9z",
  //   ],
  // },
  //
  {
    id: "27",
    name: "ຮ້ານປື້ມ Book Garden",
    type: "store",
    logo: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/book+garden-100.jpg",
    phone: "020 2821 6900",
    address: "ບ ຫ້ວຍຫົງ, ມ ຈັນທະບູລີ",
    about: "ຮ້ານປື້ມ Book Garden",
    discountImage:"https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/banner+book+garden.jpg",
    map: "https://www.google.com/maps/place/18%C2%B001'19.5%22N+102%C2%B037'07.0%22E/@18.0220616,102.6184857,21z/data=!4m5!3m4!1s0x0:0xc3161ab185cbd20d!8m2!3d18.0220909!4d102.6186066?hl=th",
    facebook: "",
    detail: "- ຊື້ປື້ມຊະນິດໃດກໍໄດ້ຄົບ 3 ຫົວ ຮັບສ່ວນຫຼຸດ 5 % <br />- ຊື້ປື້ມຊະນິດໃດກໍໄດ້ຄົບ 5 ຫົວ ຮັບສ່ວນຫຼຸດ 13 %",

    slide: [
      "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/poster-book+garden-100.jpg",
    ],
  },
  // {
  //   id: "28",
  //   name: "Pretty Little Thing ",
  //   type: "store",
  //   logo: "https://drive.google.com/uc?id=1vME_6Nmhc0bnqXPCK4-zvqjMiUtFCHk2",
  //   phone: "020 5293 5949",
  //   address: "ຂາຍອອນລາຍ, ບ. ດົງນາໂຊກ, ມ ສີໂຄດ",
  //   about: "Pretty Little Thing",
  //   map:"",
  //   facebook: "",
  //   detail:
  //     "ຊື້ສິນຄ້າຊະນິດໃດກໍໄດ້ 2 ຢ່າງຂຶ້ນໄປ ຫລຸດ 10% (ຍົກເວັ້ນສິນຄ້າທີ່ຫຼຸດລາຄາຢູ່ແລ້ວ)",

  //   slide: [
  //     "https://drive.google.com/uc?id=1ItJHIuTTI6m98mdCy9u9WlueDvnZMnhs",
  //     "https://drive.google.com/uc?id=1oiiS7YXuCm83pNa7JAGffzzlLA4Rhe_G",
  //     "https://drive.google.com/uc?id=132-rDe5appObJ4PPUcCsCuxTs1OmQHfc",
  //   ],
  // },
  {
    id: "29",
    name: "Especial Cafe",
    type: "food",
    logo: "https://drive.google.com/uc?id=1cHDXyxtshifX1hy5UrbWYc_Q-0PhiR7-",
    phone: "020 5866 4073",
    address: "ບ. ອານຸ, ມ ຈັນທະບູລີ",
    about: "Especial Cafe",
    discountImage: "https://drive.google.com/uc?id=1cHDXyxtshifX1hy5UrbWYc_Q-0PhiR7-",
    map: "",
    facebook: "",
    detail:
      "ຫຼຸດ 10%  ເຄື່ອງດື່ມທີ່ຮ້ານເຮັດເອງ ຮ້ອນ ຫຼື ເຢັນກໍໄດ້ (ຍົກເວັ້ນເຄື່ອງດື່ມສຳເລັດຮູບເຊັ່ນ: ນ້ຳດື່ມຕຸກ, ແປັບຊີ, ຯລຯ)",

    slide: [
      "https://drive.google.com/uc?id=1ItJHIuTTI6m98mdCy9u9WlueDvnZMnhs",
      "https://drive.google.com/uc?id=1oiiS7YXuCm83pNa7JAGffzzlLA4Rhe_G",
      "https://drive.google.com/uc?id=132-rDe5appObJ4PPUcCsCuxTs1OmQHfc",
    ],
  },
  {
    id: "30",
    name: "Slurp Cafe",
    type: "food",
    logo: "https://drive.google.com/uc?id=1eSWrtDhcmXJNzTABNfmCDJTiQ6q3TDUz",
    phone: "020 5895 5557",
    address: "ບ. ທົ່ງກາງ, ມ. ສີສັດຕະນາກ",
    about: "Slurp Cafe",
    discountImage: "https://drive.google.com/uc?id=1eSWrtDhcmXJNzTABNfmCDJTiQ6q3TDUz",
    map: "",
    facebook: "",
    detail: "ຫຼຸດ 15% ທຸກເມນູອາຫານ (ຍົກເວັ້ນເມນູເຄື່ອງດື່ມ)",

    slide: [
      "https://drive.google.com/uc?id=15m-yHXMKHK25xxeLhT6ZaVZ5vd1DlSvb",
      "https://drive.google.com/uc?id=1VvWSszsmyoSjETQNrVoUOo4i9BAFasww",
      "https://drive.google.com/uc?id=15y3FTgLYHnRfeEbFzLad_CJdjJ6v-roe",
    ],
  },
  {
    id: "31",
    name: "Watchan Pizza",
    type: "food",
    logo: "https://drive.google.com/uc?id=1WmN4vVtod_T5Y-SgL-tbiDOqUJSSGUTZ",
    phone: "020 5649 8799",
    address: "ບ. ວັດຈັນ, ມ. ຈັນທະບູລີ",
    about: "Watchan Pizza",
    discountImage: "https://drive.google.com/uc?id=1WmN4vVtod_T5Y-SgL-tbiDOqUJSSGUTZ",
    map: "",
    facebook: "",
    detail: "ຫຼຸດ 8%  ທຸກລາຍການ ແລະ ບໍ່ຈຳກັດຍອດຊື້ຂັ້ນຕ່ຳ",

    slide: [
      "https://drive.google.com/uc?id=1t8DxSbANUM2h7g-HsH7m2eLFnNfQinG9",
      "https://drive.google.com/uc?id=1SK2p1uR2bP4r00n_Jd3LfzDQke7u0UOM",
      "https://drive.google.com/uc?id=1refjbLrx2PTqyRlAerE7gOe2VMXww5nI",
    ],
  },
];
