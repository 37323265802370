import React from "react";
import "./index.css"
const Webofsciencemobile = () => {
  return (
    <div>
      <div className="webofscience-container">
        <h1 style={{color:"grey", fontSize:"30px"}}>Web Of Science</h1>
        <h1 style={{color:"grey", fontSize:"40px"}}>Comming Soon</h1>
      </div>
    </div>
  );
};

export default Webofsciencemobile;