import React from "react";
import * as Icon from "react-bootstrap-icons";
import "./Domestic.css";
import useReactRouter from "use-react-router";
import { Link } from "react-router-dom";

const Government = () => {
  const loop = [1];
  const { history } = useReactRouter();

  return (
    <div>
      <div className="">
        <div id="category" className="d-flex align-items-center px-2 py-2 ">
          <div className="me-3 d-flex align-items-center">
            <Icon.ChevronLeft onClick={() => history.goBack()} />
          </div>
          <h4 className="fw-bold mt-2">ຂະເເໜງການພັກລັດ</h4>
        </div>
        <div id="domestic-main" className="p-2 mt-5">
          {loop.map((item, index) => (
            <Link
              key={index}
              to="/government_research"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="container my-2">
                <div
                  id="domestic-item"
                  className="row border border-primary rounded p-1"
                >
                  <div className="col-4 rounded">
                    <img
                      width={70}
                      className=""
                      src="https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/gov.png"
                      alt=""
                    />
                  </div>
                  <div id="domestic-text" className="col-8 py-2 px-3">
                    <h1 className="text-truncate ">ພາກລັດທະບານ</h1>
                    <h1 className=" text-truncate">
                      National University Of Laos
                    </h1>
                    <h2 className="text-center text-primary">
                      ລາຍລະອຽດເພິ່ມເຕິມ
                      <span className="ms-1">
                        <Icon.ArrowRight />
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Government;
