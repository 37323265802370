import React, { useEffect, useState } from "react";
import { Typography, Menu, MenuItem, IconButton } from "@material-ui/core";
import FileIconOption from "./FileIconOption";

const File = ({ fileName, date, id, fileLink }) => {
  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);
  const fileExtension = fileLink.split(".").pop();
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "ປ່ຽນຊື່ສຳເລັດ",
    type: "success",
  });

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus((predata) => ({
          ...predata,
          open: false,
        }));
      }, 2000);
    }
  }, [actionStatus.open]);

  const downloadFile = () => {
    // Create an anchor element
    const link = document.createElement("a");
    // Set the href attribute to the file link
    link.href = fileLink;
    // Set the download attribute to trigger a download
    link.download = fileName;
    // Append the anchor element to the body
    document.body.appendChild(link);
    // Trigger a click event on the anchor element
    link.click();
    // Remove the anchor element from the body
    document.body.removeChild(link);
  };

  return (
    <div
      className="d-flex flex-row align-items-center justify-content-between col-12 folder"
      style={{ position: "relative" }}
    >
      <div className="d-flex flex-row align-items-center">
        <div className="mx-3">
          <FileIconOption type={fileExtension} />
        </div>
        <div>
          <Typography>{fileName}</Typography>
          <Typography
            style={{
              fontSize: "14px",
            }}
          >
            {"ສ້າງວັນທີ : " + new Date(date).toLocaleDateString()}
          </Typography>
        </div>
      </div>
      <div className="px-3">
        <IconButton onClick={downloadFile}>
          <img
            width="24"
            height="24"
            src="/downloadicon.png"
            alt="download--v1"
          />
        </IconButton>
      </div>
    </div>
  );
};

export default File;
