import React, { useEffect, useState } from "react";
import { NEW_API_URL, API_KEY } from "../../common/constraint";
import axios from "axios";
import { USER_KEY } from "../../consts/user";
import { MobileHeader } from "../../common";
import useReactRouter from 'use-react-router'

export const StudentScore = () => {
  const [scores, setScores] = useState([]);

  const fetchScore = async () => {
    if (studentId) {
      await axios
        .get(
          NEW_API_URL +
            `/score/studentid/${studentId}/${selectedYear}/${selectedSemester}`,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setScores(res.data);
        })
        .catch((err) => {
          console.log("fetch student error");
          console.log(err);
        });
    }
  };

  const user = JSON.parse(localStorage.getItem(USER_KEY));

  const [studentId, setStudentId] = useState("");

  useEffect(() => {
    if (user) {
      setStudentId(user?.studentData?.id || "");
    }
  }, [user]);

  const [selectedYear, setSelectedYear] = useState(1);
  const [selectedSemester, setSelecteSemester] = useState(1);

  useEffect(() => {
    fetchScore();
  }, [selectedSemester, selectedYear, studentId]);

  //   useEffect(() => {
  //     console.log("student id");
  //     console.log(studentId);
  //     console.log("selectedYear");
  //     console.log(selectedYear);
  //     console.log("selectedSemester");
  //     console.log(selectedSemester);
  //   }, [studentId, selectedYear, selectedSemester]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleSemesterChange = (e) => {
    setSelecteSemester(e.target.value);
  };
  const { history, location, match } = useReactRouter()

  return (
    <div className="container mt-4">
      <MobileHeader
        showLeftArrow
        title={"ຄະເເນນນັກສຶກສາ"}
        leftButtonClick={() => history.goBack()}
      />
      {/* <div className="text-center">
        <h3>ລາຍການຄະເເນນນັກສຶກສາ</h3>
      </div> */}
      <div className="row mt-5 pt-5">
        <div className="col text-center">
          <label>ເລືອກປິ:</label>
          <select
            className="form-control text-center"
            style={{ height: "40px" }} // Set the desired height here
            value={selectedYear}
            onChange={handleYearChange}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={5}>6</option>
          </select>
        </div>
        <div className="col text-center">
          <label>ເລືອກພາກຮຽນ:</label>
          <select
            className="form-control text-center"
            style={{ height: "40px" }} // Set the desired height here
            value={selectedSemester}
            onChange={handleSemesterChange}
          >
            <option value={1}> 1</option>
            <option value={2}> 2</option>
          </select>
        </div>
      </div>

      <div className="row mt-3">
        {scores.length > 0 ? (
          <table className="table table-striped table-hover">
            <thead
              style={{
                backgroundColor: "#ced4da",
              }}
            >
              <tr>
                <th
                  className="text-center"
                  scope="col"
                  style={{ width: "50px" }}
                >
                  ລຳດັບ
                </th>
                {/* <th className="text-center" scope="col">
                    ລະຫັດວິຊາ
                  </th> */}
                <th className="text-center" scope="col">
                  ຊື່ວິຊາ
                </th>
                <th className="text-center" scope="col">
                  ຄະເເນນ
                </th>
              </tr>
            </thead>
            <tbody>
              {scores.map((score, index) => (
                <tr key={index}>
                  <th className="text-center">{index + 1}</th>
                  {/* <td className="text-center">{score?.course?.courseCode}</td> */}
                  <td className="text-center">{score?.course?.title}</td>
                  <td className="text-center">
                    {score?.grade || "ບໍ່ມິຄະເເນນ"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center pt-4 w-100">
            <img
              className="mb-3"
              width="32"
              height="32"
              src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/external-empty-science-education-dreamstale-lineal-dreamstale.png"
              alt="external-empty-science-education-dreamstale-lineal-dreamstale"
            />

            <h4>ຍັງບໍ່ມິຂໍ້ມຸນຄະເເນນ</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentScore;
