import React from 'react'
import { Breadcrumb } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { MobileHeader } from '../../common';

export default function EmeraldUsing() {
    const { history } = useReactRouter();
    return (
        <div style={{backgroundColor: "#FFFF", minHeight: "100vh"}}>
            <MobileHeader
                title="ຫ້ອງສະໝຸດ"
                showLeftArrow
                leftButtonClick={() => history.push("/document-lists")}
            />
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/GoswvOVAcSE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
            </iframe>
            <div style={{ padding: 10 }} className="emerald-using-text">
                <b>ການຄົ້ນຫາຂໍ້ມູນບົດຄົ້ນຄວ້າຕ່າງປະເທດ</b>
                <div style={{padding:"0px 5px 0px 5px"}}>
                &nbsp;&nbsp;ພວກເຮົາຍັງມີແຜນທີ່ຈະເຊື່ອມໂຍງກັບສຳນັກຄົ້ນຄວ້າອື່ນໆທົ່ວໂລກໃນໄວໆນີ້
                </div>
            </div>

        </div>
    )
}
