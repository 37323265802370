import React from "react";
import { MobileView } from "react-device-detect";
import { SECONDARY_COLOR } from "../consts";
import Consts from "../consts";
export const LeftArrow = () => (
  <i
    className="fas fa-arrow-left"
    style={{ fontSize: 24, color: Consts.PRIMARY_COLOR }}
  />
);
// hamburger bars
export const Hamburger = () => (
  <i
    className="fas fa-bars"
    style={{ fontSize: 24, color: Consts.PRIMARY_COLOR }}
  />
);

// closeButton
export const CloseButton = () => (
  <i
    className="fas fa-times"
    style={{ fontSize: 24, color: Consts.PRIMARY_COLOR }}
  />
);

// searchButton
export const SearchButton = () => (
  <i
    className="fas fa-search"
    style={{ fontSize: 24, color: Consts.FONT_COLOR_WHITE }}
  />
);

export const List = () => (
  <i
    className="fa fa-list"
    style={{ fontSize: 24, color: Consts.PRIMARY_COLOR }}
  />
);

export const Grid = () => (
  <i
    className="fa fa-th-large"
    style={{ fontSize: 24, color: Consts.PRIMARY_COLOR }}
  />
);

// header
export const WhiteMobileHeader = ({
  title,
  showLeftArrow,
  showHamburger,
  showCloseButton,
  showRightSearchButton,
  rightButtonTitle,
  leftButtonClick,
  rightButtonClick,
  isGrid,
  style,
}) => {
  return (
    <MobileView>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Consts.FONT_COLOR_WHITE,
          height: 60,
          width: "100%",
          textAlign: "center",
          color: Consts.PRIMARY_COLOR,
          fontWeight: "bold",
          position: "fixed",
          left: 0,
          top: 0,
          zIndex: 1,
          boxShadow: "1px 0px 5px 0px gray",
          // flexWrap: "wrap",
          ...style,
        }}
      >
        {/* left button */}
        {showLeftArrow || showHamburger || showCloseButton ? (
          <div>
            <button
              style={{
                width: 100,
                marginLeft: -30,
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
              }}
              onClick={() => leftButtonClick()}
            >
              {showLeftArrow && <LeftArrow />}
              {showHamburger && <Hamburger />}
              {showCloseButton && <CloseButton />}
            </button>
          </div>
        ) : (
          <div style={{ width: 100 }} />
        )}

        {/* title */}
        {/* <div style={{textAlign: 'center'}}>{title}</div> */}
        <div
          style={{
            textAlign: "center",
            // marginLeft: -10,
            overflowX: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginRight: "auto",
            color: Consts.FONT_COLOR_BLACK,
            fontWeight: "bold",
            fontSize: 18,
            width: "100%",
          }}
        >
          {title}
        </div>

        {/* right button */}
        {showRightSearchButton || rightButtonTitle ? (
          <div>
            <button
              style={{
                width: 100,
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                marginRight: -20,
              }}
              onClick={() => rightButtonClick()}
            >
              {showRightSearchButton && <SearchButton />}
              {rightButtonTitle && (
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: SECONDARY_COLOR,
                  }}
                >
                  {rightButtonTitle}
                </span>
              )}
            </button>
          </div>
        ) : (
          <div style={{ width: 100 }} />
        )}
      </div>
    </MobileView>
  );
};
