import React, { useCallback } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Consts from "../../consts";
import { useDropzone } from "react-dropzone";

const CourseDocEdit = ({
  courseDocEditModal,
  _handlCourseDocEditModalClose,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <Modal
        show={courseDocEditModal}
        onHide={_handlCourseDocEditModalClose}
        size="lg"
      >
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          ແກ້ໃຂເອກະສານບົດສອນ
        </Modal.Title>
        <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
          <p className="text-center">ວິຊາຖານຂໍ້ມູນ</p>

          {/* file updload box */}
          <div style={{ border: "1px solid #eee", padding: 20, width: "100%" }}>
            {/* ຫົວຂໍ້ */}
            <Form.Group
              as={Row}
              style={{
                margin: 0,
                marginBottom: 10,
                paddingLeft: 20,
                fontSize: 16,
              }}
            >
              <Form.Label column sm="4" className="text-left">
                ຫົວຂໍ້
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  placeholder="ກະລຸນາປ້ອນ"
                  style={{ borderRadius: 0 }}
                />
              </Col>
            </Form.Group>

            {/* ອັບໂຫລດໄຟລ */}
            <Form.Group
              as={Row}
              style={{
                margin: 0,
                marginBottom: 10,
                paddingLeft: 20,
                fontSize: 16,
              }}
            >
              <Form.Label column sm="4" className="text-left">
                ອັບໂຫລດໄຟລ
              </Form.Label>
              <Col sm="8">
                <div
                  {...getRootProps()}
                  style={{
                    height: 100,
                    border: "1px solid #ddd",
                    outline: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  <input {...getInputProps()} />

                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#f3f3f3",
                      marginLeft: 20,
                      marginTop: 20,
                      marginRight: 20,
                      textAlign: "center",
                      height: 50,
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      DB101.pdf
                    </span>
                  </div>
                  <span>Drag and drop or Browse</span>
                </div>
              </Col>
            </Form.Group>
          </div>

          <div style={{ height: 20 }} />
          <div className="row" style={{ textAlign: "center" }}>
            <div style={{ padding: 15 }} className="col">
              <Button
                onClick={_handlCourseDocEditModalClose}
                style={{
                  width: "60%",
                  backgroundColor: "#fff",
                  color: "#6f6f6f",
                  borderColor: "#6f6f6f",
                  borderRadius: 0,
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className="col">
              <Button
                style={{
                  width: "60%",
                  backgroundColor: Consts.SECONDARY_COLOR,
                  color: "#fff",
                  borderColor: Consts.SECONDARY_COLOR,
                  borderRadius: 0,
                }}
              >
                ບັນທຶກການແກ້ໃຂ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CourseDocEdit;
