import React, { Component } from "react";
import { MobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import { SHOPS } from "./shops";
import { Card, Col, Row } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const StoreAndService = () => {
  const { history, location } = useReactRouter();
  const _shop = SHOPS.filter((type) => type.type === "store");
  return (
    <div>
      <MobileHeader
        title="ຮ້ານຄ້າ ແລະ ບໍລິການທົ່ວໄປ"
        showLeftArrow
        leftButtonClick={() => history.push("/stores")}
      />
      <div
        style={{
          marginTop: 60,
          height: "100hv",
        }}
      >
        <div>
          <p
            style={{
              color: "#2085B0",
              fontSize: 18,
              fontWeight: 700,
              marginLeft: 24,
              marginTop: 82,
              marginBottom: 12,
            }}
          >
            ຮ້ານທັງໝົດ
          </p>
        </div>
        {_shop?.length > 0 &&
          _shop.map((store, index) => (
            <Card
              onClick={() =>
                history.push({
                  pathname: `/store-detail/${store.id}`,
                  state: store,
                })
              }
              key={index}
              style={{
                margin: "0px 24px 18px 24px",
                borderRadius: "12px 12px 12px 12px",
                border: "none",
                overflow: "hidden",
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Row>
                <Col xs={12}>
                  <div
                    style={{
                      width: "100%",
                      height: 224,
                      overflow: "hidden",
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      style={{
                        width: "100%",
                        height: 224,
                        borderRadius: "12px 12px 0px 0px",
                      }}
                      alt=""
                      src={
                        store?.logo ? store?.logo : "assets/wallpaper-image.png"
                      }
                    />
                  </div>
                  <Row
                    style={{
                      marginTop: 5,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  >
                    <div style={{ width: "10%" }}>
                      <i
                        className="fa fa-store"
                        aria-hidden="true"
                        style={{ fontSize: "1.1em", color: "#057CAE" }}
                      />
                    </div>
                    <div
                      style={{
                        width: "90%"
                      }}
                    >
                      <p
                        style={{
                          color: "#057CAE",
                          fontSize: 14,
                          width: "100%",
                          overflowX: "hidden",
                          overflowY: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          textAlign: "start",
                        }}
                      >
                        {store?.name}
                      </p>
                    </div>
                  </Row>
                  <Row
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      marginBottom: "15px",
                    }}
                  >
                    <div style={{ width: "10%" }}>
                      <i
                        className="fas fa-map-marker-alt"
                        aria-hidden="true"
                        style={{ fontSize: "1.1em", color: "#057CAE" }}
                      />
                    </div>
                    <div style={{ width: "90%" }}>
                      <p
                        style={{
                          color: "#717171",
                          fontSize: 14,
                          textAlign: "start",
                        }}
                      >
                        {store?.address}
                      </p>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Card>
          ))}
      </div>
    </div>
  );
};
export default StoreAndService;
