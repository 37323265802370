import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import { useQuery } from "@apollo/react-hooks";
// component ------------------------------------------>
import { CustomButton } from "../../common";

// constance ------------------------------------------>
import { USER_KEY } from "../../consts/user";
import { STUDENT } from "../../apollo/user";

import Consts from "../../consts";
const SearchModal = ({ showSearchView, _handleSearchViewClose, onSearch }) => {
  // states -------------------------------------------------------------->
  const [faculty, setFaculty] = useState("");
  const [userLoginData, setUserLoginData] = useState({});
  const [whereDepartment, setWhereDepartment] = useState("");
  // apollo -------------------------------------------------------------->

  const { data: student } = useQuery(STUDENT, {
    variables: {
      where: {
        id:
          userLoginData &&
          userLoginData?.studentData &&
          userLoginData?.studentData?.id,
      },
    },
  });

  useEffect(() => {
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  if (user) {
    setUserLoginData(user);
  }
    if (faculty) {
      setWhereDepartment(faculty);
    }
  }, [faculty]);

  useEffect(() => {
    setFaculty(student?.student?.faculty?.name);
  }, [student]);

  // console.log('student', student?.student?.faculty?.name);
  return (
    <Formik
      initialValues={{ }}
      validate={(values) => {
        const errors = {};
        setFaculty(values.faculty);
        return errors;
      }}
      onSubmit={(values) => {
        if (values.faculty === "ກາລຸນາເລືອກ") {
          values.faculty = null;
        }
        if (values.department === "ກາລຸນາເລືອກ") {
          values.department = null;
        }
        if (values.major === "ກາລຸນາເລືອກ") {
          values.major = null;
        }
        if (values.year === "ກາລຸນາເລືອກ") {
          values.year = null;
        }
        if (values.semester === "ກາລຸນາເລືອກ") {
          values.semester = null;
        }
        onSearch(values);
        _handleSearchViewClose();
        values.year = null;
        values.major = null;
        values.department = null;
        values.faculty = null;
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        /* and other goodies */
      }) => (
        <Modal show={showSearchView} onHide={_handleSearchViewClose} size="lg">
          <Modal.Title
            className="d-flex justify-content-between"
            style={{
              paddingTop: 20,
            }}>
            <div></div>
            <div style={{ marginRight: -35 }}>ຄົ້ນຫາວິຊາ</div>
            <button
              style={{
                backgroundColor: "#fff",
                border: "none",
                outline: "none",
              }}
              onClick={_handleSearchViewClose}>
              <i
                className="fa fa-times"
                style={{
                  // float: "right",
                  marginTop: -2,
                  marginRight: 20,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
            </button>
          </Modal.Title>

          <Modal.Body>
            <div style={{ padding: 20 }}>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="12">
                  ຄະນະ
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    type="text"
                    name="faculty"
                    value={student?.student?.faculty?.name}
                    disabled
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="12">
                  ພາກວິຊາ
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    type="text"
                    name="department"
                    value={student?.student?.department?.name}
                    disabled
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="12">
                  ສາຂາວິຊາ
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    type="text"
                    name="major"
                    value={student?.student?.major?.name}
                    disabled
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="12">
                  ປີ
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    as="select"
                    name="year"
                    // value={values.year}
                    disabled
                    value={student?.student?.yearLevel}
                    onChange={handleChange}
                  >
                    <option value={null}>ກາລຸນາເລືອກ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="12">
                  ພາກຮຽນ
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    as="select"
                    name="semester"
                    value={values.semester}
                    onChange={handleChange}
                  >
                    <option value={""}>ທັງໝົດ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr className="mt-2 mb-3" />
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="12">
                  ລະຫັດວິຊາ
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    type="text"
                    name="courseCode"
                    placeholder='ກາລຸນາປ້ອນລະຫັດວິຊາ'
                    value={values.courseCode}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </div>
            <div style={{ height: 20 }} />
            <div className="row">
              <div style={{ padding: 15 }} className="col">
                <CustomButton
                  confirm
                  onClick={handleSubmit}
                  width="100%"
                  title="ຄົ້ນຫາ"
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default SearchModal;
