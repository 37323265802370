import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// Component Import
import Category from "../../common/Research/Category";
import ProductCard from "../../common/Research/ProductCard";
import RecentCard from "../../common/Research/RecentCard";
import ExploreCard from "../../common/Research/ExploreCard";
import SearchView from "./SearchView";
import Header from "../../common/Research/Header";
import Loading from "../../common/Loading";

// Style
import "./index.css";

import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/constraint";

const NewAllResearchItem = () => {
  const [showSearch, setShowSearch] = useState(false);
  const history = useHistory();

  const toggleSearch = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
  };

  const categories = [
    {
      name: "ໃນປະເທດ",
      img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/Flag_of_Laos_Flat_Round-2048x2048.png",
      link: "/domestic",
    },
    {
      name: "ຕ່າງປະເທດ",
      img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/Logo-slms-student/WhatsApp+Image+2024-02-12+at+10.12.15.jpeg",
      link: "/international",
    },
    {
      name: "ນິຕິກຳຕ່າງໆ",
      img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/Ellipse+4.png",
      link: "/legislation_research",
    },
    {
      name: "ຂະເເໜງການພັກລັດ",
      img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/Ellipse+5.png",
      link: "/government",
    },
  ];

  const [fileDatas, setFileDatas] = useState([]);
  const [popData, setPopData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get new api
  const getNewResearch = async () => {
    setLoading(true);
    await axios
      .get(`${NEW_API_URL}laodoc`, {
        headers: {
          api_key: API_KEY,
        },
        params: {
          limit: 5,
        },
      })
      .then((res) => {
        if (res.data) {
          setFileDatas(res.data);
        } else {
          setFileDatas([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  // Function get popular documentß
  const getPopResearch = async () => {
    setLoading(true);
    await axios
      .get(`${NEW_API_URL}laodoc/rate`, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((res) => {
        if (res.data) {
          setPopData(res.data);
        } else {
          setPopData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  // Navigate function
  const navigate = async (item) => {
    setLoading(true);
    await axios
      .get(`${NEW_API_URL}laodoc/view`, {
        headers: {
          api_key: API_KEY,
        },
        params: {
          documentId: item._id,
        },
      })
      .then((res) => {
        console.log("view counted", res.data.viewCount);
      })
      .catch((err) => {
        console.log(err);
      });

    history.push(`/researchdetail/${item._id}`, { item: item });
    setLoading(false);
  };

  useEffect(() => {
    getNewResearch();
    getPopResearch();
  }, []);

  if (loading) return <Loading />;

  return (
    <div>
      <div id="Research" className="bg-white">
        {!showSearch && (
          <div className="">
            <div id="Header">
              <Header onToggleSearch={toggleSearch} />
            </div>
            <main className="mx-2 my-3">
              <div id="Categories">
                <h2 className="">ປະເພດບົດຄົ້ນຄວ້າ</h2>
                <div id="Category">
                  {categories.map((item, index) => (
                    <Category id="item" key={index} item={item} />
                  ))}
                </div>
              </div>
              <div id="latestProducts" className="my-2">
                <div className="headText">
                  <h2 className="">Lastest Product</h2>
                  <div className="">View All </div>
                </div>
                <div>
                  {fileDatas && fileDatas.length ? (
                    <div
                      id="latestProduct"
                      className="p-2 mb-4 d-flex overflow-scroll"
                    >
                      {fileDatas.map((item, index) => (
                        <div key={index} onClick={() => navigate(item)}>
                          <ProductCard item={item} />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "200px",
                        background: "#eee",
                        borderRadius: "5px",
                      }}
                    >
                      <h1 style={{ fontSize: "medium", fontWeight: "bold" }}>
                        ບໍ່ມີລາຍການທີ່ຈະສະເເດງ
                      </h1>
                    </div>
                  )}
                </div>
              </div>
              <div id="Recent-Read" className="my-3">
                <h1>Popular</h1>
                <div>
                  {fileDatas && fileDatas.length ? (
                    <div
                      className="py-2"
                      style={{ display: "flex", overflow: "scroll" }}
                    >
                      {popData.map((item, index) => (
                        <span key={index} onClick={() => navigate(item)}>
                          <RecentCard item={item} />
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "200px",
                        background: "#eee",
                        borderRadius: "5px",
                      }}
                    >
                      <h1 style={{ fontSize: "medium", fontWeight: "bold" }}>
                        ບໍ່ມີລາຍການທີ່ຈະສະເເດງ
                      </h1>
                    </div>
                  )}
                </div>
              </div>
              {/* <div id="Explore-More">
                <h1 className="">Explore More</h1>
                <div className="p-2 ">
                  {categories.map((item, index) => (
                    <ExploreCard key={index} />
                  ))}
                </div>
              </div> */}
            </main>
          </div>
        )}
        {showSearch && (
          <div>
            <SearchView onClose={toggleSearch} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewAllResearchItem;
