import { gql } from "apollo-boost";

export const GET_DEPARTMENTS = gql`
  query Departments(
    $where: CustomDepartmentWhereInput
    $orderBy: DepartmentOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    departments(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      name
      description
    }
  }
`;
