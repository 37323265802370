import React from "react";

const ExploreCard = () => {
  return (
    <>
      <div
        id="Explore"
        className="d-flex align-items-center border-bottom py-2"
      >
        <div id="explore-img" className=" me-2 rounded-circle  overflow-hidden">
          <img
            width={35}
            className=""
            src="https://www.nuol.edu.la/images/nuol.png"
            alt=""
          />
        </div>
        <h1 className="text-truncate">ບົດຄົ້ນຄວ້າ ມະຫາວິທະຍາໄລແຫ່ງຊາດ</h1>
      </div>
    </>
  );
};

export default ExploreCard;
