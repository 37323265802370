import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { Breadcrumb } from "react-bootstrap";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

import { CustomContainer, CustomButton } from "../../common";
import "./index.css";
import { GRADES, UPGRADE_GRADE, GRADES_FILTER } from "./../../apollo/grade";

import Consts from "../../consts";
const RegisterToUpgradeMobileView = ({ student, courses }) => {
  /**
   * routes
   */
  const { history } = useReactRouter();
  /**
   * use states
   */
  const [studentId, setStudentId] = useState();
  const [gradeCourse, setGradeCourse] = useState();
  const [newData, setNewData] = useState([]);

  /**
   * apollo
   */
  const [loadGrade, { data, called, loading }] = useLazyQuery(GRADES);
  const [loadGradeFilter, { data: gradeFilter }] = useLazyQuery(GRADES_FILTER);
  const [upgradeGrade, { data: upgradeGradeData }] = useMutation(UPGRADE_GRADE);
  useEffect(() => {
    // setStudentId(student?.id);
    setStudentId(student?.userId);
    loadGrade();
    loadGradeFilter();
  }, [student]);

  useEffect(() => {
    setGradeCourse(data?.grades?.data);
    loadGrade({
      variables: {
        where: {
          student: studentId,
          // sendingGradeStatus_in: ["ADMIN_APPROVED"],
        },
      },
    });
    const loadGradeFilter = async () => {
      let _newData = [];
      for (var i = 0; i < data?.grades?.data?.length; i++) {
        for (var j = 0; j < _newData.length; j++) {
          if (data?.grades?.data[i]?.course?.id === _newData[j]?.course?.id) {
            delete _newData[j];
          }
        }
        _newData.push(data?.grades?.data[i]);
      }
      let _newData2 = [];
      for (var i = 0; i < _newData.length; i++) {
        if (_newData[i]) {
          _newData2.push(_newData[i]);
        }
      }
      setNewData(_newData2);
    };
    if (data?.grades?.data) {
      loadGradeFilter();
    }
  }, [data, student]);
  // console.log("newData", newData)
  const getGrade = async (e) => {
    await upgradeGrade(e);
    history.push("upgrade-list");
    window.location.reload(true);
  };
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => history.goBack()}>ຈັດການຄະແນນ</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>​ການ​ອັບ​ເກ​ຣດ​ຄະ​ແນນ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <h3>​ການ​ອັບ​ເກ​ຣດ​ຄະ​ແນນ</h3>
        <div style={{ marginRight: 100, marginLeft: 100 }}>
          <Formik
            validate={(values) => {
              const errors = {};
              if (values.courseId === "") {
                errors.courseId = "ກະລຸນາເລືອກວິຊາທີ່ຈະແກ້ເກຣດ";
              }
              return errors;
            }}
            onSubmit={async (values) => {
              await getGrade({
                variables: {
                  where: {
                    id: values.courseId,
                  },
                },
              });
            }}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <div>
                <div className="row">
                  <FontAwesomeIcon
                    icon={["fa", "caret-down"]}
                    color={Consts.PRIMARY_COLOR}
                    style={{ fontSize: 30 }}
                  />
                  <h5 style={{ marginLeft: 25 }}> ຂໍ້ມູນນັກຮຽນ</h5>
                </div>
                <div className="row">
                  <div className="col-md-5 ml-5">
                    <p>ຊື່ ແລະ ນາມ​ສະ​ກຸນ​ນັກ​ສຶກ​ສາ</p>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      value={`${student?.firstNameL} ${student?.lastNameL}`}
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 ml-5">
                    <p>ລະ​ຫັດ​ບັດ​ນັກ​ສຶກ​ສາ</p>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      value={student?.userId}
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <FontAwesomeIcon
                    icon={["fa", "caret-down"]}
                    color={Consts.PRIMARY_COLOR}
                    style={{ fontSize: 30 }}
                  />
                  <h5 style={{ marginLeft: 25 }}>ລາຍ​ລະ​ອຽດການ​ອັບ​ເກ​ຣດ</h5>
                </div>
                <div className="row">
                  <div className="col-md-5 ml-5">
                    <p>ວິຊາທີ່ເປີດລົງທະບຽນແກ້ເກຣດ</p>
                  </div>
                  <div className="col-md-6">
                    <select
                      className="form-control"
                      name="courseId"
                      onChange={handleChange}
                    >
                      <option>ເລືອກວິຊາແກ້ເກຣດ</option>
                      {newData &&
                        newData?.map((item, index) => (
                          <option
                            value={item?.id}
                            key={index}
                            style={{
                              display: item?.letterGrade === "A" || item?.course?.isUpgrade === false
                              ? "none" : "block"
                            }}
                            disabled={
                              // item?.course?.isUpgrade === false
                              //   ? true
                              //   : 
                                item?.numberTest > 1 &&
                                  item?.gredeStatus === "REQUESTING"
                                ? true
                                : item?.numberTest > 1 &&
                                  item?.gredeStatus === "APROVED"
                                ? true
                                : item?.numberTest > 1 &&
                                  item?.gredeStatus === "REJECTED"
                                ? false
                                : false
                            }
                          >
                            {
                            // item?.course?.isUpgrade === false
                            //   ? "ປິດລົງທະບຽນແກ້ເກຣດ"
                            //   : 
                              item?.numberTest > 1 &&
                                item?.gredeStatus === "REQUESTING"
                              ? item?.course?.title + " ລົງທະບຽນແລ້ວ"
                              : item?.numberTest > 1 &&
                                item?.gredeStatus === "APROVED"
                              ? item?.course?.title + " ລົງທະບຽນແລ້ວ"
                              : item?.numberTest > 1 &&
                                item?.gredeStatus === "REJECTED"
                              ? item?.course?.title
                              : item?.course?.title}
                          </option>
                        ))}
                    </select>
                    <p style={{ color: "red" }}>{errors.courseId}</p>
                  </div>
                </div>
                <div style={{ height: 50 }}></div>

                <div className="d-flex justify-content-center">
                  <CustomButton
                    title="ຍົກເລີກ"
                    onClick={() => history.push("upgrade-list")}
                  />
                  <div style={{ width: 100 }} />
                  <CustomButton
                    type
                    title="ບັນທຶກ"
                    confirm
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )}
          </Formik>
        </div>
      </CustomContainer>
    </div>
  );
};

export default RegisterToUpgradeMobileView;
