export const SCHOLARSHIPS = [
  {
    id: "1",
    title: "ທຶນການສຶກສາຈາກ ສປ ຈີນ",
    entitle: "Guangxi Vocational & Technical College",
    detail: `&nbsp;‼️ຂ່າວດີ ‼️ <br/>
      ທຶນການສຶກສາຈາກ ສປ ຈີນ <br/>
      #ປິດຮັບສະໝັກ ວັນທີ 01/07/2024 <br/>
      ❗️ລາຍລະອຽດ ສອບຖາມໄດ້ທີ່ລິ້ງ <br/>
      <a href=" www.moes.dsa.edu.la" target="_blank"> www.moes.dsa.edu.la/</a> <br/>
      `,
    skill: `ເພິ່ມເຕິມໄດ້ທີ່:  <a href=" www.moes.dsa.edu.la" target="_blank"> www.moes.dsa.edu.la</a> <br/>`,
    attachment: `ເພິ່ມເຕິມໄດ້ທີ່:  <a href=" www.moes.dsa.edu.la" target="_blank"> www.moes.dsa.edu.la/</a> <br/>`,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_scholarship/WhatsApp+Image+2024-06-19+at+16.32.13.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_scholarship/WhatsApp+Image+2024-06-19+at+16.32.13.jpeg",
    file: "",
  },
  {
    id: "2",
    title:
      "ແຈ້ງທຶນການສຶກສາຂອງ Youth of Excellence Scheme of China Program Chinese Government Scholarship ສປ ຈີນ ປະຈຳປີ 2024 ",
    entitle: "studyinchina.csc.edu.cn",
    detail:
      "ທຶນການສຶກສາຂອງ Youth of Excellence Scheme of China Program Chinese Government Scholarship ສປ ຈີນ ປະຈຳປີ 2024 (ສະໝັກທາງ Online), ຕິດຕາມຂໍ້ມູນໄດ້ທີ່ www.moes.dsa.edu.la ຫຼື ສອບຖາມຂໍ້ມູນໄດ້ທີ່ເບີ 021 255035 ໃນໂມງລັດຖະການ",
    skill: `
    + ເງື່ອນໄຂຜູ້ສະໝັກ: <br/>
    &nbsp; - ເປັ້ນຄົນລາວຖືສັນຊາດລາວອາຍຸບໍ່ເກີນ 45 ປີ, ສຸຂະພາບເເຂງເເຮງດີ <br/>
    &nbsp; - ຕຳເເໜ່ງຮອງພະເເນກຂື້ນໄປ ຫຼື ເປັນບຸດຄົນດີເດັ່ນມີປະສົບການເຮັດວຽກ 3 ປີຂື້ນໄປ <br/>
    &nbsp; - ມີຄວາມຮູ້ດ້ານພາສາອັງກິດໃນລະດັບດີ ຄະເເນນ TELTS 6.0 ຫຼື TOFL Internet score 80, ການສືກສາຕ້ອງຈົບປະລິຍາຕີຄະເເນນໃນລະດັບດີ <br/>
    &nbsp; - ຕ້ອງມີໜັງສືນຳສົ່ງເຫັນດີຈາກ ກົມຈັດຕັ້ງ ແລະ ພະນັກງານ <br/>
    &nbsp; - ຂຽນເປົ້າໝາຍຈຸປະສົງຕົນເອງ (Personal Statement of Research) ໃຫ້ໄດ້ 500 ຄຳສັບເປັນພາສາອັງກິດ <br/>
    &nbsp; - ໜັງສືຢັ້ງຢືນຈາກຫົວໜ້າບ່ອນທີ່ຕົນສັນກັດຢູ່ 2 ທ່ານໃຫ້ໃສ່ເບີໂທ ກັບ ອີເມວພ້ອມ (Two Recommendation Letters) <br/>
    &nbsp; - ສຳເນົາໃບປະກາດ ແລະ ໃບຄະເເນນຈົບປະລິຍາຕີ <br/>
    &nbsp; - ໜັງສືຢັ້ງຢືນການກວດສຸຂະພາບຕາມເເບບຟອມທີ່ກຳນົດໃຫ້  <br/>
    &nbsp; - ສຳເນົາ Passport (ຫົວສີຟ້າ)
    `,
    attachment: `ສາມາດສະໝັກທາງອອນໄລໄດ້ທີ່ <a href="http://studyinchina.csc.edu.cn//#/login" target="_blank">https://studyinchina.csc.edu.cn//#/login</a>
    (ໃຫ້ type ລະຫັດ A 1563 ) ຫຼັງຈາກສະໝັກເປັນທີ່ຮຽບຮ້ອຍແລ້ວໃຫ້ປີ້ນແບບຟອມທີ່ສະໝັກອອກມາລົງລາຍເຊັນຕົວເອງແລ້ວສະເເກນເອກະສານທຸກຢ່າງເປັນ PDF ຕາມລຳດັບຂອງເອກະສານແລ້ວສົ່ງເຂົ້າ 
    E-mail: dsa.moes@yahoo.com ພ້ອມດ້ວຍເອກະສານສຳເນົາ1ຊຸດ ສົ່ງມາຍັງກົມກິດຈະກຳນັກສືກສາ ກະຊວງສືກສາທິການແລະກິລາ ກ່ອນວັນທີ່ 08/04/2024`,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-01-23+at+11.03.07.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-01-23+at+11.03.07.jpeg",
    file: "",
  },
  {
    id: "4",
    title: "ທຶນການສຶກສາຈາກ ລັດຖະບານປະເທດອົດສະຕາລີ",
    entitle: "Australia awards laos",
    detail: `&nbsp;‼️ຂ່າວດີ ‼️ <br/>
      ທຶນການສຶກສາຈາກ ລັດຖະບານປະເທດອົດສະຕາລີ <br/>
      #ປິດຮັບສະໝັກ ວັນທີ 03/05/2024 <br/>
      ❗️ລາຍລະອຽດ ສອບຖາມໄດ້ທີ່ລິ້ງ <br/>
      <a href=" https://australiaawardslaos.org/apply/" target="_blank"> https://australiaawardslaos.org/apply/</a> <br/>
      `,
    skill: `ເພິ່ມເຕິມໄດ້ທີ່:  <a href=" https://australiaawardslaos.org/apply/" target="_blank"> https://australiaawardslaos.org/apply/</a> <br/>`,
    attachment: `ເພິ່ມເຕິມໄດ້ທີ່:  <a href=" https://australiaawardslaos.org/apply/" target="_blank"> https://australiaawardslaos.org/apply/</a> <br/>`,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-01-30+at+10.37.05.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-01-30+at+10.37.05.jpeg",
    file: "",
  },
  {
    id: "5",
    title: "ທຶນການສຶກສາຈາກລັດຖະບານປະເທດເບນຊິກ ສຳລັບປະລິນຍາໂທ",
    entitle: "www.scholarshipsads.com",
    detail: `&nbsp; ‼️ຂ່າວດີ‼️ <br/>
    ທຶນການສຶກສາຈາກລັດຖະບານປະເທດເບນຊິກ ສຳລັບປະລິນຍາໂທ (ທຸກສາຂາ)  <br/>
    #ປິດຮັບສະໝັກ ວັນທີ 28/05/2024 <br/>
    ❗️ລາຍລະອຽດສາມາດສອບຖາມໄດ້ຜ່ານລິ້ງນີ້  <br/>
     <a href="https://www.scholarshipsads.com/category/tags/laos/" target="_blank">https://www.scholarshipsads.com/category/tags/laos/</a> <br/> <br/>
    `,
    skill: `ເພິ່ມເຕິມໄດ້ທີ່:  <a href="https://www.scholarshipsads.com/category/tags/laos/" target="_blank">https://www.scholarshipsads.com/category/tags/laos/</a> <br/>`,
    attachment: `ເພິ່ມເຕິມໄດ້ທີ່:  <a href="https://www.scholarshipsads.com/category/tags/laos/" target="_blank">https://www.scholarshipsads.com/category/tags/laos/</a> <br/>`,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-01-30+at+10.37.04.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-01-30+at+10.37.04.jpeg",
    file: "",
  },
  {
    id: "6",
    title: "ທືນເເລກປ່ຽນການສຶກສາຈາກປະເທດສິງກະໂປ",
    entitle: "Nanyang Technological University: NTU",
    detail: `&nbsp; ແຈ້ງທຶນ: <br/>
    -----------------------------------------  <br/>
    ມະຫາວິທະຍາໄລເຕັກໂນໂລຊີນານຢາງ (Nanyang Technological University: NTU)  <br/>
     ປະເທດສິງກະໂປ ກໍາລັງຈະຈັດໂຄງການແລກປ່ຽນການສຶກສາ ASEAN Summer @NTU Programme with TF LEaRN Scholarship 2024  <br/>
      ໃນລະຫວ່າງວັນທີ 26 ມິຖຸນາ - 27 ກໍລະກົດ 2024 ຊຶ່ງຈະຈັດຢູ່ 2 ປະເທດຄື:  <br/>
       ວັນທີ 26 ມິຖຸນາ - 15 ກໍລະກົດ 2024 ແມ່ນຈະຈັດຢູ່ທີ່ ປະເທດສິງກະໂປ   <br/>
       ແລະ ວັນທີ 15 – 27 ກໍລະກົດ 2024 ແມ່ນຈະຈັດຢູ່ທີ່ ຮ່າໂນ່ຍ, ສສ ຫວຽດນາມ,   <br/>
       ໂດຍຢູ່ພາຍໃຕ້ການສະໜອງທຶນການສຶກສາຂອງ ມູນິທິເທມະເສກ (Temasek Foundation).  <br/>
       <a href="https://www.nuol.edu.la/index.php/%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%AE%E0%BA%BD%E0%BA%99-%E0%BA%A1%E0%BA%8A/2022-05-05-03-07-43/%E0%BB%81%E0%BA%88%E0%BB%89%E0%BA%87%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%AA%E0%BA%B0%E0%BB%9D%E0%BA%B1%E0%BA%81%E0%BA%97%E0%BA%B6%E0%BA%99/297-call-for-application-asean-summer-ntu-programme-with-tf-learn-scholarship-2024,-nanyang-technological-university,-singapore
       " target="_blank">ອ່ານຂໍ້ມູນເພີ່ມເຕີມ</a>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `<a href="https://www.nuol.edu.la/index.php/%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%AE%E0%BA%BD%E0%BA%99-%E0%BA%A1%E0%BA%8A/2022-05-05-03-07-43/%E0%BB%81%E0%BA%88%E0%BB%89%E0%BA%87%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%AA%E0%BA%B0%E0%BB%9D%E0%BA%B1%E0%BA%81%E0%BA%97%E0%BA%B6%E0%BA%99/297-call-for-application-asean-summer-ntu-programme-with-tf-learn-scholarship-2024,-nanyang-technological-university,-singapore
    " target="_blank">ສາມາດອ່ານຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີ່ນີ້</a>`,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-12+at+11.16.21.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-12+at+11.16.21.jpeg",
    file: "",
  },
  {
    id: "7",
    title:
      "ທືນເເລກປ່ຽນຄວາມຮູ້ວັດທະນະທຳ ຈາກ ມະຫາວິທະຍາໄລເຕັກໂນໂລຊີຣາຊະມຸງຄຸນຣັດຕະນະໂກສິນ",
    entitle: "Rajamangala University of Technology Rattanakosin: RMUTR",
    detail: `&nbsp; ແຈ້ງທຶນ: <br/>
    ----------------------------------------- <br/>
    ມະຫາວິທະຍາໄລເຕັກໂນໂລຊີຣາຊະມຸງຄຸນຣັດຕະນະໂກສິນ (Rajamangala University of Technology Rattanakosin: RMUTR)   <br/>
    ກໍາລັງຈະຈັດງານຕັ້ງຄ້າຍ RMUTR International Youth Camp 2024 <br/>
     ໃນລະຫວ່າງວັນທີ 22 – 30 ເມສາ 2024 ທີ່ ປະເທດໄທ.  <br/>
     ຈຸດປະສົງຂອງງານແມ່ນເພື່ອເປີດໂອກາດໃຫ້ນັກສຶກສາໄວໜຸ່ມທີ່ເຂົ້າຮ່ວມໄດ້ແລກປ່ຽນທາງດ້ານວັດທະນະທໍາ-ສັງຄົມ ແລະ ເພື່ອພັດທະນາທັກສະໃນການສື່ສານໃຫ້ແກ່ນັກສຶກສາ. <br/>
     <a href="https://www.nuol.edu.la/index.php/%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%AE%E0%BA%BD%E0%BA%99-%E0%BA%A1%E0%BA%8A/2022-05-05-03-07-43/%E0%BB%81%E0%BA%88%E0%BB%89%E0%BA%87%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%AA%E0%BA%B0%E0%BB%9D%E0%BA%B1%E0%BA%81%E0%BA%97%E0%BA%B6%E0%BA%99/298-call-for-application-rmutr-international-youth-camp-2024,-rajamangala-university-of-technology-rattanakosin,-thailand
     " target="_blank">ອ່ານຂໍ້ມູນເພີ່ມເຕີມ</a>
    `,
    skill: `ບໍ່ມີຂໍ້ມູນ`,
    attachment: `<a href="https://www.nuol.edu.la/index.php/%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%AE%E0%BA%BD%E0%BA%99-%E0%BA%A1%E0%BA%8A/2022-05-05-03-07-43/%E0%BB%81%E0%BA%88%E0%BB%89%E0%BA%87%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%AA%E0%BA%B0%E0%BB%9D%E0%BA%B1%E0%BA%81%E0%BA%97%E0%BA%B6%E0%BA%99/298-call-for-application-rmutr-international-youth-camp-2024,-rajamangala-university-of-technology-rattanakosin,-thailand
    " target="_blank">ອ່ານຂໍ້ມູນເພີ່ມເຕີມ</a>`,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-12+at+11.16.48+(1).jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-12+at+11.16.48+(1).jpeg",
    file: "",
  },
  {
    id: "8",
    title: "ທຶນເເລກປ່ຽນການສືກສາຈາກປະເທດຢີ່ປຸ່ນ",
    entitle: "University of the Ryukyus",
    detail: `&nbsp; ແຈ້ງທຶນ: <br/>
    ----------------------------------------- <br/>
    ມະຫາວິທະຍາໄລຣິວກິວ (University of the Ryukyus) ປະເທດຍີ່ປຸ່ນ <br/>
    ກໍາລັງຈະຈັດໂຄງການແລກປ່ຽນການສຶກສາ Rise Program ໄລຍະ 1 ສົກສຶກສາ ປະຈໍາສົກປີ 2024-2025 ເລີ່ມແຕ່ເດືອນ ຕຸລາ 2024 - ກັນຍາ 2025, ພາຍໃຕ້ທຶນການສຶກສາ JASSO Scholarship. <br/>
    <a href="https://www.nuol.edu.la/index.php/%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%AE%E0%BA%BD%E0%BA%99-%E0%BA%A1%E0%BA%8A/2022-05-05-03-07-43/%E0%BB%81%E0%BA%88%E0%BB%89%E0%BA%87%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%AA%E0%BA%B0%E0%BB%9D%E0%BA%B1%E0%BA%81%E0%BA%97%E0%BA%B6%E0%BA%99/299-call-for-application-rise-program,-university-of-the-ryukyus,-japan" target='_blank' >ອ່ານຂໍ້ມູນເພີ່ມເຕີມ</a>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `<a href="https://www.nuol.edu.la/index.php/%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%AE%E0%BA%BD%E0%BA%99-%E0%BA%A1%E0%BA%8A/2022-05-05-03-07-43/%E0%BB%81%E0%BA%88%E0%BB%89%E0%BA%87%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%AA%E0%BA%B0%E0%BB%9D%E0%BA%B1%E0%BA%81%E0%BA%97%E0%BA%B6%E0%BA%99/299-call-for-application-rise-program,-university-of-the-ryukyus,-japan" target='_blank' >ອ່ານຂໍ້ມູນເພີ່ມເຕີມ</a>
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-12+at+11.17.20.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-12+at+11.17.20.jpeg",
    file: "",
  },
  {
    id: "9",
    title: "ທຶນການສຶກສາຈາກ ປະເທດນິວຊີແລນ",
    entitle: "MNZS",
    detail: `&nbsp; ແຈ້ງທຶນການສຶກສາ ປະເທດນິວຊີແລນ (MNZS ສະໝັກອອນລາຍ) ສຳລັບສົກປີ 2024.  <br/>
    ສາມາດເຂົ້າເບີ່ງຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີ່ <a href="https://www.moes.dsa.edu.la" target="_blank">www.moes.dsa.edu.la</a> <br/>
     ສອບຖາມຂໍ້ມູນໄດ້ທີ່ເບີ 021 255035 ໃນໂມງລັດຖະການ. <br/>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.31.20.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.31.20.jpeg",
    file: "",
  },
  {
    id: "10",
    title: "ທຶນການສຶກສາຈາກ ປະເທດ ສ.ເກົາຫຼີ",
    entitle: "KOICA Scholarship Program 2024",
    detail: `&nbsp; ແຈ້ງທຶນການສຶກສາ ປະເທດ ສ.ເກົາຫຼີ, <br/>
     ສຳລັບປີ 2024 (KOICA Scholarship Program 2024) <br/>
      ສາມາດເຂົ້າເບີ່ງຂໍ້ມູນໃນເວັບໄຊ <a href="https://www.moes.dsa.edu.la" target="_blank" >www.moes.dsa.edu.la</a> ແລະ  <br/> 
      ສາມາດສອບຖາມໄດ້ທີ່ເບີ 021 255035 ໃນໂມງລັດຖະການ <br/>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-21+at+12.30.08.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-21+at+12.30.08.jpeg",
    file: "",
  },
  {
    id: "11",
    title: "ທຶນການສຶກສາຂອງ ແຂວງກຸຍໂຈ ສປ ຈີນ ສຳລັບປີ 2024-2025",
    entitle: "ແຂວງກຸຍໂຈ ສປ ຈີນ",
    detail: `&nbsp; ແຈ້ງທຶນການສຶກສາຂອງ ແຂວງກຸຍໂຈ ສປ ຈີນ ສຳລັບປີ 2024-2025  <br/>
    ສາມາດເຂົ້າເບີ່ງຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີ່ <a href="https://www.moes.dsa.edu.la" target="_blank" >www.moes.dsa.edu.la</a> ແລະ 
    ສອບຖາມຂໍ້ມູນໄດ້ທີ່ເບີ 021 255035 ໃນໂມງລັດຖະການ  <br/>
     <br/>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-22+at+09.34.32.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-22+at+09.34.32.jpeg",
    file: "",
  },
  {
    id: "12",
    title: "ທຶນການສຶກສາຂອງ Korea Science Academy",
    entitle: "Korea Advanced Institute of Science and Technology (KAIST)",
    detail: `&nbsp; ແຈ້ງທຶນການສຶກສາຂອງ Korea Science Academy <br/>
     ທີ່ຂຶ້ນກັບ Korea Advanced Institute of Science and Technology (KAIST) <br/>
     ສາມາດເຂົ້າເບີ່ງຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີ່ເວັບໄຊ <a href="https://www.moes.dsa.edu.la" target="_blank" >www.moes.dsa.edu.la</a>  <br/>
     ຫຼື ສອບຖາມໄດ້ທີ່ເບີ 021 255035 ໃນໂມງລັດຖະການ <br/>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-23+at+15.22.24.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-23+at+15.22.24.jpeg",
    file: "",
  },
  {
    id: "13",
    title: "ທຶນການສຶກສາ ສຳລັບປະລິນຍາເອກ ແລະ ປະລິນຍາໂທ",
    entitle: "ລັດຖະບານ ສສ ຫວຽດນາມ ສຳລັບປີ 2024",
    detail: `&nbsp; ແຈ້ງທຶນການສຶກສາ ສຳລັບປະລິນຍາເອກ ແລະ ປະລິນຍາໂທ ຂອງ ລັດຖະບານ ສສ ຫວຽດນາມ ສຳລັບປີ 2024 <br/>
    ສາມາດເຂົ້າເບີ່ງຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີເວັບໄຊ www.moes.dsa.edu.la <br/>
     ແລະ ສອບຖາມໄດ້ທີ່ເບີ 021 255035 ໃນໂມງລັດຖະການ <br/>
    
     `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-03-01+at+11.04.04.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-03-01+at+11.04.04.jpeg",
    file: "",
  },
  {
    id: "14",
    title: "ແຈ້ງທຶນການສຶກສາລະດັບປະລິນຍາຕີ ແລະ ມັດທະຍົມຕອນປາຍ (ມໍ5)",
    entitle: "ລັດຖະບານ ສສ ຫວຽດນາມ ສຳລັບປີ 2024",
    detail: `&nbsp; ແຈ້ງທຶນການສຶກສາລະດັບປະລິນຍາຕີ ແລະ ມັດທະຍົມຕອນປາຍ (ມໍ5) ຂອງ ລັດຖະບານ ສສ ຫວຽດນາມ ສຳລັບປີ 2024
    ສາມາດເຂົ້າເບີ່ງຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີເວັບໄຊ  <a href="https://www.moes.dsa.edu.la" target="_blank">www.moes.dsa.edu.la</a> <br/>
    ແລະ ສອບຖາມໄດ້ທີ່ເບີ 021 255035 ໃນໂມງລັດຖະການ`,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-03-01+at+11.03.02.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-03-01+at+11.03.02.jpeg",
    file: "",
  },
  {
    id: "14",
    title: "ແຈ້ງທຶນການສຶກສາຂອງພະແນກສຶກສາເຂດປົກຄອງຕົນເອງເຜົ່າຈ້ວງກວາງຊີ ສປ.ຈີນ",
    entitle: "ສປ. ຈີນ ສົກປີ 2024-2025",
    detail: `&nbsp; ‼️ແຈ້ງທຶນການສຶກສາຂອງພະແນກສຶກສາເຂດປົກຄອງຕົນເອງເຜົ່າຈ້ວງກວາງຊີ ສປ.ຈີນ ສົກປີ 2024-2025 <br/>
    📌ສາມາດເຂົ້າເບີ່ງຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີ່ <a href="https://www.moes.dsa.edu.la" target="_blank">www.moes.dsa.edu.la</a> <br/>
     ຫຼື ສອບຖາມໄດ້ທີ່ເບີ 021 255035 ໃນໂມງລັດຖະການ <br/>
    <br/>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/SchoolaShip/%E0%BA%97%E0%BA%B7%E0%BA%99%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%AA%E0%BA%B7%E0%BA%81%E0%BA%AA%E0%BA%B2.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/SchoolaShip/%E0%BA%97%E0%BA%B7%E0%BA%99%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%AA%E0%BA%B7%E0%BA%81%E0%BA%AA%E0%BA%B2.jpeg",
    file: "",
  },
  {
    id: "15",
    title: "ທຶນການສຶກສາຂອງ ມະຫາວິທະຍາໄລ Fulbright ສສ ຫວຽດນາມ ປະຈຳປີ 2024",
    entitle: "ສສ ຫວຽດນາມ ປະຈຳປີ 2024",
    detail: `&nbsp; ແຈ້ງທຶນການສຶກສາຂອງ ມະຫາວິທະຍາໄລ Fulbright ສສ ຫວຽດນາມ ປະຈຳປີ 2024 (ສະໝັ້ກອອນລາຍ), <br/>
     ສາມາດເບີ່ງຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີ <a href="https://www.moes.dsa.edu.la" target="_blank">www.moes.dsa.edu.la</a> <br/>
      ຫຼື ສອບຖາມຂໍ້ມູນໄດ້ທີ່ເບີ 021 255033 ໃນໂມງລັດຖະການ <br/>
    <br/>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/schoolaship_img/20-03-2024/WhatsApp+Image+2024-03-20+at+09.19.34+(2).jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/schoolaship_img/20-03-2024/WhatsApp+Image+2024-03-20+at+09.19.34+(2).jpeg",
    file: "",
  },
  {
    id: "16",
    title: "ທຶນການສຶກສາຂອງ ພະແນກສຶກສາ ແຂວງຢູນນານ ສປ.ຈີນ ປະຈຳປີ  2024",
    entitle: "ພະແນກສຶກສາ ແຂວງຢູນນານ ສປ.ຈີນ",
    detail: `&nbsp;ແຈ້ງທຶນການສຶກສາຂອງ ພະແນກສຶກສາ ແຂວງຢູນນານ ສປ.ຈີນ ປະຈຳປີ  2024, <br/>
     ສາມາດເບີ່ງຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີ <a href="https://www.moes.dsa.edu.la" target="_blank">www.moes.dsa.edu.la</a> <br/>
      ຫຼື ສອບຖາມໄດ້ທີ່ເບີ 021 255035 - 021 255033 ໃນໂມງລັດຖະການ <br/>
    <br/>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/schoolaship_img/20-03-2024/WhatsApp+Image+2024-03-19+at+11.27.44+(1).jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/schoolaship_img/20-03-2024/WhatsApp+Image+2024-03-19+at+11.27.44+(1).jpeg",
    file: "",
  },
  {
    id: "17",
    title: "ແຈ້ງທຶນການສຶກສາຂອງ Vietnam Maritime University ປະເທດ ສສ ຫວຽດນາມ ສົກປີ 2024-2025",
    entitle: "ປະເທດ ສສ ຫວຽດນາມ",
    detail: `&nbsp;ແຈ້ງທຶນການສຶກສາຂອງ Vietnam Maritime University ປະເທດ ສສ ຫວຽດນາມ ສົກປີ 2024-2025<br/>
    ສາມາດເບີ່ງຂໍ້ມູນເພີ່ມຕື່ມໄດ້ທີ່ www.moes.dsa.edu.la ແລະ ສອບຖາມໄດ້ທີ່ເບີ 021 265035 - 021 255033 ໃນໂມງລັດຖະການ. <br/>
    <br/>
    `,
    skill: `ບໍມີຂໍ້ມູນ`,
    attachment: `
    ບໍມີຂໍ້ມູນ
    `,
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_scholarship/WhatsApp+Image+2024-06-13+at+09.47.43.jpeg",
    image:
      "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_scholarship/WhatsApp+Image+2024-06-13+at+09.47.43.jpeg",
    file: "",
  },
];
