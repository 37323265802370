import React from 'react'

export default function ControlPanel ({file,pageNumber,numPages,setPageNumber,scale,setScale}) {
  const isFirstPage = pageNumber === 1
  const isLastPage = pageNumber === numPages

  const firstPageClass = isFirstPage ? 'disabled' : 'clickable'
  const lastPageClass = isLastPage ? 'disabled' : 'clickable'

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1)
  }
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1)
  }
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1)
  }
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages)
  }

  const onPageChange = e => {
    const { value } = e.target
    setPageNumber(Number(value))
  }

  const isMinZoom = scale < 0.6
  const isMaxZoom = scale >= 2.0

  const zoomOutClass = isMinZoom ? 'disabled' : 'clickable'
  const zoomInClass = isMaxZoom ? 'disabled' : 'clickable'

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1)
  }

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1)
  }
  return (
    <div className="control-panel align-items-baseline justify-content-between">
      <div className="d-flex justify-content-between align-items-baseline">
        <div onClick={() => goToFirstPage()}>
          <i
            className={`fas fa-fast-backward mx-3 ${firstPageClass}`}
            
          />
        </div>

        <div onClick={goToPreviousPage}>
          <i
            className={`fas fa-backward mx-3 ${firstPageClass}`}
          />
        </div>
        <span>
          ໜ້າ{' '}
          <input
            name='pageNumber'
            type='number'
            min={1}
            max={numPages || 1}
            className='p-0 pl-1 mx-2'
            value={pageNumber}
            onChange={onPageChange}
          />{' '}
          ຂອງ {numPages}
        </span>
        <div onClick={() => goToNextPage()}>
          <i className={`fas fa-forward mx-3 ${lastPageClass}`} />
        </div>

        <div onClick={goToLastPage}>
          <i className={`fas fa-fast-forward mx-3 ${lastPageClass}`} />
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-baseline'>
        <div  onClick={zoomOut}>
          <i
            className={`fas fa-search-minus mx-3 ${zoomOutClass}`}
           
          />
        </div>
        <span>
          {(scale * 100).toFixed()}%</span>
          <div onClick={zoomIn}>
          <i
          className={`fas fa-search-plus mx-3 ${zoomInClass}`}
        />
          </div>
        
      </div>
      <div className='mx-3'>
        <a href={file} download={true} title='download'>
          <i className='fas fa-file-download clickable' />
        </a>
      </div>
    </div>
  )
}
