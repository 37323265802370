import React from "react";
import * as Icon from "react-bootstrap-icons";
import "./Domestic.css";
import useReactRouter from "use-react-router";

const International = () => {
  const { history } = useReactRouter();

  const items = [
    {
      name: "Emerald Insight",
      title: "www.emerald.com",
      img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/emerald.jpg",
      link: "https://www.emerald.com/insight/",
    },
    {
      name: "Scopus",
      title: "Comprehensive, multidisciplinary, trusted abstract and citation database",
      img: "/scopus.png",
      link: "/scopus",
    },
    // {
    //   name: "ProQuest",
    //   title: "www.proguest.com",
    //   img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/Proquest+Logo+filled+background.png",
    //   link: "https://www.proquest.com/",
    // },
    // {
    //   name: "Clarivate Web of Science",
    //   title: "www.clarivate.com",
    //   img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/clarivate.jpg",
    //   link: "http://webofscience.com",
    // },
  ];

  return (
    <div>
      <div id="domestic" className="">
        <div id="category" className="d-flex align-items-center px-2 py-2 ">
          <div className="me-3 d-flex align-items-center">
            <Icon.ChevronLeft onClick={() => history.goBack()} />
          </div>
          <h4 className="fw-bold mt-2">ບົດຄົ້ນຄວ້າສາກົນ</h4>
        </div>
        <div id="domestic-main" className="p-2 mt-5">
          {items.map((item, index) => (
            <a
              href={item.link}
              key={index}
              referrerPolicy="no-referrer-when-downgrade"
              target="_blank"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="container my-2">
                <div
                  id="domestic-item"
                  className="row border border-primary rounded p-1"
                >
                  <div className="col-4 rounded">
                    <img width={70} className="" src={item.img} alt="" />
                  </div>
                  <div id="domestic-text" className="col-8 py-2 px-3">
                    <h1 className="text-truncate ">{item.name}</h1>
                    <h1 className=" text-truncate">{item.title}</h1>
                    <h2 className="text-center text-primary">
                      ລາຍລະອຽດເພິ່ມເຕິມ
                      <span className="ms-1">
                        <Icon.ArrowRight />
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default International;
