import React, { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import useReactRouter from "use-react-router";
import moment from "moment";

// api import
import { API_KEY, NEW_API_URL } from "../../../common/constraint";
import axios from "axios";

function ResearchDetail() {
  const { history, location } = useReactRouter();
  const { item } = location.state;
  const [rateStatus, setRateStatus] = useState(true);

  const giveRate = async (id) => {
    if (rateStatus) {
      setRateStatus(false);
      console.log("fecthing");
      await axios
        .post(`${NEW_API_URL}laodoc/rate?documentId=${id}&rate=1`, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((res) => {
          console.log("give rate", res.data.rate);
          setRateStatus(false);
        })
        .catch((err) => {
          console.log(err);
          setRateStatus(true);
        });
    }
  };

  const onButtonClick = (url) => {
    // using Java Script method to get PDF file
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = url;
        alink.click();
      });
    });
  };

  return (
    <div className="bg-white" style={{ minHeight: "100vh" }}>
      <header
        id="dynamic-header"
        className="py-2 bg-white shadow-sm"
        style={{ position: "fixed", top: "0", left: "0", right: "0" }}
      >
        <div className="container mx-auto">
          <div className="row">
            <div className="col col-1 pt-2">
              <Icon.ChevronLeft onClick={() => history.goBack()} />
            </div>
            <div className="col col-10">
              <h2 className="h5 fw-bolder text-truncate pt-2">
                Latest Products
              </h2>
            </div>
          </div>
        </div>
      </header>
      <main style={{ marginTop: "55px" }}>
        <div
          id="img"
          style={{ maxHeight: "70vh", height: "100%", width: "100%" }}
          className="overflow-hidden d-flex justify-content-center align-items-top"
        >
          <img
            className=" object-fit-cover"
            style={{ width: "100%", objectPosition: "top" }}
            src={item.image}
            alt=""
          />
        </div>
        <div id="content" className="p-2">
          <div className="px-3 mb-4 mt-2  d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {rateStatus ? (
                <div onClick={() => giveRate(item._id)}>
                  <Icon.Heart size={25} />
                </div>
              ) : (
                <div>
                  <Icon.HeartFill color="#fa4867" size={25} />
                </div>
              )}
              <span style={{ fontSize: "small" }}>
                &nbsp;&nbsp;{item.rate} ຄະເເນນ
              </span>
            </div>
            <div onClick={() => onButtonClick(item.file)}>
              <Icon.Download size={25} />
            </div>
          </div>
          <div id="research-info">
            <h3 className="fw-bolder">{item?.title}</h3>
            <div style={{ fontSize: "small" }}>
              <div>
                ສ້າງໂດຍ: <span>{item?.author}</span>
              </div>
              <div>
                ອັບເດດວັນທີ່:
                <span className="text-danger">
                  &nbsp;{moment(item.createdAt).format("DD-MM-YYYY")}
                </span>
              </div>
            </div>
            <div className="my-3">
              <h3 className="fw-bold">ລາຍລະອຽດ</h3>
              <div>
                <p>{item?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ResearchDetail;
