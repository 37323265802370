import React, { useState, useEffect } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { useLazyQuery } from "@apollo/react-hooks";
import Consts from "../consts";
import { USER_KEY } from "../consts/user";
import { setGenders } from "./../super";

import "./sideNav.css";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import { Image } from "react-bootstrap";
import { STUDENT } from "../apollo/user";

function CustomSideNavMobile({ location, history, showNav, closeNav }) {
  const [stId, setStId] = useState();
  const [loadGrade, { data, called, loading }] = useLazyQuery(STUDENT);

  const [selectStatus, setSelectStatus] = useState(
    location?.pathname.split("/")[1].split("-")[0]
  );
  const [loginUserData, setLoginUserData] = useState([]);

  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY);
    if (userData) {
      const user = JSON.parse(userData);
      setStId(
        user?.studentData?.userId.split("/")[0] +
          user?.studentData?.userId.split("/")[1]
      );
      setLoginUserData(user);
    }
  }, []);
  
  useEffect(() => {
    if(loginUserData) {
      loadGrade({
        variables: {
          where: {
            id: loginUserData?.studentData?.id,
          },
        },
      });
    }
  }, [loginUserData]);
  // console.log("object", data)
  const _openProfile = () => {
    history.push(`/profile`);
  };

  return (
    <div>
      <div
        style={{
          position: "fixed",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <SideNav
        className="sidenav---sidenav---_2tBP sidenav---expanded---1KdUL"
        onSelect={(selected) => {
          setSelectStatus(selected.split("-")[0]);
          const to = "/" + selected;
          if (location.pathname !== to) {
            history.push(to);
            if (to === "/timetable-list") {
              window.location.reload(true);
            }
          }
        }}
        style={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          zIndex: 10000,
          display: !showNav ? "none" : "flex",
          overflow: "scroll",
        }}
      >
        <SideNav.Nav
          className="sidenav---sidenav-nav---3tvij sidenav---expanded---1KdUL"
          defaultSelected={location?.pathname.split("/")[1]}
        >
          <div
            style={{
              width: "100%",
              height: 50,
              backgroundColor: "#fff",
              paddingTop: 7,
              paddingLeft: 7,
              justifyContent: "center",
            }}
          >
            <button
              onClick={closeNav}
              style={{
                backgroundColor: "#fff",
                border: "none",
                outline: "none",
              }}
            >
              <i
                className="fa fa-times"
                style={{
                  fontSize: "1.75em",
                  color: Consts.PRIMARY_COLOR,
                }}
              />
            </button>
          </div>

          <div
            onClick={() => _openProfile()}
            style={{
              backgroundColor: "#fff",
              height: 160,
              padding: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 20,
            }}
          >
            {data?.student[0]?.image?.url ? (
              <Image
                src={data?.student[0]?.image?.url}
                // src={URL + data?.student[0]?.image?.url}
                roundedCircle
                width={70}
                height={70}
                style={{ boxShadow: "#f1f1f1 1px 1px 5px 5px" }}
              />
            ) : (
              <Image
                src="./assets/userpng.png"
                roundedCircle
                width={70}
                height={70}
                style={{ boxShadow: "#f1f1f1 1px 1px 5px 5px" }}
              />
            )}
            {loginUserData && (
              <p className="mt-3">
                {setGenders(loginUserData?.studentData?.gender) +
                  " " +
                  loginUserData?.studentData?.firstNameL +
                  " " +
                  loginUserData?.studentData?.lastNameL}
              </p>
            )}
          </div>

          <NavItem
            eventKey="course-list"
            active={selectStatus === "course" ? true : false}
          >
            <NavIcon>
              <i
                className="fa fa-plus-square"
                aria-hidden="true"
                style={{
                  fontSize: "1.75em",
                  height: 50,
                }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ລົງທະບຽນວິຊາຮຽນ
            </NavText>
          </NavItem>
          <NavItem
            eventKey="registered-course-list"
            active={selectStatus === "registered" ? true : false}
          >
            <NavIcon>
              <i
                className="fa fa-bookmark"
                style={{
                  fontSize: "1.75em",
                  height: 50,
                }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ວິຊາທີ່ລົງທະບຽນ
            </NavText>
          </NavItem>

          <NavItem
            eventKey="timetable-list"
            active={selectStatus === "timetable" ? true : false}
          >
            <NavIcon>
              <i
                className="fa fa-table"
                aria-hidden="true"
                style={{
                  fontSize: "1.75em",
                  height: 50,
                }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ຕາຕະລາງຮຽນ
            </NavText>
          </NavItem>

          <NavItem
            eventKey="doc-list"
            active={selectStatus === "doc" ? true : false}
          >
            <NavIcon>
              <i
                className="fa fa-folder"
                aria-hidden="true"
                style={{
                  fontSize: "1.75em",
                  height: 47,
                }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>ເອກະສານ</NavText>
          </NavItem>

          <NavItem
            eventKey="notice-list"
            active={selectStatus === "notice" ? true : false}
          >
            <NavIcon>
              <i
                className="fa fa-bullhorn"
                aria-hidden="true"
                style={{ fontSize: "1.75em", height: 50 }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ແຈ້ງການ ແລະ ລະບຽບ
            </NavText>
          </NavItem>
          <NavItem
            eventKey="stores"
            active={selectStatus === "stores" ? true : false}
          >
            <NavIcon>
              <i
                className="fa fa-store"
                aria-hidden="true"
                style={{
                  fontSize: "1.60em",
                  height: 50,
                }}
              />
              &nbsp;
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>ຮ້ານຄ້າ</NavText>
          </NavItem>
          <NavItem>
            <NavIcon>
              <i
                className="fa fa-info"
                aria-hidden="true"
                style={{
                  fontSize: "1.75em",
                  height: 50,
                }}
              />
            </NavIcon>
            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ຂໍ້ມູນຄະແນນ
            </NavText>

            <NavItem
              eventKey="grade-list"
              style={{
                marginLeft: 30,
              }}
            >
              <NavText
                style={{
                  padding: 5,
                  color: Consts.PRIMARY_COLOR,
                }}
              >
                <i className="fas fa-clipboard-list fa-2x"></i> &nbsp; ຄະແນນ
              </NavText>
            </NavItem>

            <NavItem
              eventKey="upgrade-list"
              style={{
                marginLeft: 30,
              }}
            >
              <NavText
                style={{
                  padding: 5,
                  color: Consts.PRIMARY_COLOR,
                }}
              >
                <i className="fa fa-arrow-alt-circle-up fa-2x"></i> &nbsp;
                ການອັບເກຣດຄະແນນ
              </NavText>
            </NavItem>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </div>
  );
}

export default CustomSideNavMobile;
