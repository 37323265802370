import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Consts from "../../consts";
import { CustomButton } from "../../common";

const DocSearch = ({ showSearchView, _handleSearchViewClose, onSearch }) => {

  let DATENOW =
    new Date().getFullYear() +
    "-" +
    (new Date().getMonth() + 1) +
    "-" +
    new Date().getDate();
  // useEffect(() => {
  //   onSearch({});
  // }, []);

  return (
    <div>
      <BrowserView>
        <Formik
          initialValues={{
            keyword: "",
            createdName: "",
            startDate: "2020-01-01",
            endDate: moment(DATENOW).format("YYYY-MM-DD"),
          }}
          onSubmit={(values) => {
            if(!values.keyword) {
              delete values.keyword
            }
            if(!values.createdName) {
              delete values.createdName
            }
            onSearch(values);
            _handleSearchViewClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Modal
              show={showSearchView}
              onHide={_handleSearchViewClose}
              size="lg"
            >
              <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
                ຄົ້ນຫາເອກະສານ
              </Modal.Title>

              <Modal.Body
                style={{ marginLeft: 50, marginRight: 50, padding: 50 }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: 20, fontWeight: "bold" }}>ໂດຍ</div>
                  <div style={{ width: "100%" }}>
                    <Form.Control
                      type="text"
                      placeholder="ປ້ອນຊື່ຜູ່ສ້າງ"
                      name="createdName"
                      value={values.createdName}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        height: 35,
                        borderRadius: 15,
                        outline: "none",
                        borderColor: "1px solid #eee",
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: 20 }} />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: 20, fontWeight: "bold" }}>
                    ວັນທີ
                  </div>
                  <div style={{ width: "50%" }}>
                    <TextField
                      id="date"
                      type="date"
                      name="startDate"
                      defaultValue={moment(values.startDate).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <TextField
                      id="date"
                      type="date"
                      name="endDate"
                      defaultValue={moment(values.endDate).format("YYYY-MM-DD")}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: 20 }} />
                <hr />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: 20, marginTop: 5 }}>ຄຳສັບ</div>
                  <div style={{ width: "100%" }}>
                    <input
                      type="text"
                      name="keyword"
                      placeholder="ຖານຂໍ້ມູນ, ບົດຄົ້ນຄ້ວາ"
                      style={{
                        width: "100%",
                        padding: "6px 12px",
                        borderRadius: 30,
                        border: "1px solid #eee",
                        outline: "none",
                      }}
                      value={values.keyword}
                      onChange={handleChange}
                    />
                    <FontAwesomeIcon
                      icon={["fa", "search"]}
                      style={{
                        position: "absolute",
                        marginTop: 10,
                        cursor: "pointer",
                        marginLeft: -35,
                        color: Consts.PRIMARY_COLOR,
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: 20 }} />
                <div className="row">
                  <div style={{ padding: 15 }} className="col">
                    <CustomButton
                      confirm
                      onClick={handleSubmit}
                      width="100%"
                      title="ຄົ້ນຫາ"
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Formik>
      </BrowserView>

      <MobileView>
        <Formik
          initialValues={{
            keyword: "",
            createdName: "",
            startDate: "2020-01-01",
            endDate: moment(DATENOW).format("YYYY-MM-DD"),
          }}
          
          onSubmit={(values) => {
            if(!values.keyword) {
              delete values.keyword
            }
            if(!values.createdName) {
              delete values.createdName
            }
            onSearch(values);
            _handleSearchViewClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Modal
              show={showSearchView}
              onHide={_handleSearchViewClose}
              size="lg"
            >
              <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
                ຄົ້ນຫາເອກະສານ
              </Modal.Title>

              <Modal.Body>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: 20, fontWeight: "bold" }}>ໂດຍ</div>
                  <div style={{ width: "100%" }}>
                    <Form.Control
                      type="text"
                      placeholder="ປ້ອນຊື່ຜູ່ສ້າງ"
                      name="createdName"
                      value={values.createdName}
                      onChange={handleChange}
                      style={{
                        width: "100%",
                        height: 35,
                        borderRadius: 15,
                        outline: "none",
                        borderColor: "1px solid #eee",
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: 20 }} />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: 20, fontWeight: "bold" }}>
                    ວັນທີ
                  </div>
                  <div style={{display:'flex',flexWrap:'wrap'}}>
                    <div style={{ width: "100%",marginBottom:20 }}>
                      <TextField
                        id="date"
                        type="date"
                        name="startDate"
                        defaultValue={moment(values.startDate).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <TextField
                        id="date"
                        type="date"
                        name="endDate"
                        defaultValue={moment(values.endDate).format("YYYY-MM-DD")}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ height: 20 }} />
                <hr />
                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: 20, marginTop: 5 }}>ຄຳສັບ</div>
                  <div style={{ width: "100%" }}>
                    <input
                      type="text"
                      name="keyword"
                      placeholder="ຖານຂໍ້ມູນ, ບົດຄົ້ນຄ້ວາ"
                      style={{
                        width: "100%",
                        padding: "6px 12px",
                        borderRadius: 30,
                        border: "1px solid #eee",
                        outline: "none",
                      }}
                      value={values.keyword}
                      onChange={handleChange}
                    />
                    <FontAwesomeIcon
                      icon={["fa", "search"]}
                      style={{
                        position: "absolute",
                        marginTop: 10,
                        cursor: "pointer",
                        marginLeft: -35,
                        color: Consts.PRIMARY_COLOR,
                      }}
                    />
                  </div>
                </div> */}
                <div style={{ height: 20 }} />
                <div className="row">
                  <div style={{ padding: 15 }} className="col">
                    <CustomButton
                      confirm
                      onClick={handleSubmit}
                      width="100%"
                      title="ຄົ້ນຫາ"
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Formik>
      </MobileView>
    </div>
  );
};

export default DocSearch;
