import React, { useState, useEffect } from "react";
import { MobileHeader } from "../../common";

import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CONTAINER_PADDING } from "../../consts";

import _ from "lodash";

import DocSearch from "./DocSearch";
import Loading from "../../common/Loading";
import Consts from "../../consts";
import { FILES } from "../../apollo/file";
import { formatDateTime, noticeStatus } from "../../super";
import { NOTICS } from "../../apollo/notic";

const DocListMobile = () => {
  const { history } = useReactRouter();

  // States
  const [showSearchView, setShowSearchView] = useState(false);
  const [filesData, setFilesData] = useState([]);
  const [isClicked, setIsClicked] = useState(1);
  const [isGrid, setIsGrid] = useState(true);
  const [fileCat, setFileCat] = useState({ type: "PUBLIC_FILE", cate: "RESEARCH", isDeleted: false});
  const [loadData, { loading, data }] = useLazyQuery(FILES);

  const [loadNoticeData, { data: noticeData }] =useLazyQuery(NOTICS);

  // Query courses
  useEffect(() => {
    if (data?.files?.data) {
      setFilesData(data?.files?.data);
    }
  }, [data]);

  useEffect(() => {
    loadData({
      variables: { where: fileCat },
    });
    loadNoticeData({
      variables: {
        where: {
          type: "NEWS",
          isPublish: true,
        },
        orderBy: "createdAt_DESC",
      },
    });
  }, []);

  useEffect(() => {
    loadData({
      variables: { where: fileCat },
    });
  }, [fileCat]);

  // function
  const _handleSearchViewClose = () => setShowSearchView(false);
  const _onSearch = (value) => {
    let keyword = value.keyword;
    // let createdName = value.createdName;
    let startDate = value.startDate;
    let endDate = value.endDate;

    let qlWhere = fileCat
    if(value.createdName) qlWhere = { ...qlWhere, createdBy: {firstname: value.createdName} }
    if(startDate || endDate) qlWhere = {
      ...qlWhere, 
      createdAt_gte: startDate,
      createdAt_lt: endDate 
    }
    loadData({
      variables: {
        where: qlWhere
      }
    })

    _handleSearchViewClose();
  };

  // Filter
  const filterData = () => {
    if (filesData?.length > 0) {
      if (fileCat.cate) {
        return filesData.filter(
          (i) =>
            i.type.toLowerCase().includes(fileCat.type.toLowerCase()) &&
            i.cate.toLowerCase().includes(fileCat.cate.toLowerCase())
        );
      } else {
        return filesData;
      }
    } else {
      return filesData;
    }
  };
  function showBorder(status) {
    // Status : 0 = ທັງໝົດ 1 = ບົດຄົ້ນຄວ້າ 2 = ຄວາມຮູ້ທົ່ວໄປ 3 = ເອກະສານວິຊາສະເພາະ
    setIsClicked(status);
    if (status === 1) {
      setFileCat({ type: "PUBLIC_FILE", cate: "RESEARCH" ,isDeleted: false});
    } else if (status === 2) {
      setFileCat({ type: "PUBLIC_FILE", cate: "GENERAL" ,isDeleted: false});
    } else if (status === 3) {
      setFileCat({ type: "PUBLIC_FILE", cate: "SPECIFIC" ,isDeleted: false});
    } 
  }
  
  const _displayStyle = () => {
    setIsGrid(!isGrid);
  };

  /**
   * Keyword Rendering component
   * @param {Keywords} param0
   */
  const Keyword = ({ keywords }) => {
    if (_.isEmpty(keywords)) {
      return <span></span>;
    }
    return keywords.map((keyword, index) => {
      if (!keyword) return <span key={index} />;
      return <span key={index}>{`#${keyword} `}</span>;
    });
  };
  if (loading) return <Loading />;

  return (
    <div>
      <MobileHeader
        title="ເອກະສານທັງໝົດ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
        showRightSearchButton
        rightButtonClick={() => setShowSearchView(true)}
      />
      <div style={{ marginTop: 80, backgroundColor: "white", height: "100hv" }}>
        <div
          style={{
            width: "100%",
            overflowX: "auto",
            whiteSpace: "nowrap",
            backgroundColor: "white",
          }}
        >
          <table>
            <thead>
              <tr style={{ paddingTop: "10%", height: 50 }}>
                <th>
                  <span
                    onClick={() => showBorder(1)}
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontSize: 16,
                      width: 80,
                      marginRight: 30,
                      textAlign: "center",
                      cursor: "pointer",
                      borderBottom:
                        isClicked === 1 ? `4px solid ${Consts.COLOR_EDIT}` : "none",
                    }}
                  >
                    ບົດຈົບຊັ້ນ / ບົດຄົ້ນຄ້ວາ
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => showBorder(2)}
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontSize: 16,
                      width: 80,
                      marginRight: 30,
                      textAlign: "center",
                      cursor: "pointer",
                      borderBottom:
                        isClicked === 2 ? `4px solid ${Consts.COLOR_EDIT}` : "none",
                    }}
                  >
                    ຄວາມຮູ້ທົ່ວໄປ
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => showBorder(3)}
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontSize: 16,
                      width: 80,
                      marginRight: 30,
                      textAlign: "center",
                      cursor: "pointer",
                      borderBottom:
                        isClicked === 3 ? `4px solid ${Consts.COLOR_EDIT}` : "none",
                    }}
                  >
                    ເອກະສານວິຊາສະເພາະ
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => showBorder(4)}
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontSize: 16,
                      width: 80,
                      marginRight: 30,
                      textAlign: "center",
                      cursor: "pointer",
                      borderBottom:
                        isClicked === 4 ? `4px solid ${Consts.COLOR_EDIT}` : "none",
                    }}
                  >
                    ໜັງສືພີມ
                  </span>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      {isClicked && isClicked !== 4 ?
        <div
          style={{
            marginTop: 20,
            paddingLeft: CONTAINER_PADDING,
            paddingRight: CONTAINER_PADDING,
          }}
        >
          <span style={{ fontWeight: "bold", fontSize: 16 }}>
            ທັງໝົດ {filterData()?.length}
          </span>
          {filterData()?.length > 0 &&
            filterData()?.map((x, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    padding: 10,
                    borderTop: "1px solid #ddd",
                  }}
                >
                  <div>
                    <div style={{ marginTop: 0 }}>
                      <div>
                        <span
                          style={{
                            fontSize: 14,
                            color: Consts.FONT_COLOR_SECONDARY,
                          }}
                        >
                          {/* {x.crete ? x.crete : "-"} */}
                          ສ້າງໂດຍ:
                          {" "}{x.createdBy?.firstname ? x.createdBy?.firstname : "-"}
                        </span>
                        <span
                          style={{
                            float: "right",
                            color: Consts.FONT_COLOR_SECONDARY,
                          }}
                        >
                          {formatDateTime(x.createdAt)}
                        </span>
                      </div>
                      <div>
                      <u onClick={() => history.push(`/doc-list/pdf`, x.file)}>{x.title ? x.title : "ບໍ່ມີຊື່"}</u>
                      </div>
                      <div
                        style={{
                          fontSize: 14,
                          color: Consts.FONT_COLOR_SECONDARY,
                        }}
                      >
                        <Keyword keywords={x.keyword} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        :
        <div
        style={{
          paddingLeft: CONTAINER_PADDING,
          paddingRight: CONTAINER_PADDING,
          backgroundColor: "white",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold", fontSize: 16 }}>
            ທັງໝົດ {noticeData?.notics?.data?.length}
          </span>
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            onClick={_displayStyle}
            icon={["fas", isGrid === true ? "list" : "th-large"]}
          />
        </div>

        <div
          style={{
            display: isGrid === true ? "block" : "none",
            paddingBottom: 20,
          }}
        >
          {noticeData?.notics?.data?.length > 0 &&
            noticeData?.notics?.data?.map((x, index) => {
              return (
                <div
                  onClick={() =>
                    history.push(`/notice-detail/${x.id}/${x.type}`)
                  }
                  key={index}
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    border: "1px solid #ddd",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      height: 171,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={
                        x?.coverImage?.url
                          ? x?.coverImage?.url
                          : "assets/wallpaper-image.png"
                      }
                    />
                  </div>

                  <div style={{ padding: 10 }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a style={{ fontSize: 14 }}>
                        ສ້າງໂດຍ:{x?.createdBy?.firstname}{" "}
                        {x?.createdBy?.lastname ? x?.createdBy?.lastname : ""}
                      </a>
                      <a style={{ fontSize: 14 }}>{noticeStatus(x.type)}</a>
                    </div>
                    <a
                      className=""
                      style={{
                        fontSize: "16px",
                        marginLeft: -1,
                        marginBottom: 10,
                        color: Consts.FONT_COLOR_PRIMARY,
                        fontWeight: "bolder",
                      }}
                    >
                      {x?.title}
                    </a>{" "}
                    <br /> 
                    <a style={{ fontSize: 14 }}>
                      {formatDateTime(x.createdAt)}
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{
            display: isGrid === true ? "none" : "block",
            paddingBottom: 20,
          }}
        >
          {noticeData?.notics?.data?.length > 0 &&
            noticeData?.notics?.data?.map((item, index) => {
              return (
                <div
                  className="list-item"
                  key={index}
                  onClick={() =>
                    history.push(`/notice-detail/${item.id}/${item.type}`)
                  }
                >
                  <table border="0" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: 80, border: "none" }}>
                          <img
                            src={
                              item?.coverImage?.url
                                ? item?.coverImage?.url
                                : "assets/wallpaper-image.png"
                            }
                            className="box-image"
                          />
                        </th>
                        {/* <th style={{ border: "none", width: "20%" }}>
                          <p>ຫົວຂໍ້: </p>
                          <p>ສ້າງ: </p>
                        </th> */}
                        <td
                          style={{
                            border: "none",
                            width: "80%",
                            paddingRight: 10,
                            paddingBottom: 5,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <a style={{ fontSize: 14, marginLeft: 5 }}>
                              {noticeStatus(item?.type)}
                            </a>
                            <a style={{ fontSize: 14 }}>
                              {formatDateTime(item?.createdAt) || "-"}
                            </a>
                          </div>
                          <div>
                            <a
                              className=""
                              style={{
                                width: "100%",
                                fontSize: 16,
                                fontWeight: "bold",
                                overflowX: "hidden",
                                overflowY: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.title}
                            </a>
                          </div>
                          <a style={{ fontSize: 14, marginLeft: 5 }}>
                            ສ້າງໂດຍ:{item?.createdBy?.firstname}{" "}
                            {item?.createdBy?.lastname
                              ? item?.createdBy?.lastname
                              : ""}
                          </a>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              );
            })}
        </div>
      </div>
}
      </div>

      <DocSearch
        showSearchView={showSearchView}
        _handleSearchViewClose={_handleSearchViewClose}
        onSearch={(value) => _onSearch(value)}
      />
    </div>
  );
};

export default DocListMobile;
