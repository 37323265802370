import React from "react";
import { Link } from "react-router-dom";

import "./Research.css";

const Category = ({ item }) => {
  return (
    <>
      <Link to={item.link} style={{ textDecoration: "none", color: "black" }}>
        <div key={item.index} id="Cate" className="">
          <div id="logo">
            <img width={45} src={item.img} alt="" />
          </div>
          <p>{item.name}</p>
        </div>
      </Link>
    </>
  );
};

export default Category;
