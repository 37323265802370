import React from "react";
import Consts from "../consts";

function CustomButton({
  title,
  onClick,
  confirm,
  addIcon,
  width,
  deleteType,
  type,
}) {
  return (
    <button
      style={{
        backgroundColor: confirm
          ? Consts.PRIMARY_COLOR
          : deleteType
          ? Consts.FONT_COLOR_SECONDARY
          : "#fff",
        color: confirm || deleteType ? "#fff" : Consts.FONT_COLOR_SECONDARY,
        width: width || 140,
        height: 40,
        border: confirm
          ? "1px solid " + Consts.PRIMARY_COLOR
          : "1px solid #ddd",
        outline: "none",
      }}
      onClick={() => onClick()}
      type={type ? "submit" : "button"}
    >
      {addIcon && <i className="fa fa-plus" />} {title}
    </button>
  );
}

export default CustomButton;
