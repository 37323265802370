import React, { useEffect, useState } from "react";
import { MobileHeader } from "../../common/MobileHeader";
import useReactRouter from "use-react-router";
import { USER_KEY } from "../../consts/user";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/constraint";
import FileList from "./FileList";
import FolderList from "./FolderList";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Typography } from "@material-ui/core";

export const CourseFileList = () => {
  const { history, match } = useReactRouter();
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  const [fileList, setFileList] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [isRootPath, setIsRootPath] = useState(true);
  const currentPath = history.location.pathname;
  const [paths, setPaths] = useState([]);

  const onPathClick = (id) => {
    const currentPath = history.location.pathname;
    const clickedIdIndex = currentPath.indexOf(id);
    if (clickedIdIndex !== -1) {
      history.push(currentPath.substring(0, clickedIdIndex + id.length));
    }
  };

  const urlParts = currentPath.split("/");
  const courseId = urlParts[3];

  const fetchPathNavigate = async () => {
    if (urlParts.length >= 3) {
      const index_of_course = urlParts.indexOf(courseId);
      if (index_of_course !== -1 && index_of_course < urlParts.length - 1) {
        const arrayAfterOne = [];
        for (let i = index_of_course + 1; i < urlParts.length; i++) {
          arrayAfterOne.push(urlParts[i]);
        }

        const stringAfterOne = arrayAfterOne.join("/");

        const response = await axios.get(
          NEW_API_URL + "course/files/path?paths=" + stringAfterOne,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        );
        setPaths(response?.data);
      }
    }
  };

  useEffect(() => {
    fetchPathNavigate();
  }, []);

  const fetchFolders = async () => {
    if (user && courseId) {
      if (urlParts.length === 4) {
        const response = await axios.get(
          NEW_API_URL +
            "course/files/student/?courseId=" +
            courseId +
            "&studentId=" +
            user?.studentData?.id,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        );
        setFileList(response?.data?.files);
        setFolderList(response?.data?.folders);
      } else {
        const urlParts = currentPath.split("/");
        const lastPart = urlParts[urlParts.length - 1];
        const response = await axios.get(
          NEW_API_URL + "course/files/?folderId=" + lastPart,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        );
        setFileList(response?.data?.files);
        setFolderList(response?.data?.folders);
      }
    }
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  return (
    <div>
      <MobileHeader
        title="ເອກພສານບົດສອນ"
        showLeftArrow
        leftButtonClick={() => history.push("/registered-course-list")}
      />
      <div
        style={{
          marginTop: "70px",
        }}
      >
        <div className="pb-3 pt-2 bg-white px-3">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="/"
              style={{
                cursor: "pointer",
              }}
              onClick={(event) => {
                event.preventDefault(); // Prevent default link behavior
                history.push("/course/files/" + courseId);
              }}
            >
              ຫນ້າຫລັກ
            </Link>
            {paths.length > 0 &&
              paths.map((path, index) => (
                <Link
                  style={{
                    cursor: "pointer",
                  }}
                  key={index}
                  onClick={(event) => {
                    event.preventDefault(); // Prevent default link behavior
                    onPathClick(path?._id); // Execute onPathClick function
                  }}
                  color="inherit"
                  href="/"
                >
                  {path?.folderName}
                </Link>
              ))}
          </Breadcrumbs>
        </div>
        {folderList.length > 0 || fileList.length > 0 ? (
          <>
            <FolderList folderList={folderList || []} />
            <FileList fileList={fileList || []} />
          </>
        ) : (
          <div
            style={{
              backgroundColor: "white",
              height: "90vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img
              width="64"
              height="64"
              src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/external-empty-science-education-dreamstale-lineal-dreamstale.png"
              alt="external-empty-science-education-dreamstale-lineal-dreamstale"
            />
            <Typography
              style={{
                marginTop: "20px",
                fontSize: "30px",
                marginBottom: "10px",
              }}
            >
              ບໍ່ມີຂໍ້ມຸນ
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseFileList;
