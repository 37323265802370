import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import useReactRouter from "use-react-router";
import moment from "moment";

// component import
import Loading from "../../../common/Loading";

// api import
import { API_KEY, NEW_API_URL } from "../../../common/constraint";
import axios from "axios";

function DomesticResearch() {
  const { history } = useReactRouter();
  const [tabs, setTabs] = useState(3);
  const [loading, setLoading] = useState(true);
  const [alldoc, setAlldoc] = useState([]);
  const [generalFiles, setGeneralFiles] = useState([]);
  const [researchFiles, setResearchFiles] = useState([]);
  const [specialFiles, setSpecialFiles] = useState([]);

  const setAlldocFilesFromdata = (data) => {
    const allDocFiles = data.filter(
      (item) =>
        item.type === "research" ||
        item.type === "general" ||
        item.type === "special"
    );
    setAlldoc(allDocFiles);
  };

  // Function to set researchFiles
  const setResearchFilesFromData = (data) => {
    const researchFilesData = data.filter((item) => item.type === "research");
    setResearchFiles(researchFilesData);
  };

  // Function to set generalFiles
  const setGeneralFilesFromData = (data) => {
    const generalFilesData = data.filter((item) => item.type === "general");
    setGeneralFiles(generalFilesData);
  };

  // Function to set specialFiles
  const setSpecialFilesFromData = (data) => {
    const specialFilesData = data.filter((item) => item.type === "special");
    setSpecialFiles(specialFilesData);
  };

  // function for get new api
  const getLaoResearch = async () => {
    setLoading(true);
    await axios
      .get(`${NEW_API_URL}laodoc`, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((res) => {
        if (res.data) {
          setAlldocFilesFromdata(res.data);
          setResearchFilesFromData(res.data);
          setGeneralFilesFromData(res.data);
          setSpecialFilesFromData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  // call the function
  useEffect(() => {
    getLaoResearch();
  }, []);

  // Navigate function
  const navigate = async (item) => {
    setLoading(true);
    await axios
      .get(`${NEW_API_URL}laodoc/view`, {
        headers: {
          api_key: API_KEY,
        },
        params: {
          documentId: item._id,
        },
      })
      .then((res) => {
        console.log("view counted", res.data.viewCount);
      })
      .catch((err) => {
        console.log(err);
      });

    history.push(`/researchdetail/${item._id}`, { item: item });
    setLoading(false);
  };

  if (loading) return <Loading />;

  return (
    <div>
      <header
        id="dynamic-header"
        className="py-2 bg-white shadow-sm"
        style={{ position: "fixed", top: "0", left: "0", right: "0" }}
      >
        <div className="container mx-auto">
          <div className="row mb-4">
            <div className="col col-1 pt-2">
              <Icon.ChevronLeft onClick={() => history.goBack()} />
            </div>
            <div className="col col-10">
              <h2 className="h5 fw-bold text-truncate pt-2">
                ບົດຄົ້ນຄວ້າປະເທດລາວ
              </h2>
            </div>
          </div>
          <div className="row text-center" style={{ fontSize: "small" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setTabs(3)}
              className={`col col-3 text-truncate ${
                tabs === 3 ? "text-primary text-decoration-underline" : ""
              }`}
            >
              ທັງໝົດ
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setTabs(0)}
              className={`col col-3 text-truncate ${
                tabs === 0 ? "text-primary text-decoration-underline" : ""
              }`}
            >
              ຄວາມຮູ້ທົ່ວໄປ
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setTabs(1)}
              className={`col col-3 text-truncate ${
                tabs === 1 ? "text-primary text-decoration-underline" : ""
              }`}
            >
              ບົດຄົ້ນຄວ້າ
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setTabs(2)}
              className={`col col-3 text-truncate ${
                tabs === 2 ? "text-primary text-decoration-underline" : ""
              }`}
            >
              ວິຊາສະເພາະ
            </div>
          </div>
        </div>
      </header>
      <main
        className="container mx-auto pb-2 pt-3"
        style={{
          marginTop: `100px`,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {tabs == 0 && (
          <div id="general" style={{ maxWidth: "425px", width: "100%" }}>
            <h4 className="fw-bold h5 text-truncate">
              {generalFiles.length} ລາຍການ
            </h4>
            <div id="domestics-list">
              {generalFiles && generalFiles.length ? (
                <div>
                  {generalFiles.map((item, index) => (
                    <div key={index} onClick={() => navigate(item)}>
                      <div
                        id="domestic-item"
                        className="px-3 pt-1 rounded bg-white shadow-sm my-2 d-flex align-content-center"
                        style={{ fontSize: "small" }}
                      >
                        <div
                          id="img"
                          className="me-3"
                          style={{
                            width: "35%",
                            minWidth: "100px",
                            maxWidth: "250px",
                            height: "100px",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          }}
                        >
                          <img
                            src={item.image}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </div>
                        <div className="pt-1" style={{ width: "65%" }}>
                          <div className="mb-3">
                            <div className="text-truncate fw-bold mb-2">
                              {item.title}
                            </div>
                            <div className="text-truncate">
                              ສ້າງໂດຍ:
                              <span className="text-truncate">
                                &nbsp;{item.author}
                              </span>
                            </div>
                            <div className="text-truncate">
                              ປະເພດບົດ:
                              <span className="text-truncate">
                                &nbsp;
                                {item.type === "researh" && (
                                  <span>ບົດຄົ້ນຄວ້າ</span>
                                )}
                                {item.type === "special" && (
                                  <span>ວິຊາສະເພາະ</span>
                                )}
                                {item.type === "general" && (
                                  <span>ຄວາມຮູ້ທົ່ວໄປ</span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="text-truncate">
                            ອັບເດດວັນທີ່:
                            <span className="text-danger text-truncate">
                              &nbsp;
                              {moment(item.createdAt).format("DD-MM-YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                  }}
                >
                  <h1 style={{ fontWeight: "bold" }}>ບໍມີລາຍການທີ່ຈະສະເເດງ</h1>
                </div>
              )}
            </div>
          </div>
        )}

        {tabs == 1 && (
          <div id="research" style={{ maxWidth: "425px", width: "100%" }}>
            <h4 className="fw-bold h5 text-truncate">
              {researchFiles.length} ລາຍການ
            </h4>
            <div id="domestics-list">
              {researchFiles && researchFiles.length ? (
                <div>
                  {researchFiles.map((item, index) => (
                    <div key={index} onClick={() => navigate(item)}>
                      <div
                        id="domestic-item"
                        className="px-3 pt-1 rounded bg-white shadow-sm my-2 d-flex align-content-center"
                        style={{ fontSize: "small" }}
                      >
                        <div
                          id="img"
                          className="me-3"
                          style={{
                            width: "35%",
                            height: "100px",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          }}
                        >
                          <img
                            src={item.image}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </div>
                        <div className="pt-1">
                          <div className="mb-3">
                            <div className="text-truncate fw-bold mb-2">
                              {item.title}
                            </div>
                            <div className="text-truncate">
                              ສ້າງໂດຍ:
                              <span className="text-truncate">
                                &nbsp;{item.author}
                              </span>
                            </div>
                            <div className="text-truncate">
                              ປະເພດບົດ:
                              <span className="text-truncate">
                                &nbsp;
                                {item.type === "researh" && (
                                  <span>ບົດຄົ້ນຄວ້າ</span>
                                )}
                                {item.type === "special" && (
                                  <span>ວິຊາສະເພາະ</span>
                                )}
                                {item.type === "general" && (
                                  <span>ຄວາມຮູ້ທົ່ວໄປ</span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="text-truncate">
                            ອັບເດດວັນທີ່:
                            <span className="text-danger text-truncate">
                              &nbsp;{" "}
                              {moment(item.createdAt).format("DD-MM-YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                  }}
                >
                  <h1 style={{ fontWeight: "bold" }}>ບໍມີລາຍການທີ່ຈະສະເເດງ</h1>
                </div>
              )}
            </div>
          </div>
        )}
        {tabs == 2 && (
          <div id="special" style={{ maxWidth: "425px", width: "100%" }}>
            <h4 className="fw-bold h5 text-truncate">
              {specialFiles.length} ລາຍການ
            </h4>
            <div id="domestics-list">
              {specialFiles && specialFiles.length ? (
                <div>
                  {specialFiles.map((item, index) => (
                    <div key={index} onClick={() => navigate(item)}>
                      <div
                        id="domestic-item"
                        className="px-3 pt-1 rounded bg-white shadow-sm my-2 d-flex align-content-center"
                        style={{ fontSize: "small" }}
                      >
                        <div
                          id="img"
                          className="me-3"
                          style={{
                            width: "35%",
                            height: "100px",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          }}
                        >
                          <img
                            src={item.image}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </div>
                        <div className="pt-1">
                          <div className="mb-3">
                            <div className="text-truncate fw-bold mb-2">
                              {item.title}
                            </div>
                            <div className="text-truncate">
                              ສ້າງໂດຍ:
                              <span className="text-truncate">
                                &nbsp;{item.author}
                              </span>
                            </div>
                            <div className="text-truncate">
                              ປະເພດບົດ:
                              <span className="text-truncate">
                                &nbsp;
                                {item.type === "research" && (
                                  <span>ບົດຄົ້ນຄວ້າ</span>
                                )}
                                {item.type === "special" && (
                                  <span>ວິຊາສະເພາະ</span>
                                )}
                                {item.type === "general" && (
                                  <span>ຄວາມຮູ້ທົ່ວໄປ</span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="text-truncate">
                            ອັບເດດວັນທີ່
                            <span className="text-danger">
                              &nbsp;
                              {moment(item.createdAt).format("DD-MM-YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                  }}
                >
                  <h1 style={{ fontWeight: "bold" }}>ບໍມີລາຍການທີ່ຈະສະເເດງ</h1>
                </div>
              )}
            </div>
          </div>
        )}
        {tabs == 3 && (
          <div id="allDoc" style={{ maxWidth: "425px", width: "100%" }}>
            <h4 className="fw-bold h5 text-truncate">{alldoc.length} ລາຍການ</h4>
            <div id="domestics-list">
              {alldoc && alldoc.length ? (
                <div>
                  {alldoc.map((item, index) => (
                    <div key={index} onClick={() => navigate(item)}>
                      <div
                        id="domestic-item"
                        className="px-3 pt-1 rounded bg-white shadow-sm my-2 d-flex align-content-center"
                        style={{ fontSize: "small" }}
                      >
                        <div
                          id="img"
                          className="me-3"
                          style={{
                            width: "35%",
                            height: "100px",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          }}
                        >
                          <img
                            src={item.image}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </div>
                        <div className="pt-1">
                          <div className="mb-3">
                            <div className="text-truncate fw-bold mb-2">
                              {item.title}
                            </div>
                            <div className="text-truncate">
                              ສ້າງໂດຍ:
                              <span className="text-truncate">
                                &nbsp;{item.author}
                              </span>
                            </div>
                            <div className="text-truncate">
                              ປະເພດບົດ:
                              <span className="text-truncate">
                                &nbsp;
                                {item.type === "research" && (
                                  <span>ບົດຄົ້ນຄວ້າ</span>
                                )}
                                {item.type === "special" && (
                                  <span>ວິຊາສະເພາະ</span>
                                )}
                                {item.type === "general" && (
                                  <span>ຄວາມຮູ້ທົ່ວໄປ</span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="text-truncate">
                            ອັບເດດວັນທີ່:
                            <span className="text-danger text-truncate">
                              &nbsp;
                              {moment(item.createdAt).format("DD-MM-YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                  }}
                >
                  <h1 style={{ fontWeight: "bold" }}>ບໍມີລາຍການທີ່ຈະສະເເດງ</h1>
                </div>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default DomesticResearch;
