import React from "react";
import { MobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import { SHOPS } from "./shops";
import { SLIDEIMAGE } from "./SlideImage";
import { Carousel } from "react-bootstrap";
import PlaceholderSrc from "./wallpaper-image.png"
import './card.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Store = () => {
  const { history } = useReactRouter();
  const _storeData = SHOPS.filter((type) => type.type === "store");
  const _foodShopData = SHOPS.filter((type) => type.type === "food");

  return (
    <div>
      <MobileHeader
        title="ສ່ວນຫຼຸດ​ຮ້າ​ນ​ຄ້າ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <div style={{ marginTop: 60, height: "100hv" }}>
        <div style={{ marginBottom: 70 }}>
          <Carousel>
            {SLIDEIMAGE &&
              SLIDEIMAGE?.map((image, index) => (
                <Carousel.Item key={{index}}>
                  <LazyLoadImage
                    className="d-block w-100"
                    src={image?.image}
                    PlaceholderSrc={PlaceholderSrc}
                  />
                </Carousel.Item>
              ))}
          </Carousel>
          <div style={{ position: "absolute", width: "100%", marginTop: "-55px" }}>
            <div className="cardButton">
              <div className='card1' onClick={() => history.push({ pathname: `/food-and-drink` })}>
                <i className="fas fa-utensils cardIcon"></i>
                <p> ຮ້ານອາຫານ  <br /> ແລະ ເຄື່ອງດື່ມ </p>
              </div>
              <div className='card2' onClick={() => history.push({ pathname: `/store-and-service` })} >
                <i className="fa fa-store cardIcon"></i>
                <p> ຮ້ານຄ້າ ແລະ <br /> ບໍລິການທົ່ວໄປ </p>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-3">
          <p style={{ color: "#2085B0", fontSize: 14, marginTop: 20, fontWeight: 700, marginBottom: "15px"}}>
            ຮ້ານອາຫານ ແລະ ເຄື່ອງດື່ມ
          </p>
          <div style={{ width: "100%", display: "flex", overflowX: "scroll"}}>
            {_foodShopData.length > 0 && _foodShopData?.map((foodShop, index) => (
              <div style={{ width: 176, left: 0, top: 0, borderRadius: 8, flexShrink: 0, flexDirection: "column", marginRight: 10, overflow: "hidden", }}
                onClick={() => history.push({ pathname: `/store-detail/${foodShop.id}`, state: foodShop })}
                key={index}
              >
                <div>
                  <LazyLoadImage
                    alt="assets/wallpaper-image.png"
                    style={{ borderRadius: 8 }}
                    effect="blur"
                    height="112px" width="100%"
                    PlaceholderSrc={PlaceholderSrc}
                    src={foodShop?.logo ? foodShop?.logo : "assets/wallpaper-image.png"}
                  />
                  <div>
                    <p style={{ fontSize: 12, marginTop: 5 }}> {foodShop?.name} </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* ຮ້ານຄ້າ ແລະ ບໍລິການທົ່ວໄປ */}

        <div className="pl-3">
          <p style={{ color: "#2085B0", fontSize: 14, fontWeight: 700, marginBottom: "15px"}}>
            ຮ້ານຄ້າ ແລະ ບໍລິການທົ່ວໄປ
          </p>
          <div style={{ width: "100%", display: "flex", overflowX: "scroll"}}>
            {_storeData.length > 0 && _storeData?.map((store, index) => (
              <div
                style={{ width: 176, left: 0, top: 0, borderRadius: 8, flexShrink: 0, flexDirection: "column", marginRight: 10, overflow: "hidden"}}
                onClick={() => history.push({ pathname: `/store-detail/${store.id}`, state: store})} key={index}
              >
                <div>
                  <div>
                    <LazyLoadImage
                      alt=""
                      effect="blur"
                      style={{ width: "100%", height: 112, borderRadius: 8}}
                      src={store?.logo}
                    />
                  </div>
                  <div>
                    <p style={{ fontSize: 12, marginTop: 5 }}>{store?.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
