import React from "react";
import useReactRouter from "use-react-router";
import { Card, Col, Divider, Row} from 'antd';

import "./index.css"
const AllResearchItemesWebsit = () => {
    const { history, match } = useReactRouter();
    const { Meta } = Card;
    const NavigateToResearch = (uri) => {
        history.push(uri);
      };

  return (
    <div>
        <div className="pt-3"> 
            <Row>
                <Col className="gutter-row" flex="1 1 200px"  md={6} >
                    <Card
                        hoverable
                        style={{ width: "100%" }}
                        cover={<img alt="example" src="/assets/nuolresearch.png" className="card-img" />}
                        onClick={()=>{NavigateToResearch("/doc-list")}}
                    >
                        <Meta title="NUOL Research" description="www.student.nuol.edu.la" />
                    </Card>
                </Col>
                <Col className="gutter-row" flex="1 1 200px"  md={6} >
                    <a href="https://www.emerald.com/insight/" referrerPolicy="no-referrer-when-downgrade" target="_blank" style={{textDecoration:"none"}}>
                    <Card
                        hoverable
                        style={{ width: "100%" }}
                        cover={<img alt="example" src="/assets/emerald.png" className="card-img" />}
                    >
                        <Meta title="Emerald insight" description="www.emerald.com" />
                    </Card>
                    </a>
                </Col>
                
                <Col className="gutter-row" flex="1 1 200px"  md={6} >
                    <Card
                        hoverable
                        style={{ width: "100%" }}
                        cover={<img alt="example" src="/assets/proquest.jpg" className="card-img" />}
                        onClick={()=>{NavigateToResearch("/proquest")}}
                    >
                        <Meta title="ProQuest" description="www.proquest.com" />
                    </Card>
                </Col>
                <Col className="gutter-row" flex="1 1 200px"  md={6} >
                    <Card
                        hoverable
                        style={{ width: "100%" }}
                        cover={<img alt="example" src="/assets/webofscience.jpg" className="card-img" />}
                        onClick={()=>{NavigateToResearch("/webofscience")}}
                    >
                        <Meta title="Web of Science" description="http://webofscience.com" />
                    </Card>
                </Col>
                <Col className="gutter-row" flex="1 1 200px"  md={6} >
                    <Card
                        hoverable
                        style={{ width: "100%" }}
                        cover={<img alt="example" src="/assets/scopus.png" className="card-img" />}
                        onClick={()=>{NavigateToResearch("/scopus")}}
                    >
                        <Meta title="Scopus" description="www.scopus.com" />
                    </Card>
                </Col>
            </Row>
            
        </div>
    </div>
  );
};

export default AllResearchItemesWebsit;

