import { faAngleRight, faArrowCircleLeft, faArrowLeft, faBackward } from '@fortawesome/free-solid-svg-icons'
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { SETTING } from "../../consts/router";
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap'
// import { useNavigate } from 'react-router-dom'


function FrequentlyAskedQuestions() {
  
  const { history, location } = useReactRouter();
  
  const _detail = (e) => {
    history.push('/detail', e)
  }
  return (
    <div>
      {/* <div style={{
        backgroundColor: "#057CAE",
        
        height: 56,
       

      }}>
        <center style={{
          backgroundColor: "#057CAE",
          color: "white",
          height: 56,
          marginTop:1

        }}>
          <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowLeft} />
          <span>ຄຳຖາມທີ່ຖຶກຖາມເລື້ອຍໆ</span>
        </center>

      </div> */}
      <div style={{

        height: 56,
        backgroundColor: "#057CAE",


      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          color: "white"



        }}>
          <div style={{
            marginTop: 18,
            marginLeft: 15

          }} ><FontAwesomeIcon style={{ height: 20, width: 20 }} icon={faArrowLeft}
              onClick={() => history.push(SETTING)}  />
          </div>
          <Col style={{
            marginTop: 15,
            display: "flex",
            
            fontSize: 20,
            paddingLeft:62
          }}  >ຄຳຖາມທີ່ຖຶກຖາມເລື້ອຍໆ</Col>
        </div>

      </div>


      {/* end */}
      {/* <div classna>
        <div>ບັດນັກສືກສາໃຊ້ເພືອຫຍັງ ?</div>
        <div>
          <FontAwesomeIcon style={{
            color: "#057CAE",
            width: 30,
            height: 30,
          }} icon={faAngleRight} />
        </div>
      </div> */}

      <div className="row" style={{
       
        paddingLeft: 21.7,
        paddingRight: 10,
        height: 65.75,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid",
        borderColor: "#00000029"
        
      }}
        onClick={(e) => _detail("1")}
      >
        <div className="col-10">
          <div>ບັດນັກສືກສາໃຊ້ເພືອຫຍັງ ?</div>
        </div>
        <div className="col-2" style={{
          paddingLeft: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <FontAwesomeIcon style={{
            color: "#057CAE",
            width: 30,
            height: 30,
          }} icon={faAngleRight} />
        </div>
      </div>
      {/* end */}
      <div className="row" style={{
        
        paddingLeft: 21.7,
        paddingRight: 10,
        height: 65.75,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid",
        borderColor:"#00000029"
      }}
        onClick={(e) => _detail("2")}
      >
        <div className="col-10">
          <div>ເເຈ້ງການເເລະລະບຽບເເມ່ນຫຍັງ ?</div>
        </div>
        <div className="col-2" style={{
          paddingLeft: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <FontAwesomeIcon style={{
            color: "#057CAE",
            width: 30,
            height: 30,
          }} icon={faAngleRight} />
        </div>
      </div>
      {/* end */}
      <div className="row" style={{
        
        
        paddingLeft: 21.7,
        paddingRight: 10,
        height: 65.75,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid",
        borderColor:"#00000029"
      }} onClick={(e) => _detail("3")}>
        <div className="col-10">
          <div>ລົງທະບຽນວິຊາຮຽນເເມ່ນເເນວໃດ ?</div>
        </div>
        <div className="col-2" style={{
          paddingLeft: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <FontAwesomeIcon style={{
            color: "#057CAE",
            width: 30,
            height: 30,
          }} icon={faAngleRight} />
        </div>
      </div>
      {/* end */}
      <div className="row" style={{
        
        paddingLeft: 21.7,
        paddingRight: 10,
        height: 65.75,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid",
        borderColor:"#00000029"
      }} onClick={(e) => _detail("4")}>
        <div className="col-10">
          <div>ວິຊາທີ່ລົງທະບຽນເເມ່ນຫຍັງ ?</div>
        </div>
        <div className="col-2" style={{
          paddingLeft: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <FontAwesomeIcon style={{
            color: "#057CAE",
            width: 30,
            height: 30,
          }} icon={faAngleRight} />
        </div>
      </div>
      {/* end */}
      <div className="row" style={{
        
        paddingLeft: 21.7,
        paddingRight: 10,
        height: 65.75,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid",
        borderColor:"#00000029"
      }} onClick={(e) => _detail("5")}>
        <div className="col-10">
          <div>ລົງທະບຽນເເກ້ເກຣດເເນວໃດ ?</div>
        </div>
        <div className="col-2" style={{
          paddingLeft: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <FontAwesomeIcon style={{
            color: "#057CAE",
            width: 30,
            height: 30,
          }} icon={faAngleRight} />
        </div>
      </div>
      {/* end */}
      <div className="row" style={{
        
        paddingLeft: 21.7,
        paddingRight: 10,
        height: 65.75,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid",
        borderColor:"#00000029"
      }} onClick={(e) => _detail("6")}>
        <div className="col-10">
          <div>ຕາຕະລາງຮຽນເເມ່ນຫຍັງ ?</div>
        </div>
        <div className="col-2" style={{
          paddingLeft: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <FontAwesomeIcon style={{
            color: "#057CAE",
            width: 30,
            height: 30,
          }} icon={faAngleRight} />
        </div>
      </div>
      {/* end */}
      <div className="row" style={{
        
        paddingLeft: 21.7,
        paddingRight: 10,
        height: 65.75,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid",
        borderColor:"#00000029"
      }} onClick={(e) => _detail("7")}>
        <div className="col-10">
          <div>ວິທີ່ເຂົ້າ App SLMS ?</div>
        </div>
        <div className="col-2" style={{
          paddingLeft: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <FontAwesomeIcon style={{
            color: "#057CAE",
            width: 30,
            height: 30,
          }} icon={faAngleRight} />
        </div>
      </div>
      {/* end */}
      <div className="row" style={{
        
        paddingLeft: 21.7,
        paddingRight: 10,
        height: 65.75,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid",
        borderColor:"#00000029"
      }} onClick={(e) => _detail("8")}>
        <div className="col-10">
          <div>ເຮົາຈະເບີ່ງຄະເເນນໄດ້ເເນວໃດ ?</div>
        </div>
        <div className="col-2" style={{
          paddingLeft: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <FontAwesomeIcon style={{
            color: "#057CAE",
            width: 30,
            height: 30,
          }} icon={faAngleRight} />
        </div>
      </div>
      {/* end */}
      </div>
      )
}

      export default FrequentlyAskedQuestions