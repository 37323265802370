import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import Consts from "../../consts";

const CourseDeleteConfirm = ({
  showDeleteConfirmView,
  _handleDeleteConfirmViewClose,
  courseTitle,
  confirmRegister,
  createStatus
}) => {
  return (
    <Modal
      show={showDeleteConfirmView}
      onHide={_handleDeleteConfirmViewClose}
      size="lg"
      centered
    >
      <Modal.Body>
        <p className="text-center" style={{ fontWeight: "bold" }}>
          ຢືນຢັນການລົງທະບຽນ
        </p>

        <p className="text-center">{courseTitle ? courseTitle : ""}</p>
        {createStatus && <p className="text-center" style={{ color: 'red', fontSize: 14 }}>
          {createStatus}
        </p>}

        <div style={{ height: 20 }} />
        <Row>
          <Col xs={6}>
            <Button
              onClick={_handleDeleteConfirmViewClose}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                color: Consts.PRIMARY_COLOR,
                borderColor: Consts.PRIMARY_COLOR,
              }}
            >
              ຍົກເລີກ
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              style={{
                width: "100%",
                float: "right",
                backgroundColor: Consts.PRIMARY_COLOR,
                color: "#fff",
                borderColor: Consts.PRIMARY_COLOR,
              }}
              onClick={() => confirmRegister()}
            >
              ລົງທະບຽນ
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CourseDeleteConfirm;
