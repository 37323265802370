import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";
import _ from "lodash";
// component ------------------------------------------------------------>
import Loading from "../../common/Loading";
import EditPasswordModal from "./EditPasswordModal";
import SearchMobileView from "./SearchMobileView";
import { MobileHeader } from "../../common";
// constance ------------------------------------------------------------>
import { COURSES } from "../../apollo/course";
import { USER_KEY } from "../../consts/user";
import { STUDENT, UPDATE_USER_PASSWORD } from "../../apollo/user";
import Consts from "../../consts";
import { removeDuplicates } from "../../helpers/user";
const CourseListMobileView = () => {
  const { history } = useReactRouter();
  const [courses, setCourses] = useState([]);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
  const [userLoginData, setUserLoginData] = useState({});
  // apollo -------------------------------------------------------->
  const [loadStudentData, { data: student }] = useLazyQuery(STUDENT);

  const [loadCourseData, { loading, data }] = useLazyQuery(COURSES);
  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);
  const [searchCourse, { data: courseSearchData }] = useLazyQuery(COURSES);
  // use ---------------------------------------------------------------->
  useEffect(() => {
    if (courseSearchData && courseSearchData.courses) {
      setCourses(courseSearchData.courses);
    } else {
      if (data && data?.courses?.data) {
        setCourses(removeDuplicates(data?.courses?.data));
      }
    }
  }, [courseSearchData, data]);
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  useEffect(() => {
    if (user) {
      setUserLoginData(user);
    }
    if (localStorage?.editPassword) {
      setShowEditPasswordModal(!showEditPasswordModal);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userLoginData)) {
      loadStudentData({
        variables: {
          where: { id: userLoginData?.studentData?.id },
        },
      });
    }
  }, [userLoginData]);

  useEffect(() => {
    if (student) {
      loadCourseData({
        variables: {
          where: {
            assessmentYear: student?.student?.assessmentYear,
            isDeleted: false,
            year: parseInt(student?.student?.yearLevel),
            major: student?.student?.major?.id,
            classRoom: student?.student?.classRoom?.id,
          },
        },
      });
    }
  }, [student]);

  const _courseDetail = (courseId) => {
    history.push(`/course-detail/${courseId}`);
    window.location.reload();
  };

  const _onSearch = async (value) => {
    if (
      value.courseCode != null ||
      value.faculty != null ||
      value.department != null ||
      value.major != null ||
      value.semester != null ||
      value.year != null
    ) {
      let condition = {};
      if (!_.isEmpty(value.courseCode)) {
        condition["courseCode"] = value.courseCode;
      }
      if (!_.isEmpty(value.year)) {
        condition["year"] = parseInt(value.year);
      }
      if (!_.isEmpty(value.semester)) {
        condition["semester"] = parseInt(value.semester);
      }
      searchCourse({
        variables: {
          where: {
            ...condition,
            isDeleted: false,
            major: student.student.major.id,
          },
        },
      });
    } else {
      searchCourse({
        variables: {
          where: {
            isDeleted: false,
            year: parseInt(student?.student?.yearLevel),
            major: student.student.major.id,
          },
        },
      });
    }
  };

  const _onEdit = async (graphQL) => {
    const res = await updateUserPassword({ variables: graphQL });
    if (res?.data) {
      localStorage.removeItem("editPassword");
      setShowEditPasswordModal(false);
    }
  };
  if (loading) return <Loading />;
  // if (error) return <h1>GraphQL error</h1>;

  return (
    <div>
      <MobileHeader
        title="ວິຊາທັງຫມົດ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
        showRightSearchButton
        rightButtonClick={() => setShowSearchModal(true)}
      />
      <div style={{ padding: 10 }}>
        <div
          style={{
            fontSize: 16,
            color: "#6f6f6f",
            fontWeight: "bold",
            marginTop: 70,
          }}
        >
          ທັງໝົດ {courses && courses?.length}
        </div>
        {courses &&
          courses?.length > 0 &&
          courses?.map((course, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  height: 88,
                  padding: 10,
                  marginTop: 12,
                }}
                onClick={() => _courseDetail(course?.id)}
              >
                <div
                  style={{
                    color: "#929292",
                  }}
                >
                  <h6
                    style={{
                      float: "left",
                      fontSize: 16,
                    }}
                  >
                    {course && course.courseCode ? course.courseCode : ""}
                  </h6>
                  <h6
                    style={{
                      float: "right",
                      marginRight: 20,
                      fontSize: 16,
                    }}
                  >
                    ພາກຮຽນ {course && course.semester ? course.semester : ""},
                    ປີ {course && course.year ? course.year : ""}
                    {/* (
                    {course &&
                    course.CourseSchedule[course.CourseSchedule.length - 1].dayTimeIndexes[course.dayTimeIndexes.length - 1] &&
                    course.CourseSchedule[course.CourseSchedule.length - 1].dayTimeIndexes[course.dayTimeIndexes.length - 1]
                      .dayString
                      ? 'ວັນ' + course.dayTimeIndexes[course.dayTimeIndexes.length - 1]
                          .dayString
                      : ""}{" "}
                    - ຊມ
                    {course &&
                      course.dayTimeIndexes[0] &&
                      course.dayTimeIndexes[0].timeIndexes &&
                      course.dayTimeIndexes[0].timeIndexes.map((x, index) => {
                        return <span key={index}>{x + 1 + " "}</span>;
                      })}
                    ) */}
                  </h6>
                </div>
                <div
                  style={{
                    clear: "left",
                    clear: "right",
                  }}
                >
                  <h5
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    {course && course.title ? course.title : ""}
                  </h5>
                </div>
                <FontAwesomeIcon
                  icon={["fa", "chevron-right"]}
                  color={Consts.PRIMARY_COLOR}
                  style={{
                    position: "relative",
                    left: "92%",
                    bottom: 35,
                  }}
                />
              </div>
            );
          })}
      </div>
      <SearchMobileView
        showSearchView={showSearchModal}
        _handleSearchViewClose={() => setShowSearchModal(false)}
        onSearch={(value) => _onSearch(value)}
      />
      {/* <EditPasswordModal
        showEditPassword={showEditPasswordModal}
        _handleEditPasswordClose={() => setShowEditPasswordModal(false)}
        onEdit={(graphQL) => _onEdit(graphQL)}
        userId={userLoginData?.userId}
      /> */}
    </div>
  );
};

export default CourseListMobileView;
