import React, { useState, useEffect } from "react";
import { USER_KEY } from "../../consts/user";
import { Tabs, Button } from "antd";

import axios from "axios";
import "./index.css";
import { API_KEY, NEW_API_URL } from "../../common/constraint";

import { useLazyQuery } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";
import { GRADE_REGITRATIONS } from "../../apollo/registration";
const NewTimeListMobile = () => {
  const [studentId, setStudentId] = useState();
  const [faculty, setFaculty] = useState("");
  const [yearLevel, setYearLevel] = useState(1);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user) {
      setYearLevel(Number(user.studentData?.yearLevel) || 1);
      setStudentId(user.studentData.id || "");
    }
  }, []);

  const [dataTable, setDataTable] = useState({});
  const [timeList, setTineList] = useState([]);
  const [courseToday, setCourseToday] = useState([]);

  const [selectedYear, setSelectedYear] = useState(1); // State to store the selected option
  const [selectedSemester, setSelectedSemester] = useState(1); // State to store the selected option
  const [selectDay, setSelectDay] = useState(0); // State to store the selected option
  const { history, match } = useReactRouter();

  const fetchTimeTableData = async () => {
    if (studentId) {
      await axios
        .get(
          NEW_API_URL +
            "grade/" +
            studentId +
            "/" +
            selectedYear +
            "/" +
            selectedSemester,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            const data = res.data.data;

            setDataTable(data);
          } else {
            setDataTable({});
          }
        })
        .catch((err) => {
          setDataTable({});
        });
    }
  };

  const fetchStudentInfo = async () => {
    if (studentId) {
      await axios
        .get(NEW_API_URL + "/student/" + studentId, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((res) => {
          if (res.data.success) {
            setFaculty(res.data.data?.faculty);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchTimeList = async () => {
    if (faculty) {
      await axios
        .get(NEW_API_URL + "time/" + faculty + "/2023 - 2024", {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data.data;
            setTineList(data);
          }
        })
        .catch((err) => {});
    }
  };

  const [loginData, setLoginData] = useState();
  const [loadGradeRegistration, { data }] = useLazyQuery(GRADE_REGITRATIONS);
  useEffect(() => {
    const data = localStorage.getItem(USER_KEY);
    if (data) {
      const user = JSON.parse(data);
      setLoginData(user);
    }
  }, []);

  useEffect(() => {
    if (loginData) {
      loadGradeRegistration({
        variables: {
          where: {
            isDeleted: false,
            student: loginData?.studentData?.userId ?? "",
            numberTest: 1,
          },
        },
      });
    }
  }, [loginData]);

  useEffect(() => {
    setSelectedYear(yearLevel);
  }, [yearLevel]);

  const _onCourseDetail = (_id) => {
    const filteredData = data?.grades?.data.filter(
      (citem) => citem?.course?.id === _id
    );
    if (filteredData.length === 0) {
      return null;
    }
    const courseId = filteredData[0].course.id; // this for course
    history.push(`/course/${courseId}`);
  };

  const [selectedDay, setSelectedDay] = useState("monday");

  const [newCourseToday, setNewCourseToday] = useState();

  useEffect(() => {
    let _newCourseToday = [];
    if (courseToday) {
      courseToday.forEach((item) => {
        if (item[0].timesStudy[0].times.length > 1) {
          item[0].timesStudy[0].times.forEach((time) => {
            _newCourseToday.push({
              ...item[0],
              timesStudy: [
                {
                  times: [time],
                },
              ],
            });
          });
        } else {
          _newCourseToday.push(item[0]);
        }
      });
    }

    setNewCourseToday(_newCourseToday);
  }, [courseToday]);

  const weekdays = ["ຈັນ", "ຄານ", "ພູດ", "ພະຫັດ", "ສຸກ", "ເສົາ", "ທິດ"];

  const handleSelectYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSelectSemesterChange = (event) => {
    setSelectedSemester(event.target.value);
  };

  const TableContent = () => {
    return (
      <div
        style={{
          marginTop: "10px",
          backgroundColor: "#e9ecef",
        }}
      >
        <div className="row mb-2 bg-white pb-4">
          <div className="col px-5">
            <label htmlFor="exampleSelect">ເລືອກປີ</label>
            <select
              className="form-control"
              id="exampleSelect"
              value={selectedYear}
              onChange={handleSelectYearChange}
            >
              <option value="">ເລືອກປີ</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
          </div>
          <div className="col px-5">
            <label htmlFor="exampleSelect">ເລືອກພາກຮຽນ</label>
            <select
              className="form-control"
              id="exampleSelect"
              value={selectedSemester}
              onChange={handleSelectSemesterChange}
            >
              <option value="">ເລືອກພາກຮຽນ</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
          </div>
        </div>
        {newCourseToday && newCourseToday.length > 0 && (
          <div
            style={{
              marginTop: "5px",
              backgroundColor: "#e9ecef",
            }}
          >
            {newCourseToday.map((courseitem, index) => (
              <div className="course" key={index}>
                <div className="text-center hour-text">
                  <b>
                    ຊົ່ວໂມງທີ {"  "}
                    <span className="pl-2">
                      {courseitem.timesStudy[0].times[0].hour}
                      {"  "}
                    </span>
                    ( {courseitem.timesStudy[0].times[0].time} ){"  "}
                  </b>
                </div>
                <div className="text-center pt-2 pb-2">
                  <div>
                    <span style={{ fontSize: "17px" }}>
                      <b>ວິຊາ</b> {courseitem.title}
                    </span>{" "}
                    {courseitem.timesStudy[0].evenWeek == true && (
                      <span className="blue-text">(ອາທິດຄູ່)</span>
                    )}{" "}
                    {courseitem.timesStudy[0].oddWeek == true && (
                      <span className="">(ອາທິດຄີກ)</span>
                    )}
                  </div>
                  <div>
                    ອາຈານ {courseitem.teacher[0].firstname}{" "}
                    {courseitem.teacher[0].lastname}
                  </div>
                  <div className="ml-3 mr-3"></div>
                  <div className=" ml-3 mr-3 pt-2">
                    <b>ສະຖານທີ່ສອນ</b>
                    <div>{courseitem.addressLearn}</div>
                  </div>
                  <div className="pt-2">
                    <Button
                      type="text"
                      style={{ color: "#057CAE" }}
                      onClick={() => _onCourseDetail(courseitem.id)}
                    >
                      <img
                        className="mx-3"
                        width="25"
                        height="25"
                        src="https://img.icons8.com/ios/50/000000/bulleted-list.png"
                        alt="bulleted-list"
                      />
                      ລາຍລະອຽດວິຊາ
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const TabItems = new Array(7).fill(null).map((_, i) => {
    return {
      label: `${weekdays[i]}`,
      key: i,
      children: <TableContent />,
    };
  });

  useEffect(() => {
    fetchTimeTableData();
    fetchStudentInfo();
    fetchTimeList();
  }, [selectedYear, selectedSemester, selectedDay, studentId, faculty]);

  const [size, setSize] = useState("small");
  const onChange = (e) => {
    setSize(e.target.value);
  };

  const LoadDayItems = ({ _day }) => {
    // this block off code define all course on the day to coursetoday state
    setCourseToday([]);
    if (_day == 0) {
      const newArray = dataTable?.monday?.filter((item) => item !== null);
      setCourseToday(newArray);
    }
    if (_day == 1) {
      const newArray = dataTable?.tuesday?.filter((item) => item !== null);
      setCourseToday(newArray);
      console.log(newArray);
    } else if (_day == 2) {
      const newArray = dataTable?.wednsday?.filter((item) => item !== null);
      setCourseToday(newArray);
      // console.log(newArray);
    } else if (_day == 3) {
      const newArray = dataTable?.thursday?.filter((item) => item !== null);
      setCourseToday(newArray);
      // console.log(newArray);
    } else if (_day == 4) {
      const newArray = dataTable?.friday?.filter((item) => item !== null);
      setCourseToday(newArray);
      // console.log(newArray);
    } else if (_day == 5) {
      const newArray = dataTable?.saturday?.filter((item) => item !== null);
      setCourseToday(newArray);
      // console.log(newArray);
    } else if (_day == 6) {
      const newArray = dataTable?.sunday?.filter((item) => item !== null);
      setCourseToday(newArray);
    }
  };

  useEffect(() => {
    if (dataTable?.length < 1) return;
    LoadDayItems({ _day: selectDay });
  }, [selectDay, dataTable]);

  return (
    <div className="tbl-mobile-content">
      <Tabs
        defaultActiveKey="1"
        type="card"
        size={size}
        items={TabItems}
        centered
        onChange={(key) => {
          setSelectDay(key);
        }}
      />
    </div>
  );
};

export default NewTimeListMobile;
