import { gql } from "apollo-boost";

export const NOTICS = gql`
  query Notics(
    $where: CustomNoticeWhereInput
    $orderBy: NoticOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    notics(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      #total
      data {
        id
        title
        hastags
        content
        isPublish
        #accessableRole
        level
        type
        startDate
        endDate
        note
        accessableFaculty {
          facultyId
          name
        }
        coverImage {
          url
        }
        createdAt
        createdBy {
          firstname
          lastname
          role
        }
        updatedAt
        updatedBy {
          firstname
          lastname
          role
        }
      }
    }
  }
`;

export const NOTIC = gql`
  query Notic($where: NoticWhereUniqueInput!) {
    notic(where: $where) {
      id
      title
      content
      createdAt
      updatedAt
      accessableRole
      type
      accessableFaculty {
        facultyId
        name
      }
      accessableDepartment {
        departmentId
        name
      }
      isPublish
      coverImage {
        url
      }
      files {
        title
        file
      }
      startDate
      endDate
      updatedBy {
        firstname
        role
      }
      createdBy {
        firstname
      }
    }
  }
`;

export const UPDATE_NOTIC = gql`
  mutation UpdateNotic(
    $data: NoticUpdateInput!
    $where: NoticWhereUniqueInput!
  ) {
    updateNotic(data: $data, where: $where) {
      id
    }
  }
`;

export const CREATE_NOTIC = gql`
  mutation CreateNotic($data: NoticCreateInput!) {
    createNotic(data: $data) {
      id
    }
  }
`;

export const DELETE_NOTIC = gql`
  mutation DeleteNotic($where: NoticWhereUniqueInput!) {
    deleteNotic(where: $where) {
      id
    }
  }
`;
