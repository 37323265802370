import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/constraint";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../common/Loading";

import "./Search.css";

const SearchView = ({ onClose }) => {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Only make the API call if the query is not empty
        if (query.trim() !== "") {
          const response = await axios.get(
            `${NEW_API_URL}laodoc/search?query=${query}`,
            {
              headers: {
                api_key: API_KEY,
              },
            }
          );
          setSearchResults(response.data);
        } else {
          // If the query is empty, clear the search results
          setSearchResults([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function whenever the query changes
    fetchData();
  }, [query]);

  // Navigate function
  const navigate = async (item) => {
    setLoading(true);
    await axios
      .get(`${NEW_API_URL}laodoc/view`, {
        headers: {
          api_key: API_KEY,
        },
        params: {
          documentId: item._id,
        },
      })
      .then((res) => {
        console.log("view counted", res.data.viewCount);
      })
      .catch((err) => {
        console.log(err);
      });

    history.push(`/researchdetail/${item._id}`, { item: item });
    setLoading(false);
  };

  if (loading) return <Loading />;
  return (
    <>
      <div id="search-page">
        <div id="search-bar" className="py-4 px-2">
          <h4 className="fw-bold">Explore More</h4>
          <div id="search-input" className="d-flex align-content-center my-4">
            <div id="input" className="rounded-pill d-flex shadow-sm">
              <Icon.Search />
              <input
                className=""
                type="text"
                placeholder="ຄົ້ນຫາປຶ້ມທີ່ຕ້ອງການ"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div onClick={onClose} className="ml-2">
              <Icon.X size={35} color="red" />
            </div>
          </div>
          <div className="d-flex align-content-center">
            <h6 className="me-4 text-primary" style={{ fontSize: "small" }}>
              Latest
            </h6>
            <h6 style={{ fontSize: "small" }}>Categories</h6>
          </div>
        </div>
        {searchResults.length > 0 ? (
          <div
            style={{
              maxWidth: "500px",
            }}
          >
            <h3 className="pt-3 pb-2 fw-bolder px-4">Result Search</h3>
            <div
              id="resaults"
              className="px-1 pb-2"
              style={{ backgroundColor: "transparent" }}
            >
              <div id="search-result">
                {searchResults.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => navigate(item)}
                    className="my-3"
                  >
                    <div
                      id="domestic-item"
                      className="px-3 pt-1 rounded bg-white shadow-sm my-2 d-flex align-content-center"
                      style={{ fontSize: "small" }}
                    >
                      <div
                        id="img"
                        className="me-3"
                        style={{
                          width: "35%",
                          height: "100px",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          src={item.image}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </div>
                      <div className="pt-1">
                        <div className="mb-3">
                          <div className="text-truncate fw-bold mb-2">
                            {item.title}
                          </div>
                          <div className="text-truncate">
                            ສ້າງໂດຍ:
                            <span className="text-truncate">
                              {" "}
                              {item.author}
                            </span>
                          </div>
                          <div className="text-truncate">
                            ປະເພດບົດ:
                            <span className="text-truncate"> {item.type}</span>
                          </div>
                        </div>
                        <div className="text-truncate">
                          ອັບເດດວັນທີ່:
                          <span className="text-danger text-truncate">
                            29/11/2023
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-5">
            <h1 className="text-center fw-bold">ບໍມີລາຍການທີ່ທ່ານຄົ້ນຫາ</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchView;
