import useReactRouter from "use-react-router";
import React from "react";
import * as Icon from "react-bootstrap-icons";

import "./Research.css";

const Header = ({ onToggleSearch }) => {
  const { history } = useReactRouter();
  return (
    <>
      <div>
        <header id="header" className="py-2 px-2 text-white rounded-bottom-4">
          <div id="backButton">
            <Icon.ChevronLeft size={22} onClick={() => history.goBack()} />
          </div>
          <div className="px-3">
            <div>
              <img
                src="https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/SLMS-Logo.png"
                alt=""
                width="50"
              />
            </div>
            <div className="my-2">
              <h1 className="fw-bold">We provide you</h1>
              <p className="">with a wide range of knowledge</p>
            </div>
            <div id="Search" onClick={onToggleSearch} className="">
              <input
                id="search-Input"
                placeholder="ຄົ້ນຫາປື້ມທີ່ຕອ້ງການ"
                type="text"
                className="bg-transparent border-white border rounded-pill py-1 px-4 me-4"
              />
              <Icon.Search id="search-Button" size={25} />
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
