import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import "./index.css";

// Component import
import NewAllResearchItem from "./newAllResearchItemesMobile";
import AllResearchItemesWebsit from "./AllResearchItemesWebsit";

const NewResearch = () => {
  return (
    <div>
      <div>
        {/* Set view for Devices */}
        <BrowserView>
          <AllResearchItemesWebsit />
        </BrowserView>
        <MobileView>
          <NewAllResearchItem />
        </MobileView>
      </div>
    </div>
  );
};

export default NewResearch;
