import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { BrowserView, isBrowser } from "react-device-detect";
import styled from "styled-components";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Login from "./pages/login/Login";

// Course
import CourseList from "./pages/courseList/CourseList";
import CourseDetail from "./pages/courseDetail/CourseDetail";
import MobileDetailReader from "./pages/courseDetail/MobileDetailReader";
import CourseDocList from "./pages/courseDocList/CourseDocList";
import NavBar from "./common/NavBar";

// resgisteredCouresList
import RegisteredCourseList from "./pages/registeredCourseList/RegisteredCourseList";
import RegisteredCourseDetail from "./pages/registeredCourseDetail/RegisteredCourseDetail";
import MobileRegisteredCourseDetailReader from "./pages/registeredCourseDetail/MobileRegisteredCourseDetailReader";
import RegisteredCourseDocList from "./pages/registeredCourseDocList/RegisteredCourseDocList";

import TimeList from "./pages/timeTable/TimeList";
import TimeCourseDetail from "./pages/timeTable/CourseDetail";
import MobileRegisteredCourseDetail from "./pages/timeTable/MobileRegisteredCourseDetail";
import MobileTimeTableCourseDetailReader from "./pages/timeTable/MobileTimeTableCourseDetailReader";

// International Research
import NewResearch from "./pages/InterNationalResearch/NewResearch";
import Proquest from "./pages/InterNationalResearch/ProQuest/Proquest";
import WebOfScience from "./pages/InterNationalResearch/WebOfScience/WebOfScience";
import Domestic from "./pages/InterNationalResearch/Categories/Domestic";
import Test from "./pages/Testt1";
// import Legislation from "./pages/InterNationalResearch/Categories/Legislation";
import International from "./pages/InterNationalResearch/Categories/International";
import Government from "./pages/InterNationalResearch/Categories/Government";
import DomesticResearch from "./pages/InterNationalResearch/Research-list/DomesticResearch";
import GovernmentResearch from "./pages/InterNationalResearch/Research-list/GovernmentResearch";
import ResearchDetail from "./pages/InterNationalResearch/Research-Details/ResearchDetail";
import LegislationResearch from "./pages/InterNationalResearch/Research-list/LegislationResearch.jsx";

// doc file
import DocList from "./pages/docList/DocList";
import Emerald from "./pages/emerald/Emerald";
import MobileDocReader from "./pages/docList/MobileDocReader";
import DocDetail from "./pages/docList/DocDetail";

import Profile from "./pages/profile/ProfileMobile";

// Notice
import NoticeList from "./pages/notice/NoticeList";
import NoticeDetail from "./pages/notice/NoticeDetail";
import NoticeRelation from "./pages/notice/NoticeRelation";
import MobileNoticeReader from "./pages/notice/MobileNoticeReader";
import MobileNoticeReaderSetting from "./pages/setting/MobileNoticeReaderSetting";

// Grade
import GradeDetail from "./pages/grade/GradeDetail";

// Upgrade
import RegistrationUpgradeForm from "./pages/upgrades/RegistrationUpgradeForm";
import UpgradeList from "./pages/upgrades/upgradeList";
import UpgradeDetailMobileView from "./pages/upgrades/UpgradeDetailMobileView";

// CustomSideNav
import { CustomSideNav } from "./common";
import Store from "./pages/store/Store";
import StudentCard from "./pages/studentCard/studentCard";
import StoreDetail from "./pages/store/StoreDetail";
import Dashboard from "./pages/dashboard/Dashboard";
import CommingSoon from "./pages/dashboard/CommingSoon";

// messenger
import ListMessenger from "./pages/messenger/ListMessenger";

// notification
import ListNotification from "./pages/notification/ListNotification";
import NotificationDetail from "./pages/notification/NotificationDetail";
// setting
import Setting from "./pages/setting/Setting";
import Contact from "./pages/setting/Contact";
import FrequentlyAskedQuestions from "./pages/setting/FrequentlyAskedQuestions";
import Detail from "./pages/setting/Detail";
import Policy from "./pages/setting/Policy";

// Vacancy
import JobVacancyList from "./pages/jobVacancy/JobVacancyList";
import JobVacancyDetail from "./pages/jobVacancy/JobVacancyDetail";

// Outcourse Activity
import OutcourseActivityList from "./pages/outcourseActivity/OutcourseActivityList";
import OutcourseActivityDetail from "./pages/outcourseActivity/OutcourseActivityDetail";

// Old student club
import OldStudentClubList from "./pages/oldStudentClub/OldStudentClubList";
import OldStudentClubDetail from "./pages/oldStudentClub/OldStudentClubDetail";

import StudentScore from "./pages/scores/studentScores";

import { ScopusPage } from "./pages/scopus";
import CourseFileList from "./pages/courseFiles/index.js";

// router
import {
  MESSENGER,
  NOTIFICATION,
  SETTING,
  CONTACT,
  FREQUENTLY_ASKED_QUESTIONS,
  EMERALD,
  EMERALD_USING,
} from "./consts/router";

// media
import Medialist from "./pages/Media/Medialist";
import PlayMedia from "./pages/Media/PlayMedia";
import FoodAndDrink from "./pages/store/FoodAndDrink";
import StoreAndService from "./pages/store/StoreAndService";
import ScholarShipList from "./pages/scholarShip/ScholarShipList";
import ScholarShipDetail from "./pages/scholarShip/ScholarShipDetail";
import OldStudentContact from "./pages/oldStudentClub/OldStudentContact";
import EmeraldUsing from "./pages/emerald/emeraldUsing";

import { PolicyPage } from "./common/PolicyPage";
import { EmeraldIfram } from "./pages/emerald/EmeraldIfram";
import CourseDetailV2 from "./pages/registeredCourseDetail/CourseDetailV2.js";

const Main = styled.main`
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  padding: 0 ${isBrowser ? 20 : 0}px;
  margin-left: ${isBrowser ? 64 : 0}px;
`;
function Routes() {
  return (
    <Router>
      <Switch>
        {/* Before login routes */}
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute path={"/policies-slms-sku"} exact component={Policy} />
        {/* After login routes (has SideNav and NavBar) */}

        <PublicRoute path="/policy" exact component={() => <PolicyPage />} />

        <PublicRoute
          path="/document-list"
          exact
          component={() => <EmeraldIfram />}
        />

        <Route
          render={({ location, history }) => (
            <React.Fragment>
              {/* sidenav */}

              <CustomSideNav location={location} history={history} />

              <Main>
                {/* navbar */}
                <BrowserView>
                  <NavBar />
                </BrowserView>

                {/* Contents */}
                <div
                  style={{
                    marginTop: isBrowser ? 60 : 0,
                    backgroundColor: "#eee",
                    minHeight: "100vh",
                  }}
                >
                  <PrivateRoute
                    path="/course-list"
                    exact
                    component={(props) => <CourseList />}
                  />
                  <PrivateRoute
                    path="/dashboard/"
                    exact
                    component={(props) => <Dashboard />}
                  />
                  <PrivateRoute
                    path="/comming-soon/"
                    exact
                    component={(props) => <CommingSoon />}
                  />
                  <PrivateRoute
                    path="/course-detail/:courseId"
                    exact
                    component={(props) => <CourseDetail />}
                  />
                  <PrivateRoute
                    path="/course-detail/:courseId/pdf"
                    exact
                    component={(props) => <MobileDetailReader />}
                  />
                  <PrivateRoute
                    path="/course-doc-list/:courseId"
                    exact
                    component={(props) => <CourseDocList />}
                  />
                  <PrivateRoute path="/registered-course-edit/:registerId" />
                  {/* registered course */}
                  <PrivateRoute
                    exact
                    path="/registered-course-list"
                    component={(props) => <RegisteredCourseList />}
                  />
                  <PrivateRoute
                    path="/timetable-list"
                    exact
                    component={(props) => <TimeList />}
                  />
                  <PrivateRoute
                    exact
                    path="/timetable-course-detail/:id"
                    component={(props) => <TimeCourseDetail />}
                  />
                  <PrivateRoute
                    exact
                    path="/course/:courseId"
                    component={props => <CourseDetailV2 />}
                  />
                  <PrivateRoute
                    exact
                    path="/timetable-registered-course-detail/:id/:regisid/pdf"
                    component={(props) => <MobileTimeTableCourseDetailReader />}
                    // component={props => <RegisteredCourseDetail />}
                  />
                  <PrivateRoute
                    path="/registered-course-detail/:id"
                    exact
                    component={(props) => <RegisteredCourseDetail />}
                  />
                  <PrivateRoute
                    path="/registered-course-detail/:id/:regisid/pdf"
                    exact
                    component={(props) => (
                      <MobileRegisteredCourseDetailReader />
                    )}
                  />
                  <PrivateRoute
                    path="/registered-course-doc-list/:id/:regisid"
                    exact
                    component={(props) => <RegisteredCourseDocList />}
                  />
                  <PrivateRoute
                    path="/doc-list"
                    exact
                    component={(props) => <DocList />}
                  />
                  <PrivateRoute
                    path="/doc-list/pdf"
                    exact
                    component={(props) => <MobileDocReader />}
                  />
                  <PrivateRoute
                    path="/documents/:docId"
                    exact
                    component={(props) => <DocDetail />}
                  />
                  <PrivateRoute
                    path="/profile"
                    exact
                    component={(props) => <Profile />}
                  />
                  <PrivateRoute
                    path="/notice-list"
                    exact
                    component={(props) => <NoticeList />}
                  />
                  <PrivateRoute
                    path="/notice-detail/:id/:type"
                    exact
                    component={(props) => <NoticeDetail />}
                  />
                  <PrivateRoute
                    path="/notice-relation/:type"
                    exact
                    component={(props) => <NoticeRelation />}
                  />
                  <PrivateRoute
                    path="/notice-detail/:id/:type/pdf"
                    exact
                    component={(props) => <MobileNoticeReader />}
                  />
                  <PrivateRoute
                    path="/setting-pdf-manual"
                    exact
                    component={(props) => <MobileNoticeReaderSetting />}
                  />
                  {/* <PrivateRoute
                    path="/grade-list"
                    exact
                    component={(props) => <GradeDetail />}
                  /> */}
                  <PrivateRoute
                    path="/grade-list"
                    exact
                    component={(props) => <StudentScore />}
                  />
                  <PrivateRoute
                    path="/register-to-upgrade"
                    exact
                    component={(props) => <RegistrationUpgradeForm />}
                  />
                  <PrivateRoute
                    path="/upgrade-list"
                    exact
                    component={(props) => <UpgradeList />}
                  />
                  <PrivateRoute
                    path="/upgrade-detail/:courseId"
                    exact
                    component={(props) => <UpgradeDetailMobileView />}
                  />
                  <PrivateRoute
                    path="/stores"
                    exact
                    component={() => <Store />}
                  />
                  <PrivateRoute
                    path="/food-and-drink"
                    exact
                    component={() => <FoodAndDrink />}
                  />
                  <PrivateRoute
                    path="/store-and-service"
                    exact
                    component={() => <StoreAndService />}
                  />
                  <PrivateRoute
                    path="/store-detail/:id"
                    exact
                    component={() => <StoreDetail />}
                  />

                  <PrivateRoute
                    path="/media/:id"
                    exact
                    component={() => <PlayMedia />}
                  />
                  <PrivateRoute
                    path="/student-card"
                    exact
                    component={() => <StudentCard />}
                  />
                  <PrivateRoute
                    path={MESSENGER}
                    exact
                    component={() => <ListMessenger />}
                  />
                  <PrivateRoute
                    path={NOTIFICATION}
                    exact
                    component={() => <ListNotification />}
                  />
                  {/* Setting */}
                  <PrivateRoute
                    path={SETTING}
                    exact
                    component={() => <Setting />}
                  />
                  <PrivateRoute
                    path={CONTACT}
                    exact
                    component={() => <Contact />}
                  />
                  <PrivateRoute
                    path={FREQUENTLY_ASKED_QUESTIONS}
                    exact
                    component={() => <FrequentlyAskedQuestions />}
                  />
                  <PrivateRoute
                    path="/proquest"
                    exact
                    component={() => <Proquest />}
                  />
                  <PrivateRoute
                    path="/webofscience"
                    exact
                    component={() => <WebOfScience />}
                  />
                  {/* <PrivateRoute
                    path={EMERALD}
                    exact
                    component={() => <Research />}
                  /> */}

                  <PrivateRoute
                    path={EMERALD_USING}
                    exact
                    component={() => <EmeraldUsing />}
                  />
                  <PrivateRoute
                    path="/notification-detail"
                    exact
                    component={() => <NotificationDetail />}
                  />
                  <PrivateRoute
                    path="/detail"
                    exact
                    component={() => <Detail />}
                  />
                  <PrivateRoute
                    path="/studentscore"
                    exact
                    component={() => <StudentScore />}
                  />

                  <PrivateRoute
                    path="/media-page"
                    exact
                    component={() => <Medialist />}
                  />
                  <PrivateRoute
                    path="/job-vacancy-list"
                    exact
                    component={() => <JobVacancyList />}
                  />
                  <PrivateRoute
                    path="/job-vacancy-detail/:id"
                    exact
                    component={() => <JobVacancyDetail />}
                  />
                  <PrivateRoute
                    path="/scholarship-list"
                    exact
                    component={() => <ScholarShipList />}
                  />
                  <PrivateRoute
                    path="/scholarship-detail/:id"
                    exact
                    component={() => <ScholarShipDetail />}
                  />
                  <PrivateRoute
                    path="/activity-list"
                    exact
                    component={() => <OutcourseActivityList />}
                  />
                  <PrivateRoute
                    path="/activity-detail/:id"
                    exact
                    component={() => <OutcourseActivityDetail />}
                  />
                  <PrivateRoute
                    path="/graduated-student-contact"
                    exact
                    component={() => <OldStudentContact />}
                  />
                  <PrivateRoute
                    path="/graduated-student-club-list"
                    exact
                    component={() => <OldStudentClubList />}
                  />
                  <PrivateRoute
                    path="/scopus"
                    exact
                    component={() => <ScopusPage />}
                  />
                  <PrivateRoute
                    path="/graduated-student-club-detail/:id"
                    exact
                    component={() => <OldStudentClubDetail />}
                  />
                  <PrivateRoute
                    path="/newresearch"
                    exact
                    component={() => <NewResearch />}
                  />
                  <PrivateRoute
                    path="/domestic"
                    exact
                    component={() => <Domestic />}
                  />
                  {/* <PrivateRoute
                    path="/legislation"
                    exact
                    component={() => <Legislation />}
                  /> */}
                  <PrivateRoute
                    path="/international"
                    exact
                    component={() => <International />}
                  />
                  <PrivateRoute
                    path="/government"
                    exact
                    component={() => <Government />}
                  />
                  <PrivateRoute
                    path="/domesticresearch"
                    exact
                    component={() => <DomesticResearch />}
                  />
                  <PrivateRoute
                    path="/government_research"
                    exact
                    component={() => <GovernmentResearch />}
                  />
                  <PrivateRoute
                    path="/researchdetail/:id"
                    exact
                    component={() => <ResearchDetail />}
                  />
                  <PrivateRoute
                    path="/legislation_research"
                    exact
                    component={() => <LegislationResearch />}
                  />

                  <PrivateRoute
                    path="/course/files/*"
                    exact
                    component={() => <CourseFileList />}
                  />

                  <PrivateRoute path="/test" exact component={() => <Test />} />
                </div>
              </Main>
            </React.Fragment>
          )}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
