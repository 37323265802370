import { gql } from "apollo-boost";

export const GRADE_REGITRATIONS = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      data {
        id
        gredeStatus
        gradeType {
          name
          score
          maxScore
        }
        student {
          id
          userId
          firstNameL
          lastNameL
          role
          yearLevel
          assessmentYear
          email
          phoneMobile
          note
        }
        course {
          id
          title
          year
          moodleCourseId
          courseCode
          semester
        }
        sendingGradeStatus
        numbericGrade
        yearLevel
        semester
        createdAt
      }
    }
  }
`;

export const GET_REGISTRATION = gql`
  query Registrations($where: RegistrationWhereUniqueInput!) {
    registrations(where: $where) {
      id
      student {
        id
        firstname
        lastname
        userId
        role
        phone
        email
        gender
      }
      course {
        id
        courseCode
        title
        description
        year
        semester
        teacher {
          userId
          firstname
          lastname
        }
        dayTimeIndexes {
          day
          timeIndexes
        }
        syllabusFile {
          id
          title
          file
        }
        files {
          id
          title
          file
        }
      }
      note
      createdBy
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_REGISTRATION = gql`
  mutation CreateRegistration($data: RegistrationCreateInput!) {
    createRegistration(data: $data) {
      id
    }
  }
`;

export const DELETE_REGISTRATION = gql`
  mutation DeleteRegistration($where: RegistrationWhereUniqueInput!) {
    deleteRegistration(where: $where) {
      id
    }
  }
`;
