import React from "react";
import { MobileHeader } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useReactRouter from "use-react-router";
import { ACTIVITIES } from "./const";
import Consts from "../../consts";
import "./index.css";
const OutcourseActivityList = () => {
  const { history } = useReactRouter();

  const _handleDetail = (id) => {
    history.push(`/activity-detail/${id}`);
  };
  return (
    <div style={{ backgroundColor: "#FFFF", minHeight: "100vh" }}>
      <MobileHeader
        title="ກິດຈະກຳນອກຫຼັກສູດ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <div className="row content">
        {ACTIVITIES.map((item, index) => (
          <div key={index} className="col-md-6">
            <div onClick={() => _handleDetail(item.id)} className="section">
              <img
                src={item.img ? item.img : "assets/wallpaper-image.png"}
                alt=""
                className="ImgList"
              />
              <div
                className="d-flex justify-content-center"
                style={{ minHeight: "80px" }}
              >
                <div className="col-9 col-md-9 text-center mt-4">
                  <b style={{ fontSize: 13 }}>{item.title}</b>
                </div>
              </div>
              <div className="text-right pr-2 pb-2 pt-1">
                <span style={{ fontSize: 14, color: Consts.PRIMARY_COLOR }}>
                  ລາຍລະອຽດເພີ່ມເຕີມ
                  <FontAwesomeIcon
                    icon={["fas", "arrow-right"]}
                    color={Consts.PRIMARY_COLOR}
                    size="xs"
                    style={{ marginLeft: 5 }}
                  />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default OutcourseActivityList;
