import React, { useState } from "react";
import { WhiteMobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { JOBS } from "./const";
import Consts from "../../consts";
import "./index.css";
const JobVacancyDetail = () => {
  const { history, match } = useReactRouter();
  const id = match.params.id;
  const [key, setKey] = useState("detail");

  return (
    <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
      <WhiteMobileHeader
        title="ລາຍລະອຽດເພີ່ມເຕີມ"
        showLeftArrow
        leftButtonClick={() => history.push("/job-vacancy-list")}
      />
      <div style={{ paddingTop: 60 }}>
        {JOBS.filter((_data) => _data?.id === id).map((item, index) => (
          <div key={index}>
            <img src={item.img} alt="" className="cardImgDetail" />
            <div
              className="nav-detail"
              style={{ boxShadow: "0px -1px 1px #bdeafd" }}
            >
              <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <Row
                  className="pt-3 pl-4 pr-4 text-center"
                  style={{ borderBottom: "2px solid #7BB500" }}
                >
                  <Col className="p-0">
                    <Nav.Link
                      eventKey="detail"
                      className={key === "detail" ? "activeTab" : "inActiveTab"}
                    >
                      ລາຍລະອຽດ
                    </Nav.Link>
                  </Col>
                  <Col className="p-0">
                    <Nav.Link
                      eventKey="skill"
                      className={key === "skill" ? "activeTab" : "inActiveTab"}
                    >
                      ຄຸນສົມບັດ
                    </Nav.Link>
                  </Col>
                  <Col className="p-0">
                    <Nav.Link
                      eventKey="howTo"
                      className={key === "howTo" ? "activeTab" : "inActiveTab"}
                    >
                      ວິທີສະໝັກ
                    </Nav.Link>
                  </Col>
                </Row>

                <Tab.Content>
                  <Tab.Pane eventKey="detail">
                    {/* <Row style={{ height: "90vh" }}>
                      <p>TAB 1</p>
                    </Row> */}
                    <div className="pt-3 px-2 pb-3">
                      <b>ລາຍລະອຽດການສະໝັກງານ</b>
                      <div className="mt-3">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.detail,
                          }}
                        ></div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="skill">
                    <div className="pt-3 px-2 pb-3">
                      <b>ຄຸນນະສົມບັດຜູ້ສະໝັກ</b>
                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                          __html: item.skill,
                        }}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="howTo">
                    <div className="pt-3 px-2 pb-3">
                      <b>ວິທີສະໝັກ</b>
                      <div className="mt-3">
                        {/* <p>{item.howTo}</p> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.howTo,
                          }}
                        ></div>
                        <p>#ຕິດຕໍ່</p>
                        <p>Whatsapp: {item.whatsapp}</p>
                        <p>ໂທ: {item.tel}</p>
                        <p>Email: {item.email}</p>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default JobVacancyDetail;
