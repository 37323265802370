import React, { useState, useEffect, useRef } from "react";
import useReactRouter from "use-react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "../../index.css";
import {
  Breadcrumb,
} from "react-bootstrap";
import { Formik } from "formik";
import ListAccessableFaculty from "./ListAccessableFaculty";

import Consts from "../../consts";
import { CustomContainer, Title } from "../../common";
import {
  formatDateTime,
  noticeStatus,
  setSwich,
  formatDateWithoutTime,
} from "../../super";
import { useQuery } from "@apollo/react-hooks";
import { NOTIC, NOTICS } from "../../apollo/notic";

export default function DetailNotice() {
  const { history, location, match } = useReactRouter();
  let noticTypes = match?.params?.type;
  const [noticDatas, setNoticDatas] = useState();
  const [noticId, setNoticId] = useState(match?.params?.id);
  const [noticType, setNoticType] = useState(noticTypes);
  const [showListFile, setShowListFile] = useState(false);
  // graphql
  const { data: noticData, Loading } = useQuery(NOTIC, {
    variables: {
      where: {
        id: noticId,
      },
    },
  });

  const { data: subNotice } = useQuery(NOTICS, {
    variables: {
      where: {
        type: noticType,
        isPublish: true,
        // id: noticId,
        // AND: [{ type: noticType }, { id_not: noticId }],
      },
      orderBy: "createdAt_DESC",
    },
  });

  const boxStyle = {
    width: "90%",
    height: 60,
    border: "1px solid " + Consts.SECONDARY_COLOR,
    backgroundColor: "white",
    cursor: "pointer",
    padding: 16,
  };

  // useEffect
  useEffect(() => {
    if (noticData) {
      setNoticDatas(noticData?.notic);
    }
  });

  // function
  // const _handleDeleteShow = () => {
  //   setShowDelete(!showDelete);
  // };

  const _showListFiles = (noticDatas) => {
    setShowListFile(true);
  };

  const closeList = () => {
    setShowListFile(false);
  };
  const _return = () => {
    history.push("/notice-list");
  };
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item onClick={_return}>
          ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍລະອຽດ {noticDatas?.title}</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}></div>
        <div className="content-top">
          <h3 className="title">ລາຍລະອຽດ</h3>
          <div className="row">
            <div className="col-md-3">
              <img
                src={
                  noticDatas?.coverImage?.url ?? "/assets/wallpaper-image.png"
                }
                className="box-view-image"
              />
            </div>
            <div className="col-md-5" style={{ padding: 10 }}>
              <div className="row">
                <div className="col-md-3">
                  <h4>
                    <b>ຫົວຂໍ້: </b>
                  </h4>
                  <p>
                    <b>ປະເພດ:</b>{" "}
                  </p>
                  <p>
                    <b>ຄະນະ:</b>{" "}
                  </p>
                  <p>
                    <b>ມື້ເປິດ-ປິດ: </b>
                  </p>
                  <p>
                    <b style={{ marginTop: 5 }}>ສະຖານະ: </b>
                  </p>

                  <p>
                    <b>ສ້າງ: </b>
                  </p>
                </div>
                <div className="col-md-8">
                  <h4 className="wrap-text">{noticDatas?.title ?? "-"} </h4>
                  <p>{noticDatas?.type ? noticeStatus(noticDatas?.type) : "-"}</p>
                  <p>
                    <div
                      onClick={_showListFiles}
                      className={
                        noticDatas?.accessableFaculty?.length > 0
                          ? "bage-full"
                          : "bage-empty"
                      }
                    >
                      {noticDatas?.accessableFaculty ? noticDatas?.accessableFaculty?.length + " ຄະນະ " : "-"}{" "}
                    </div>
                  </p>
                  <p>
                    {noticDatas?.startDate ? formatDateWithoutTime(noticDatas?.startDate) : "-"} ~{" "}
                    {noticDatas?.endDate ? formatDateWithoutTime(noticDatas?.endDate) : "-"}
                  </p>
                  <p>{noticDatas?.isPublish ? setSwich(noticDatas?.isPublish) : "-"}</p>
                  <p>
                    {noticDatas?.createdAt ? formatDateTime(noticDatas?.createdAt) : "-"} ( ໂດຍ:{" "}
                    {noticDatas?.createdBy?.firstname}{" "}
                    {noticDatas?.createdBy?.lastname
                      ? noticDatas?.createdBy?.lastname
                      : ""}
                    )
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" style={{ textAlign: "left" }}>
              <div
                style={{
                  display: "block",
                  marginTop: 30,
                }}
              >
                <p>
                  <b>ໄຟລ໌ເອກະສານ</b> ( {noticDatas?.files?.length || 0} )
                </p>
                <div
                  style={{
                    overflowY: "scroll",
                    maxHeight: 250,
                  }}
                >
                  {noticDatas?.files.map((item, index) => {
                    return (
                      <ListItem button>
                        <ListItemIcon>
                          <i className="fa fa-file"></i>
                        </ListItemIcon>
                        <ListItemText
                          className="wrap-text"
                          style={{ color: "rgb(5, 124, 174)" }}
                          primary={item?.title}
                        />
                        <ListItemIcon>
                          <a
                            href={item?.file}
                            style={{ color: "rgb(5, 124, 174)" }}
                          >
                            <i className="fa fa-download"></i>
                          </a>
                        </ListItemIcon>
                      </ListItem>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: 10 }}>
          <div className="col-md-7">
            <div className="tab-title">
              <h4>
                <b>ເນື້ອໃນ</b>
              </h4>
            </div>
            <div
              style={{ textAlign: "justify", textIndent: 50 }}
              dangerouslySetInnerHTML={{ __html: noticDatas?.content }}
            ></div>
          </div>
          <div
            className="col-md-5"
            style={{ borderLeft: "solid 0.1px #f1f1f1" }}
          >
            <div
              style={{
                padding: 10,
                marginBottom: 15,
              }}
            >
              <h4
                style={{
                  color: "rgb(5, 124, 174)",
                  fontWeight: "bold",
                }}
              >
                <b>ຫົວຂໍ້ທີ່ກ່ຽວຂ້ອງ</b>
              </h4>
            </div>
            <div style={{ overflowY: "scroll", maxHeight: 500 }}>
              {subNotice &&
              subNotice?.notics?.data?.length &&
              subNotice?.notics?.data?.map((item, index) => {
                return (
                  <div
                    className="list-item"
                    key={index}
                    onClick={() => {
                      setNoticId(item?.id);
                      setNoticType(item?.type);
                    }}
                  >
                    <table border="0" style={{ width: "100%", border: "none" }}>
                      <thead>
                        <tr>
                          <th style={{ width: 100, border: "none" }}>
                            <img
                              src={
                                item?.coverImage?.url ||
                                "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                              }
                              className="box-image"
                            />
                          </th>
                          <th
                            style={{
                              border: "none",
                              width: "25%",
                              paddingLeft: 20,
                            }}
                          >
                            <p>ຫົວຂໍ້: </p>
                            <p>ສ້າງ: </p>
                          </th>
                          <td style={{ width: "80%", border: "none" }}>
                            <p className="wrap-text"> {item?.title || "-"}</p>
                            <p className="wrap-text">
                              {" "}
                              {formatDateTime(item?.createdAt) || "-"}
                            </p>
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CustomContainer>
      <ListAccessableFaculty
        data={noticDatas}
        showList={showListFile}
        closeList={closeList}
      />
    </div>
  );
}
