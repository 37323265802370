export const JOBS = [

  {
    id: "88",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️X.J Electronic & Furniture ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານ",
    detail: `
   ▶️ຮັບຫຼາຍຕຳແໜ່ງ ເງິນເດືອນສູງ <br/>
   ✅ພະນັກງານອອກແບບກຣາບຟິກ (Digital Marketing) <br/>
   ✅ພະນັກງານບັນຊີ <br/>
   ✅ພະນັກງານຂາຍໜ້າຮ້ານ <br/>

    `,
    skill: `

   `,
    howTo: `
    `,
    whatsapp: "",
    tel: "020 58368852",
    email: " ",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/CSL/Logo%E2%80%BC%EF%B8%8FNoy+Moua+Computer+Science+Lab%E2%80%BC%EF%B8%8F.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/CSL/Post%E2%80%BC%EF%B8%8FNoy+Moua+Computer+Science+Lab%E2%80%BC%EF%B8%8F.jpeg",
  },
  {
    id: "87",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ສະຖາບັນການເງິນຈູລະພາກທີ່ບໍ່ຮັບເງິນຝາກ ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານ",
    detail: `
    1.ພະນັກງານສິນເຊື່ອ 3 ຕໍາແໜ່ງ (ຊາຍ) <br/>
    2.ບັນຊີ 2 ຕໍາແໜ່ງ (ຍິງ-ຊາຍ) <br/>
    `,
    skill: `

   `,
    howTo: `
    `,
    whatsapp: "",
    tel: "☎️02099990091 & 030 5559978",
    email: " ",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AA%E0%BA%B0%E0%BA%96%E0%BA%B2%E0%BA%9A%E0%BA%B1%E0%BA%99%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BB%80%E0%BA%87%E0%BA%B4%E0%BA%99%E0%BA%88%E0%BA%B8%E0%BA%A5%E0%BA%B0%E0%BA%9E%E0%BA%B2%E0%BA%81/Logo%E2%80%BC%EF%B8%8F%E0%BA%AA%E0%BA%B0%E0%BA%96%E0%BA%B2%E0%BA%9A%E0%BA%B1%E0%BA%99%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BB%80%E0%BA%87%E0%BA%B4%E0%BA%99%E0%BA%88%E0%BA%B8%E0%BA%A5%E0%BA%B0%E0%BA%9E%E0%BA%B2%E0%BA%81%E0%BA%97%E0%BA%B5%E0%BB%88%E0%BA%9A%E0%BB%8D%E0%BB%88%E0%BA%AE%E0%BA%B1%E0%BA%9A%E0%BB%80%E0%BA%87%E0%BA%B4%E0%BA%99%E0%BA%9D%E0%BA%B2%E0%BA%81%E0%BA%AA%E0%BA%B8%E0%BA%A5%E0%BA%B4%E0%BA%A7%E0%BA%BB%E0%BA%87%E2%80%BC%EF%B8%8F+(1).jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AA%E0%BA%B0%E0%BA%96%E0%BA%B2%E0%BA%9A%E0%BA%B1%E0%BA%99%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BB%80%E0%BA%87%E0%BA%B4%E0%BA%99%E0%BA%88%E0%BA%B8%E0%BA%A5%E0%BA%B0%E0%BA%9E%E0%BA%B2%E0%BA%81/Poster%E2%80%BC%EF%B8%8F%E0%BA%AA%E0%BA%B0%E0%BA%96%E0%BA%B2%E0%BA%9A%E0%BA%B1%E0%BA%99%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BB%80%E0%BA%87%E0%BA%B4%E0%BA%99%E0%BA%88%E0%BA%B8%E0%BA%A5%E0%BA%B0%E0%BA%9E%E0%BA%B2%E0%BA%81%E0%BA%97%E0%BA%B5%E0%BB%88%E0%BA%9A%E0%BB%8D%E0%BB%88%E0%BA%AE%E0%BA%B1%E0%BA%9A%E0%BB%80%E0%BA%87%E0%BA%B4%E0%BA%99%E0%BA%9D%E0%BA%B2%E0%BA%81%E0%BA%AA%E0%BA%B8%E0%BA%A5%E0%BA%B4%E0%BA%A7%E0%BA%BB%E0%BA%87%E2%80%BC%EF%B8%8F.jpg",
  },
  {
    id: "86",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ບໍລິສັດ ອ້ອມອາຊີສາກົນ ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານ",
    detail: `
    1.ພະນັກງານຂາຍ <br/>
    2.ພະນັກງານການຕະຫລາດ <br/>
    3.ຜູ້ຊ່ວຍວິສະວະກຳ <br/>
    4.ແປພາສາເກົາຫລີ <br/>
    5.ພະນັກງານບັນຊີ <br/>
    `,
    skill: `

   `,
    howTo: `
    `,
    whatsapp: "",
    tel: "020 56529738 ຫຼື 020 91112299",
    email: " ",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AD%E0%BB%89%E0%BA%AD%E0%BA%A1%E0%BA%AD%E0%BA%B2%E0%BA%8A%E0%BA%B5/Logo%E2%80%BC%EF%B8%8F%E0%BA%9A%E0%BB%8D%E0%BA%A5%E0%BA%B4%E0%BA%AA%E0%BA%B1%E0%BA%94+%E0%BA%AD%E0%BB%89%E0%BA%AD%E0%BA%A1%E0%BA%AD%E0%BA%B2%E0%BA%8A%E0%BA%B5+%E0%BA%AA%E0%BA%B2%E0%BA%81%E0%BA%BB%E0%BA%99+%E2%80%BC%EF%B8%8F+.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AD%E0%BB%89%E0%BA%AD%E0%BA%A1%E0%BA%AD%E0%BA%B2%E0%BA%8A%E0%BA%B5/Post%E2%80%BC%EF%B8%8F%E0%BA%9A%E0%BB%8D%E0%BA%A5%E0%BA%B4%E0%BA%AA%E0%BA%B1%E0%BA%94+%E0%BA%AD%E0%BB%89%E0%BA%AD%E0%BA%A1%E0%BA%AD%E0%BA%B2%E0%BA%8A%E0%BA%B5+%E0%BA%AA%E0%BA%B2%E0%BA%81%E0%BA%BB%E0%BA%99+%E2%80%BC%EF%B8%8F+.jpg",
  },
  {
    id: "85",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ບໍລິສັດ ໂລກ້າ ຈໍາກັດຜູ້ດຽວ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານ",
    detail: `
    1. ຝ່າຍປະສານງານ ແລະ ຊ່ວຍເຫຼືອ (Part-Time) 1 ຕໍາແໜ່ງ <br />
    `,
    skill: `

   `,
    howTo: `
 
    `,
    whatsapp: "",
    tel: "020 98 111 129",
    email: " ",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Loca/Logo%E2%80%BC%EF%B8%8F%E0%BB%82%E0%BA%A5%E0%BA%81%E0%BB%89%E0%BA%B2%E2%80%BC%EF%B8%8F.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Loca/Post%E2%80%BC%EF%B8%8F%E0%BB%82%E0%BA%A5%E0%BA%81%E0%BB%89%E0%BA%B2%E2%80%BC%EF%B8%8F.jpeg",
  },
  {
    id: "84",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ເອສບີເອສຕົວແທນຫວຍໂຊກໄຊ ແລະ ຫວຍສົມໃຈນຶກ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    ✅ເງິນເດືອນເລີ່ມຕົ້ນ 2,500,000-3,xxx,000 ກີບ <br/>
    ເຮັດວຽກກ່ຽວກັບຫວຍ ປະຈຳສາຂາ ດັ່ງນີ້: <br/>
    1.ນະຄອນຫຼວງ 10 ຕຳແໜ່ງ(ປະຈຳສຳນັກງານ2ຕຳແໜ່ງ, ໜ່ວຍບໍລິການພາຍໃນ 8ຕຳແໜ່ງ)  <br/>
    2. ບໍລິຄຳໄຊ 4ຕຳແໜ່ງ  <br/>
    3. ຄຳມ່ວນ 4ຕຳແໜ່ງ  <br/>
    4. ສະຫວັນນະເຂດ 4ຕຳແໜ່ງ  <br/>
    5. ສາລະວັນ 2ຕຳແໜ່ງ  <br/>
    6.ຈຳປາສັກ 2ຕຳແໜ່ງ  <br/>
    7. ເຊກອງ 2ຕຳແໜ່ງ  <br/>
    8. ອັດຕະປື 4ຕຳແໜ່ງ  <br/>
    9. ບໍ່ແກ້ວ 2ຕຳແໜ່ງ  <br/>
    10. ອຸດົມໄຊ 4 ຕຳແໜ່ງ  <br/>
    11. ຜົ້ງສາລີ 2ຕຳແໜ່ງ  <br/>
    12. ຫຼວງພະບາງ 3 ຕຳແໜ່ງ  <br/>
    13. ວຽງຈັນ 4 ຕຳແໜ່ງ  <br/>
    14.ໄຊຍະບູລີ 3 ຕຳແໜ່ງ  <br/>
    15. ຊຽງຂວາງ 3 ຕຳແໜ່ງ  <br/>
    `,
    skill: `
   `,
    howTo: `
    ລາຍລະອຽດເພີ່ມເຕີມ: https://workhub.bestech.la/application/apply <br/>
    `,
    whatsapp: "",
    tel: "020 78593333",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/soxay/Logo%E2%80%BC%EF%B8%8F%E0%BB%80%E0%BA%AD%E0%BA%AA%E0%BA%9A%E0%BA%B5%E0%BB%80%E0%BA%AD%E0%BA%AA%E0%BA%95%E0%BA%BB%E0%BA%A7%E0%BB%81%E0%BA%97%E0%BA%99%E0%BA%AB%E0%BA%A7%E0%BA%8D%E0%BB%82%E0%BA%8A%E0%BA%81%E0%BB%84%E0%BA%8A+%E0%BB%81%E0%BA%A5%E0%BA%B0+%E0%BA%AB%E0%BA%A7%E0%BA%8D%E0%BA%AA%E0%BA%BB%E0%BA%A1%E0%BB%83%E0%BA%88%E0%BA%99%E0%BA%B6%E0%BA%81%E2%80%BC%EF%B8%8F.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/soxay/Post%E2%80%BC%EF%B8%8F%E0%BB%80%E0%BA%AD%E0%BA%AA%E0%BA%9A%E0%BA%B5%E0%BB%80%E0%BA%AD%E0%BA%AA%E0%BA%95%E0%BA%BB%E0%BA%A7%E0%BB%81%E0%BA%97%E0%BA%99%E0%BA%AB%E0%BA%A7%E0%BA%8D%E0%BB%82%E0%BA%8A%E0%BA%81%E0%BB%84%E0%BA%8A+%E0%BB%81%E0%BA%A5%E0%BA%B0+%E0%BA%AB%E0%BA%A7%E0%BA%8D%E0%BA%AA%E0%BA%BB%E0%BA%A1%E0%BB%83%E0%BA%88%E0%BA%99%E0%BA%B6%E0%BA%81%E2%80%BC%EF%B8%8F.jpg",
  },
  {
    id: "83",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ບໍລິສັດ LSV Logistics‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ການຕະຫຼາດ  1 ຕຳແໜ່ງ (ເພດຍິງ) <br />
    2. ສະຕ໋ອກ ແລະ ຈັດສົ່ງສິນຄ້າ 2 ຕໍາແໜ່ງ (ເພດຊາຍ) <br/>
    3. ແອັດມິນ 1 ຕໍາແໜ່ງ (ເພດຍີງ) <br/>
    `,
    skill: `
    1. ສາມາດສື່ສານໄດ້ດີ <br/>
    2. ມີຄວາມຮັບຜິດຊອບຕໍ່ໜ້າທີ່ວຽກງານ <br/>
    3. ມີຄວາມຊື່ສັດຫ້າວຫັນ <br/>
    4. ກະຕື້ລີ້ລົ້ນຕໍ່ໜ້າທີ່ວຽກງານ <br/>
    5. ມັດພັດທະນາຕົນເອງ <br/>
   `,
    howTo: `
 
    `,
    whatsapp: "",
    tel: "020 5752 9222",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/LSV/Logo%E2%80%BC%EF%B8%8FLSV+LOGISTICS+%E2%80%BC%EF%B8%8F.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/LSV/Post%E2%80%BC%EF%B8%8FLSV+LOGISTICS+%E2%80%BC%EF%B8%8F.jpg",
  },
  {
    id: "82",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ບໍລິສັດ ເຈເຄ ອີ ແອນ ອີ ລາວ ຈໍາກັດຜູ້ດຽວ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານ ຫ້ອງການ ປະຈໍາໂຕະຄອມ 08 ຕຳແໜ່ງ <br />
    `,
    skill: `
   `,
    howTo: `
 
    `,
    whatsapp: "",
    tel: "020 5994 5656 , 020 9194 2627",
    email: " jkenelaosky2@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/EElao/logo_EE.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/EElao/Poster_EE.jpg",
  },
  {
    id: "81",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ບໍລິສັດ ໂສມາພາ ອິນຟໍເມຊັນ ເຕັກໂນໂລຊີ (ລາວ) ຈຳກັດ ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. Contact & Service Center  ຫຼາຍຕຳແໜ່ງ <br />
    `,
    skill: `
   `,
    howTo: `
 
    `,
    whatsapp: "",
    tel: " ",
    email: "Recruitment@somapait.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Somapa+IT/Logo_IT.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Somapa+IT/IT_poster.jpg",
  },
  {
    id: "80",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️SWL Distribution Sole Co.,‼️",
    compaynyAddress:
      "📍ສະຖານທີ່ເຮັດວຽກ: ອາຊຽນມໍ ASEAN MALL ,  ບ້ານ ໂພນທັນ,ເມືອງ ໄຊເສດຖາ ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານ ຜູ້ຊ່ວຍບັນຊີ 01 ຕຳແໜ່ງ <br />
    `,
    skill: `
   `,
    howTo: `
 
    `,
    whatsapp: "",
    tel: " 020 5510 0999",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/swl/Logo_swl.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/swl/Poster_swl.jpg",
  },
  {
    id: "79",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️Xmall‼️",
    compaynyAddress:
      "📍ສະຖານທີ່ເຮັດວຽກ: ອາຊຽນມໍ ASEAN MALL ,  ບ້ານ ໂພນທັນ,ເມືອງ ໄຊເສດຖາ ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານຈັດເຄື່ອງ 1ຕໍ່າແໜ່ງ <br />
    2. ພະນັກງານແພັກເຄື່ອງ 1ຕໍ່ແໜ່ງ <br />
  
    `,
    skill: `
   `,
    howTo: `
    ☎️ ສາມາດສອບຖາມຂໍ້ມູນໄດ້ທີ່: <br />
    - ໃບຢັ້ງຢືນທີ່ຢູ່, ບັດປະຈຳຕົວ ຫຼື ສຳໂມນະຄົວກອບປີ້ 1ຊູດ <br />
    - ຮູບ 3X4ນິ້ວ ຖ່າຍບໍ່ເກີນ 3ເດືອນ 1ໃບ <br />
    
    `,
    whatsapp: "",
    tel: " 020 23181888,020 76499349",
    email: "Inthasone.i@bkilao.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/xmall/Logo_xmall.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/xmall/Poster_xmall.jpg",
  },
  {
    id: "78",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ບໍລິສັດ ກຸງເທບປະກັນໄພ (ລາວ) ຈຳກັດ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານບັນຊີ <br />
    2. ພະນັກງານຮັບປະກັນ / Underwriting <br />

    `,
    skill: `
   `,
    howTo: `
    ☎️ ສາມາດສອບຖາມຂໍ້ມູນໄດ້ທີ່: <br />
    ສົນໃຈສະໝັກຕິດຕໍ່ຕາມອີເມລ ແລະ ເບີໂທ ທີ່ຢູ່ແຕ່ລະແຂວງໄດ້ເລີຍ
    `,
    whatsapp: "",
    tel: "021 417282-4",
    email: "Inthasone.i@bkilao.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Thai_insurance/Logo_Thai.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Thai_insurance/Poster_Thai.jpg",
  },
  {
    id: "77",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ບໍລິສັດ ສະຕາ ໂທລະຄົມ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1.  ປະຈຳສາຂາ ແຂວງນະຄອນຫຼວງ <br />
    2.  ປະຈຳສາຂາ ແຂວງບໍ່ແກ້ວ <br />
    3.  ປະຈຳສາຂາ ແຂວງໄຊສົມບູນ <br />
    4.  ປະຈຳສາຂາ ແຂວງວຽງຈັນ <br />
    5. ປະຈຳສາຂາ ແຂວງໄຊຍະບູລີ <br />
    6. ປະຈຳສາຂາ ແຂວງຜົ້ງສາລີ <br />
    7. ປະຈຳສາຂາ ແຂວງຫຼວງພະບາງ <br />
    8. ປະຈຳສາຂາ ແຂວງຫຼວງນ້ຳທາ <br />
    9. ປະຈຳສາຂາ ແຂວງອຸດົມໄຊ <br />
    10. ປະຈຳສາຂາ ແຂວງຊຽງຂວາງ <br />
    11. ປະຈຳສາຂາ ແຂວງຈຳປາສັກ <br />
    12. ປະຈຳສາຂາ ແຂວງສະຫວັນນະເຂດ <br />
    13. ປະຈຳສາຂາ ແຂວງເຊກອງ <br />
    14. ປະຈຳສາຂາ ແຂວງສາລະວັນ <br />
    15. ປະຈຳສາຂາ ແຂວງຄຳມ່ວນ <br />


    `,
    skill: `
   `,
    howTo: `
    ☎️ ສາມາດສອບຖາມຂໍ້ມູນໄດ້ທີ່: <br />
    ສົນໃຈສະໝັກຕິດຕໍ່ຕາມອີເມລ ແລະ ເບີໂທ ທີ່ຢູ່ແຕ່ລະແຂວງໄດ້ເລີຍ
    `,
    whatsapp: "",
    tel: "",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Unitel/Logo_start.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Unitel/Poster_start.jpg",
  },
  {
    id: "76",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "ທະນາຄານ BFL BRED Group",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. Corporate Relationship Manager <br />
    2. Senior Sales Executive - Retail Banking <br />
    3. Senior Core Banking <br />


    `,
    skill: `
   `,
    howTo: `
    ☎️ ສາມາດສອບຖາມຂໍ້ມູນໄດ້ທີ່: <br />
    recruitment@bfl.la <br/>
    📌Closing date for applications is 20 June 2024📌
    `,
    whatsapp: "",
    tel: "",
    email: "recruitment@bfl.la",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/BFL_Bank/Logo_BFL.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/BFL_Bank/Poster_BFL.jpg",
  },

  {
    id: "75",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "ທະນາຄານເອຊີລີດາ",
    compaynyAddress:
      "📍📍ບ້ານໂພນສະຫວັນເໜືອ, ເມືອງສີສັດຕະນາກ, ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານໜ່ວຍງານລະບົບເຄື່ອຂ່າຍ IT  01 ຕຳແໜ່ງ  <br />
    2. ພະນັກງານໜ່ວຍງານບໍລິຫານລະບົບ ແລະ ຖານຂໍ້ມູນ IT  01 ຕຳແໜ່ງ <br />
    3. ພະນັກງານສິນເຊື່ອ  16 ຕຳແໜ່ງ <br />
    4. ພະນັກງານຕະຫຼາດ  06 ຕຳແໜ່ງ <br />
    5. ພະນັກງານປະຕິບັດງານ  05 ຕຳແໜ່ງ <br />
    6. ຜູ້ຊ່ວຍບໍລິການລູກຄ້າ  06 ຕຳແໜ່ງ <br />
    7. ພະນັກງານອານາໄມ  02 ຕຳແໜ່ງ <br />

    `,
    skill: `
   `,
    howTo: `
    ☎️ ສາມາດສອບຖາມຂໍ້ມູນໄດ້ທີ່: <br />
    020 9292 4999, 020 9300 0204, 020 9206 9494
    `,
    whatsapp: "020 9534 5001",
    tel: "020 9292 4999, 020 9300 0204, 020 9206 9494",
    email: "ERU@acledabank.com.la",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Bank+AC/6d9a8d2b-5a06-4434-84cd-34a32a6801e7.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Bank+AC/9d949c98-787d-49e8-9b8b-7ac2e6a7dbde.jpg",
  },
  {
    id: "74",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "ບໍລິສັດ ເບດຟິດ ຈຳກັດ ຜູ້ດຽວ",
    compaynyAddress:
      "📍ບ້ານນາສາງໄຜ່, ເມືອງໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ຮອງຫົວໜ້າພະແນກບັນຊີ-ການເງິນ  02 ຕຳແໜ່ງ <br/>
    `,
    skill: `
   `,
    howTo: `
    ☎️ ສາມາດສອບຖາມຂໍ້ມູນໄດ້ທີ່: <br />
    020 99 924 459
    `,
    whatsapp: "020 99 924 459",
    tel: "020 99 924 459",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/BF+Company/Logo_BF.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/BF+Company/Poster_BF.jpg",
  },
  {
    id: "73",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ໂຮງຮຽນ ແມ່ຂອງ‼️",
    compaynyAddress:
      "📍ຖະຫນົນສຸພານຸວົງ,ບ້ານສີຖານເຫນືອ,ສີໂຄດຕະບອງ,ນະຄອນຫລວງ",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ຫົວຫນ້າຕ້ອນຮັບຮ້ານອາຫານ(ເພດຍີງແລະສາມາດເວົ້າພາສາຈີນໄດ້) <br />
    2. ພະນັກງານເສີບຮ້ານອາຫານ (ເພດຍິງ) <br />
    3. ພະນັກງານຕ້ອນຮັບເຄົາເຕີໂຮງແຮມພາກຄໍ່າ (ເພດຊາຍ) <br />
    4. ພະນັກງານປ້ອງກັນ <br />
    5. ພະນັກງງານສ້ອມແປງ <br />
    `,
    skill: `
   `,
    howTo: `
    ☎️ ສາມາດສອບຖາມຂໍ້ມູນໄດ້ທີ່: <br />
    021-212938,212939, 020-22204725
    `,
    whatsapp: "020-22204725",
    tel: "021-212938",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Mekong_hotel/Logo_Mekong.jpg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Mekong_hotel/Poster_mekong.jpg",
  },
  {
    id: "72",
    title: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ໂຮງຮຽນ ອັດສະວິນໂຊກໄຊ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ຄູປະຖົມ ແລະ ຄູອະນຸບານ <br />
    2. ຜູ້ຊ່ວຍຄູອະນຸບານ ແລະ ເອື້ອຍລ້ຽງເດັກ <br />
    3. ແມ່ບ້ານອະນາໄມ <br />
    4. ລຸງຍາມ-ຄົນດູແລສວນ <br />
    `,
    skill: `
    ✅ຖ້າໄດ້ພາສາອັງກິດຈະພິຈາລະນາເປັນພິເສດ <br />
    ✅ຄູທີ່ບໍ່ຈົບໃນສາຍຄູ ແມ່ນເຮົາຈະມີຫຼັກສູດຝຶກອົບຮົມໃຫ້ <br />
    ✅ຖ້າຫາກຄູທີ່ຜ່ານງານແລ້ວ ເຮົາຈະສົ່ງໄປຮຽນຄູເພື່ອຍົກລະດັບທີ່ປະເທດໄທ <br />
   `,
    howTo: `
    ☎️ ສາມາດສອບຖາມຂໍ້ມູນໄດ້ທີ່: <br />
    020 56945629 / 020 5647 2203
    `,
    whatsapp: "020 5647 2203",
    tel: "020 5647 2203",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BB%82%E0%BA%AE%E0%BA%87%E0%BA%AE%E0%BA%BD%E0%BA%99+%E0%BA%AD%E0%BA%B1%E0%BA%94%E0%BA%AA%E0%BA%B0%E0%BA%A7%E0%BA%B4%E0%BA%99%E0%BB%82%E0%BA%8A%E0%BA%81%E0%BB%84%E0%BA%8A/Logo_school.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BB%82%E0%BA%AE%E0%BA%87%E0%BA%AE%E0%BA%BD%E0%BA%99+%E0%BA%AD%E0%BA%B1%E0%BA%94%E0%BA%AA%E0%BA%B0%E0%BA%A7%E0%BA%B4%E0%BA%99%E0%BB%82%E0%BA%8A%E0%BA%81%E0%BB%84%E0%BA%8A/poster_school.jpeg",
  },
  {
    id: "71",
    title: "ຮັບສະໝັກເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ  ດ່ວນ!!!",
    companyName: "‼️ອິນຊີ ປະກັນຊີວິດ‼️",
    compaynyAddress:
      "📍ສຳນັກງານໃຫ່ຍຕັ້ງຢູ່: ຖະໜົນ 23 ສິງຫາ, ບ້ານໜອງບອນ, ເມືອງໄຊເສດຖາ ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ຮັບສະໝັກເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ  ດ່ວນ!!!",
    detail: `
    👉🏻ທ່ານທີ່ສົນໃຈສະໝັກສາມາດໂຫລດແບບຟອມສະໝັກວຽກຕາມ Link ທີ່ຢູ່ດ້ານລຸ່ມໄດ້ເລີຍ ຫຼື ທ່ານສາມາດຍື່ນເອກະສານສະໝັກຢູ່ທີ່ ບໍລິສັດ ອິນຊີປະກັນຊີວິດ
    <a href="https://drive.google.com/file/d/1VzjZrb4gdglRoPvRAJCMFm3Q-Bq1RxSc/view?usp=sharing">https://drive.google.com/file/d/1VzjZrb4gdglRoPvRAJCMFm3Q-Bq1RxSc/view?usp=sharing</a>
    <br /> 📍ສຳນັກງານໃຫ່ຍຕັ້ງຢູ່: ຖະໜົນ 23 ສິງຫາ, ບ້ານໜອງບອນ, ເມືອງໄຊເສດຖາ ນະຄອນຫຼວງວຽງຈັນ 
    `,
    skill: `
   `,
    howTo: `
    ສອບຖາມຂໍ້ມູນເພີ່ມເຕີມ:
    `,
    whatsapp: "020 56922265",
    tel: "020 56922265",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AD%E0%BA%B4%E0%BA%99%E0%BA%8A%E0%BA%B5+%E0%BA%9B%E0%BA%B0%E0%BA%81%E0%BA%B1%E0%BA%99%E0%BA%8A%E0%BA%B5%E0%BA%A7%E0%BA%B4%E0%BA%94/Logo_%E0%BA%AD%E0%BA%B4%E0%BA%99%E0%BA%8A%E0%BA%B5.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AD%E0%BA%B4%E0%BA%99%E0%BA%8A%E0%BA%B5+%E0%BA%9B%E0%BA%B0%E0%BA%81%E0%BA%B1%E0%BA%99%E0%BA%8A%E0%BA%B5%E0%BA%A7%E0%BA%B4%E0%BA%94/Poster_%E0%BA%AD%E0%BA%B4%E0%BA%99%E0%BA%8A%E0%BA%B5.jpeg",
  },
  {
    id: "70",
    title: "📣ຮັບສະໝັກ ພະນັກງານ ຈໍານວນຫຼາຍ📣",
    companyName: "📌ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ ‼️",
    compaynyAddress:
      "📍ປະຈຳສຳນັກງານໃຫຍ່: ສະຖານທີ່ຕັ້ງຢູ່ ບ້ານໂພນໄຊ ເມືອງໄຊເສດຖາ ເເຂວງນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "📣ຮັບສະໝັກ ພະນັກງານ ຈໍານວນຫຼາຍ📣",
    detail: `
    📍ປະຈຳສຳນັກງານໃຫຍ່: ສະຖານທີ່ຕັ້ງຢູ່ ບ້ານໂພນໄຊ ເມືອງໄຊເສດຖາ ເເຂວງນະຄອນຫຼວງວຽງຈັນ  
    `,
    skill: `
   `,
    howTo: `
    👉ສົນໃຈສາມາດຕິດຕໍ່ສອບຖາມ
    `,
    whatsapp: "020 2308 2220",
    tel: "020 2308 2220",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AD%E0%BA%B2%E0%BA%99%E0%BA%B8%E0%BA%AA%E0%BA%B4%E0%BA%94+%E0%BB%82%E0%BA%A5%E0%BA%88%E0%BA%B4%E0%BA%AA%E0%BA%95%E0%BA%B4%E0%BA%81/Logo-Anousith.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AD%E0%BA%B2%E0%BA%99%E0%BA%B8%E0%BA%AA%E0%BA%B4%E0%BA%94+%E0%BB%82%E0%BA%A5%E0%BA%88%E0%BA%B4%E0%BA%AA%E0%BA%95%E0%BA%B4%E0%BA%81/Poster-Anousith.jpeg",
  },
  {
    id: "69",
    title: "ຮັບສະໝັກພະນັກງານ ຈຳນວນຫຼາຍ",
    companyName: "‼️ພາລວຍ Motor‼️",
    compaynyAddress:
      "📍ທີ່ຕັ້ງ: ບ້ານ ໜອງພະຍາ,ເມືອງໄຊທານີ,ນະຄອນຫລວງວຽງຈັນ",
    vacancy: "ຮັບສະໝັກພະນັກງານ ຈຳນວນຫຼາຍ",
    detail: `
   📍 ທີ່ຕັ້ງ: ບ້ານ ໜອງພະຍາ,ເມືອງໄຊທານີ,ນະຄອນຫລວງວຽງຈັນ 
    ກົດເບິ່ງແຜນທີ່: <a href="https://maps.app.goo.gl/BELUSpWhn35vyfaM8">https://maps.app.goo.gl/BELUSpWhn35vyfaM8</a>

    `,
    skill: `
   `,
    howTo: `
    👇🏼ສົນໃຈສົ່ງ C.V ມາທີ່👇🏼 <br />
    📩ອີເມວ soukthavy625@gmail.com 
    ຫຼື ໂທ 020 92265235 <br />
    <a href="https://wa.me/8562092265235" >https://wa.me/8562092265235</a> <br />
    ພ້ອມກັບລະບຸຕຳແໜ່ງ <br />
    _____________ <br />
    📲 ສອບຖາມລາຍລະອຽດເພີ່ມເຕີມ: <br />
    ☎️ໂທ: 020 77577755 ຫຼື ທັກWhatsapp: <a href="https://wa.me/8562077577755" >https://wa.me/8562077577755</a>
    `,
    whatsapp: "020 92265235",
    tel: "020 92265235",
    email: "soukthavy625@gmail.com ",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Pmotor/Logo_pmotor.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Pmotor/Poster_pmotor.jpeg",
  },
  {
    id: "68",
    title: "ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ",
    companyName: "‼️ປັນຍາ Home ຄົບເຄື່ອງເລື່ອງບ້ານ ‼️",
    compaynyAddress:
      "📍ຖະໜົນ13ໃຕ້ ບ້ານ ໂຄກວິໄລ, ເມືອງ ໄຊທານີ, ນະຄອນຫລວງວຽງຈັນ",
    vacancy: "ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານຂາຍ 03ຕຳແໜ່ງ <br />
    ສົນໃຈສະໝັກໄດ້ທີເບີ 020 58615168 <br />
    📍ຖະໜົນ13ໃຕ້ ບ້ານ ໂຄກວິໄລ, ເມືອງ ໄຊທານີ, ນະຄອນຫລວງວຽງຈັນ
    `,
    skill: `
   `,
    howTo: `
    ສົນໃຈສະໝັກໄດ້ທີເບີ 020 58615168
    `,
    whatsapp: "020 58615168",
    tel: "020 58615168",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%9B%E0%BA%B1%E0%BA%99%E0%BA%8D%E0%BA%B2+Home/Logo-%E0%BA%82%E0%BA%B2%E0%BA%8D.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%9B%E0%BA%B1%E0%BA%99%E0%BA%8D%E0%BA%B2+Home/poster-%E0%BA%82%E0%BA%B2%E0%BA%8D.jpeg",
  },
  {
    id: "67",
    title: "ຕ້ອງການຮັບສະໝັກພະນັກງານຕຳແໜ່ງວິຊາການ ຈຳນວນ 09 ຕຳແໜ່ງ",
    companyName: "‼️ບໍລິສັດ ໄປສະນີລາວ ຈຳກັດ‼️",
    compaynyAddress:
      "📍ສຳນັກງານໃຫຍ່ ຖະໜົນຄຸວຽງ, ບ້ານ ຊຽງຢືນ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼຼວງວຽງຈັນ (ໜ້າຕະຫຼາດເຊົ້າມໍ)",
    vacancy: "ຕ້ອງການຮັບສະໝັກພະນັກງານຕຳແໜ່ງວິຊາການ ຈຳນວນ 09 ຕຳແໜ່ງ",
    detail: `
    
    🔊ຕ້ອງການຮັບສະໝັກພະນັກງານຕຳແໜ່ງວິຊາການ ຈຳນວນ 09 ຕຳແໜ່ງ ປະຈຳຫ້ອງການ-ບໍລິຫານ ແລະ ສູນແລກປ່ຽນເມ ທີ່ສຳນັກງານໃຫຍ່ ບໍລິສັດ ໄປສະນີລາວ ຈຳກັດ. <br />
    👉ຕ້ອງການເພື່ອນຮ່ວມງານ  09 ຕຳແໜ່ງ ດັ່ງນີ້: <br />
    ✅ພະນັກງານເລຂານຸການ ເພດຍິງ ຮຽນຈົບເລຂານຸການ 01 ຕຳແໜ່ງ. <br />
    ✅ພະນັກງານ ໄອທີ ມີຄວາມຮູ້ດ້ານ Software, Hardware ແລະ ຂຽນ Program 01 ຕຳແໜ່ງ. <br />
    ✅ພະນັກງານ ບໍລິຫານ ເພດຊາຍ ຮຽນຈົບບໍລິຫານທຸລະກິດ 01 ຕຳແໜ່ງ. <br />
    ✅ພະນັກງານຂັບລົດໃຫຍ່ ເພດຊາຍ ມີໃບຂັບຂີ່ ABC ຫຼື D 03 ຕຳແໜ່ງ.<br />
    ✅ພະນັກງານຄັດແຍກ ແລະ ຂົນສົ່ງພັດສະດຸໄປສະນີ & EMS ເພດຊາຍ 03 ຕຳແໜ່ງ. <br />
    
    <a href="https://maps.google.com/?q=17.964926,102.613319">https://maps.google.com/?q=17.964926,102.613319</a> <br />
    📍ທີ່ສຳນັກງານໃຫຍ່ ຖະໜົນຄຸວຽງ, ບ້ານ ຊຽງຢືນ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼຼວງວຽງຈັນ (ໜ້າຕະຫຼາດເຊົ້າມໍ).
    `,
    skill: `
    ✅ອາຍຸ 20-35 ປີ. <br />
    ✅ວຸດທິການສຶກສາຈົບຊັ້ນສູງ, ປະລິນຍາຕີ ຂື້ນໄປ ແລະ ສາຂາອື່ນໆ ທີ່ກ່ຽວຂ້ອງ. <br />
    ✅ວາຈາສຸພາບ ແລະ ມີບຸກຄະລິກດິ. <br />
    ✅ສາມາດນຳໃຊ້ Microsoft Word, Excel , Power Point ໄດ້ດີ. <br />
    ✅ສາມາດເວົ້າ, ອ່ານ, ຟັງ ແລະ ຂຽນ ພາສາອັງກິດ, ພາສາຈີນ ຫຼື ຫວຽດນາມ ຈະພິຈາລະນາເປັນພິເສດ. <br />
    ✅ມີທັກສະໃນການສື່ສານ ແລະ ມະນຸດສຳພັນດີ. <br />
    ✅ອົດທົນ, ຊື່ສັດ, ມີຄວາມລະອຽດຮອບຄອບ, ມີວິໄນ, ມີຄວາມຮັບຜິດຊອບສູງຕໍ່ໜ້າທີ່ ທີ່ໄດ້ຮັບມອບໝາຍ. <br />
    ✅ມີຄວາມສາມັກຄີ ແລະ ເຮັດວຽກເປັນໝູ່ຄະນະໄດ້ດີ. <br />
   `,
    howTo: `
    ➡️ ກະລຸນາປະກອບເອກະສານມາພ້ອມນີ້: <br />
    1.)ໃບຄຳຮ້ອງສະໝັກເຂົ້າເຮັດວຽກ (ຂຽນດ້ວຍຕົນເອງ). <br />
    2.)ໃບຢັ້ງຢືນທີ່ຢູ່ (ຈ້ຳກາແດງ ແລະ ບໍ່ເກີນ 3 ເດືອນ). <br />
    3.)ໃບຢັ້ງຢືນຮັບປະກັນ (ຈ້ຳກາແດງ ແລະ ບໍ່ເກີນ 3 ເດືອນ). <br />
    4.)ໃບຢັ້ງຢືນສຸຂະພາບ (ຈ້ຳກາແດງ ແລະ ບໍ່ເກີນ 3 ເດືອນ). <br />
    5.)ໃບແຈ້ງໂທດ (ຈ້ຳກາແດງ ແລະ ບໍ່ເກີນ 3 ເດືອນ). <br />
    6.)ຊີວະປະຫວດທົ່ວໄປ (ແບບເພົາ 4 ໜ້າ). <br />
    7.)ສຳເນົາໃບປະກາດສະນີຍະບັດ ຊັ້ນມັດທະຍົມຕອນປາຍ. <br />
    8.)ສຳເນົາໃບປະກາດສະນີຍະບັດ (ຈົບວິຊາສະເພາະ) ພ້ອມດ້ວຍໃບຢັ້ງຢືນຄະແນນ. <br />
    9.) ສຳເນົາປື້ມສຳມະໂນຄົວ / ບັດປະຈຳຕົວ. <br />
    10.)ຮູບຕິດບັດຂະໜາດ 3x4 ຈຳນວນ 5 ໃບ. <br />
    11.) ໃບຜ່ານງານ ແລະ ເອກະສານປະກອບອື່ນໆ...... (ຖ້າມີ). <br />

    📱ໂທລະສັບ ແລະ WhatsApp: 020 2944 5272 <br />
    ☎️021 214870 (ພະແນກ ບຸກຄະລາກອນ) <br />
    ----------------------------------- <br />
    ☎️ສອບຖາມເພີ່ມເຕີມໂທ:1442
    `,
    whatsapp: "020 2944 5272",
    tel: "021 214870",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BB%84%E0%BA%9B%E0%BA%AA%E0%BA%B0%E0%BA%99%E0%BA%B5%E0%BA%A5%E0%BA%B2%E0%BA%A7/Logo-laopost.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BB%84%E0%BA%9B%E0%BA%AA%E0%BA%B0%E0%BA%99%E0%BA%B5%E0%BA%A5%E0%BA%B2%E0%BA%A7/poster-laopost.jpeg",
  },
  {
    id: "66",
    title: "ຮັບສະໝັກພະນັດງານ ດ່ວນ !!!",
    companyName: "‼️ກຸ່ມບໍລິສັດ ສົມໃຈນຶກ ຈຳກັດ‼️",
    compaynyAddress:
      "📍ສໍານັກງານຕັ້ງຢູ່: ບ້ານດອນກອຍ, ເມືອງສີສັດຕະນາກ, ນວ",
    vacancy: "ຮັບສະໝັກພະນັດງານ ດ່ວນ !!!",
    detail: `
    1. ພະນັກງານ  ເລຂາ (ຜູ້ຊ່ວຍ) 01ຕຳແໜ່ງ <br />
    👇🏼ສົນໃຈສະໝັກ👇🏼 <br />
    ☎️ ຕິດຕໍ່: 020 55603310 <br />
    📍 ສໍານັກງານຕັ້ງຢູ່: ບ້ານດອນກອຍ, ເມືອງສີສັດຕະນາກ, ນວ
    `,
    skill: `
   `,
    howTo: `
    👇🏼ສົນໃຈສະໝັກ👇🏼 <br />
    ☎️ ຕິດຕໍ່: 020 55603310`,
    whatsapp: "020 55603310",
    tel: "020 55603310",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AA%E0%BA%BB%E0%BA%A1%E0%BB%83%E0%BA%88%E0%BA%99%E0%BA%B6%E0%BA%81/Logo_1.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AA%E0%BA%BB%E0%BA%A1%E0%BB%83%E0%BA%88%E0%BA%99%E0%BA%B6%E0%BA%81/Poster_1.jpeg",
  },
  {
    id: "65",
    title: "ເປີດຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ",
    companyName: "‼️ສູນການແພດນະຄອນຫລວງວຽງຈັນ-ປັກກິ່ງ‼️",
    compaynyAddress:
      "📍ສະຖານທີ່: ບ້ານ ໜອງບົວທອງເໜືອ ເມືອງສີໂຄດຕະບອງ ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ເປີດຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ",
    detail: `
    👉ພະຍາບານ 10 ຕຳແໜ່ງ<br />
    👉ແອັດມິນ 6 ຕຳແໜ່ງ <br />
    👉ນັກແປຈີນ-ລາວ ( ປາກເປົ່າ ) 8 ຕຳແໜ່ງ <br />
    📍ສະຖານທີ່: ບ້ານ ໜອງບົວທອງເໜືອ ເມືອງສີໂຄດຕະບອງ ນະຄອນຫຼວງວຽງຈັນ<br />
    Location: Nongbuathong village, Sikhottabong district, vientiane Capital.<br />
    (📞) ເບີໂທຕິດຕໍ່ & Whatsapp: 020 92188783, 020 98823362
    `,
    skill: `
   `,
    howTo: `
    ສົນໃຈສາມາດຕິດຕໍ່`,
    whatsapp: "020 92188783",
    tel: "020 92188783",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AA%E0%BA%B9%E0%BA%99%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BB%81%E0%BA%9E%E0%BA%94/Logo-%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BB%81%E0%BA%9E%E0%BA%94.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%AA%E0%BA%B9%E0%BA%99%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BB%81%E0%BA%9E%E0%BA%94/Poster-%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BB%81%E0%BA%9E%E0%BA%94.jpeg",
  },
  {
    id: "64",
    title: "ຮັບສະໝັກພະນັກງານດ່ວນ",
    companyName: "‼️ຄລີນິກປົວແຂ້ວ T-SMILE DENTAL ‼️",
    compaynyAddress:
      "📍ປະຈຳຢູ່ທີ່ສາຂາ ດົງໂດກ",
    vacancy: "ຮັບສະໝັກພະນັກງານດ່ວນ",
    detail: `
    1. ຜູ້ຊ່ວຍໝໍ: 3 ຕຳແໜ່ງ <br />
    📍ສະຖານທີ່ເຮັດວຽກ: ບ້ານໂພນສີນວນ, ເມືອງສີສັດຕະນາກ, ນະຄອນຫຼວງວຽງຈັນ (ກົງກັນຂ້າມກັບທະນາຄານລາວກໍ່ສ້າງ) <br />
    ✏️ເອກະສານປະກອບມີ: <br />
    + ຊິວະປະຫວັດ <br />
    + ໃບປະກາດ
    + ບັດປະຈໍາຕົວສະບັບກອບປີ <br />
    📌ສິດຜົນປະໂຫຍດທີ່ໄດ້ຮັບ: <br />
    - ໄດ້ອໍານວຍຄວາມສະດວກທີ່ສຸດເພື່ອພັດທະນາຄວາມສາມາດຕົວເອງ <br />
    - ສະພາບແວດລ້ອມການເຮັດວຽກຄ່ອງແຄ່ວວ່ອງໄວ. <br />
    - ໄດ້ພັກວັນບຸນ ປີໃໝ່ຕາມຕະຕາລາງຂອງບໍລິສັດ . <br />
    - ແຕ່ລະປີຈະໄດ້ໄປທ່ຽວໃນແລະຕ່າງປະເທດ. <br />
    - ຢ້ຽມຢາມຖາມຂ່າວໃນເວລາເຈັບບວດ. <br />
    `,
    skill: `
   `,
    howTo: `
    ຕິດຕໍ່: ຜູ້ສະໝັກສາມາດມາສໍາພາດໂດຍກົງ ຫຼື ຕິດຕໍ່ Whastapp: 020.9229.3939 (Ms.Noud) ຫຼື 020.9222.33939 (Ms Nui)`,
    whatsapp: "020 92 293 939",
    tel: "020 92 293 939",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%84%E0%BA%B5%E0%BA%99%E0%BA%B4%E0%BA%81%E0%BB%81%E0%BA%82%E0%BB%89%E0%BA%A7/Logo-%E0%BB%81%E0%BA%82%E0%BB%89%E0%BA%A7.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%84%E0%BA%B5%E0%BA%99%E0%BA%B4%E0%BA%81%E0%BB%81%E0%BA%82%E0%BB%89%E0%BA%A7/Poster-%E0%BB%81%E0%BA%82%E0%BB%89%E0%BA%A7.jpeg",
  },
  {
    id: "63",
    title: "ຮັບສະໝັກເພື່ອນຮ່ວມງານ",
    companyName: "‼️ຮ້ານຂາຍຢາ ສຸດສະດາ‼️",
    compaynyAddress:
      "📍ປະຈຳຢູ່ທີ່ສາຂາ ດົງໂດກ",
    vacancy: "ຮັບສະໝັກເພື່ອນຮ່ວມງານ",
    detail: `
    1. ການຢາ 02 ຕຳແໜ່ງ
    `,
    skill: `
   `,
    howTo: `
    ສົນໃຈສະໝັກໄດ້ທີ່ເບີ 020 56 649 797`,
    whatsapp: "020 56 649 797",
    tel: "020 56 649 797",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%A2%E0%BA%B2/Logo-%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%A2%E0%BA%B2.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%A2%E0%BA%B2/poster-%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%A2%E0%BA%B2.jpeg",
  },
  {
    id: "62",
    title: "ປະກາດຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️YS Import&Export Co.,Ltd‼️",
    compaynyAddress:
      "",
    vacancy: "ປະກາດຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານແປພາສາ ຈີນ - ລາວ & ລາວ - ຈີນ ຈຳນວນຫຼາຍ.  <br />
    2. ພະນັກງານຂາຍ ຢາງລົດໃຫຍ່ ແລະ ນ້ຳມັນເຄື່ອງ ຈຳນວນຫຼາຍ. <br />
    📍ບ້ານໜອງແຕ່ງເໜືອ, ເມືອງສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັ
    `,
    skill: `
   `,
    howTo: `
    👉ສົນໃຈສະໝັກຕິດຕໍ່ພົວພັນມາໄດ້ທີ່: <br />
    📲ໂທ&WA: 020 9199 6198`,
    whatsapp: "020 9199 6198",
    tel: "020 9199 6198",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Ys+import-export/logo-ys.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Ys+import-export/Poster-ys.jpeg",
  },
  {
    id: "61",
    title: "‼️𝐗𝐌𝐓 ເປີດຮັບສະໝັກເພື່ອນຮ່ວມງານ‼️",
    companyName: "‼️𝐗𝐌𝐓‼️",
    compaynyAddress:
      "",
    vacancy: "‼️𝐗𝐌𝐓 ເປີດຮັບສະໝັກເພື່ອນຮ່ວມງານ‼️",
    detail: `
    ‼️𝐗𝐌𝐓 ເປີດຮັບສະໝັກເພື່ອນຮ່ວມງານ‼️ <br />
    ຫຼາຍຕຳແໜ່ງດັ່ງນີ້: <br />
    1. Activity Lead (1 ຕຳແໜ່ງ) <br />
    2. Project Manager (2 ຕຳແໜ່ງ) <br />
    3. Back-end (Intern) (1 ຕຳແໜ່ງ) <br />
    4.Front-end (Intern) (1 ຕຳແໜ່ງ) <br />
    `,
    skill: `
   `,
    howTo: `
    ທ່ານສາມາດສົ່ງຊີວະປະຫວັດຫຍໍ້ໂດຍກົງ (CV ຫຼື Resume) ເຂົ້າມາໄດ້ທີ່  <br />
    📩Email: info@xmtechnovator.org  <br />
    ຫຼື ຕິດຕໍ່ມາໄດ້ທີ່ເພຈ: XM Technovator (Facebook Page) <br />
    JD: <a href="https://bit.ly/xmt-jd">https://bit.ly/xmt-jd</a>`,
    whatsapp: "020 92 636 392",
    tel: "020 92 636 392",
    email: "info@xmtechnovator.org",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/XMT/logo-xam.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/XMT/Poster-xam.jpeg",
  },
  {
    id: "60",
    title: "ຮັບສະໝັກພະນັກງານ 01ຕຳແໜ່ງ",
    companyName: "‼️Wisdom ວິສດອມ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານ 01ຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານ ສ້າງຄອນເທັນ (Content creator) 
    `,
    skill: `
   `,
    howTo: `
    👉🏻ສົນໃຈສະໝັກ ຕິດຕໍ່ໄດ້ທີ່ <br />
    📲Tel & Whatapp 020 92 636 392`,
    whatsapp: "020 92 636 392",
    tel: "020 92 636 392",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/wisdom/wisdom_logo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/wisdom/wisdom_poster.jpeg",
  },
  {
    id: "59",
    title: "ຮັບສະໝັກພະນັກງານຂາຍ ຫຼາຍຕຳແໜ່ງ ດ່ວນ!!",
    companyName: "‼️Viettien‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຂາຍ ຫຼາຍຕຳແໜ່ງ ດ່ວນ!!",
    detail: `
    ✅ ມີເງິນເດືອນພື້ນຖານ ແລະ ເງິນໂບນັດ <br />
    ✅ ມີສະຫວັດດີການດີ <br />
    ✅ ມີວັນພັກປະຈຳປີ ແລະ ວັນພັກຕາມເທດສະການ <br />
    ✅ ມີເງິນຊຸກຍູ້ນໍ້າມັນລົດ, ວັນເກີດ, ງານແຕ່ງ, ເກີດລູກ ແລະ ປະກັນສັງຄົມ <br />
    ✅ ມີທຣິບທ່ອງທ່ຽວປະຈຳປີຟຣີ <br />
    ✅ ແລະສະຫວັດດີການອື່ນໆ <br />
    
    `,
    skill: `
   `,
    howTo: `
    ☎️ສາມາດສົ່ງ CV ຫລື ສອບຖາມຂໍ້ມູນເພີ່ມທີ່ເບີ Tel/WhatsApp: 020 97299999, ຕາມໂມງລັດຖະການ 8:00-17:00 (ວັນຈັນ-ວັນສຸກ)`,
    whatsapp: "020 97299999",
    tel: "020 97299999",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/viettien/Logo_viettien.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/viettien/poster_viettien.jpeg",
  },
  {
    id: "58",
    title: "ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ ",
    companyName: "‼️Sitthi Logistics‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ ",
    detail: `
    1. HR Manager 01 ຕຳແໜ່ງ <a href="https://sitthilogistics.com/en/job/2024-002/">https://sitthilogistics.com/en/job/2024-002/</a> <br />
    2. HR Officer 02 ຕຳແໜ່ງ <a href="https://sitthilogistics.com/en/job/2024-003/">https://sitthilogistics.com/en/job/2024-003/</a> <br />
    3. ພະນັກງານຂັບລົດນຳ້ມັນ (ຫົວລາກ+ຫາງລາກ) 20 ຕຳແໜ່ງ <br />
    4. ພະນັກງານຂັບລົດຂົນສົ່ງ 10 ລໍ້ (ຫາງພ່ວງແມ່+ລູກ) 10 ຕຳແໜ່ງ <br />
    5. ພະນັກງານຂັບລົດຂົນສົ່ງ 6 ລໍ້ (10 ໂຕ່ນຖັງແຫ້ງ) 10 ຕຳແໜ່ງ <br />
    ເວັບໄຊ: <a href="https://sitthilogistics.com">https://sitthilogistics.com</a> 
    `,
    skill: `
   `,
    howTo: `
    ☎️ຕິດຕໍ່ສອບຖາມໂທ: 020 5530 9532 / 021 262 204 <br />
    📩ອີເມວ: hr@sitthilogistics.com/Recruitment@ptlholding.com`,
    whatsapp: "020 5530 9532",
    tel: "020 5530 9532",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/sittha-logis/logo_sittha.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/sittha-logis/Poster_sittha.jpeg",
  },
  {
    id: "57",
    title: "ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ",
    companyName: "ສູນການຄ້າວຽງຈັນເຊັນເຕີ",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານບໍລິຫານ  / Admin officer <br />
    2. ພະນັກງານການຕະຫຼາດ / BD+OPN Marketing <br />
    3. ພະນັກງານການຕະຫຼາດອອນລາຍ/Online marketing 4.  ພະນັກງານປະຕິບັດການ / Operation marketing <br />
    5. ພະນັກງານປະຕິບັດການສູນອາຫານ / Operation Foodcenter <br />
    6. ພະນັກງານຂັບລົດ / Drivers <br />
    7. ພະນັກງານເກັບເງິນສົດ / Cashier <br />
    8. ພະນັກງານບໍລິການລູກຄ້າ / Customer service <br />
    9. ພະນັກງານຊ່າງໄຟ / Electrician <br />
    10. ພະນັກງານຂາຍ / Sale <br />
    11. ພະນັກງານແມ່ບ້ານ / Cleaner <br />
    ✨ສະຫວັດດີການ-ເງິນເດືອນດີ   <br />
    📍ສະຖານທີ່ເຮັດວຽກ: ສູນການຄ້າວຽງຈັນເຊັນເຕີ <br />
    
    `,
    skill: `
   `,
    howTo: `
    ສົນໃຈສອບຖາມລາຍລະອຽດເພິ່ມເຕີມ ໂທ: 021-228299 / 020-98874632（WhatsApp `,
    whatsapp: "020-98874632",
    tel: "020-98874632",
    email: "Recruiting@vclao.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/vientiane+centre/Logo-v.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/vientiane+centre/post-v.jpeg",
  },
  {
    id: "56",
    title: "ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ ",
    companyName: "‼️Saikhong Group‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ ",
    detail: `
    1. ບັນຊີ <br />
    2. ⁠Sale <br />
    3. ⁠Supervisor <br />
    4. ⁠ຂັບລົດ <br />
    👉🏻ສົນໃຈຕິດຕໍ່ເບີໂທ 020 55328949 <br />
    (ໃນໂມງລັດຖະການ)
    `,
    skill: `
   `,
    howTo: `
    👉🏻ສົນໃຈຕິດຕໍ່ເບີໂທ 020 55328949 <br />
    (ໃນໂມງລັດຖະການ)`,
    whatsapp: "020 55328949",
    tel: "020 55328949",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/saikong+group/saikong_logo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/saikong+group/saikong_poster.jpeg",
  },
  {
    id: "55",
    title: "ຮັບສະໝັກທີ່ປຶກສາດ້ານການຂາຍ",
    companyName: "‼️MITSUBISHI ‼️",
    compaynyAddress:
      "",
    vacancy: "ຮັບສະໝັກທີ່ປຶກສາດ້ານການຂາຍ",
    detail: `
    📌 ລາຍໄດ້ສູງສຸດ 3X,xxx,xxx ກີບຕໍ່ເດືອນ <br />
    📌 ເຮົາມີການເທຣນສອນການຂາຍໃຫ້ພະນັກງານ <br />
    📌 ພ້ອມສະຫວັດດີການພິເສດອີກຫລາກຫລາຍ 
    `,
    skill: `
   `,
    howTo: `
    👉🏻ສົນໃຈສາມາດ ຍື່ນ ເອກະສານ ສະໝັກວຽກຜ່ານທາງ
    ດາວໂຫຼດແບບຟອມສະໝັກວຽກກົດລິ້ງດ້ານລຸ່ມນີ້ 👇 <br />
    <a href="https://drive.google.com/.../1WrrzQY20JXv8zIr7nBx.../view...">https://drive.google.com/.../1WrrzQY20JXv8zIr7nBx.../view...</a>https://drive.google.com/.../1WrrzQY20JXv8zIr7nBx.../view...
    👉🏻ຕິດຕໍ່ສອບຖາມຂໍ້ມູນເພີມເຕີມໄດ້ທີ່ເບີ:<br />
    Tel: (+856) 21 455 044, (+856) 20 5988 4994`,
    whatsapp: "020 59 883 335",
    tel: "020 59 883 335",
    email: "Recruitment@kolaogroup.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/motor/Logo_motor.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/motor/Post_motor.jpeg",
  },
  {
    id: "54",
    title: "ຕ້ອງການຮັບສະໝັກພະນັກງານຈຳນວນຫຼາຍ",
    companyName: "‼️Mek Imports – Exports Sole Co., Ltd.‼️",
    compaynyAddress:
      "📍 ສະຖານທີ່: ຖະໜົນ Asean (T2), ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ຕ້ອງການຮັບສະໝັກພະນັກງານຈຳນວນຫຼາຍ",
    detail: `
    👉🏻ສະຫວັດດີການທີ່ຈະໄດ້ຮັບ <br />
    - ປະກັນສັງຄົມ <br />
    - ພາຫະນະສຳລັບການຂາຍ <br />
    - ເງິນຄອມມິດຊັ່ນ ແລະ ເງິນສົ່ງເສີມ <br />
    - ລາງວັນຕອບແທນສຳລັບພະນັກງານດີເດັ່ນປະຈຳໄຕມາດ ແລະ ປະຈຳປີ <br />
    - ວັນພັກລາກິດສ່ວນຕົວ, ລາພັກປະຈຳປີ, ລາເຈັບປ່ວຍ, ລາພັກເກີດລູກ <br />
    - ກວດສຸພາບປະຈຳປີຟຮີ <br />
    - ເບ້ຍສຳລັບການໄປຕ່າງແຂວງ ແລະ ຕ່າງປະເທດ <br />
    - ການຊຸກຍູ້ຊ່ວຍເຫຼືອຍາມເຫດສຸກເສີນ <br />
    - ກອງທຶນສະສົມລ້ຽງຊີບ <br />
    - ວັນພັກລາກິດສ່ວນຕົວ, ພັກເກີດລູກ, ພັກເຈັບປ່ວຍ. <br />
    - ເງິນເດືອນ 13 (ຖ້າຜ່ານເງື່ອນໄຂບໍລິສັດ) <br />
    - ໂບນັສທ້າຍປີ (ຖ້າຜ່ານເງື່ອນໄຂບໍລິສັດ) <br />

    ກ່ຽວກັບບໍລິສັດ: <a href="https://bit.ly/mekprofile">https://bit.ly/mekprofile</a> <br />

    📍ສາຂາໃຫຍ່ນະຄອນຫຼວງ <br />
    245, ບ້ານ ສີດໍາດວນ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ <br />
    📍ສາຂາຫຼວງພະບາງ <br />
    ບ້ານ ນາງຊ່າງເຫວີຍ, ເມືອງ ຫຼວງພະບາງ, ແຂວງ ຫຼວງພະບາງ <br />
    📍ສາຂາສະຫວັນນະເຂດ <br />
    ບ້ານ ໂພນສະຫງ່າເໜືອ, ເມືອງ ນະຄອນໄກສອນ, ແຂວງສະຫວັນນະເຂດ <br />
    📍ສາຂາປາກເຊ <br />
    ບ້ານໂພນສີໄຄ, ເມືອງ ປາກເຊ, ແຂວງ ຈຳປາສັກ

    `,
    skill: `
   `,
    howTo: `
    👇🏼ສົນໃຈສະໝັກ👇🏼 <br />
    📲ໂທ ຫຼື Whatsapp: 020-55-531-282 <br />
    ດາວໂຫລດຟອມສະໝັກໄດ້ທີ່: <a href="https://bit.ly/mekapplication">https://bit.ly/mekapplication</a> <br />
    ຫຼື ສາມາດເຂົ້າມາກອກຟອມທີ່ບໍລິສັດ ຕິດຕໍ່ ￼⁨020 55 531 282⁩`,
    whatsapp: "020 55 531 282",
    tel: "020 55 531 282",
    email: "recruitment@meklaos.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/mek+import/Logo_mex.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/mek+import/Poster_mex.jpeg",
  },
  {
    id: "53",
    title: "ຕ້ອງການຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️KKS Residence‼️",
    compaynyAddress:
      "📍 ສະຖານທີ່: ຖະໜົນ Asean (T2), ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ຕ້ອງການຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    1. ພະນັກງານຕ້ອນຮັບ 3ຕຳແໜ່ງ <br />
    2. ⁠ພະນັກງານ ອານາໄມຫ້ອງພັກ 3 ຕຳແໜ່ງ <br />
    📍ສະຖານທີ່: ຖະໜົນ Asean (T2), ນະຄອນຫຼວງວຽງຈັນ <br />
    ✔️ມີທີພັກໃຫ້, ໂບນັດປະຈຳປີ, ລ້ຽງເຂົ້າ 1 ຄາບ, ເຮັດວຽກ 6 ມື້/ທິດ ແລະ ວັນພັກປະຈຳປີ 15 ວັນ
    `,
    skill: `
   `,
    howTo: `
    ຕິດຕໍ່ເພື່ອສອບຖາມຂໍ້ມູນ: <br />
    📞ເບີໂທ: 020 77 792 779 <br />
    📍ແຜນທີ່: <a href="https://maps.app.goo.gl/4TB2uNH11BDuAkRp6">https://maps.app.goo.gl/4TB2uNH11BDuAkRp6</a>
    `,
    whatsapp: "020 77 792 779",
    tel: "020 77 792 779",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/KKS+Apartment/Logo-kks.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/KKS+Apartment/Poster-kks.jpeg",
  },
  {
    id: "52",
    title: "ຕ້ອງການຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️JHA Corporate Gift Supplies ‼️",
    compaynyAddress:
      "📍 ສະຖານທີ: ບ້ານ ໂພນພະເນົາ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ (ກົງກັນຂ້າມກັບປ້ຳນ້ຳມັນ ປ.ຕ.ທ",
    vacancy: "ຕ້ອງການຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    ຕ້ອງການຮັບສະໝັກພະນັກງານຫຼາຍຕຳແໜ່ງ <br />
    1. ພະນັກງານຂາຍ 2 ຕຳແໜ່ງ✨ <br />
    2. ພະນັກງານຈັດຊື້ 1 ຕຳແໜ່ງ✨ <br />
    3. Stock Supervisor 1 ຕຳແໜ່ງ✨ <br />
    
    ✨ຜົນຕອບແທນ✨ ເງິນເດືອນ ເລີ່ມຕົ້ນ 𝟓.𝟎𝟎𝟎.𝟎𝟎𝟎 ກີບ ຫຼື ອີງຕາມປະສົບການ ແລະ ມີເງິນ ນໍ້າມັນລົດ, ບັດໂທລະສັບ ແລະ ໂບນັດປະຈໍາເດືອນ✨ <br />
    ‼ ‼ ປິດຮັບສະໝັກ: ວັນທີ 31 ພຶດສະພາ 2024 ‼ ‼ <br />
    -------------------------------- <br />
    📍🏡 Office & Showroom: ບ້ານ ຂາມງອຍ (ບ້ານ ໂຊກຄຳ), ເມືອງ ໄຊເສດຖາ, ນະຄອນຫລວງວຽງຈັນ <br />
    📌 google map: <a href="https://maps.app.goo.gl/VUaY6VDnX2d3Hgur6">https://maps.app.goo.gl/VUaY6VDnX2d3Hgur6</a>
    `,
    skill: `
    ✨ຄວາມຕ້ອງການຂອງວຽກ✨ <br />
    - ຈົບການສຶກສາ ປະລິນຍາຕີ ດ້ານ ບໍລິຫານທຸລະກິດ, ການເງິນ, ການຕະຫລາດ ຫລື ສາຂາທີ່ກ່ຽວຂ້ອງ <br />
    - ມີປະສົບການດ້ານຈັດຊື້ ແລະ ການຂາຍ ຢ່າງນ້ອຍ 2 ປີຂື້ນໄປ <br />
    - ຮູ້ນໍາໃຊ້ຄອມພິວເຕີ Word, Excel ລະດັບເກັ່ງ <br />
    - ເວົ້າ, ຂຽນ ແລະ ຟັງພາສາອັງກິດໄດ້ດີ, (ຖ້າໄດ້ພາສາຫວຽດນາມຈະພິຈາລະນາເປັນພິເສດ) <br />
    - ອາຍຸ 25-45 ປີ, ບໍ່ຈຳກັດເພດ
   `,
    howTo: `
      ‼ ເອກະສານປະກອບ ‼ <br />
    - ຊີວະປະຫວັດເປັນພາສາອັງກິດ <br />
    - ສໍາເນົາໃບປະກາດສະນິຍະບັດວິຊາສະເພາະ ແລະ ໃບຄະແນນ <br />
    - ໃບຜ່ານງານ <br />

    ‼ ‼ ປິດຮັບສະໝັກ: ວັນທີ 31 ພຶດສະພາ 2024 ‼ ‼ <br />
    ຂໍ້ມູນຕິດຕໍ່ Contact us: <br />
    ☎ 021 254400 <br />
    📱020 5222 2524
    `,
    whatsapp: "020 5222 2524",
    tel: "020 5222 2524",
    email: "Email: fc@jha-group.com ",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/JHA/Logo_Jha.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/JHA/Poster_jha.jpeg",
  },
  {
    id: "51",
    title: "ປະກາດຮັບສະຫມັກ ເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ",
    companyName: "‼️ບໍລິສັດ ມະນີຍົມ ໂອໂຕ ກຣຸບ ‼️",
    compaynyAddress:
      "📍ຫ້ອງຂາຍຕັ້ງຢູ່ ຖະໜົນທົ່ງຂັນຄຳ ບ້ານທົ່ງຕູມ ເມືອງຈັນທະບູລີ ນະຄອນຫຼວງວຽງຈັນ ຕິດກັບບ່ອນອອກຫວຍ ເປີດບໍລິການ ວັນຈັນ-ເສົາ ເວລາ 8:30 - 17:30",
    vacancy: "ປະກາດຮັບສະຫມັກ ເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    ລີ້ງແຜນທີ່ສູນມະນີຍົມລົດຫຼູ <br />
    <a href="https://goo.gl/maps/nXjTovdcDcmeXyvR6">https://goo.gl/maps/nXjTovdcDcmeXyvR6</a>
    `,
    skill: `
   `,
    howTo: `
    ✔️ສາມາດສົ່ງຟອມມາສະມັກໄດ້ທີ່ <br />
    📲WhatsApp ຫຼື ໂທ: 020 5999 8822 <br />
    https://wa.me/8562059998822`,
    whatsapp: "020 5999 8822",
    tel: "020 5999 8822",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/auto+group/logo-auto.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/auto+group/poster-auto.jpeg",
  },
//   {
//     id: "50",
//     title: "ເປີດຮັບເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ",
//     companyName: "‼️ບໍລິສັດ ທິວາຣາ ໂອໂຕ ຈຳກັດ‼️",
//     compaynyAddress:
//       "📍 ສະຖານທີ: ບ້ານ ໂພນພະເນົາ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ (ກົງກັນຂ້າມກັບປ້ຳນ້ຳມັນ ປ.ຕ.ທ",
//     vacancy: "ເປີດຮັບເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ",
//     detail: `
// 📍 ສະຖານທີ: ບ້ານ ໂພນພະເນົາ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ (ກົງກັນຂ້າມກັບປ້ຳນ້ຳມັນ ປ.ຕ.ທ
//     `,
//     skill: `
//     ✨ຕ້ອງການຄົນທີ່ດຸໝັ່ນ-ຫ້າວຫັນໃນໜ້າທີ່ວຽກງານ, ເປັນຄົນກະຕືລືລົ້ນສະແຫວງຫາຄວາມຮູ້ໃໝ່ໆເພື່ອພັດທະນາອົງກອນ, ສາມາດເຮັດວຽກເປັນທີມໄດ້; ເປັນຄົນມີນ້ຳໃຈ-ຊື່ສັດ <br />
//     🌟ໝາຍເຫດ: ຖ້າມີປະສົບການຈະພິຈາລະນາພິເສດ
//    `,
//     howTo: `
//     ✔️ສາມາດສົ່ງຟອມມາສະມັກໄດ້ທີ່
//     ☎ ຕິດຕໍ່ໄດ້ທີ່: 021 221666
//     📞 ສາຍດ່ວນ: 020 55811193`,
//     whatsapp: "020 55811193",
//     tel: "020 55811193",
//     email: "",
//     logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/7Lion/7Lionlogo.jpeg",
//     img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/7Lion/7Lioncover.jpeg",
//   },
  {
    id: "48",
    title: "ຮັບສະໝັກພະນັກງານ ຫົວໜ້າຝ່າຍຂາຍ 1 ຕຳແໜ່ງ",
    companyName: "Zion Electronic",
    compaynyAddress:
      "📍ຖະໜົນສຸພານຸວົງ ຮ່ອມ ໜ້າຕະຫຼາດກົກໂພ, ບ້ານ ວັດໄຕນ້ອຍທົ່ງ, ເມືອງ ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ (ໃກ້ກັບສະໜາມບິນວັດໄຕ)",
    vacancy: "ຮັບສະໝັກພະນັກງານ ຫົວໜ້າຝ່າຍຂາຍ 1 ຕຳແໜ່ງ",
    detail: `
    ຮັບສະໝັກພະນັກງານ ຫົວໜ້າຝ່າຍຂາຍ 1 ຕຳແໜ່ງ <br />
    1. ສ້າງທີມຂາຍ, ຈັດຕັ້ງການຝຶກອົບຮົມພະນັກງານ, ການຈັດແບ່ງວຽກ ແລະ ໃຫ້ຄຳແນະນຳທຸລະກິດຕາມກົດລະບຽບຂອງບໍລິສັດ <br />
    2. ກຳນົດເປົ້າໝາຍຂອງທີມງານ ແລະ ຕິດຕາມຄວາມຄືບໜ້າການເຮັດວຽກໃຫ້ສຳເລັດເປົ້າໝາຍ, ປະເມີນຜົນການປະຕິບັດໜ້າທີ່ຂອງທີມງານ, ໃຫ້ຄໍາແນະນໍາ, ປັບປຸງ ແລະ ຊ່ວຍເຫຼືອສະມາຊິກໃນທີມ, ປັບປຸງປະສິດທິພາບການຂາຍ ແລະ ເສີມສ້າງຄວາມສາມັກຄີໃນທີມ <br />
    3. ນໍາພາທີມງານພັດທະນາການຕະຫຼາດ, ເຈລະຈາລູກຄ້າ, ເຊັນສັນຍາ ແລະ ໄດ້ຮັບການຈ່າຍເງິນໃຫ້ທັນຕາມເວລາທີ່ກຳນົດ <br />
    4. ຮັບຜິດຊອບການບໍລິຫານຄວາມສໍາພັນຂອງລູກຄ້າ, ການບໍລິການຫລັງການຂາຍ ແລະ ການດູແລຈັດການທີ່ທັນເວລາ, ດູແລການຮ້ອງທຸກລູກຄ້າ, ສ້າງຄວາມສຳພັນທີ່ດີຕໍ່ລູກຄ້າໃນໄລຍະຍາວ <br />
    5. ການຈັດການຂໍ້ມູນການຂາຍ: ຈັດທີມງານເຂົ້າໃຈສະຖານະການຕະຫຼາດ, ການປ່ຽນແປງຂອງຜະລິດຕະພັນຂອງບໍລິສັດໃນຂອບເຂດການຂາຍຂອງບໍລິສັດ, ວາງກກົນລະຍຸດທາງດ້ານການຂາຍ ແລະ ການວິເຄາະຍຸດທະສາດການຕະຫຼາດຂອງຄູ່ແຂ່ງ ຄາດຄະເນ ແລະ ສ້າງມາດຕະຮັບມືກັບການຕະຫຼາດ, ລາຍງານສະຖານະການໃຫ້ຜະແນກໃຫ້ທັນເວລາ, ແລະ ເອົາໃຈໃສ່ເປັນຢ່າງດີ <br />

    *ເງີນເດືອນເລີ່ມຕົ້ນ 18,000,000 ກີບ (ຂຶ້ນກັບຄວາມສາມາດ) + ຄ່າຄອມມິຊັນສ່ວນໂຕ+ຄ່າຄອມມິຊັນຂອງທີມງານ+ເງີນໂບນັດທ້າຍປີ <br />

    *ມີມື້ພັກປະຈຳປີຈຳນວນ 15 ມື້, ເຮັດວຽກວັນຈັນ - ເສົາ ເວລາ: 08:00-17:00 ໂມງ <br />

    *ໝາຍເຫດ: ເນື່ອງຈາກວ່າບໍລິສັດຂອງພວກເຮົາມີການໄປທ່ຽວເປັນກຸ່ມ ແລະ ຮັບປະທານອາຫານຮ່ວມກັນຢູ່ຕະຫຼອດ ສະນັ້ນຜູ້ສະໝັກຈຳເປັນຕ້ອງມີໃບກວດສຸຂະພາບເພື່ອຢັ້ງຢືນວ່າໂຕເອງບໍ່ມີພະຍາດໃດໆ <br />
    📍ບໍລິສັດຕັ້ງຢູ່: ຖະໜົນສຸພານຸວົງ ຮ່ອມ ໜ້າຕະຫຼາດກົກໂພ, ບ້ານ ວັດໄຕນ້ອຍທົ່ງ, ເມືອງ ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ (ໃກ້ກັບສະໜາມບິນວັດໄຕ)
       `,
    skill: `
    1. ຮຽນຈົບລະດັບວິທະຍາໄລ ຫຼື ສູງກວ່າ <br />
    2. ບໍ່ຈຳກັດສັນຊາດ ຫຼື ເພດ <br />
    3. ອາຍຸບໍ່ເກີນ 45 ປີ <br />
    4. ຖ້າສາມາດສາມາດຂັບລົດໄດ້ ຫຼື ມີປະສົບການໄປຮຽນຕ່າງປະເທດຈະພິຈາລະນາເປັນພິເສດ <br />
    5. ມີປະສົບການການຂາຍ 5 ປີຂຶ້ນໄປໃນດ້ານຜະລິດຕະພັນອີເລັກໂທຣນິກ ຫຼື ອະສັງຫາລິມະຊັບ <br />
    6. ມີມະນຸດສຳພັນດີດີ, ບໍ່ມີປະຫວັດການເຮັດວຽກທີ່ບໍ່ດີ ແລະ ບໍ່ມີພະຍາດຕິດແປດ <br />
    7. ມີຄວາມຊຳນານດ້ານພາສາຕ່າງປະເທດຢ່າງໜ້ອຍໜຶ່ງພາສາເຊັ່ນ: ພາສາຈີນ, ພາສາອັງກິດ ຫຼື ພາສາເກົາຫຼີ (ຫາກໄດ້ຫຼາຍພາສາຈະພິຈາລະນາເປັນພິເສດ) <br />
    `,
    howTo: `
    👉🏻ສົນໃຈສະໝັກ ຫຼື ສອບຖາມລາຍລະອຽດເພີ່ມ
📞 ໂທ/WhatsApp: 020 91555123 ຫຼື 📌ສົ່ງ CV ມາໄດ້ທີ່ Email: aqsiqexpert@163.com`,
    whatsapp: "020 91555123",
    tel: "020 91555123",
    email: "aqsiqexpert@163.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/7Lion/7Lionlogo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/7Lion/7Lioncover.jpeg",
  },
  {
    id: "47",
    title: "🔊ຮັບສະຫມັກບັນຊີດ່ວນ ✔️",
    companyName: "✔️ກຸ່ມທຸລະກິດ ບຸດສະຫວັດ✔️",
    compaynyAddress:
      "ບໍ່ມີຂໍ້ມູນ",
    vacancy: "🔊ຮັບສະຫມັກບັນຊີດ່ວນ ✔️",
    detail: `
    ຮັບສະຫມັກບັນຊີດ່ວນ 2 ຕຳແຫນ່ງ ປະຈຳແທັກຊີ້&ສະປາ ສາຂາ: ດົງປາແຫລບ, ມ.ຈັນທະບູລີ, ນວຈ:
    `,
    skill: `
    -ມີພື້ນຖານນຳໃຊ້: office word, excel <br />
    -ມີປະສົບການມາກ່ອນຈະພິຈາລະນາພິເສດ <br />
    -ເງິນເດືອນເລີ່ມຕົ້ນ 4-5ລ້ານກວ່າ (ເປັນວຽກ) <br />
    ‼️ຫມາຍເຫດ: ນັກສຶກສາພາດທາມກໍ່ສະຫມັກໄດ້
`,
    howTo: `
    ສົນໃຈຕິດຕໍ່ສອບຖາມໄດ້ທີ່: Tel/WA: 020 56 936 459
`,
    whatsapp: "020 56 936 459",
    tel: "020 56 936 459",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Bout_savath/boutsavathlogo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Bout_savath/boutsavathcover.jpeg",
  },
  {
    id: "46",
    title: "🔊ດ່ວນຮັບພະນັກງານຕ້ອນຮັບ✔️",
    companyName: "✔️ໂຮງແຮມ ເລີຊາມເມີ ວຽງຈັນ✔️",
    compaynyAddress:
      "ບໍ່ມີຂໍ້ມູນ",
    vacancy: "🔊ດ່ວນຮັບພະນັກງານຕ້ອນຮັບ✔️",
    detail: `
    1. ພະນັກງານ ຕ້ອນຮັບ
    `,
    skill: `
    - ບໍ່ຈຳເປັນຕ້ອງມີປະສົບການກໍສາມາດສະຫມັກໄດ້
`,
    howTo: `
    👉🏻ສົນໃຈສົ່ງ CV ແລະ ສອບຖາມຂໍ້ມູນໄດ້ທີ່:<br />
    📞ໂທ: 020 59 099 086<br />
    ຫຼື ເຂົ້າມາປະກອບຟອມສະໝັກ ແລະ ສອບຖາມທີ່ໂຮງແຮມໄດ້ເລີຍ. <br />
    📍ໂຮງແຮມຕັ້ງຢູ່ບ້ານມີໄຊ ຕຶກຕິດກັບຮ້ານ Bamboo House Mekong.<br />
    <a href="https://goo.gl/maps/QoC7TLDrxSUEYG2D8">https://goo.gl/maps/QoC7TLDrxSUEYG2D8</a>

`,
    whatsapp: "020 59 099 086",
    tel: "020 59 099 086",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Hotel_lersumer/hotellersumerlogo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Hotel_lersumer/hotellersumercover.jpeg",
  },
  {
    id: "45",
    title: "🔊ປະກາດຮັບພະນັກງານຫຼາຍຕຳແໜ່ງ✔️",
    companyName: "Manignom Heavy Equipment and Construction - MHC",
    compaynyAddress:
      "ບໍ່ມີຂໍ້ມູນ",
    vacancy: "🔊ປະກາດຮັບພະນັກງານຫຼາຍຕຳແໜ່ງ✔️",
    detail: `
    1.ພະນັກງານບັນຊີສາງ <br />
    2.ພະນັກງານທີ່ປືກສາບໍລິການຫຼັງ <br />
    3.ນາຍຊ່າງ <br />
    `,
    skill: `
    
`,
    howTo: `
    👉🏻ສົນໃຈສົ່ງ CV ຫຼື ຜົນງານ ມາທາງ WhatsApp: 020 5429 4466
`,
    whatsapp: "020 5429 4466",
    tel: "020 5429 4466",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/MHC/mhclogo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/MHC/mhccover.jpeg",
  },
  {
    id: "44",
    title: "🔊ເປີດຮັບສະໝັກພະນັກງານ✔️",
    companyName: "ບໍລິສັດ PHU GIA ເຕັກໂນໂລຊີ ເຫຼັກກ້າ‼️🔊ຕ້ອງການຮັບສະໝັກພະນັກງານ",
    compaynyAddress:
      "ບໍ່ມີຂໍ້ມູນ",
    vacancy: "🔊ເປີດຮັບສະໝັກພະນັກງານ✔️",
    detail: `
    1. ພະນັກງານບັນຊີ : 02 ຕຳແໜ່ງ 
    `,
    skill: `
    + ມີໃບປະກາດ, ມີຄວາມຊ່ຽວຊານ ແລະ ມີປະສົບການດ້ານບັນຊີ ຫຼື ຈົບໃໝ່ກໍໄດ້ ເຮົາຈະເຝິກອົບຮົມໃຫ້ <br />
    + ຄົນລາວທີ່ຮູ້ພາສາຫວຽດ<br />
    + ເງິນເດືອນ: ຕົກລົງຕອນສຳພາດ<br />
`,
    howTo: `
    
`,
    whatsapp: "020 93239883",
    tel: "020 59959779",
    email: "oka@okaedu.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/PHU/phulogo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/PHU/phucover.jpeg",
  },
  {
    id: "43",
    title: "🔊ເປີດຮັບສະໝັກພະນັກງານ ສາຂາວຽງຈັນ✔️",
    companyName: "Vientiane International Dental Hospital",
    compaynyAddress:
      "ບໍ່ມີຂໍ້ມູນ",
    vacancy: "🔊ເປີດຮັບສະໝັກພະນັກງານ ສາຂາວຽງຈັນ✔️",
    detail: `
    1. ພະນັກງານຕ້ອນຮັບ ແລະ ບໍລິການ <br />
    2. ຕຳແໜ່ງຜູ້ຊ່ວຍທັນຕະແພດ
    `,
    skill: `
   ບໍ່ມີຂໍ້ມູນ
`,
    howTo: `
    📌 ສະໝັກເລີຍ <br />
    📞Tel : 02059959779, 062-871-2111 <br />
    💌 E-mail : tdh.recruit.hr@gmail.com <br />
    📲 Line ID : @935dozkd <br />
    🦷 Link : <a href='https://lin.ee/ZncfSvp' target='_blank'>https://lin.ee/ZncfSvp</a>
`,
    whatsapp: "020 59959779",
    tel: "020 59959779",
    email: "tdh.recruit.hr@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/vientiane_international/vtlogo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/vientiane_international/vtcover.jpeg",
  },
  {
    id: "42",
    title: "🔊ຕ້ອງການພະນັກງານ✔️",
    companyName: "ຮ້ານ ແດຣີຄວີນ",
    compaynyAddress:
      "ບໍ່ມີຂໍ້ມູນ",
    vacancy: "🔊ຕ້ອງການພະນັກງານ✔️",
    detail: `
    🔆ກະວຽກປະຈຳ: 09:00 - 18:00; 12:00 - 21:00 <br />
    🔆ກະວຽກພາທທາມ: 09:00 - 15:00; 15:00 - 21:00 <br />
    📍ບໍລິສັດ EFG ປະເທດລາວເປັນບໍລິສັດໃນເຄືອຂອງກຸ່ມບໍລິສັດ RMA Laos <br />
    `,
    skill: `
   ບໍ່ມີຂໍ້ມູນ
`,
    howTo: `
    📝ຖ້າໃຜສົນໃຈສາມາດຕິດຕໍ່ມາເດີ: <br />
    ເວັບໄຊ້: <a href="https://sites.google.com/view/efglaoscareers" target="_blank">https://sites.google.com/view/efglaoscareers</a>  <br />
`,
    whatsapp: "020 29 824 233",
    tel: "020 29 824 233",
    email: "hrlao@rmagroup.net",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/EFG/efgcover.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/EFG/efglogo.jpeg",
  },
  {
    id: "41",
    title: "🔊ປະກາດຮັບສະໝັກເພື່ອນຮ່ວມງານ✔️",
    companyName: "ບໍລິສັດ ເອັກພີ ມະຫາໄຊຊັບ ຈຳກັດ",
    compaynyAddress:
      "ບໍ່ມີຂໍ້ມູນ",
    vacancy: "🔊ປະກາດຮັບສະໝັກເພື່ອນຮ່ວມງານ✔️",
    detail: `
    1. ພະນັກງານບັນຊີ <br />
      ✅ບໍ່ຈຳກັດເພດ ອາຍຸ 25-35 ປີ <br />
      ✅ຈົບຊັ້ນກາງ ຂື້ນໄປໃນສາຂາ ການເງິນ-ການບັນຊີ <br />
      ✅ຮູ້ນໍາໃຊ້ຄອມພີວເຕີ Word, Excel ແລະ Power point <br />
      ✅ສາມາດເຮັດວຽກເປັນທີມໄດ້, ມະນຸດສຳພັນທີ່ດີ, ຊື່ສັດສຸດຈະລິດ <br />
      ✅ລົງບັນຊີປະຈໍາວັນ <br />
      ✅ຈັນ-ເສົາ <br />
    2. ພະນັກງານ ການຕະຫຼາດ ແລະ ພະນັກງານຂາຍ <br />
      ✅ບໍ່ຈຳກັດວຸດທິພາວະ <br />
      ✅ສາມາດສ້າງຄອນເທັນຜ່ານຊ່ອງທາງອອນລາຍໄດ້ (facebook, TikTok,...) <br />
      ✅ດຸໝັ່ນ ມີຄວາມຄິດສ້າງສັນ ມີຄວາມຮັບຜິດຊອບຕໍ່ໜ້າທີ່ <br />
      ✅ຖ້າມີປະສົບການຂາຍ ຫຼື ການຕະຫຼາດມາກ່ອນຈະພິຈາລະນາເປັນພິເສດ <br />
      #ເງິນເດືອນບໍ່ຈຳກັດ ຂັ້ນຕ່ຳ 2.500.000 ກີບ - 5 ລ້ານ ຍັງບໍ່ລວມ %
    `,
    skill: `
   ບໍ່ມີຂໍ້ມູນ
`,
    howTo: `
    📌ສົນໃຈສາມາດສົ່ງ Resume ແລະ ນັດສຳພາດໄດ້: 📱📲 2096664396, 20 77325604
`,
    whatsapp: "020 96664396",
    tel: "20 77325604",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BB%80%E0%BA%AD%E0%BA%B1%E0%BA%A1%E0%BA%9E%E0%BA%B5+%E0%BA%A1%E0%BA%B0%E0%BA%AB%E0%BA%B2%E0%BB%84%E0%BA%8A%E0%BA%88%E0%BA%B3%E0%BA%81%E0%BA%B1%E0%BA%94/mplogo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BB%80%E0%BA%AD%E0%BA%B1%E0%BA%A1%E0%BA%9E%E0%BA%B5+%E0%BA%A1%E0%BA%B0%E0%BA%AB%E0%BA%B2%E0%BB%84%E0%BA%8A%E0%BA%88%E0%BA%B3%E0%BA%81%E0%BA%B1%E0%BA%94/mpcover.jpeg",
  },
  {
    id: "40",
    title: "🔊ປະກາດຮັບສະໝັກພະນັກງານ ຫຼາຍຕຳແໜ່ງ✔️",
    companyName: "ໂຮງງານຕັດຫຍິບ HUGEL INTERNATIONAL",
    compaynyAddress:
      "📍ສະຖານທີ່: ຕັ້ງຢູ່ບ້ານ ໜອງພະຍາ ຮ່ອມ 4, ເມືອງໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ (ທາງເຂົ້າບ້ານດົງຊຽງດີ, ເມືອງນາຊາຍທອງ), ໂຮງງານສົ່ງເຄື່ອງອອກໄປ ຢູໂຣບ, ອົດສະຕາລີ ແລະ ອາເມຣິກາ",
    vacancy: "🔊ປະກາດຮັບສະໝັກພະນັກງານ ຫຼາຍຕຳແໜ່ງ✔️",
    detail: `
    📍ສະຖານທີ່: ຕັ້ງຢູ່ບ້ານ ໜອງພະຍາ ຮ່ອມ 4, ເມືອງໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ (ທາງເຂົ້າບ້ານດົງຊຽງດີ, ເມືອງນາຊາຍທອງ), ໂຮງງານສົ່ງເຄື່ອງອອກໄປ ຢູໂຣບ, ອົດສະຕາລີ ແລະ ອາເມຣິກາ. <br />
    (ນອກນີ້ທາງໂຮງງານຂອງພວກເຮົາຍັງມີກິດຈະກຳສົ່ງເສີມດ້ານສຸຂະພາບ ແລະ ຈິດໃຈຂອງພະນັກງານເຊັ່ນ: ກິລາ,ເຕັ້ນອອກກຳລັງກາຍ, ນັ່ງສະມາທິ)
`,
    skill: `
   ບໍ່ມີຂໍ້ມູນ
`,
    howTo: `
    🤝ສົນໃຈມາຮ່ວມງານນໍາກັນຕິດຕໍ່ພວກເຮົາໄດ້ເລີຍມື້ນີ້! <br />
    📞📱ໂທຕິດຕໍ່ : 030 5406605, 020 52708590 (ໂທ & ວັອດແອັບ)
`,
    whatsapp: "020 52708590",
    tel: "030 5406605",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BB%82%E0%BA%AE%E0%BA%87%E0%BA%87%E0%BA%B2%E0%BA%99%E0%BA%95%E0%BA%B1%E0%BA%94%E0%BA%AB%E0%BA%8D%E0%BA%B4%E0%BA%9A/%E0%BB%82%E0%BA%AE%E0%BA%87%E0%BA%87%E0%BA%B2%E0%BA%99logo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/%E0%BB%82%E0%BA%AE%E0%BA%87%E0%BA%87%E0%BA%B2%E0%BA%99%E0%BA%95%E0%BA%B1%E0%BA%94%E0%BA%AB%E0%BA%8D%E0%BA%B4%E0%BA%9A/%E0%BB%82%E0%BA%AE%E0%BA%87%E0%BA%87%E0%BA%B2%E0%BA%99cover.jpeg",
  },
  {
    id: "39",
    title: "ຮັບສະໝັກເພື່ອນຮ່ວມງານ ຫລາຍຕຳແໜ່ງ",
    companyName: "Bezz Solution Sole Co., Ltd.",
    compaynyAddress:
      "ບໍ່ມີຂໍ້ມູນ",
    vacancy: "🔊ຮັບສະໝັກເພື່ອນຮ່ວມງານ ຫລາຍຕຳແໜ່ງ✔️",
    detail: `
    1. ພະນັກງານທີປຶກສາການຂາຍ 5 ຕໍາແໜ່ງ <br />
    2. ພະນັກງານບໍລິການ ແລະ ຝຶກອົບຮົມລູກຄ້າ 2 ຕໍາແໜ່ງ <br />
    😎 ໜ້າວຽກໂດຍທົ່ວໄປ: <br />
    1. ຕິດຕໍ່ພົວພັນລູກຄ້າເພື່ອນໍາສະເໜີການບໍລິການ (ເໜັ້ນລູກຄ້າ SMEs) <br />
    3. ປະສານງານລູກຄ້າ, ຄູ່ຮ່ວມທຸລະກິດ ເພື່ອຮັກສາສາຍສໍາພັນ <br />
    4. ວາງແຜນງານ ແລະ ລາຍງານຜົນລາຍຕາມແບບແຜນ <br />
    5. ເກັບກໍາ, ສຶກສາຂໍ້ມູນ ແລະ ພັດທະນາເຄື່ອງມືການຂາຍຂອງບໍລິສັດ <br />
`,
    skill: `
    1. ມີຄວາມກ້າຫານ, ຄວາມກະຕືລືລົ້ນ ແລະ ມີພາວະຜູ້ນໍາ <br />
    2. ມີປະສົບການດ້ານການຂາຍ ຫຼື ບໍລິການລູກຄ້າ <br />
    3. ມີຄວາມສາມາດໃຊ້ PowerPoint (ຈໍາເປັນຕ້ອງໄດ້ໃຊ້) ພ້ອມກັບ Word ແລະ Excel ຂັ້ນພື້ນຖານໄດ້ <br />
`,
    howTo: `
    🤝ສົນໃຈມາຮ່ວມງານນໍາກັນຕິດຕໍ່ພວກເຮົາໄດ້ເລີຍມື້ນີ້! <br />
    Contact Bezz Solution Sole Co., Ltd. Now! <br />
    📲 Mobile Number/Whatsapp: 020 58 565 856 <br />
    📧 E-mail: Beczeb2014@gmail.com <br />
    Bezz Solution, We think one step ahead! <br />
`,
    whatsapp: "020 58 565 856",
    tel: "020 58 565 856",
    email: "Beczeb2014@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Bezz+solution/bezzlogo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Bezz+solution/bezzimage.jpeg",
  },
  {
    id: "38",
    title: "Best Telecome ຕ້ອງການເພື່ອນຮ່ວມງານ",
    companyName: "Best Telecome",
    compaynyAddress:
      "📍ສະຖານທີ່ເຮັດວຽກ: ຖະໜົນ ສາຍລົມ, ບ້ານ ສາຍລົມ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ຕ້ອງການເພື່ອນຮ່ວມງານ",
    detail: `
    ‼️ເບສ ເທເລຄອນ ‼️ <br/>
    ກຳລັງຊອກຫາເພື່ອນຮ່ວມງານ <br/>
    1. ຝ່າຍຈັດສັນ ແລະ ວ່າຈ້າງບຸກຄະລາກອນ <br/>
    <br/>
    📍ສະຖານທີ່ເຮັດວຽກ: ຖະໜົນ ສາຍລົມ, ບ້ານ ສາຍລົມ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ <br/>
    <br/>
    Website: www.besttelecom.la <br/>
    ✅ສອບຖາມຂໍ້ມູນຜ່ານທາງຂໍ້ຄວາມ ຫຼື ໂທສອບຖາມ: 021 844 287✅ <br/>
    ✔️ທ່ານໃດສົນໃຈສະໝັກສາມາດສົ່ງ CV ຜ່ານທາງ ອີເມວ: recruitment@besttelecom.la     <br/>
       `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ`,
    howTo: `
    Website: www.besttelecom.la <br/>
    ✅ສອບຖາມຂໍ້ມູນຜ່ານທາງຂໍ້ຄວາມ ຫຼື ໂທສອບຖາມ: 021 844 287✅   <br/>
    ✔️ທ່ານໃດສົນໃຈສະໝັກສາມາດສົ່ງ CV ຜ່ານທາງ ອີເມວ: recruitment@besttelecom.la    <br/>  
       `,
    whatsapp: "021 844 287",
    tel: "021 844 287",
    email: "recruitment@besttelecom.la",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.20.37.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.20.39.jpeg",
  },
  {
    id: "37",
    title: "ຂົນສົ່ງດ່ວນ ຈີນ-ລາວ ຕ້ອງການເພື່ອນຮ່ວມງານຫລາຍຕຳແໜ່ງ",
    companyName: "GUANGZHOU CARGO",
    compaynyAddress: "",
    vacancy: "ຕ້ອງການເພື່ອນຮ່ວມງານ",
    detail: ` GUANGZHOU CARGO <br/>
    ຂົນສົ່ງດ່ວນ ຈີນ-ລາວ <br/>
    <br/>
    ‼️ຕ້ອງການເພື່ອນຮ່ວມງານຫລາຍຕຳແໜ່ງ  <br/>
    ສົນໃຈ ສາມາດຕິດຕໍ່ນັດສຳພາດໄດ້ທີ່  <br/>
    Tel/WA: 020-96688186 <br/>
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ‼️ຕ້ອງການເພື່ອນຮ່ວມງານຫລາຍຕຳແໜ່ງ  <br/>
    ສົນໃຈ ສາມາດຕິດຕໍ່ນັດສຳພາດໄດ້ທີ່  <br/>
    Tel/WA: 020-96688186    <br/> 
       `,
    whatsapp: "020-96688186",
    tel: "020-96688186",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.20.05.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.20.08.jpeg",
  },
  {
    id: "36",
    title: "ສະບາຍດີເດບ ຕ້ອງການເພື່ອນຮ່ວມງານດ່ວນ",
    companyName: "SABAIYDEV",
    compaynyAddress:
      "📍ສະຖານທີຕັ້ງຢູ່ : ບ້ານ ໜອງໜ່ຽງ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "‼️ຮັບສະໝັກເພື່ອນງານຫຼາຍຕຳແໜ່ງ ດ່ວນນນ...‼️",
    detail: `
    ‼️ຮັບສະໝັກເພື່ອນງານຫຼາຍຕຳແໜ່ງ ດ່ວນນນ...‼️ <br/>
    <br/>
    📍ສະຖານທີຕັ້ງຢູ່ : ບ້ານ ໜອງໜ່ຽງ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ <br/>
    👉🏼ທ່ານໃດສົນໃຈສະໝັກວຽກແມ່ນສາມາດດາວໂຫລດແບບຟອມສະໝັກວຽກໃນລິ້ງ🔗  ຂຽນແລ້ວນີບກັບ CV ແລ້ວສົ່ງມາທີ່ເບີ ⤵️ <br/>
    • WhatsApp 020 55 581 145  &  📥 career@sabaiydev.com <br/>
    #ໝາຍເຫດຖ້າບໍ່ນີບແບບຟອມສະໝັກມາທາງເຮົາຈະບໍ່ຮັບພິຈາລະນາ <br/>
    .......................................... <br/>
    ☎️ Call : 021 755789  <br/>
    🎬 Tiktok: https://www.tiktok.com/@sabaiydev  <br/>
    🇫 Facebook: Sabaiydev     <br/>
       `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉🏼ທ່ານໃດສົນໃຈສະໝັກວຽກແມ່ນສາມາດດາວໂຫລດແບບຟອມສະໝັກວຽກໃນລິ້ງ🔗  ຂຽນແລ້ວນີບກັບ CV ແລ້ວສົ່ງມາທີ່ເບີ ⤵️ <br/>
    • WhatsApp 020 55 581 145  &  📥 career@sabaiydev.com <br/>
    #ໝາຍເຫດຖ້າບໍ່ນີບແບບຟອມສະໝັກມາທາງເຮົາຈະບໍ່ຮັບພິຈາລະນາ <br/>
    .......................................... <br/>
    ☎️ Call : 021 755789  <br/>
    🎬 Tiktok: https://www.tiktok.com/@sabaiydev <br/>
    🇫 Facebook: Sabaiydev    <br/>
       `,
    whatsapp: "021 755789 ",
    tel: "021 755789 ",
    email: "career@sabaiydev.com",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.19.33.jpeg",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.19.33+(1).jpeg",
  },
  {
    id: "35",
    title: "Laothani ຕ້ອງການເພື່ອນຮ່ວມງານ",
    companyName: "ບໍລິສັດ ລາວທານີ ກົນຈັກໜັກ ຈຳກັດ",
    compaynyAddress: "ສະຖານທີ່: ບ້ານສີວິໄລ, ເມືອງໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ຕ້ອງການເພື່ອນຮ່ວມງານ",
    detail: `
    ‼️ດ່ວນ ‼️ <br/>
    ✔️ຮັບສະໝັກພະນັກງານຫຼາຍຕໍາແໜ່ງ✔️ <br/>
    1. ພະນັກງານ ຂາຍອາໄຫຼ່ <br/>
    2. ພະນັກງານ ຂາຍລົດໄຟຟ້າ <br/>
    3. ພະນັກງານ ຂາຍລົດກົນຈັກໜັກ <br/>
    4. ພະນັກງານ ຂາຍລົດຍົນ   <br/>
    5. ພະນັກງານບໍລິການຫຼັງການຂາຍ <br/>
    6. ພະນັກງານບໍລິຫານຝ່າຍຂາຍ <br/>
    ສໍາລັບທ່ານທີ່ສົນໃຈສາມາດສົ່ງ CV ( ລະບຸຕໍາແໜ່ງທີ່ສະໝັກ )ມາທີ່: <br/>
    📩 Email: recruitment@laothani.com <br/>
    ສອບຖາມຂໍ້ມູນເພີ່ມຕື່ມ: <br/>
    📱 wa: 020 5760 4999 <br/>
    📍 ຫຼືສາມາດມາຢື່ນເອກະສານດ້ວຍຕົວເອງທີ່: <br/>
    📌ບໍລິສັດ ລາວທານີ ກົນຈັກໜັກ ຈຳກັດ <br/>
    ສະຖານທີ່: ບ້ານສີວິໄລ, ເມືອງໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ສໍາລັບທ່ານທີ່ສົນໃຈສາມາດສົ່ງ CV ( ລະບຸຕໍາແໜ່ງທີ່ສະໝັກ )ມາທີ່: <br/>
    📩 Email: recruitment@laothani.com <br/>
    ສອບຖາມຂໍ້ມູນເພີ່ມຕື່ມ: <br/>
    📱 wa: 020 5760 4999 <br/>
    📍 ຫຼືສາມາດມາຢື່ນເອກະສານດ້ວຍຕົວເອງທີ່: <br/>
    📌ບໍລິສັດ ລາວທານີ ກົນຈັກໜັກ ຈຳກັດ <br/>
    ສະຖານທີ່: ບ້ານສີວິໄລ, ເມືອງໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ <br/>
       `,
    whatsapp: "020 5760 4999",
    tel: "020 5760 4999",
    email: "recruitment@laothani.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.19.01.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.19.02.jpeg",
  },
  {
    id: "34",
    title: "ບໍລິສັດ ສະມາດຄລີນ ຈຳກັດ ຮັບສະໝັກພະນັກງານດ່ວນ !!",
    companyName: "ບໍລິສັດ ສະມາດຄລີນ ຈຳກັດ",
    compaynyAddress: "📍ສຳນັກງານຕັ້ງຢູ່: ບ້ານຄຳຮຸ່ງ, ເມືອງໄຊທານີ, ນະຄອນຫຼວງ",
    vacancy: "📢ປະກາດຮັບສະໝັກ ພະນັກງານ",
    detail: `
    ‼️ບໍລິສັດ ສະມາດຄລີນ ຈຳກັດ‼️ <br/>
    📢ປະກາດຮັບສະໝັກ ພະນັກງານ  <br/>
    <br/>
    1.  ພະນັກງານ ເຈົ້າໜ້າທີ່ຝ່າຍຂາຍ 01 ຕຳແໜ່ງ  <br/>
    <br/>
    👉ສົນໃຈສະໝັກຕິດຕໍ່ພົວພັນ/ສົ່ງCV ມາໄດ້ທີ່:  <br/>
    👤: Ms. Ping  <br/>
    📲: 020 5476 2157  <br/>
    📧: thalinda@smartklinlao.com  <br/>
    <br/>
    👤: Ms. Pouna <br/>
    📲: 020 5476 2157 <br/>
    📧: p.ounsenban@smartklinlao.com <br/>
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉ສົນໃຈສະໝັກຕິດຕໍ່ພົວພັນ/ສົ່ງCV ມາໄດ້ທີ່: <br/>
    👤: Ms. Ping <br/>
    📲: 020 5476 2157 <br/>
    📧: thalinda@smartklinlao.com <br/>
    <br/>
    👤: Ms. Pouna <br/>
    📲: 020 5476 2157 <br/>
    📧: p.ounsenban@smartklinlao.com <br/>
       `,
    whatsapp: "020 5476 2157",
    tel: "020 5476 2157",
    email: "thalinda@smartklinlao.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.18.29.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.18.30.jpeg",
  },
  {
    id: "33",
    title: "ປະກາດຮັບສະໝັກ ເພື່ອນຮ່ວມງານຈຳນວນຫຼາຍ",
    companyName: "ຕະຫຼາດໃໝ່ໜອງໜ້ຽວ Nongniew Market",
    compaynyAddress: "",
    vacancy: "ປະກາດຮັບສະໝັກ ເພື່ອນຮ່ວມງານຈຳນວນຫຼາຍ",
    detail: `
    ‼️ຕະຫຼາດໃໝ່ໜອງໜ້ຽວ‼️ <br/>
    📢ປະກາດຮັບສະໝັກ ເພື່ອນຮ່ວມງານຈຳນວນຫຼາຍ📢 <br/>
    <br/>
    1. ພະນັກງານ ປະຊາສຳພັນ <br/>
    2. ໂຊຊຽວມິເດຍ <br/>
    <br/>
    👉ສົນໃຈສະໝັກສາມາດສົ່ງ CV ມາໄດ້ທີ່: <br/>
    📲WhatsApp: 020 9444 9525 , 020 5808 1878 <br/>
    📎FB: ຕະຫຼາດໃໝ່ໜອງໜ້ຽວ Nongniew Market  <br/>   
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉ສົນໃຈສະໝັກສາມາດສົ່ງ CV ມາໄດ້ທີ່: <br/>
📲WhatsApp: 020 9444 9525 , 020 5808 1878 <br/>
📎FB: ຕະຫຼາດໃໝ່ໜອງໜ້ຽວ Nongniew Market <br/>
 `,
    whatsapp: "020 9444 9525",
    tel: "020 5808 1878",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.17.57.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.17.59.jpeg",
  },
  {
    id: "32",
    title: "📌ຮັບສະໝັກ ພະນັກງານ 03ຕຳແໜ່ງ📌",
    companyName: "HECC - Heavy Equipment Clinic Center",
    compaynyAddress:
      "📍ຫ້ອງການຕັ້ງຢູ່: ບ້ານຊຳເກດ, ເມືອງສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "📌ຮັບສະໝັກ ພະນັກງານ 03ຕຳແໜ່ງ📌",
    detail: `
    HECC - Heavy Equipment Clinic Center <br/>
📌ຮັບສະໝັກ ພະນັກງານ 03ຕຳແໜ່ງ📌 <br/>
1. Marketing Manager (ຜູ້ຈັດການຝ່າຍການຕະຫຼາດ) <br/>
2. Administration Coordinator (ຜູ້ປະສານງານຝ່າຍບໍລິຫານ) <br/>
3. Sales Manager (ຜູ້ຈັດການຝ່າຍຂາຍ) <br/>
<br/>
👉Submit your update CV to: <br/>
📧Email: recruitment@hecc.group <br/>
📲Call/WhatsApp: 020 5857 4633 <br/>
📎FB: HECC - Heavy Equipment Clinic Center <br/>
📍ຫ້ອງການຕັ້ງຢູ່: ບ້ານຊຳເກດ, ເມືອງສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ <br/>`,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉Submit your update CV to: <br/>
    📧Email: recruitment@hecc.group <br/>
    📲Call/WhatsApp: 020 5857 4633 <br/>
    📎FB: HECC - Heavy Equipment Clinic Center <br/>
    📍ຫ້ອງການຕັ້ງຢູ່: ບ້ານຊຳເກດ, ເມືອງສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ <br/>`,
    whatsapp: "020 5857 4633",
    tel: "020 5857 4633",
    email: " recruitment@hecc.group",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.17.30.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.17.29.jpeg",
  },
  {
    id: "31",
    title: "KPV Group ‼️ປະກາດຮັບສະໝັກ ພະນັກງານ‼️",
    companyName: "KPV Group",
    compaynyAddress:
      "📍ບ້ານວັດໄຕໃຫຍ່ທ່າ(ຂ້າງຮ່ອມ4), ເມືອງສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "‼️ປະກາດຮັບສະໝັກ ພະນັກງານ‼️",
    detail: `
    🔊KPV Group <br/>
‼️ປະກາດຮັບສະໝັກ ພະນັກງານ‼️ <br/>
<br/>
1. Backend Developer 02 ຕຳແໜ່ງ <br/>
2. Mobile App Developer 02 ຕຳແໜ່ງ <br/>
👉ສົນໃຈສະໝັກສົ່ງຜົນງານທີ່ຜ່ານມາ ຫຼື Portfolio ໄດ້ທີ່: <br/>
📧Email: recruitmentkpvgroup@gmail.com <br/>
📲ໂທ: 020 5552 0187 <br/>
<br/>
📍ບ້ານວັດໄຕໃຫຍ່ທ່າ(ຂ້າງຮ່ອມ4), ເມືອງສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ <br/>
`,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉ສົນໃຈສະໝັກສົ່ງຜົນງານທີ່ຜ່ານມາ ຫຼື Portfolio ໄດ້ທີ່: <br/>
📧Email: recruitmentkpvgroup@gmail.com <br/>
📲ໂທ: 020 5552 0187 <br/>
<br/>
📍ບ້ານວັດໄຕໃຫຍ່ທ່າ(ຂ້າງຮ່ອມ4), ເມືອງສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ <br/>`,
    whatsapp: "020 5552 0187",
    tel: "020 5552 0187",
    email: "recruitmentkpvgroup@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.16.49.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.16.51.jpeg",
  },
  {
    id: "30",
    title: "ຮ້ານພິມຮຸ່ງເຮືອງ ຂາຍສົ່ງ-ຂາຍຍ່ອຍ ປະກາດຮັບສະໝັກ ພະນັກງານ ",
    companyName: "ຮ້ານພິມຮຸ່ງເຮືອງ ຂາຍສົ່ງ-ຂາຍຍ່ອຍ",
    compaynyAddress:
      "📍ສະຖານທີ່ຕັ້ງຢູ່ : ບ້ານ ຊຽງດາ , ເມືອງ ໄຊເສດຖາ , ນະຄອນຫລວງວຽງຈັນ",
    vacancy: "ປະກາດຮັບສະໝັກ ພະນັກງານ",
    detail: `
    ຮ້ານພິມຮຸ່ງເຮືອງ ຂາຍສົ່ງ-ຂາຍຍ່ອຍ <br/>
    <br/>
📢ປະກາດຮັບສະໝັກ ພະນັກງານ📢 <br/>
<br/>
1. ຕຳແຫນ່ງພະນັກງານຂາຍສິນຄ້າ   2 ຄົນ <br/> 
2. ຕຳແຫນ່ງຂັບລົດຂົນສົ່ງສິນຄ້າ      1 ຄົນ  <br/>
3. ຕຳແຫນ່ງແອດມິນ ຫ້ອງການ      1 ຄົນ <br/>
4. ກຳມະກອນຍົກເຄື່ອງ                  2 ຄົນ <br/>
<br/>
👉ສົນໃຈຕິດຕໍ່ :​ 020 5630 6888 , 020 5404 7357 <br/>
<br/>
📍ສະຖານທີ່ຕັ້ງຢູ່ : ບ້ານ ຊຽງດາ , ເມືອງ ໄຊເສດຖາ , ນະຄອນຫລວງວຽງຈັນ <br/>
`,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉ສົນໃຈຕິດຕໍ່ :​ 020 5630 6888 , 020 5404 7357 <br/>
<br/>
📍ສະຖານທີ່ຕັ້ງຢູ່ : ບ້ານ ຊຽງດາ , ເມືອງ ໄຊເສດຖາ , ນະຄອນຫລວງວຽງຈັນ <br/>`,
    whatsapp: "020 5630 6888",
    tel: "020 5404 7357",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.15.56.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.15.59.jpeg",
  },
  {
    id: "29",
    title: "ໂຮງແຮມ ຄຣາວ ພລາຊ່າ ວຽງຈັນ ແລະ ຮໍລິເດ ອິນ ແອນ ສຫວີທສ໌ ວຽງຈັນ",
    companyName: "ໂຮງແຮມ ຄຣາວ ພລາຊ່າ ວຽງຈັນ ແລະ ຮໍລິເດ ອິນ ແອນ ສຫວີທສ໌ ວຽງຈັນ",
    compaynyAddress:
      "📍ໂຮງແຮມ ຄຣາວ ພລາຊ່າ ວຽງຈັນ ບ້ານ ໜອງດ້ວງເໜືອ, ເມືອງ ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ",
    vacancy: "ປະກາດຮັບສະໝັກ ພະນັກງານ",
    detail: `
    📢ໂຮງແຮມ ຄຣາວ ພລາຊ່າ ວຽງຈັນ ແລະ ຮໍລິເດ ອິນ ແອນ ສຫວີທສ໌ ວຽງຈັນ 🔈 <br/>
    <br/>
ສຳລັບຜູ້ໃດທີ່ສົນໃຈສາມາດເຂົ້າມາສະໝັກໄດ້ທີ່ພະແນກບຸກຄະລາກອນ ຫຼື ສົ່ງຊີວີມາທີ່: Email: Vientiane.recruitment@ihg.com <br/>
ຫຼື ທ່ານສາມາດສະໝັກອອນໄລນ໌ ຕາມລິ້ງທາງລຸ່ມນີ້ເລີຍ <br/>
<a href="https://goo.gl/b5ujAK" target="_blank" >https://goo.gl/b5ujAK</a> <br/>
👉🏻ສອບຖາມຂໍ້ມູນເພີ່ມຕື່ມໄດ້ທີ່ເບີ 021 922883 <br/>
📍ໂຮງແຮມ ຄຣາວ ພລາຊ່າ ວຽງຈັນ <br/>
ບ້ານ ໜອງດ້ວງເໜືອ, ເມືອງ ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ <br/>
<br/>
`,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉🏻ສອບຖາມຂໍ້ມູນເພີ່ມຕື່ມໄດ້ທີ່ເບີ 021 922883 <br/>
    📍ໂຮງແຮມ ຄຣາວ ພລາຊ່າ ວຽງຈັນ <br/>
    ບ້ານ ໜອງດ້ວງເໜືອ, ເມືອງ ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ <br/>
    <br/>`,
    whatsapp: "021 922883",
    tel: "021 922883",
    email: "Vientiane.recruitment@ihg.co",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.15.25.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-14+at+10.15.27.jpeg",
  },
  {
    id: "28",
    title: "ຕ້ອງການ ເພື່ອນຮ່ວມງານ",
    companyName: "RMA Lao",
    compaynyAddress: "",
    vacancy: "ບໍລິສັດ RMA Lao ກຳລັງຕ້ອງການ ເພື່ອນຮ່ວມງານ",
    detail: `
    ‼️ດ່ວນໆ‼️ <br/>
🔊ບໍລິສັດ RMA Lao ກຳລັງຕ້ອງການ ເພື່ອນຮ່ວມງານ  <br/>
📌 Financial Analyst <br/>
📌 Payroll officer <br/>
📌 Lending officer <br/>
📌 Junior Risk and Compliance officer <br/>
👉🏻ສົນໃຈສະໝັກໄດ້ທີ່: Email: hrlao@rmagroup.net <br/>
 Tel/WA: +856-20-28045459 <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉🏻ສົນໃຈສະໝັກໄດ້ທີ່: Email: hrlao@rmagroup.net <br/>
      ☎️Tel/WA: +856-20-28045459 <br/>
    `,
    whatsapp: "+856-20-28045459",
    tel: "+856-20-28045459",
    email: " hrlao@rmagroup.net",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.49.29.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.49.29+(1).jpeg",
  },
  {
    id: "27",
    title: "",
    companyName: "www.laolaos.la",
    compaynyAddress: "",
    vacancy: "‼️ຮັບສະໝັກພະນັກງານດ່ວນ‼️",
    detail: `
    ‼️ຮັບສະໝັກພະນັກງານດ່ວນ‼️ <br/>
🔹ຕຳແໜ່ງ: ພະນັກງານເຊວຂາຍ (02 ຕຳແໜ່ງ) <br/>
❌ໜາຍເຫດ: ສະໝັກວຽກແມ່ນໃຫ້ຕິດຕໍ່ໄປທາງເບີນີ້ເທົ່ານັ້ນ. <br/>
👉🏻ຂໍ້ມູນເພີ່ມເຕີມ: ￼⁨020 77 771 127⁩ <br/>
<a href="https://wa.me/8562077771127" target="_blank" >https://wa.me/8562077771127</a> <br/>
ຫຼື ເຂົ້າມາສົ່ງໄດ້ທີ່ຫ້ອງການຕັ້ງຢູ່ອາຄານອາຊຽນມໍ. <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉🏻ຂໍ້ມູນເພີ່ມເຕີມ: ￼⁨020 77 771 127⁩ <br/>
<a href="https://wa.me/8562077771127" target="_blank" >https://wa.me/8562077771127</a> <br/>
ຫຼື ເຂົ້າມາສົ່ງໄດ້ທີ່ຫ້ອງການຕັ້ງຢູ່ອາຄານອາຊຽນມໍ. <br/>
    `,
    whatsapp: "020 77 771 127",
    tel: "020 77 771 127",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.49.49.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.49.50.jpeg",
  },
  {
    id: "26",
    title: "",
    companyName: "PG Steel ບໍລິສັດ ຟູຊາ ອຸດສະຫະກຳເຫຼັກ ຈຳກັດ",
    compaynyAddress: "",
    vacancy: "ຮັບສະໝັກພະນັກງານ ຫຼາຍຕຳແໜ່ງ✔️",
    detail: `
    PG Steel ບໍລິສັດ ຟູຊາ ອຸດສະຫະກຳເຫຼັກ ຈຳກັດ <br/>
🔊ຮັບສະໝັກພະນັກງານ ຫຼາຍຕຳແໜ່ງ✔️  <br/>
▶️ຕ້ອງການສະໝັກຕຳແໜ່ງໃດ  <br/>
▶️ຕິດຕໍ່ຫາພວກເຮົາໄດ້ເລີຍ👉🏻ທີ່ເບີ 020 9699 9940  <br/>
📍ສະຖານທີ່ຕັ້ງ ບ້ານດອນຫນູນ (ຕິດໄຟແດງດອນໜູນ)  <br/>
ເມືອງໄຊທານີ ນະຄອນຫລວງວຽງຈັນ  <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ▶️ຕິດຕໍ່ຫາພວກເຮົາໄດ້ເລີຍ👉🏻ທີ່ເບີ 020 9699 9940  <br/>
📍ສະຖານທີ່ຕັ້ງ ບ້ານດອນຫນູນ (ຕິດໄຟແດງດອນໜູນ)  <br/>
ເມືອງໄຊທານີ ນະຄອນຫລວງວຽງຈັນ  <br/>
    `,
    whatsapp: "020 9699 9940",
    tel: "020 9699 9940",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.50.35.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.50.36.jpeg",
  },
  {
    id: "25",
    title: "",
    companyName: "ໂຮງແຮມ SureStay Hotel by Best Western Vientiane",
    compaynyAddress: "",
    vacancy: "ຕ້ອງການເພື່ອນຮ່ວມງານຫລາຍຕຳແໜ່ງ",
    detail: `
    ‼️ໂຮງແຮມ SureStay Hotel by Best Western Vientiane ‼️ <br/>
🔊ຕ້ອງການເພື່ອນຮ່ວມງານຫລາຍຕຳແໜ່ງ📌 <br/>
-  Restaurant Manager <br/>
-  Accountant <br/>
-  Receptionist <br/>
-  Bartenders <br/>
-  Waiters/Waitress  <br/>
-  Cashier  <br/>
✔️ເງີນເດືອນເລີ່ມຕົ້ນ 3,500,000ກີບ/ເດືອນ (ສຳລັບຜູ້ບໍ່ມີປະສົບການ)  <br/>
-  ຖ້າມີປະສົບການແມ່ນຈະພິຈະລານາເພີ່ມເຕີມ <br/>
💁‍♀️ນອກຈາກນີ້ຍັງມີສະຫວັດດີການອື່ນໆອີກເຊັ່ນ <br/>
-  ເບ້ຍລ້ຽງອາຫານມື້ລະ 30,000ກີບ <br/>
-  ເງີນຂະຫຍັນ <br/>
-  ມື້ພັກ 6ມື້ / ເດືອນ <br/>
-  ມື້ພັກປະຈຳປີ <br/>
-  ປະກັນສັງຄົມ <br/>
<br/>
📌 ສຳລັບຜູ້ທີ່ສົນໃຈສາມາດຕິດຕໍ່ສອບຖາມລາຍລະອຽດດັ່ງລຸ່ມນີ້: <br/>
☎️Tel: 020 5238 9865​  📲WhatsApp: 020 5238 9865​ | 020​ 5235 6466 <br/>
📩Email: hr@surestayvientiane.com   <br/>
‼️ ຂໍ້ມູນເງີນເດືອນແມ່ນຈະແຈ້ງຫາກທ່ານຜ່ານການສຳພາດ.   <br/>
❌ຫມາຍເຫດ:    <br/>
ຮັບພະນັກງານລາຍວັນ(Casual)  <br/>
ເວລາ: 16:00-01:00 1××.000ກີບ   <br/>
ເວລາ: 17:00-00:00 1××.000ກີບ  <br/>
ເວລາ: 17:00-10:00 1××.000ກີບ  <br/>
Location📍: ໂຮງແຮມຊົວສະເຕ, ຖະໜົນເຈົ້າອານຸ,ບ້ານວັນຈັນ,ເມືອງຈັນທະບູລີ,ນະຄອນຫຼວງວຽງຈັນ  <br/>
👉🏻ສົນໃຈຖາມລາຍລະອຽດໄດ້ເດີ ຫຼື ຕິດຕໍ່ຫາເບີໂທ👇   <br/>
☎️Tel: 020 5238 9865​  📲WhatsApp: 020 5238 9865​ |  020​ 5235 6466  <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    📌 ສຳລັບຜູ້ທີ່ສົນໃຈສາມາດຕິດຕໍ່ສອບຖາມລາຍລະອຽດດັ່ງລຸ່ມນີ້: <br/>
☎️Tel: 020 5238 9865​  📲WhatsApp: 020 5238 9865​ | 020​ 5235 6466 <br/>
📩Email: hr@surestayvientiane.com   <br/>
‼️ ຂໍ້ມູນເງີນເດືອນແມ່ນຈະແຈ້ງຫາກທ່ານຜ່ານການສຳພາດ.   <br/>
<br/>
Location📍: ໂຮງແຮມຊົວສະເຕ, ຖະໜົນເຈົ້າອານຸ,ບ້ານວັນຈັນ,ເມືອງຈັນທະບູລີ,ນະຄອນຫຼວງວຽງຈັນ  <br/>
👉🏻ສົນໃຈຖາມລາຍລະອຽດໄດ້ເດີ ຫຼື ຕິດຕໍ່ຫາເບີໂທ👇   <br/>
☎️Tel: 020 5238 9865​  📲WhatsApp: 020 5238 9865​ |  020​ 5235 6466  <br/>
    `,
    whatsapp: "020 5238 9865",
    tel: "020 5238 9865",
    email: "hr@surestayvientiane.com ",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Hotel_lersumer/hotellersumerlogo.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student_jobvacancy/Hotel_lersumer/hotellersumercover.jpeg",
  },
  {
    id: "24",
    title: "",
    companyName: "Lao Cuisine Center",
    compaynyAddress: "",
    vacancy: "ປະກາດຮັບສະໝັກພະນັກງານ ປະຈຳສາຂາສະໜາມບິນ ແລະ ນາໄຊ",
    detail: `
    🔊ປະກາດຮັບສະໝັກພະນັກງານ ປະຈຳສາຂາສະໜາມບິນ ແລະ ນາໄຊ ‼️ <br/>
1. ຜູ້ຈັດການທົ່ວໄປ  <br/>
2. ຝ່າຍຂາຍ <br/>
3. ⁠ຝ່າຍບັນຊີ   <br/>
4. ⁠ຝ່າຍການຕະຫຼາດ  <br/>
✔️ເງື່ອນໄຂພິເສດ ພະນັກງານ:   <br/>
- ຄ່າເປີເຊັນງານ ✅    <br/>
🔥ເງິນເດືອນ ແມ່ນຂຶ້ນກັບປະສົບການ ແລະ ຄວາມສາມາດໃນການດຳເນີນວຽກງານຂອງທ່ານເອງ  <br/>
👉🏻ຕິດຕໍ່ເບີ 02058846888 ທາງວັອດແອັບເທົ່ານັ້ນ.  <br/>
<br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    `,
    whatsapp: "02058846888",
    tel: "02058846888",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.51.28.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.51.29.jpeg",
  },
  {
    id: "23",
    title: "",
    companyName: "ບໍລິສັດ ກະລໍລີ ມິເນໂລນ ລາວ ຈໍາກັດຜູ້ດຽວ",
    compaynyAddress: "",
    vacancy: "ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ",
    detail: `
    ບໍລິສັດ ກະລໍລີ ມິເນໂລນ ລາວ ຈໍາກັດຜູ້ດຽວ‼️ <br/>
🔊ຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ📌 <br/>
1. ພະນັກງານບັນຊີ-ການເງິນ✔️ <br/>
✅ເງື່ອນໄຂ: <br/>
- ເພດ ຍິງ-ຊາຍ ຈົບປະລິນຍາຕີ ສາຂາ ບັນຊີ/ການເງິນ  <br/>
- ຜູ້ມີຄວາມຮູ້ແລະຖະນັດໃນ ພາສາອັງກິດ ຫຼື ຈີນ ແມ່ນຈະພິຈາລະນາພິເສດ <br/>
- ເງິນເດືອນອີງຕາມຄວາມສາມາດ ແລະ ປະສົບການ ເລີ່ມຕົ້ນ 7,000,000ກີບ <br/>
<br/>
2. ພະນັກງານແປພາສາຈີນ ✔️ <br/>
✅ເງື່ອນໄຂ: <br/>
- ເພດ-ຊາຍ <br/>
- ອາຍຸ25ປີ ຂຶ້ນໄປ <br/>
- ຈົບການສຶກສາຊັ້ນສູງຂຶ້ນໄປ ໃນສາຂາທີ່ກ່ຽວຂ້ອງ  <br/>
- ສາມາດອ່ານ&ຂຽນພາສາຈີນໄດ້ລະດັບສູງ  <br/>
<br/>
3. ວິຊາການ ທໍລະນີສາດ ✔️  <br/>
✅ເງື່ອນໄຂ:  <br/>
- ເພດ-ຊາຍ ອາຍຸ 25ປີ ຂຶ້ນໄປ  <br/>
- ຈົບການສຶກສາໃນສາຂາທີ່ກ່ຽວຂ້ອງ   <br/>
- ມີປະສົບການ ດ້ານສໍາຫຼວດ/ຂຸດຄົ້ນ ແຮ່ທາດ 3ປີຂຶ້ນໄປ   <br/>
<br/>
4. ຄົນຂັບລົດ✔️  <br/>
✅ເງື່ອນໄຂ:  <br/>
- ເພດ-ຊາຍ  <br/>
- ອາຍຸ 25ປີ ຂຶ້ນໄປ  <br/>
- ສາມາດຂັບລົດອອກຕ່າງແຂວງໄດ້  <br/>
<br/>
✅ເອກະສານໃນການປະກອບ:  <br/>
- ໃບປະກາດ ແລະ ໃບຂະແນນ  <br/>
- ໃບຢັ້ງຢືນການຜ່ານງານ, CV   <br/>
- ໃບແຈ້ງໂທດ  <br/>
- ບັດປະຈໍາໂຕ ແລະ ສໍາມະໂນຄົວ  <br/>
☎ສົ່ງ CV ຫຼື ຕິດຕໍ່ຫ້ອງການໄດ້ທີ່: 021 350 427, 020 5232 3315  <br/>
📍ບໍລິສັດຕັ້ງຢູ່ທີ່: ບ້ານທົ່ງກາງ, ເມືອງສີສັດຕະນາກ, ນະຄອນຫຼວງວຽງຈັນ  <br/>
<br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ☎ສົ່ງ CV ຫຼື ຕິດຕໍ່ຫ້ອງການໄດ້ທີ່: 021 350 427, 020 5232 3315  <br/>
📍ບໍລິສັດຕັ້ງຢູ່ທີ່: ບ້ານທົ່ງກາງ, ເມືອງສີສັດຕະນາກ, ນະຄອນຫຼວງວຽງຈັນ  <br/>
    `,
    whatsapp: "020 5232 3315",
    tel: "021 350 427",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.51.49.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.51.49+(1).jpeg",
  },
  {
    id: "22",
    title: "",
    companyName: "Mixay Express",
    compaynyAddress: "",
    vacancy: "ຕ້ອງການຮັບສະໝັກເພື່ອນຮ່ວມງານປະຈຳສຳນັກງານໃຫ່ຍ ນະຄອນຫຼວງວຽງຈັນ📌",
    detail: `
    ‼️ດ່ວນໆ‼️ <br/>
🔊ຕ້ອງການຮັບສະໝັກເພື່ອນຮ່ວມງານປະຈຳສຳນັກງານໃຫ່ຍ ນະຄອນຫຼວງວຽງຈັນ📌 <br/>
 ເງື່ອນໄຂ:  <br/>
- ບໍ່ຈຳເພດ ອາຍຸ 22 ປີຂື້ນໄປ  <br/>
- ວຸດທິການສຶກສາຈົບ ຊັ້ນສູງ ຂື້ນໄປ  <br/>
- ມີມະນຸດສຳພັນດີ ແລະ ສາມາດເຮັດວຽກເປັນທີມໄດ້  <br/>
- ສາມາດນຳໃຊ້ຄອມພິວເຕີ້ໄດ້ (word, Excel, powerpiont)  <br/>
- ມີຄວາມຊື່ສັດ, ຫ້າວຫັນ, ອັດທົນຕໍ່ໜ້າວຽກ ແລະ ຍິ້ມແຍ້ມແຈ່ມໃສ່  <br/>
<br/>
📌ວອດແອັບ <a href="https://wa.me/message/CIYNJGS22M54J1" target="_blank" >https://wa.me/message/CIYNJGS22M54J1</a>  <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    📌ວອດແອັບ <a href="https://wa.me/message/CIYNJGS22M54J1" target="_blank" >https://wa.me/message/CIYNJGS22M54J1</a>  <br/>
     `,
    whatsapp: "20 22303031",
    tel: "",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.52.06.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.52.07.jpeg",
  },
  {
    id: "21",
    title: "",
    companyName: "TACDO",
    compaynyAddress: "",
    vacancy: "ຮັບສະໝັກພະນັກງານ 2ຕຳ​ແໜ່ງ",
    detail: `
    ‼️ຮັບສະໝັກພະນັກງານ 2ຕຳ​ແໜ່ງ‼️ <br/>
1. ປະ​ຈຳ ຄອນ​ອິນສອນ​ຂັບ​ລົດ  <br/>
#ເງື່ອນໄຂ:  <br/>
• ເປັນ​ຄົນ​ກ້າ​ຫານ ແລະ ສູ້​ຊົນ​ສະ​ພາບ​ວຽກ​ຕ່າງ​ໆ  <br/>
- ມະ​ນຸດ​ສຳ​ພັນ​ດີເຂົ້າ​ກັບ​ຄົນ​ອື່ນ​ໄດ້  <br/>
- ⁠ສ​າ​ມາດ​ເດີນ​ທາງ​ຕ່າງ​ແຂວງ​ໄດ້  <br/>
- ⁠ສາ​ມາດ​ນຳ​ໃຊ້​ຄອມ​ພີວ​ເຕີ (Word-Excel…)  <br/>
- ⁠ຂັບ​ລົດ​ໃຫຍ່​ໄດ້ ຈະ​ພິ​ຈາ​ລະ​ນາ​ເປັນ​ພິ​ເສດ  <br/>
- ⁠ລະ​ດັບ​ການ​ສຶກ​ສາ ຈົບ​ຊັ້ນ​ກາງ​ຂື້ນ​ໄປ  <br/>
2. ປະ​ຈຳ ໂຄງ​ການລຸກຊໍາບວກ  <br/>
ເງື່ອນ​ໄຂ:  <br/>
- ເກັ່ງ​ພາ​ສາອ​ັງ​ກິດ  <br/>
- ⁠ສາ​ມາດ​ນຳ​ໃຊ້​ຄອມ​ພີວ​ເຕີ (Word-Excel…)  <br/>
- ⁠ເຄີຍ​ເຮັດ​ວຽກ​ບໍ​ລິ​ຫານ​ໂຄງ​ການ​ຢ່າງ​ໜ່ອຍ 3ປີ​ຂື້ນ​ໄປ.  <br/>
- ⁠ພິມ​ເອ​ກະ​ສານ​ໄວ  <br/>
- ⁠ມະ​ນຸດ​ສຳ​ພັນ​ດີ, ເຂົ້າ​ກັບ​ຄົນ​ອື່ນ​ໄດ້​ດີ  <br/>
- ⁠ລະ​ດັບ​ການ​ສຶ​ກ​ສາ​ຈົບ​ຊັ້ນ​ສູງ​ຂື້ນ​ໄປ  <br/>
- ⁠ສະ​ໝັກ​ຜ່ານ​ແທັກ​ໂດ​  <br/>
📍ບ້ານ ດອນ​ປ່າ​ໃໝ່, ເມືອງ ສີ​ສັດ​ຕະ​ນາກ, ນະ​ຄອນຫຼວງວຽງ​ຈັນ  <br/>
☎️ 020 5253 7337, 020 5216 1859  <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    📍ບ້ານ ດອນ​ປ່າ​ໃໝ່, ເມືອງ ສີ​ສັດ​ຕະ​ນາກ, ນະ​ຄອນຫຼວງວຽງ​ຈັນ  <br/>
☎️ 020 5253 7337, 020 5216 1859  <br/>
    `,
    whatsapp: "020 5253 7337",
    tel: "020 5216 1859",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.52.28.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.52.29.jpeg",
  },
  {
    id: "20",
    title: "",
    companyName: "ຮ້ານ ແອັກຊັນ ຄາເມຣາ",
    compaynyAddress: "",
    vacancy: "ເປີດປະກາດ ຮັບ🔊ສະໝັກພະນັກງານຂາຍ 2 ຕໍາແໜ່ງ #Sale",
    detail: `
    ‼️ຮ້ານ ແອັກຊັນ ຄາເມຣາ - ເປີດປະກາດ ຮັບ🔊ສະໝັກພະນັກງານຂາຍ 2 ຕໍາແໜ່ງ #Sale ✨ <br/>
    <br/>
✅ ມີໂບນັດ ແລະ ອັບຂັ້ນເງິນເດືອນ   <br/>
✅ ມີເປີເຊັນການຂາຍ   <br/>
✅ ມີຄ່າໂທລະສັບ ແລະ ນໍ້າມັນລົດ   <br/>
‼️ໄລຍະເຮັດວຽກແບບ Full time   <br/>
(ເລີ່ມ 8:30 - 18:00 )   <br/>
📍ສະຖານທີ່ຕັ້ງຢູ່ : ບ້ານຄໍາຮຸ່ງ, ເມືອງໄຊທານີ, ແຂວງ ນະຄອນຫຼວງວຽງຈັນ ( ຕໍ່ໜ້າ ຄຣີນິກປົວແຂ້ວ 4 ຊັ້ນ )   <br/>
ແຜນທີ່ຮ້ານ:  <a href="https://bit.ly/39gFFl0" target="_blank"> https://bit.ly/39gFFl0</a>   <br/>
📥 ສົນໃຈ ສາມາດສົ່ງ ຜົນງານ ຫຼື ຊີວະປະຫວັດ ( CV ) ມາທາງເບີ WhatsApp 020 9236 3060, 020 5894 6317 ຫຼື ທາງອີເມວ Info@actionscamera.la  <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    📍ສະຖານທີ່ຕັ້ງຢູ່ : ບ້ານຄໍາຮຸ່ງ, ເມືອງໄຊທານີ, ແຂວງ ນະຄອນຫຼວງວຽງຈັນ ( ຕໍ່ໜ້າ ຄຣີນິກປົວແຂ້ວ 4 ຊັ້ນ )   <br/>
ແຜນທີ່ຮ້ານ: <a href="https://bit.ly/39gFFl0" target="_blank"> https://bit.ly/39gFFl0</a>  <br/>
📥 ສົນໃຈ ສາມາດສົ່ງ ຜົນງານ ຫຼື ຊີວະປະຫວັດ ( CV ) ມາທາງເບີ WhatsApp 020 9236 3060, 020 5894 6317 ຫຼື ທາງອີເມວ Info@actionscamera.la  <br/>
   
    `,
    whatsapp: "020 9236 3060, 020 5894 6317",
    tel: "",
    email: "Info@actionscamera.la",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.52.45.jpeg",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.52.45+(1).jpeg",
  },
  {
    id: "19",
    title: "",
    companyName: "MG",
    compaynyAddress: "",
    vacancy: "ຮັບສະໝັກເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    🔊ປະກາດ ‼️ <br/>
📌ຮັບສະໝັກເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ  <br/>
1. ພະນັກງານຂາຍ ຫລາຍຕຳແໜ່ງ Sales Consultant  <br/>
2. ຫົວໜ້າຊ່າງ Chief Technician  <br/>
3. ຊ່າງທົວໄປ Mechanic   <br/>
4. ທີ່ປຶກສາຫລັງການຂາຍ SA  <br/>
5. ພະນັກງານຫ້ອງການ Admin  <br/>
6. ພະນັກງານສາງອາໄຫລ່ Part Special List  <br/>
7. ພະນັກງານລ້າງລົດ Car Retailers  <br/>
8. ການຕະຫລາດ Marketing  <br/>
9. ແຄັດເຊຍ Cashier  <br/>
<br/>
***ເງື່ອນໄຂຜູ້ສະໝັກ:  <br/>
- ຮຽນຈົບຊັ້ນສູງຂຶ້ນໄປ  <br/>
- ອາຍຸ 20 ປີຂຶ້ນໄປ  <br/>
-ເປັນຄົນມີຄວາມອົດທົນ, ດຸໝັ່ນ ແລະ ສາມາດເຮັດວຽກເປັນທີມໄດ້  <br/>
- ມີປະສົບການພິຈາລານາພິເສດ  <br/>
<br/>
📌ສົນໃຈສາມາດເຂົ້າມາສະໝັກ ຫຼື ສົ່ງປະຫວັດຫຍໍ້ (CV) ແລະ ຟອມສະໝັກ ໄດ້ທີ່:  <br/>
Email: hr.mg@vmmlao.com ຫລື WhatsApp ￼⁨020 77 704 761⁩  <br/>
<br/>
* ຟອມສະໝັກງານ : <a href="https://drive.google.com/file/d/1H_w0TnPU1j8a2sgky5XBJiY0OJWAIPM0/view?usp=sharing" target='_blank' >https://drive.google.com/file/d/1H_w0TnPU1j8a2sgky5XBJiY0OJWAIPM0/view?usp=sharing</a>  <br/>
<br/>
ສຳນັກງານຕັ້ງຢູ່: ບ. ສີບຸນເຮືອງທ່າ, ມ. ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ.  <br/>
ແຜນທີ່: <a href="https://maps.apple.com/?address=11,%20Vientiane,%20Laos&ll=17.975271,102.546127&q=MG%20VMM&t=m " target='_blank' >https://maps.apple.com/?address=11,%20Vientiane,%20Laos&ll=17.975271,102.546127&q=MG%20VMM&t=m</a> <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    📌ສົນໃຈສາມາດເຂົ້າມາສະໝັກ ຫຼື ສົ່ງປະຫວັດຫຍໍ້ (CV) ແລະ ຟອມສະໝັກ ໄດ້ທີ່:  <br/>
Email: hr.mg@vmmlao.com ຫລື WhatsApp ￼⁨020 77 704 761⁩  <br/>
<br/>
* ຟອມສະໝັກງານ : <a href="https://drive.google.com/file/d/1H_w0TnPU1j8a2sgky5XBJiY0OJWAIPM0/view?usp=sharing" target='_blank' >https://drive.google.com/file/d/1H_w0TnPU1j8a2sgky5XBJiY0OJWAIPM0/view?usp=sharing</a>  <br/>
<br/>
ສຳນັກງານຕັ້ງຢູ່: ບ. ສີບຸນເຮືອງທ່າ, ມ. ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ.  <br/>
ແຜນທີ່: <a href="https://maps.apple.com/?address=11,%20Vientiane,%20Laos&ll=17.975271,102.546127&q=MG%20VMM&t=m " target='_blank' >https://maps.apple.com/?address=11,%20Vientiane,%20Laos&ll=17.975271,102.546127&q=MG%20VMM&t=m</a> <br/>
   
    `,
    whatsapp: "￼⁨020 77 704 761⁩ ",
    tel: "",
    email: "hr.mg@vmmlao.com",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.53.13.jpeg",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.53.14.jpeg",
  },
  {
    id: "18",
    title: "",
    companyName: "ສູນພາສາແພນດ້",
    compaynyAddress: "",
    vacancy: " ຮັບສະໝັກພະນັກງານ",
    detail: `
    ‼️ສູນພາສາແພນດ້າ ຮັບສະໝັກພະນັກງານ‼️ <br/>
🌟ອາຈານສອນພາສາຈີນ 1 ຕຳແໜ່ງ  <br/>
ເງືອນໄຂ: <br/>
•ຊາຍ,ຍິງ <br/>
•ອາຍຸ 21-35 ປີ <br/>
•ມັກຮັກໃນການສອນມີ  <br/>
•ມີໃບ HSK 5 <br/>
ເງິນເດືອນ 6,000,000 + ຊົ່ວໂມງສອນ <br/>
🌟ຜູ້ຊ່ວຍທີ່ປຶກສາທຶນ 1 ຕຳແໜ່ງ <br/>
ເງື່ອນໄຂ: <br/>
•ຊາຍ,ຍິງ <br/>
•ມີຄວາມຊຳນານການໃຊ້ຄອມພິວເຕີ້ <br/>
•ມີໃບ HSK 5  <br/>
•ອັດສະຍາໄສແລະມະນຸດສຳພັນດີ  <br/>
ເງິນເດືອນ 6,000,000-12,000,000  <br/>
📌ຊ່ອງທາງການສະໝັກແລະສົ່ງ CV  <br/>
020 5515 8886  <br/>
020 5432 8887  <br/>
Email 📧 : laopanda2012@163.com  <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    📌ຊ່ອງທາງການສະໝັກແລະສົ່ງ CV  <br/>
020 5515 8886  <br/>
020 5432 8887  <br/>
Email 📧 : laopanda2012@163.com  <br/>
    `,
    whatsapp: "020 5515 8886",
    tel: "020 5432 8887",
    email: "laopanda2012@163.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.53.58.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.53.57.jpeg",
  },
  {
    id: "17",
    title: "",
    companyName: "MAHASOK",
    compaynyAddress: "",
    vacancy: "ຕ້ອງການຮັບສະໝັກພະນັກງານຂາຍ",
    detail: `
    ‼️ບໍລິສັດມະຫາໂຊກ ການຄ້າຈໍາກັດຜູ້ດຽວ ‼️  <br/> 
🔊ຕ້ອງການຮັບສະໝັກພະນັກງານຂາຍ ຫຼາຍຕຳແໜ່ງດັ່ງນີ້:  <br/> 
1. ຫົວໜ້າພະແນກຂາຍ ຈຳນວນ 1 ຕຳແໜ່ງ  <br/> 
2. ພະນັກງານຂາຍເຄື່ອງສຳອາງສີຈັນ ຈຳນວນ 1 ຕຳແໜ່ງ  <br/> 
3. ພະນັກງານຂາຍຍ່ອຍເຂົ້າໜົມ ຈຳນວນ 2 ຕຳແໜ່ງ  <br/> 
☎️ໂທສອບຖາມຂໍ້ມູນເພີ່ມຕື່ມ : 020 52 283 626   <br/> 
👉🏻ສົ່ງໃບສະໝັກໄດ້ທີ່ :  mahasokadm@gmail.com  <br/> 
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ໂທສອບຖາມຂໍ້ມູນເພີ່ມຕື່ມ : 020 52 283 626   <br/> 
👉🏻ສົ່ງໃບສະໝັກໄດ້ທີ່ :  mahasokadm@gmail.com  <br/> 
    `,
    whatsapp: "020 52 283 626",
    tel: "",
    email: "mahasokadm@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.54.14.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.54.15.jpeg",
  },
  {
    id: "16",
    title: "",
    companyName: "ບໍລິສັດ PSL ທີ່ປຶກສາບັນຊີ-ກວດສອບ ແລະການຄ້າ",
    compaynyAddress: "",
    vacancy: "ປະກາດຮັບສະໝັກເພື່ອນຮ່ວມງານ 2ຕຳແໜ່ງ",
    detail: `
    ‼️ບໍລິສັດ PSL ທີ່ປຶກສາບັນຊີ-ກວດສອບ ແລະການຄ້າ ຈຳກັດ‼️ <br/>
🔊ປະກາດຮັບສະໝັກເພື່ອນຮ່ວມງານ 2ຕຳແໜ່ງ   <br/>
1. ຜູ້ຊ່ວຍບັນຊີ (ເພດຍິງ)  <br/>
2. ບໍລິຫານ ຫ້ອງການ (ເພດຊາຍ)  <br/>
👉🏻ສົນໃຈສະໝັກຕິດຕໍ່ໄດ້ທີ່ 020 91992081   <br/>
📍ບ້ານ ໜອງບອນ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ  <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉🏻ສົນໃຈສະໝັກຕິດຕໍ່ໄດ້ທີ່ 020 91992081   <br/>
📍ບ້ານ ໜອງບອນ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ  <br/>
    `,
    whatsapp: "020 91992081",
    tel: "",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.54.44.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.54.43.jpeg",
  },
  {
    id: "15",
    title: "",
    companyName: "Papon Sportswear",
    compaynyAddress: "",
    vacancy: "ຕ້ອງການເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ✔️ ",
    detail: `
    ‼️Papon Sportswear ‼️ <br/>
🔊ຕ້ອງການເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ✔️  <br/>
1. ພະນັກງານອອກແບບກຣາຟິກ 5 ຕໍາແໜ່ງ   <br/>
2. ພະນັກງານບັນຊີ 5 ຕໍາແໜ່ງ   <br/>
👉🏻ສົນໃຈຕິດຕໍ່: 02092444263 📌  <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉🏻ສົນໃຈຕິດຕໍ່: 02092444263 📌  <br/>
    `,
    whatsapp: "02092444263",
    tel: "",
    email: "",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.55.17.jpeg",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.55.17+(1).jpeg",
  },
  {
    id: "14",
    title: "",
    companyName: "Lao Culture",
    compaynyAddress: "",
    vacancy: "ປະກາດຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ",
    detail: `
    ‼️ກຸ່ມບໍລິສັດ ທ່ອງທ່ຽວ ວັດທະນະທຳລາວ ມະຫາຊົນ‼️ <br/>
🔊ປະກາດຮັບສະໝັກພະນັກງານຫລາຍຕຳແໜ່ງ✔️  <br/>
<br/>
1. ຜູ້ຊ່ວຍວຽກຜູ້ອຳນວຍການໃຫຍ່  <br/>
2. ພະນັກງານບໍລິຫານ  <br/>
3. ຜູ້ແປພາສາ  <br/>
4. ຜູ້ຮັບຜິດຊອບວຽກການຂາຍ  <br/>
5. ພະນັກງານການຂາຍ  <br/>
6. ນັກຖ່າຍທຳ  <br/>
7. ນັກອອກແບບ-ຕັດຕໍ່ຮູບເງົາ-ໂທລະພາບ  <br/>
8. ນັກຈັດແສງສະຫວ່າງ  <br/>
9. ນັດອອກແບບ-ຕັກແຕ່ງຮູບພາບ  <br/>
10. ວິຊາການດ້ານວິສະວະກຳ   <br/>
11. ວິຊາການດ້ານກະສິກຳ  <br/>
12. ນັກຄັງເງິນ  <br/>
13. ນັກບັນຊີ  <br/>
14. ຫົວໜ້າພະແນກການເງິນ  <br/>
15. ຜູ້ຊ່ວຍວຽກຫົວໜ້າຝ່າຍການເງິນ  <br/>
<br/>
✅ລາຍລະອຽດແຕ່ລະຕຳແໜ່ງສາມາດເຂົ້າເບິ່ງໄດ້ທີ່ລິ້ງ:  <br/>
<a href="https://drive.google.com/drive/folders/15kXRd_UypZOXJ9fl0lqZNMN4f0dVeU9K?usp=sharing" target='_blank'>https://drive.google.com/drive/folders/15kXRd_UypZOXJ9fl0lqZNMN4f0dVeU9K?usp=sharing</a> <br/>
<br/>
👉ສົນໃຈສະໝັກຕິດຕໍ່ພົວພັນມາໄດ້ທີ່:  <br/>
📧Email: lctc.hr@gmail.com  <br/>
📲Mobile: 020 2666 8888 / 020 2666 9999  <br/>
<br/>
📍ບ້ານໂພນພະເນົາ, ເມືອງໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ  <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ✅ລາຍລະອຽດແຕ່ລະຕຳແໜ່ງສາມາດເຂົ້າເບິ່ງໄດ້ທີ່ລິ້ງ:  <br/>
<a href="https://drive.google.com/drive/folders/15kXRd_UypZOXJ9fl0lqZNMN4f0dVeU9K?usp=sharing" target='_blank'>https://drive.google.com/drive/folders/15kXRd_UypZOXJ9fl0lqZNMN4f0dVeU9K?usp=sharing</a> <br/>
<br/>
👉ສົນໃຈສະໝັກຕິດຕໍ່ພົວພັນມາໄດ້ທີ່:  <br/>
📧Email: lctc.hr@gmail.com  <br/>
📲Mobile: 020 2666 8888 / 020 2666 9999  <br/>
<br/>
📍ບ້ານໂພນພະເນົາ, ເມືອງໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ  <br/>
    `,
    whatsapp: "",
    tel: "020 2666 8888 / 020 2666 9999",
    email: "lctc.hr@gmail.com",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.56.07+(1).jpeg",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.55.45.jpeg",
  },
  {
    id: "13",
    title: "",
    companyName: "MAHATHUEN",
    compaynyAddress: "",
    vacancy: "ປະກາດຮັບສະໝັກ ພະນັກງານ",
    detail: `
    ‼️ບໍລິສັດ ມະຫາທືນ ເຊົ່າສິນເຊື່ອ ມະຫາຊົນ <br/>
🔊ປະກາດຮັບສະໝັກ ພະນັກງານ📌   <br/>
<br/>
1. ກວດສອບ ແລະ ບໍລິການສິນເຊື່ອ   <br/>
2. IT Support  <br/>
<br/>
✅ສະແກນ QR ເພື່ອດາວໂຫຼດແບບຟອມສະໝັກ  <br/>
👉ຕິດຕໍ່ສອບຖາມ ພະແນກບຸກຄະລາກອນ:  <br/>
📧Email: hr@mahathuen.com  <br/>
📲WhatsApp: 020 9085 2362   <br/>
☎️Tel: 021 418062-6 ຕໍ່ 188  <br/>
<br/>
📎FB: Mahathuen Leasing Public Company  <br/>
📍ສະຖານທີ່: ຖະໜົນໄກສອນພົມວິຫານ, ບ້ານໂພນພະເນົາ, ເມືອງໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ   <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ✅ສະແກນ QR ເພື່ອດາວໂຫຼດແບບຟອມສະໝັກ  <br/>
👉ຕິດຕໍ່ສອບຖາມ ພະແນກບຸກຄະລາກອນ:  <br/>
📧Email: hr@mahathuen.com  <br/>
📲WhatsApp: 020 9085 2362   <br/>
☎️Tel: 021 418062-6 ຕໍ່ 188  <br/>
<br/>
📎FB: Mahathuen Leasing Public Company  <br/>
📍ສະຖານທີ່: ຖະໜົນໄກສອນພົມວິຫານ, ບ້ານໂພນພະເນົາ, ເມືອງໄຊເສດຖາ, ນະຄອນຫຼວງວຽງຈັນ   <br/>
    `,
    whatsapp: "020 9085 2362",
    tel: "021 418062-6 ຕໍ່ 188",
    email: " hr@mahathuen.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.56.07.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.56.07+(1).jpeg",
  },
  {
    id: "12",
    title: "",
    companyName: "SPL",
    compaynyAddress: "",
    vacancy: "ປະກາດຮັບສະໝັກພະນັກງານ",
    detail: `
    ‼️ບໍລິສັດ ສີສົມບັດ ເຊົ່າສິນເຊື່ອ ຈໍາກັດ‼️ <br/>
🔊ປະກາດຮັບສະໝັກພະນັກງານ📌  <br/>
<br/>
1. ຫົວໜ້າໜ່ວຍບໍລິການ (ປະຈຳແຂວງຫຼວງພະບາງ)  <br/>
<br/>
👉ສົນໃຈສະໝັກສົ່ງ CV ມາໄດ້ທີ່:  <br/>
📧Email: vte@sisombathleasing.com  <br/>
📱ໂທ: 020 2228 2230   <br/>
<br/>
📍ສຳນັກງານໃຫຍ່ຕັ້ງຢູ່: ຖະໜົນເຈົ້າຣາດຊະວົງ, ໜ່ວຍ 48, ບ້ານດອນແດງ, ເມືອງຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ.   <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    👉ສົນໃຈສະໝັກສົ່ງ CV ມາໄດ້ທີ່:  <br/>
📧Email: vte@sisombathleasing.com  <br/>
📱ໂທ: 020 2228 2230   <br/>
<br/>
📍ສຳນັກງານໃຫຍ່ຕັ້ງຢູ່: ຖະໜົນເຈົ້າຣາດຊະວົງ, ໜ່ວຍ 48, ບ້ານດອນແດງ, ເມືອງຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ.   <br/>
    `,
    whatsapp: "",
    tel: "020 2228 2230",
    email: "vte@sisombathleasing.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.56.29.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.56.29+(1).jpeg",
  },
  {
    id: "11",
    title: "",
    companyName: "Papon Sportswear",
    compaynyAddress: "",
    vacancy: "ຕ້ອງການເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    ‼️Papon Sportswear ‼️ <br/>
🔊ຕ້ອງການເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ✔️ <br/>
1. ພະນັກງານອອກແບບກຣາຟິກ 5 ຕໍາແໜ່ງ  <br/>
2. ພະນັກງານບັນຊີ 5 ຕໍາແໜ່ງ    <br/>
👉🏻ສົນໃຈຕິດຕໍ່: 02092444263 📌  <br/>


    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    `,
    whatsapp: "02092444263",
    tel: "02092444263",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.55.17+(1).jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.55.17.jpeg",
  },
  {
    id: "10",
    title: "",
    companyName: "ບໍລິສັດ ແອັນເທັກ ຈຳກັດຜູ້ດຽວ",
    compaynyAddress: "",
    vacancy: "ປະກາດຮັບສະໝັກພະນັກງານຫຼາຍຕໍາແຫນ່ງ ",
    detail: `
    ‼️ບໍລິສັດ ແອັນເທັກ ຈຳກັດຜູ້ດຽວ‼️   <br/>
🔊ປະກາດຮັບສະໝັກພະນັກງານຫຼາຍຕໍາແຫນ່ງ   <br/>
1. ຝ່າຍບຸກຄະລາກອນ ແລະ ຕຸລາການ (HR and Admin)   <br/>
2. ຝ່າຍຈັດຊື້ (Procurement) ໄດ້ພາສາຈີນ HSK3   <br/>
3. ຫົວໜ້າ ການຕະຫຼາດ (Marketing Manager)   <br/>
4. ພະນັກງານສາງ (ແພັກສິນຄ້າ)   <br/>
5. ພະນັກງານສາງ (ຍົກຍໍສິນຄ້າ)   <br/>
6. ພະນັກງານຂາຍ (ພາກເຊົ້າ, ແລງ, ຄໍ່າ)   <br/>
♻️ ສາມາດສົ່ງຟອມສະໝັກງານ ແລະ ເອກະສານປະກອບໄດ້ທີ່   <br/>
 Whatsapp : 020 99 111 558   <br/>
 Mail : Sinthakone.m@ez-service.la   <br/>
♻️ ເງິນເດືອນ ແລະ ສະຫວັດດີການດີ   <br/>
♻️ ເວລາຕິດຕໍ່ສະໝັກງານ 08:30-17:30 (ວັນຈັນ-ວັນເສົາ)   <br/>
----------------------------   <br/>
✔️ມາກ່ອນມີສິດກ່ອນ✔️   <br/>
🏢 AnTech Co., LTD   <br/>
🌐 <a href='https://www.antech.la/' target='_blank'>https://www.antech.la/</a>    <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ♻️ ສາມາດສົ່ງຟອມສະໝັກງານ ແລະ ເອກະສານປະກອບໄດ້ທີ່   <br/>
 Whatsapp : 020 99 111 558   <br/>
 Mail : Sinthakone.m@ez-service.la   <br/>
♻️ ເງິນເດືອນ ແລະ ສະຫວັດດີການດີ   <br/>
♻️ ເວລາຕິດຕໍ່ສະໝັກງານ 08:30-17:30 (ວັນຈັນ-ວັນເສົາ)   <br/>
----------------------------   <br/>
✔️ມາກ່ອນມີສິດກ່ອນ✔️   <br/>
🏢 AnTech Co., LTD   <br/>
🌐 <a href='https://www.antech.la/' target='_blank'>https://www.antech.la/</a>   <br/>
    `,
    whatsapp: "",
    tel: "",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.57.44+(1).jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.57.44.jpeg",
  },
  {
    id: "9",
    title: "",
    companyName: "ບໍລິສັດ ແວວຄຳ ເຊົ່າສິນເຊື່ອລາວ ",
    compaynyAddress: "",
    vacancy: "ຕ້ອງການຮັບສະໝັກເພື່ອນຮ່ວມງານປະຈຳນະຄອນຫລວງວຽງຈັນ.",
    detail: `
    ບໍລິສັດ ແວວຄຳ ເຊົ່າສິນເຊື່ອລາວ   <br/>
ຕ້ອງການຮັບສະໝັກເພື່ອນຮ່ວມງານປະຈຳນະຄອນຫລວງວຽງຈັນ.   <br/>
📲ສອບຖາມຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີ່ 021 417 400   <br/>
✅ດາວໂຫຼດແບບຟອມໄດ້ທີ່ນີ້ ➡️ <a href='https://tinyurl.com/bebvzxdz' target='_blank'>https://tinyurl.com/bebvzxdz</a>   <br/>
ຫລືສົ່ງຜ່ານອີເມວ: ga.hr.welcomelao@gmail.com   <br/>
    `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    📲ສອບຖາມຂໍ້ມູນເພີ່ມເຕີມໄດ້ທີ່ 021 417 400   <br/>
✅ດາວໂຫຼດແບບຟອມໄດ້ທີ່ນີ້ ➡️ <a href='https://tinyurl.com/bebvzxdz' target='_blank'>https://tinyurl.com/bebvzxdz</a>   <br/>
ຫລືສົ່ງຜ່ານອີເມວ: ga.hr.welcomelao@gmail.com   <br/>
    `,
    whatsapp: "",
    tel: "021 417 400",
    email: "ga.hr.welcomelao@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.57.18.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/WhatsApp+Image+2024-02-28+at+10.57.17.jpeg",
  },
  {
    id: "8",
    title: "",
    companyName: "ບໍລິສັດ ສາມັກຄີ ບໍລິຫານຈັດການ ອະສັງຫາລິມະຊັບ ຈໍາກັດ",
    compaynyAddress: "ຕ້ອງການຮັບສະຫມັກພະນັກງານຕາມລາຍລະອຽດດັ່ງລຸ່ມນີ້",
    vacancy: ".",
    detail: `
    ‼️ບໍລິສັດ ສາມັກຄີ ບໍລິຫານຈັດການ ອະສັງຫາລິມະຊັບ ຈໍາກັດ‼️ <br/>
ຕ້ອງການຮັບສະຫມັກພະນັກງານຕາມລາຍລະອຽດດັ່ງລຸ່ມນີ້ <br/>
<br/>
1. General maneger:(1 ຄົນ) <br/>
- ເງື່ອນໄຂຜູ້ສະໝັກ: <br/>
 *ໄດ້ພາສາອັງກິດ ແລະ ພາສາຈີນ <br/>
 *ມີທັກສະການສື່ສານທີ່ດີ <br/>
 *ມີຄວາມສາມາດການຮຽນຮູ້ໄວ  <br/>
 *ຫາກມີປະສົບການດ້ານອະສັງຫາມາກ່ອນ ຈະພິຈາລະນາເປັນບູລິມະສິດ  <br/>
 <br/>
🟡ໜ້າວຽກ: ບໍລິຫານຈັດການວຽກງານທົ່ວໄປຂອງຕຶກ, ຈັດການເອກະສານ ແລະ ເນື້ອໃນສັນຍາທີ່ເຊັນກັບລູກຄ້າ, ເປັນຕົວກາງດ້ານການປະສານຂອງບັນດາພະແນກ ໃນການແກ້ໄຂວຽກງານຕ່າງໆ.  <br/>
<br/>
- ເງິນເດືອນ: ເລີ່ມຕົ້ນ 1000 ໂດລາ  <br/>
- ມີລາພັກປະຈໍາປີ  <br/>
- ມີເງິນອຸດໜຸນຄ່ານໍາມັນ  <br/>
- ງານລ້ຽງບໍລິສັດໃນເທດສະການຕ່າງໆ.  <br/>
<br/>
2. Administrative Assistant(2 ຄົນ)  <br/>
- ເງື່ອນໄຂຜູ້ສະໝັກ:   <br/>
 *ໄດ້ພາສາອັງກິດ ຫຼື ພາສາຈີນ  <br/>
 *ມີທັກສະການສື່ສານທີ່ດີ  <br/>
 *ມີຄວາມຄິດດ້ານການບໍລິການທີ່ດີ  <br/>
 <br/>
🟡ໜ້າວຽກ: ໃຫ້ການສະໜັບສະໜຸນຂໍ້ມູນຕຶກອາຄານໃຫ້ແກ່ລູກຄ້າ, ຈັດສັນເວລາການປະຊຸມພາຍໃນບໍລິສັດ ແລະ ການປະຊຸມກັບລູກຄ້າ, ບັນທຶກເກັບກໍາເອກະສານຫ້ອງການ ແລະ ໜ້າວຽກອື່ນຂອງແອັດມິນ.  <br/>
- ເງິນເດືອນ: ເລີ່ມຕົ້ນ 500 ໂດລາ  <br/>
- ມີລາພັກປະຈໍາປີ ມີເງິນອຸດໜຸນຄ່ານໍາມັນ  <br/>
- ງານລ້ຽງບໍລິສັດໃນເທດສະການຕ່າງໆ.  <br/>
<br/>
✔️ເອກະສານປະກອບຂອງການສະໝັກ: ຊີວະປະຫວັດ CV ເປັນສະບັບພາສາອັງກິດ ແລະ ພາສາຈີນ.  <br/>
<br/>
📍ສະຖານທີ່ຕັ້ງຢູ່: ບ້ານດອນຕິ້ວ, ເມືອງ ໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ  <br/>
📞ເບີໂທ: 020 5479 2895  <br/>
ອີເມວ: unitedproperties.apply@gmail.com  <br/>
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ✔️ເອກະສານປະກອບຂອງການສະໝັກ: ຊີວະປະຫວັດ CV ເປັນສະບັບພາສາອັງກິດ ແລະ ພາສາຈີນ.   <br/>
    <br/>
📍ສະຖານທີ່ຕັ້ງຢູ່: ບ້ານດອນຕິ້ວ, ເມືອງ ໄຊທານີ, ນະຄອນຫຼວງວຽງຈັນ  <br/>
📞ເບີໂທ: 020 5479 2895  <br/>
ອີເມວ: unitedproperties.apply@gmail.com  <br/>
     `,
    whatsapp: "",
    tel: " 020 5479 2895",
    email: "unitedproperties.apply@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/WhatsApp+Image+2024-03-12+at+09.54.53.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/WhatsApp+Image+2024-03-12+at+09.54.53+(1).jpeg",
  },
  {
    id: "7",
    title: "",
    companyName: "VK CAR PLAZA",
    compaynyAddress: "",
    vacancy: "ຕ້ອງການເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ",
    detail: `
    ‼️ບໍລິສັດ VK CAR PLAZA ‼️ <br/>
ຕ້ອງການເພື່ອນຮ່ວມງານຫຼາຍຕຳແໜ່ງ <br/>
<br/>
 ✔️ສົນໃຈສະໝັກ ຫຼື ສົ່ງປະຫວັດຫຍໍ້ (CV) ມາໄດ້ທີ່: <br/>
 Email: laovkgroup@gmail.com <br/>
WhatsApp: 020 9998 4493 <br/>
📍ສະຖານທີ່ເຮັດວຽກຕັ້ງຢູ່: ບ້ານ ດົງນາທອງ, ເມືອງ ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ <br/>
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ✔️ສົນໃຈສະໝັກ ຫຼື ສົ່ງປະຫວັດຫຍໍ້ (CV) ມາໄດ້ທີ່: <br/>
 Email: laovkgroup@gmail.com <br/>
WhatsApp: 020 9998 4493 <br/>
📍ສະຖານທີ່ເຮັດວຽກຕັ້ງຢູ່: ບ້ານ ດົງນາທອງ, ເມືອງ ສີໂຄດຕະບອງ, ນະຄອນຫຼວງວຽງຈັນ <br/>
  
     `,
    whatsapp: "020 9998 4493",
    tel: "",
    email: "laovkgroup@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/WhatsApp+Image+2024-03-12+at+09.54.54.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/WhatsApp+Image+2024-03-12+at+09.54.54+(1).jpeg",
  },
  {
    id: "6",
    title: "",
    companyName: "Sokxay Group",
    compaynyAddress: "",
    vacancy: "‼️ຕ້ອງການເພື່ອນຮ່ວມງານ",
    detail: `
    ‼️ຕ້ອງການເພື່ອນຮ່ວມງານ ປະຈໍາ 4 ແຂວງ ໄຊຍະບູລີ ບໍລິຄຳໄຊ ນະຄອນຫຼວງ ອຸດົມໄຊ‼️ <br/>
 ✔️ສອບຖາມຂໍ້ມູນເພີ່ມເຕີມ 020 78593333 <br/>
📍ສຳນັກງານຕັ້ງຢູ່ບ້ານ ດອນກອຍ, ເມືອງສີສັດຕະນາກ, ນະຄອນຫລວງວຽງຈັນ <br/>
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
     `,
    whatsapp: "",
    tel: "020 78593333",
    email: "sbs.lotto@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/WhatsApp+Image+2024-03-12+at+09.54.55.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/WhatsApp+Image+2024-03-12+at+09.54.55+(1).jpeg",
  },
  {
    id: "5",
    title: "",
    companyName: "DBOOK",
    compaynyAddress: "",
    vacancy: "‼️ປະກາດຮັບສະໝັກພະນັກງານ‼️",
    detail: `
    ‼️ປະກາດຮັບສະໝັກພະນັກງານ‼️  <br/>
1. ພະນັກງານຫ້ອງການປະຈຳສຳນັກພິມດີບຸກ  <br/>
📍ສະຖານທີ່ ບ້ານພະຂາວ ເມືອງໄຊທານີ ນະຄອນຫລວງວຽງຈັນ  <br/>
✔️ລາຍລະອຽດ ແລະ ຟອມສະໝັກ: <a href="https://forms.gle/t1BiPEN3u1J93wo1A" target="_blank" >https://forms.gle/t1BiPEN3u1J93wo1A</a> <br/>
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
     `,
    whatsapp: "",
    tel: "",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/dbook.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/dbook.1jpeg.jpeg",
  },
  {
    id: "4",
    title: "",
    companyName: "MEX Import & Export",
    compaynyAddress: "",
    vacancy: "‼️ດ່ວນ‼️ຕ້ອງການຮັບສະໝັກພະນັກງານຈຳນວນຫຼາຍ",
    detail: `
    ‼️ດ່ວນ‼️ຕ້ອງການຮັບສະໝັກພະນັກງານຈຳນວນຫຼາຍ ພາຍໃນພະແນກຝ່າຍຂາຍ  <br/>
1. ຜູ້ຈັດການຝ່າຍຂາຍລະດັບເຂດ (ເຂດ A, D)   <br/>
2. ພະນັກງານຂາຍທົ່ວໄປ ຈຳນວນຫຼາຍ (ເຂດ B, C)  <br/>
3. ພະນັກງານຂັບລົດ ຈຳນວນຫຼາຍ (ເຂດ A, B, C, D, E)  <br/>
📍ຈຸດປະຈຳການເຂດການຄ້າ  <br/>
- ເຂດ A - ປະຈຳທີ່ເມືອງໄຊ ແຂວງອຸດົມໄຊ  <br/>
- ເຂດ B - ປະຈຳທີ່ເມືອງຫຼວງພະບາງ ແຂວງຫຼວງພະບາງ  <br/>
- ເຂດ C - ປະຈຳທີ່ເມືອງຈັນທະບູລີ ນະຄອນຫຼວງວຽງຈັນ  <br/>
- ເຂດ D - ປະຈຳທີ່ເມືອງໄກສອນພົມວິຫານ ແຂວງສະຫວັນນະເຂດ  <br/>
- ເຂດ E - ປະຈຳທີ່ເມືອງປາກເຊ ແຂວງຈຳປາສັກ  <br/>
✔️ສະຫວັດຕິການທີ່ຈະໄດ້ຮັບ✔️  <br/>
- ປະກັນສັງຄົມ  <br/>
- ພາຫະນະສຳລັບການຂາຍ  <br/>
- ເງິນຄອມມິດຊັ່ນ ແລະ ເງິນສົ່ງເສີມ  <br/>
- ລາງວັນຕອບແທນສຳລັບພະນັກງານດີເດັ່ນປະຈຳໄຕມາດ ແລະ ປະຈຳປີ  <br/>
- ວັນພັກລາກິດສ່ວນຕົວ, ລາພັກປະຈຳປີ, ລາເຈັບປ່ວຍ, ລາພັກເກີດລູກ  <br/>
- ກວດສຸພາບປະຈຳປີຟຮີ  <br/>
- ເບ້ຍສຳລັບການໄປຕ່າງແຂວງ ແລະ ຕ່າງປະເທດ  <br/>
- ການຊຸກຍູ້ຊ່ວຍເຫຼືອຍາມເຫດສຸກເສີນ  <br/>
- ກອງທຶນສະສົມລ້ຽງຊີບ  <br/>
- ວັນພັກລາກິດສ່ວນຕົວ, ພັກເກີດລູກ, ພັກເຈັບປ່ວຍ.  <br/>
✨ກ່ຽວກັບບໍລິສັດ: bit.ly/mekprofile  <br/>
📌ສົນໃຈສະໝັກ   <br/>
ໂທ ຫຼື Whatsapp: 020-55-531-282  <br/>
Email: recruitment@meklaos.com  <br/>
📌ປິດຮັບສະໝັກວັນທີ 31 ມີນາ 2024📌  <br/>
<br/>
ດາວໂຫລດຟອມສະໝັກໄດ້ທີ່: bit.ly/mekapplication  <br/>
ຫຼື ສາມາດເຂົ້າມາກອກຟອມທີ່ບໍລິສັດ ຕິດຕໍ່ 020-55-531-282  <br/>
 `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    📌ສົນໃຈສະໝັກ   <br/>
ໂທ ຫຼື Whatsapp: 020-55-531-282  <br/>
Email: recruitment@meklaos.com  <br/>
📌ປິດຮັບສະໝັກວັນທີ 31 ມີນາ 2024📌  <br/>
<br/>
ດາວໂຫລດຟອມສະໝັກໄດ້ທີ່: bit.ly/mekapplication  <br/>
ຫຼື ສາມາດເຂົ້າມາກອກຟອມທີ່ບໍລິສັດ ຕິດຕໍ່ 020-55-531-282  <br/>
     `,
    whatsapp: "020-55-531-282",
    tel: "",
    email: "recruitment@meklaos.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/mex.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/mex1.jpeg",
  },
  {
    id: "3",
    title: "",
    companyName: "ບໍລິສັດ ເອັນເອັນຊີ ຈຳກັດ",
    compaynyAddress: "",
    vacancy: "ຮັບສະໝັກພະນັກງານການຕະຫຼາດ",
    detail: `
    ‼️ບໍລິສັດ ເອັນເອັນຊີ ຈຳກັດ‼️ <br/>
ຮັບສະໝັກພະນັກງານການຕະຫຼາດ <br/>
👉1 ໜ້າທີ່ຮັບຜິດຊອບ  <br/>
- ບໍລິຫານສີນຄ້າທີ່ໄດ້ຮັບມອບໝາຍໃຫ້  <br/>
- ຂື້ນແຜນການຕະຫຼາດ ແລະ ຕິດຕາມສະຫຼຸບແຜນ  <br/>
- ຂື້ນແຜນຕິດຕາມການຂາຍ + ຊຸກຍູ້ການຂາຍສີນຄ້າດັ່ງກ່າວ  <br/>
- ເຮັດວຽກພົວພັນກັບເຊວເລື່ອງການຂາຍ ແລະ ຊອກສີນຄ້າໃໝ່  <br/>
- ຕ້ອງໄດ້ພົວພັນກັບເຊວ, ຜູ້ສະໜອງ ແລະ ລູກຄ້າ  <br/>
*ໝາຍເຫດ: ຖ້າໄດ້ພາສາອັງກິດ, ພາສາຫວຽດ ຫຼື ພາສາຈີນ ຈະພິຈາລະນາເປັນພິເສດ <br/>
👉2 ເງື່ອນໄຂ <br/>
- ເພດ: ຍີງ/ຊາຍ <br/>
- ອາຍຸ: 20-35ປີ  <br/>
- ການສືກສາ: ຈົບການຕະຫຼາດ ຫຼື ບໍລິຫານ ຫລື ມີປະສົບການທີ່ກ່ຽວຂ້ອງ  <br/>
- ເປັນຄົນຫ້າວຫັນ, ມີຄວາມຮັບຜິດຊອບຕໍ່ໜ້າທີ່ວຽກງານ, ດຸໝັ່ນ, ອົດທົນ  <br/>
- ເປັນຄົນມະນຸດສຳພັນດີ, ສາມາດເຮັດວຽກເປັນທີມໄດ້ <br/>
 👉3. ຜົນປະໂຫຍດ <br/>
- ໄດ້ ຮັບເງິນເດືອນ ສູງຕາມຄວາມສາມາດ, ມີເງິນໂບນັດ ແຕ່ລະໄຕມາດ  <br/>
- ມີເງິນເດືອນ 13, ມີພັກປະຈຳປີ 15ວັນ <br/>
- ໄດ້ເຂົ້າຮ່ວມບັນດາກິດຈະກຳລວມຂອງບໍລິສັດ, ງານກີລາ, ຝຶກອົບຮົມ <br/>
- ໄດ້ໄປທ່ຽວກັບບໍລິສັດ <br/>
- ໄດ້ເຂົ້າປະກັນສັງຄົມຫລັງຜ່ານທົດລອງ, ໄດ້ໂບນັດ ວັນ ລັດຖະການຕ່າງໆ... <br/>
- ສະພາບແວດລອມເຮັດວຽກດີ, ມ່ວນຊື່ນ ແລະ ໄດ້ພັດທະນາເລື່ອຍໆ <br/>
👉4. ເອກະສານປະກອບມີ: <br/>
- ໃບສະໝັກວຽກ (CV) <br/>
- ສຳເນົາບັດປະຈຳຕົວ / ສຳມະໂນຄົວ (ກ໋ອບປີ) <br/>
- ໃບຢັ້ງຢືນທີ່ຢູ່ <br/>
- ໃບຄະແນນ, ປະກາດ <br/>
🌿 ກຳນົດສົ່ງເອກະສານຮອດ 22/03/2024 <br/>
✔️ສາມາດສົ່ງເອກະສານຜ່ານທາງອີເມວ ຫຼື ສົ່ງໄດ້ໂດຍຕົງທີ່ບໍລິສັດ. ຂໍ້ມູນຕິດຕໍ່: <br/>
🏢 ບໍລິສັດ NNC Pharma Co., Ltd <br/>
📍 ບ້ານໂຊກຄຳ, ເມືອງໄຊເສດຖາ, ນະຄອນຫລວງວຽງຈັນ <br/>
📩 Email: nncnhansu2020@gmail.com <br/>
☎️ ຕິດຕໍ່: Ms Oanh (ແອັວງ): ￼⁨020 52 152 999 <br/>
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ✔️ສາມາດສົ່ງເອກະສານຜ່ານທາງອີເມວ ຫຼື ສົ່ງໄດ້ໂດຍຕົງທີ່ບໍລິສັດ. ຂໍ້ມູນຕິດຕໍ່: <br/>
🏢 ບໍລິສັດ NNC Pharma Co., Ltd <br/>
📍 ບ້ານໂຊກຄຳ, ເມືອງໄຊເສດຖາ, ນະຄອນຫລວງວຽງຈັນ <br/>
📩 Email: nncnhansu2020@gmail.com <br/>
☎️ ຕິດຕໍ່: Ms Oanh (ແອັວງ): ￼⁨020 52 152 999 <br/>
   
     `,
    whatsapp: "",
    tel: "020 52 152 999",
    email: "nncnhansu2020@gmail.com",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/nnc.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/nnc1.jpeg",
  },
  {
    id: "2",
    title: "",
    companyName: "ບໍລິສັດ ທະນາ ກຣຸບ",
    compaynyAddress: "",
    vacancy: "ປະກາດຮັບສະຫມັກພະນັກງານຫລາຍຕຳແໜ່ງ",
    detail: `
    ‼️ບໍລິສັດ ທະນາ ກຣຸບ‼️ <br/>
    ປະກາດຮັບສະຫມັກພະນັກງານຫລາຍຕຳແໜ່ງ <br/>
    📍ປະຈໍາສາຂາປາກເຊ 💢ດ່ວນ💢 <br/>
    ▶️ຜູ້ຈັດການ               1 ຕຳແໜ່ງ <br/>
    ▶️ຝ່າຍບຸກຄະລາກອນ  2 ຕຳແໜ່ງ <br/>
    ▶️ຝ່າຍຂາຍ                2 ຕຳແໜ່ງ <br/>
    ▶️ຝ່າຍບັນຊີ              2 ຕຳແໜ່ງ <br/>
    ▶️ຂັບລົດ                  2 ຕຳແໜ່ງ <br/>
    ▶️ຄັງສິນຄ້າ               2 ຕຳແໜ່ງ <br/>
    ▶️ຂື້ນລົງສິນຄ້າ          3 ຕຳແໜ່ງ <br/>
    <br/>
    📍ປະຈໍາສາຂານະຄອນຫຼວງວຽງຈັນ💢ດ່ວນ💢 <br/>
    ▶️ຜູ້ຈັດການສາຂາ                   1 ຕຳແໜ່ງ <br/>
    ▶️ພະນັກງານຂັບລົດ 10 ລໍ້        2 ຕຳແໜ່ງ <br/>
    ▶️ພະນັກງານຄລັງສີນຄ້າ           2 ຕຳແໜ່ງ <br/>
    ▶️ພະນັກງານການຕະຫລາດ      2 ຕຳແໜ່ງ <br/>
    ▶️ພະນັກງານຂາຍ                  1 ຕຳແໜ່ງ <br/>
    ▶️ພະນັກງານບັນຊີ                 1 ຕຳແໜ່ງ <br/>
    <br/>
    📍ປະຈໍາສາຂາສະຫວັນນະເຂດ💢ດ່ວນ💢 <br/>
    ▶️ຜູ້ຈັດການສາຂາ                   1 ຕຳແໜ່ງ <br/>
    ▶️ພະນັກງານການຕະຫລາດ      1 ຕຳແໜ່ງ <br/>
    ▶️ພະນັກງານຄລັງສີນຄ້າ           1 ຕຳແໜ່ງ <br/>
    <br/>
    ✨ເອກະສານປະກອບມີ <br/>
    - ສຳມະໂນຄົວ ຫຼື ບັດປະຈຳຕົວ <br/>
    - ໃບຢັ້ງຢືນທີຢູ່ <br/>
    - ໃບກວດສຸຂະພາບ <br/>
    - ໃບປະກາດ <br/>
    - ໃບຄະແນນ <br/>
    - ໃບຜ່ານງານ ( ຖ້າມີ ) <br/>
    - ຮູບ3×4= 2 ໃບ <br/>
    <br/>
    ✅️ບໍ່ມີປະສົບການກໍສາມາດເຮັດໄດ້ເດີ <br/>
    ✅️ບ່ອນເຮັດວຽກບ້ານ ຕານມີໄຊ -ຫນອງພະຍາ <br/>
    ✅️ມີບ່ອນພັກໃຫ້ພ້ອມ <br/>
    ✅️ມື້ເຮັດວຽກວັນຈັນ- ເສົາ <br/>
    ✅️ເວລາເຮັດວຽກ8:00 - 5:00 ໂມງ <br/>
    ✅️ສົນໃຈສອບຖາມຂໍມູນທີ່ເບີ 020 94288873, 020 95568605 <br/>
         
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
    ✨ເອກະສານປະກອບມີ <br/>
    - ສຳມະໂນຄົວ ຫຼື ບັດປະຈຳຕົວ <br/>
    - ໃບຢັ້ງຢືນທີຢູ່ <br/>
    - ໃບກວດສຸຂະພາບ <br/>
    - ໃບປະກາດ <br/>
    - ໃບຄະແນນ <br/>
    - ໃບຜ່ານງານ ( ຖ້າມີ ) <br/>
    - ຮູບ3×4= 2 ໃບ <br/>
    <br/>
    ✅️ບໍ່ມີປະສົບການກໍສາມາດເຮັດໄດ້ເດີ <br/>
    ✅️ບ່ອນເຮັດວຽກບ້ານ ຕານມີໄຊ -ຫນອງພະຍາ <br/>
    ✅️ມີບ່ອນພັກໃຫ້ພ້ອມ <br/>
    ✅️ມື້ເຮັດວຽກວັນຈັນ- ເສົາ <br/>
    ✅️ເວລາເຮັດວຽກ8:00 - 5:00 ໂມງ <br/>
    ✅️ສົນໃຈສອບຖາມຂໍມູນທີ່ເບີ 020 94288873, 020 95568605 <br/>
  `,
    whatsapp: "20 55198458",
    tel: "020 94288873, 020 95568605",
    email: "hr@lth.com.la",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/tlt.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/lth1.jpeg",
  },
  {
    id: "1",
    title: "",
    companyName: "ບໍລິສັດ ສົມໃຈນຶກ ເທຣດດິ້ງ ແອນ ລອຕເຕີຣີ ຈຳກັດ",
    compaynyAddress: "",
    vacancy: "🔊ປະກາດຮັບພເພື່ອນຮ່ວມງານ",
    detail: `
    ‼️ບໍລິສັດ ສົມໃຈນຶກ ເທຣດດິ້ງ ແອນ ລອຕເຕີຣີ ຈຳກັດ‼️
🔊ປະກາດຮັບພເພື່ອນຮ່ວມງານ
✅ ພະນັກງານ ວິຊາການ ການຕະຫຼາດ 2 ຕໍາແໜ່ງ
✔️ປະຈໍາ: ສໍານັກງານໃຫຍ່ (ນະຄອນຫຼວງ)
📍ສຳນັກງານຕັ້ງຢູ່: ບ້ານດອນກອຍ, ເມືອງສີສັດຕະນາກ, ນະຄອນຫລວງວຽງຈັນ
☎️ ສອບຖາມຂໍ້ມູນໂທ 020 9922 0898, 9433 9286
     `,
    skill: `
      ບໍ່ມີຂໍ້ມູນ
      `,
    howTo: `
     `,
    whatsapp: "",
    tel: "020 9922 0898, 9433 9286",
    email: "",
    logo: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/%E0%BA%AB%E0%BA%A7%E0%BA%8D.jpeg",
    img: "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/Tualor/images/slms_student_noul/13%3A3%3A2024/%E0%BA%AB%E0%BA%A7%E0%BA%8D1.jpeg",
  },

];
