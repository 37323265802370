import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import useReactRouter from "use-react-router";
import _ from "lodash";
import { MobileHeader } from "../../common";
// component ----------------------------------------------------->
import CourseListBrowserView from "./CourseListBrowserView";
import CourseListMobileView from "./CourseListMobileView";
const CourseList = () => {
  const { history, location } = useReactRouter();
  return (
    <div>
      <BrowserView>
        <CourseListBrowserView />
      </BrowserView>
      <MobileView>
        <CourseListMobileView />
      </MobileView>
    </div>
  );
};

export default CourseList;
