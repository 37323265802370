import React, { useState, useCallback } from "react";
import { Breadcrumb } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Consts from "../../consts";
import { CustomContainer, SearchBar, Title, CustomButton } from "../../common";

function CourseDetail() {
  const { history, location, match } = useReactRouter();

  // States
  const [showDeleteConfirmView, setShowDeleteConfirmView] = useState(false);

  // Set states
  const _handleDeleteConfirmViewClose = () => setShowDeleteConfirmView(false);
  const _handleDeleteConfirmViewShow = () => setShowDeleteConfirmView(true);

  const _viewDoc = () => {
    history.push("/course-doc-list");
  };

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/timetable-list")}>
          ວິຊາທີ່ຮຽນ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍລະອຽດວິຊາ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer></CustomContainer>
    </div>
  );
}

export default CourseDetail;
