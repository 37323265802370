import React from "react";
import "./Research.css";
import moment from "moment";

const RecentCard = ({ item }) => {
  return (
    <>
      <div
        id="productCard"
        className="shadow-sm mx-2"
        style={{ width: "250px" }}
      >
        <div id="img">
          <img className="" src={item.image} alt="" />
        </div>
        <div id="text">
          <div className="text-truncate">{item.title}</div>
          <div className="text-truncate">ແຕ່ງໂດຍ:&nbsp;{item.author}</div>
          <div className="text-truncate">
            ອັບເດດ:
            <span className="tex-truncate">
              &nbsp;{moment(item.createdAt).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentCard;
