/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Consts from "../../consts";
import { MobileHeader } from "../../common";
import useReactRouter from "use-react-router";

export default function NotificationDetail() {
  const { history } = useReactRouter();

  return (
    <div>
      <MobileHeader
        showLeftArrow
        leftButtonClick={() => history.goBack()}
        title="ລາຍລະອຽດການແຈ້ງເຕືອນ"
      />
      <div style={{ marginTop: 70, backgroundColor: "white", height: "100hv" }}>
        <img alt="" style={{ width: "100%" }} src="/assets/covid19.jpeg" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 20,
          }}
        >
          <a style={{ fontSize: 14 }}>ສ້າງໂດຍ: ວິຊາການ ມຊ</a>
        </div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bolder",
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 10,
          }}
        ></div>
        <div
          style={{
            fontSize: 14,
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 10,
          }}
        >
          <a
            href="#"
            style={{
              color: Consts.PRIMARY_COLOR,
              textDecoration: "underline",
              fontSize: 14,
            }}
            //   onClick={() => setShowDetail(!showDetail)}
          >
            ເບີ່ງລາຍລະອຽດ
          </a>
          <div>01 ຕຸລາ 2021, 08:08</div>
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 14,
          }}
        >
          ມື້ຜ່ານມາໄດ້ກວດວິເຄາະທັງໝົດ 6,337 ຕົວຢ່າງ.
          <br />
          <br />
          <br />
          (1). ຜົນການວິໄຈພົບຜູ້ຕິດເຊື້ອ COVID-19 ຈຳນວນ ( + 385 ) ກໍລະນີ, “( + 21
          ) ກໍລະນີນຳເຂົ້າ ທີ່ພັກຢູ່ສູນກັກກັນຖືກຕ້ອງຕາມກົດໝາຍ ແລະ ( + 364 )
          ກໍລະນີຊຸມຊົນທີ່ໄດ້ມີການສຳພັດກັບຜູ້ຕິດເຊື້ອຜ່ານມາ” ແລະ
          ລວມສະຖິຕິຜູ້ຕິດເຊື້ອທັງໝົດ ( 23,846 ) ກໍລະນີ:
          <br />
          <br /> 1. ນະຄອນຫຼວງວຽງຈັນ (+ ) ກໍລະນີ, “( + 00 ) ກໍລະນີນຳເຂົ້າ
          ທີ່ພັກຢູ່ສູນກັກກັນຖືກຕ້ອງຕາມກົດໝາຍ ແລະ ( + 128 )
          ກໍລະນີຊຸມຊົນທີ່ໄດ້ມີການສຳພັດກັບຜູ້ຕິດເຊື້ອຜ່ານມາ,
          ຈາກຈຳນວນການວິເຄາະທັງໝົດ 1,731 ຕົວຢ່າງ,
          <br />
          <br />
          2. ແຂວງບໍແກ້ວ ( + 14 ) ກໍລະນີຜູ້ຕິດເຊື້ອຊຸມຊົນຈາກ “(+ 06 ) ເຂດພິເສດ
          ແລະ (+ 08 ) ເມືອງຕົ້ນເຜີ້ງ”,
          <br />
          <br />
          3. ແຂວງຫຼວງພະບາງ ( + 71 )
          ກໍລະນີຊຸມຊົນທີ່ໄດ້ມີການສຳພັດກັບຜູ້ຕິດເຊື້ອຜ່ານມາ;
          <br />
          <br /> 4. ແຂວງວຽງຈັນ ( + 24 )
          ກໍລະນີຊູມຊົນທີ່ໄກ້ສິດຕິດກັບຜູ້ຕິດເຊື້ອຜ່ານມາ;
          <br />
          <br />
          5. ແຂວງໄຊສົມບູນ ( + 16 ) ກໍລະນີຊູມຊົນທີ່ໄກ້ສິດຕິດກັບຜູ້ຕິດເຊື້ອຜ່ານມາ;
          <br />
          <br />
          6. ແຂວງບໍລິຄຳໄຊ ( + 18 ) ກໍລະນີ, “(+ 03 ) ກໍລະນີນຳເຂົ້າ
          ທີ່ພັກຢູ່ສູນກັກກັນຖືກຕ້ອງຕາມກົດໝາຍ ແລະ ( + 15 )
          ກໍລະນີຊູມຊົນທີ່ໄກ້ສິດຕິດກັບຜູ້ຕິດເຊື້ອຜ່ານມາ”;
          <br />
          <br />
          7. ແຂວງຄຳມ່ວນ ( + 11 ) ກໍລະນີ, “(+ 00 ) ກໍລະນີນຳເຂົ້າ
          ທີ່ພັກຢູ່ສູນກັກກັນຖືກຕ້ອງຕາມກົດໝາຍ ແລະ ( + 11 )
          ກໍລະນີຊູມຊົນທີ່ໄກ້ສິດຕິດກັບຜູ້ຕິດເຊື້ອຜ່ານມາ”,
          <br />
          <br />
          8. ແຂວງສາລະວັນ (+ 15 ) ກໍລະນີ, “ (+ 15 ) ກໍລະນີນຳເຂົ້າ
          ທີ່ພັກຢູ່ສູນກັກກັນຖືກຕ້ອງຕາມກົດໝາຍ ແລະ ( + 00 )
          ກໍລະນີຊູມຊົນທີ່ໄກ້ສິດຕິດກັບຜູ້ຕິດເຊື້ອຜ່ານມາ”,
          <br />
          <br />
          9. ແຂວງສະຫວັນນະເຂດ ( + 38 ) ກໍລະນີ, “(+ 03 ) ກໍລະນີນຳເຂົ້າ
          ທີ່ພັກຢູ່ສູນກັກກັນຖືກຕ້ອງຕາມກົດໝາຍ ແລະ ( + 35 )
          ກໍລະນີຊູມຊົນທີ່ໄກ້ສິດຕິດກັບຜູ້ຕິດເຊື້ອກໍລະນີຜ່ານມາ,
          ຈາກຈຳນວນການວິເຄາະທັງໝົດ 285 ຕົວຢ່າງ,
          <br />
          <br />
          10. ແຂວງຈຳປາສັກ ( + 20 ) ກໍລະນີ, “( + 00 ) ກໍລະນີນຳເຂົ້າ
          ທີ່ພັກຢູ່ສູນກັກກັນຖືກຕ້ອງຕາມກົດໝາຍ ແລະ ( + 20 )
          ກໍລະນີຊູມຊົນສ່ວນໃຫຍ່ທີ່ໄກ້ສິດຕິດກັບຜູ້ຕິດເຊື້ອກໍລະນີຜ່ານມາ,
          ຈາກຈຳນວນການວິເຄາະທັງໝົດ 301 ຕົວຢ່າງ,
          <br />
          <br />
          11. ແຂວງອັດຕະປື ( + 03 ) ກໍລະນີຊູມຊົນທີ່ໄກ້ສິດຕິດກັບຜູ້ຕິດເຊື້ອຜ່ານມາ,
          (2). ລວມຍອດຜູ້ທີ່ຍັງຮັບການປິ່ນປົວ ( ) ກໍລະນີ; (3). ປິ່ນປົວຫາຍດີວັນນີ້
          ( - ) ກໍລະນີ ແລະ ລວມສະຖິຕິຜູ້ທີ່ປິ່ນປົວຫາຍດີທັງໝົດ ແລະ
          ອອກຈາກໂຮງໝໍແລ້ວຈຳນວນ ( - 18,075 ) ກໍລະນີ; (4). ເສຍຊີວິດ ( + 01 )
          ກໍລະນີ (ໃໝ່) ແລະ ລວມສະຖິຕິຈຳນວນຜູ້ເສັຍຊີວິດ ( 18 ) ກໍລະນີ; (5).
          ລາຍລະອຽດຜູ້ຕິດເຊື້ອຢູ່ໃນຊຸມຊົນ ໃນນະຄອນຫຼວງ ແລະ ບັນດາແຂວງ
          ມີລາຍລະອຽດດັ່ງນີ້:
          https://web.facebook.com/photo/?fbid=1653128024896916&set=pcb.1653128078230244
          https://web.facebook.com/photo?fbid=1653127758230276&set=pcb.1653128078230244
          ແຫຼ່ງທີ່ມາ: https://web.facebook.com/sourioudong.sundara
        </div>
      </div>
    </div>
  );
}
