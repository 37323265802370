import { gql } from "apollo-boost";

export const FILE_BY_KEYWORD = gql`
  query FileByKeyword($keyword: String, $createdName: String, $startDate: String, $endDate: String) {
    fileByKeyword(keyword: $keyword, createdName: $createdName, startDate: $startDate, endDate: $endDate) {
      id
      title
      description
      file
      keyword
      type
      cate
      createdAt
      updatedAt
      createdBy{
        role
        firstname
        lastname
      }
      updatedBy{
        role
        firstname
        lastname
      }
    }
  }
`;
