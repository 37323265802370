import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import { Dialog } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
function Profile({ showUserProfile, _handleShowUserProfile, data }) {
  const PL = 30;
  return (
    <div>
      <Dialog
        onClose={_handleShowUserProfile}
        aria-labelledby="customized-dialog-title"
        open={showUserProfile}
        fullWidth="md"
      >
        <MuiDialogTitle id="customized-dialog-title"
          style={{ height: 50 }}
          onClose={_handleShowUserProfile}>
          <h4 style={{ fontWeight: "bold", textAlign: 'center' }}>
            ຂໍ້ມູນຜູ້ໃຊ້
          </h4>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Row>
            <Col sm="4" style={{ fontSize: 16, fontWeight: "bold" }}>
              <i color="#057CAE" class="fas fa-sort-down"></i> ຂໍ້ມູນຜູ້ໃຊ້
            </Col>
            <Col sm="8"></Col>
          </Row>

          <Row style={{ paddingLeft: PL }}>
            <Col sm="4">ຊື່</Col>
            <Col sm="8">{data?.student?.firstNameL ?? "-"}</Col>
          </Row>
          <Row as={Row} style={{ paddingLeft: PL }}>
            <Col sm="4">ນາມສະກຸນ</Col>
            <Col sm="8">{data?.student?.lastNameL ?? "-"}</Col>
          </Row>

          <Row as={Row} style={{ paddingLeft: PL }}>
            <Col sm="4">ເບີໂທ</Col>
            <Col sm="8">{data?.student?.phoneMobile ?? "-"}</Col>
          </Row>
          <Row as={Row} style={{ paddingLeft: PL }}>
            <Col sm="4">ອີເມວ</Col>
            <Col sm="8">{data?.student?.email ?? "-"}</Col>
          </Row>

          <Row as={Row}>
            <Col sm="4" style={{ fontSize: 16, fontWeight: "bold" }}>
              <i color="#057CAE" className="fas fa-sort-down"></i> ໄອດີ ແລະ
              ລະຫັດຜ່ານ
            </Col>
            <Col sm="8"></Col>
          </Row>
          <Row as={Row} style={{ paddingLeft: PL }}>
            <Col sm="4">ໄອດີ</Col>
            <Col sm="8">{data?.student?.userId ?? "-"}</Col>
          </Row>
          <Row as={Row} style={{ paddingLeft: PL }}>
            <Col sm="4">ລະຫັດຜ່ານ</Col>
            <Col sm="8">******</Col>
          </Row>
        </MuiDialogContent>
        <MuiDialogActions>
          <div style={{ textAlign: "center", width: "100%" }}>
            <Button
              className="btn-exit"
              variant="outline-secondary"
              onClick={_handleShowUserProfile}
            >
              ອອກ
            </Button>
          </div>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
}

Profile.protoTypes = {
  data: PropTypes.object.isRequired,
};
export default Profile;
