import React, { useEffect, useState } from "react";
import { API_KEY, NEW_API_URL } from "../../common/constraint";
import axios from "axios";
import { MobileHeader } from "../../common";
import { USER_KEY } from "../../consts/user";
import useReactRouter from "use-react-router";

export const CourseDetailV2 = () => {
  const [studentId, setStudemtId] = useState("");
  const { history, match } = useReactRouter();
  const { courseId } = match.params;
  const [courseDetail, setCourseDetail] = useState();

  useEffect(() => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);
    setStudemtId(userObject?.studentData?.id);
  }, [USER_KEY]);
  const fetchCourseDetail = async () => {
    if (courseId && studentId) {
      await axios
        .get(NEW_API_URL + "coursedetail/" + courseId, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((res) => {
          setCourseDetail(res.data);
        })
        .catch((err) => {
          setCourseDetail();
        });
    }
  };

  useEffect(() => {
    fetchCourseDetail();
  }, [courseId, studentId]);

  return (
    <div className="mx-0 px-0 mt-5 pt-5 bg-white w-100">
      <MobileHeader
        title="ລາຍລະອຽດວິຊາ"
        showLeftArrow
        leftButtonClick={() => history.push("/timetable-list")}
      />
      <div className="row px-2">
        <div className="col text-start">
          <h5> {"ລະຫັດວິຊາ : " + courseDetail?.courseCode}</h5>
        </div>
        <div className="col text-start">
          <h5> {"ຊື່ວິຊາ : " + courseDetail?.title}</h5>
        </div>
      </div>
      <div className="row px-2">
        <div className="col text-start">
          <h5> {"ລາຍລະອຽດ : " + courseDetail?.description}</h5>
        </div>
        <div className="col text-start">
          <h5> {"ສະຖານທິຮຽນ : " + courseDetail?.addressLearn}</h5>
        </div>
      </div>
      <div className="row px-2">
        <div className="col text-start">
          <h5>
            {"ອາຈານສອນ : " +
              courseDetail?.teacher[0]?.firstname +
              " " +
              courseDetail?.teacher[0]?.lastname}
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-12 my-2 text-center">
          <h4>ລາຍການເວລາຮຽນ</h4>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ overflowX: "auto", width: "100%" }}>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead className="app-bg text-white">
              <td className="px-3 py-2 text-center">
                <h6>ຊົ່ວໂມງ</h6>
              </td>
              <td className="px-3 py-2 text-center">
                <h6>ວັນຮຽນ</h6>
              </td>
              <td className="px-3 py-2 text-center">
                <h6>ເວລາຮຽນ</h6>
              </td>
              <td className="px-3 py-2 text-center">
                <h6>ອາທິດຮຽນ</h6>
              </td>
            </thead>
            <tbody>
              {courseDetail &&
                courseDetail.timesStudy.map((item, index) => {
                  let week = "";
                  if (item?.oddWeek) {
                    week = "ອາທິດຄີກ";
                  }
                  if (item?.evenWeek) {
                    week = "ອາທິດຄູ່";
                  }
                  if (item?.allWeek) {
                    week = "ທຸກອາທິດ";
                  }

                  let times = "";

                  item.times.map((time) => {
                    times += time?.time;
                  });

                  return (
                    <>
                      {item.times.map((i) => (
                        <tr>
                          <td className="text-center py-2 px-3">
                            <p>{i?.hour}</p>
                          </td>
                          <td className="text-center py-2 px-3">
                            <p>{item?.day || ""}</p>
                          </td>
                          <td className="text-center py-2 px-3">
                            <p>{i?.time}</p>
                          </td>
                          <td className="text-center py-2 px-3">
                            <p>{week}</p>
                          </td>
                        </tr>
                      ))}
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CourseDetailV2;
