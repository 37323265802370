import React, { useState } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import { useQuery } from "@apollo/react-hooks";
// component ------------------------------------------>
import { CustomButton } from "../../common";

// constance ------------------------------------------>
import { GET_FACULTIES } from "../../apollo/faculty";
import { GET_DEPARTMENTS } from "../../apollo/department";
const SearchModal = ({ showSearchView, _handleSearchViewClose, onSearch }) => {
  // states -------------------------------------------------------------->
  const [faculty, setFaculty] = useState("");
  const [whereDepartment, setWhereDepartment] = useState("");
  // apollo -------------------------------------------------------------->
  const { loading: loadingFaculties, data: faculties } = useQuery(
    GET_FACULTIES
  );
  const { loading: loadingDepartments, data: departments } = useQuery(
    GET_DEPARTMENTS,
    {
      variables: { where: { faculty: whereDepartment } },
    }
  );

  React.useEffect(() => {
    if (faculty) {
      setWhereDepartment(faculty);
    }
  }, [faculty]);
  return (
    <Formik
      initialValues={{
        faculty: null,
        department: null,
        year: null,
        courseCode: null,
      }}
      validate={(values) => {
        const errors = {};
        setFaculty(values.faculty);
        return errors;
      }}
      onSubmit={(values) => {
        if (values.faculty === "ກະລຸນາເລືອກ") {
          values.faculty = null;
        }
        if (values.department === "ກະລຸນາເລືອກ") {
          values.department = null;
        }
        if (values.year === "ກະລຸນາເລືອກ") {
          values.year = null;
        }
        onSearch(values);
        _handleSearchViewClose();
        values.year = null;
        values.department = null;
        values.faculty = null;
        values.courseCode = null;
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Modal show={showSearchView} onHide={_handleSearchViewClose} size="lg">
          <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
            ຄົ້ນຫາວິຊາຮຽນ
          </Modal.Title>

          <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
            <div style={{ border: "1px solid #eee", padding: 20 }}>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="2">
                  ຄະນະ
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="select"
                    name="faculty"
                    value={values.faculty}
                    onChange={handleChange}
                  >
                    <option value={null}>
                      {loadingFaculties ? "ກຳລັງໂຫລດ..." : "ກະລຸນາເລືອກ"}
                    </option>
                    {faculties &&
                      faculties.faculties &&
                      faculties.faculties.map((faculty, index) => (
                        <option key={index}>{faculty.name}</option>
                      ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="2">
                  ສາຂາວິຊາ
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="select"
                    name="department"
                    value={values.department}
                    onChange={handleChange}
                  >
                    <option value={null}>
                      {loadingDepartments ? "ກຳລັງໂຫລດ..." : "ກະລຸນາເລືອກ"}
                    </option>
                    {departments &&
                      departments.departments &&
                      departments.departments.map((department, index) => (
                        <option key={index}>{department.name}</option>
                      ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="2">
                  ປີ
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="select"
                    name="year"
                    value={values.year}
                    onChange={handleChange}
                  >
                    <option value={null}>ກະລຸນາເລືອກ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <hr className="mt-2 mb-3" />
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="2">
                  ລະຫັດວິຊາ
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    name="courseCode"
                    value={values.courseCode}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </div>
            <div style={{ height: 20 }} />
            <div className="row">
              <div style={{ padding: 15 }} className="col">
                <CustomButton
                  confirm
                  onClick={handleSubmit}
                  width="100%"
                  title="ຄົ້ນຫາ"
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default SearchModal;
