import React, { useState, useEffect } from "react";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/constraint";
import { MobileHeader } from "../../common/MobileHeader";
import useReactRouter from "use-react-router";
import { Typography } from "@material-ui/core";
import { USER_KEY } from "../../consts/user";

function RegisteredCourseList() {
  const [courseList, setCourseList] = useState([]);
  const { history } = useReactRouter();
  const user = JSON.parse(localStorage.getItem(USER_KEY));

  const fetchCourses = async () => {
    try {
      const response = await axios.get(
        NEW_API_URL + "course/registered?studentId=" + user?.studentData?.id,
        {
          headers: {
            api_key: API_KEY,
          },
        }
      );
      setCourseList(response?.data || []);
    } catch (err) {
      console.error("Error fetching courses:", err);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const navigateToCourseFiles = (courseId) => {
    history.push(`/course/files/${courseId}`);
  };
  const navigateToCourseDetail = (courseId) => {
    history.push(`/course/${courseId}`);
  };

  useEffect(() => {
    console.log("courseList");
    console.log(courseList);
  }, [courseList]);
  return (
    <div>
      <MobileHeader
        title="ວິຊາທີ່ລົງທະບຽນທັງໝົດ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <div style={{ marginTop: "80px" }}>
        {courseList.map((course) => (
          <div className="item" key={course?._id}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              {course?.title}
            </Typography>
            <div className="row">
              <div className="col-4 mt-2 pt-1">
                <Typography>{course?.courseCode}</Typography>
              </div>
              <div className="col-8 d-flex justify-content-end">
                {" "}
                {/* Modified this line */}
                <div
                  onClick={() => navigateToCourseFiles(course?._id)}
                  className="icon-bt"
                >
                  <img
                    width="25"
                    height="25"
                    src="https://img.icons8.com/sf-ultralight/25/file.png"
                    alt="file"
                  />{" "}
                  <Typography
                    style={{
                      color: "#4895ef",
                    }}
                  >
                    ເອກພສານບົດສອນ
                  </Typography>
                </div>
                <div
                  onClick={() => {
                    navigateToCourseDetail(course?._id);
                  }}
                  className="icon-bt"
                >
                  <img
                    style={{ marginRight: "10px" }}
                    width="24"
                    height="24"
                    src="https://img.icons8.com/forma-light/24/info.png"
                    alt="info"
                  />
                  <Typography
                    style={{
                      color: "#4895ef",
                    }}
                  >
                    ລາຍລະອຽດວິຊາ
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RegisteredCourseList;
