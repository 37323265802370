import React from "react";
import useReactRouter from "use-react-router";
import Loading from "../../common/Loading";

import { Breadcrumb } from "react-bootstrap";
import { useQuery } from "@apollo/react-hooks";
import Empty from "../../common/Empty";


import { CustomContainer, CustomButton, TableHeader } from "../../common";
import "./index.css";
import { GRADES } from "./../../apollo/grade";
import { USER_KEY } from "../../consts/user";
import { formatDateTime, upgradeStatus } from "./../../super";

const UpgradeBrowserView = () => {
  /**
   * routes
   */
  const { history } = useReactRouter();

  const user = JSON.parse(localStorage.getItem(USER_KEY));
  // console.log("user::::::::::::::::::::::::", user.studentData.id);
  const { data, error, loading } = useQuery(GRADES, {
    variables: {
      where: {
        // student: user && user.studentData && user.studentData.id,
        student: user && user.studentData && user.studentData.userId,
        numberTest: 2,
      },
    },
  });

  const _registerToUpgrade = () => {
    history.push("/register-to-upgrade");
  };
  if (data) {
    // console.log("data:", data);
  }
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item> ຈັດການຄະແນນ</Breadcrumb.Item>
        <Breadcrumb.Item active>ຂໍ້ມູນການລົງທະບຽນອັບເກຣດ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <h3>ຂໍ້ມູນການລົງທະບຽນອັບເກຣດ</h3>
        <div style={{ float: "right", marginBottom: 10 }}>
          <CustomButton
            title="ເພີ່ມລາຍ​​ການ​ອັບ​ເກ​ຣດ"
            addIcon
            onClick={() => _registerToUpgrade()}
            confirm
            width={160}
          />
        </div>

        <div>
          <table
            border="1"
            bordercolor="#fff"
            style={{
              width: "100%",
              marginTop: 20,
              border: "1px solid #E4E4E4",
            }}
            className="table"
          >
            <thead>
              <TableHeader>
                <th>ລຳດັບ</th>
                <th>ຊື່ວິຊາທີ່ແກ້ເກຣດ</th>
                <th>ພາກຮຽນ</th>
                <th>ປີຮຽນ</th>
                <th>​ວັນ​ທີ່​ເວ​ລາ​ລົງ​ທະ​ບຽນ</th>
                <th>ສະ​ຖາ​ນະ</th>
              </TableHeader>
            </thead>
            <tbody>
              {loading ? (
                <Loading />
              ) : (
                data?.grades?.data?.map((grade, index) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <td>{index + 1}</td>
                      <td>{grade?.course?.title ?? "-"}</td>

                      <td>ພາກຮຽນ {grade?.semester ?? "-"}</td>
                      <td>ປີຮຽນ {grade?.yearLevel ?? "-"}</td>
                      <td>{formatDateTime(grade?.createdAt)}</td>
                      <td>
                        <p
                          style={{
                            color:
                              grade?.gredeStatus === "REQUESTING"
                                ? "#F29702"
                                : grade?.gredeStatus === "APROVED" ||
                                  grade?.gredeStatus === "FINISHED"
                                  ? "lightgreen"
                                  : "red",
                          }}
                        >
                          {grade?.gredeStatus
                            ? upgradeStatus(grade?.gredeStatus)
                            : "-"}
                        </p>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          {
            !data?.grades?.data ?? <Empty/>
          }
        </div>
      </CustomContainer>
    </div>
  );
};

export default UpgradeBrowserView;
