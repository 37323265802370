import React from "react";
import useReactRouter from "use-react-router";

export const PolicyPage = () => {
  const { history } = useReactRouter();

  return (
    <div className="container mt-3 px-5">
      <button
        onClick={() => {
          history.push("/");
        }}
        className="fbt newbt"
      >
        Back
      </button>
      <div className="col text-center w-100">
        <h5>Privacy Policy</h5>
      </div>
      <div className="row">
        <h5>I. Introduction</h5>
      </div>
      <div className="row">
        <div>
          <iframe
            src="https://student.nuol.edu.la/lubniagsystememerald"
            title="Embedded Content"
          ></iframe>
        </div>
      </div>
      <div className="row">
        <span>
          Lao Development Hub and Consulting Sole Co. Ltd or abbreviated as
          ‘LaoDevHub’ owns the cloud-based application called ‘Kintone® ‘. The
          company is an expert in the development of applications,
          communication, information sharing, automation, modernization,
          digitization, and connectivity with other systems. In addition, our
          company also provides various services such as IT consulting,
          education, investment and distribution of IT equipment.
        </span>
      </div>
      <div className="mt-1 row">
        <span>
          Our company has cooperated with various public universities and
          educational institutions namely: the National University of Laos
          (NUOL); Savannakhet University (SKU); Champassak University (CU); and
          the Academy of Accounting and Finance (AFA) of the Ministry of Finance
          to develop the education system, such as transforming it into a modern
          teaching-learning management system (the so called: ‘Smart Learning
          Management System’ or ‘SLMS’) under the Public-Private-Partnership or
          PPP projects. The SLMS platform is an education management program
          that uses a cloud-based system to manage the management, record,
          monitor data, make reports and send learning-teaching information in
          various fields including core courses, training or even bringing
          information to further develop existing education. This type of
          management system has been developed and used along with the
          electronic education system (E-Education or E-Learning) as a system
          that is easy to use, easy to access, and flexible to develop in order
          to respond and be accurate according to the actual needs of learning
          and teaching from time to time in a sustainable manner.
        </span>
      </div>
      <div className="mt-2 row">
        <h5>II. The scope of privacy protection</h5>
      </div>
      <div className="row">
        <span>
          When it comes to the privacy protection, the company manages and
          protects the personal information of the users and the various rights
          of choice regarding the personal information of the users; This
          privacy policy applies only to the personal information that the
          Company collects from users of the system services that are part of
          the Company's program partners.
        </span>
      </div>
      <div className="row mt-2">
        <span> The necessary information to be stored by the company: </span>
      </div>
      <div className="row ps-5">
        <ul>
          <li>Comprehensive database information of the students;</li>
          <li>Comprehensive database information of the teachers;</li>
          <li>Timetable and class attendance of the teachers;</li>
          <li>Curriculum information;</li>
          <li>Student scores;</li>
          <li>
            Teacher/learning materials, reading materials, exercise and others;
          </li>
          <li>Notices, regulations or agreements of each institution;</li>
        </ul>
      </div>
      <div className="row mt-2">
        <span> How the company uses the information of users?</span>
      </div>

      <div className="row">
        <ul>
          <li>To manage the smart learning management information;</li>
          <li>
            To protect the security and rights in the information access of the
            individual, especially the students;
          </li>
          <li>For the identification and verification;</li>
          <li>
            To protect and control the software update, as well as other types
            of updates including the necessary information to ensure that the
            service is performed with ease;
          </li>
          <li>
            To enable checking and conduct surveys to control the stability of
            the system and understand the user experience received from SLMS;
          </li>
          <li>
            To perform background checks of the business profile or screen the
            activity (not limited to background checks) that is in accordance
            with legal binding or enforcement of risk management as stipulated
            in the law and used by the company;
          </li>
          <li>To conduct an audit of the service and business of SLMS.</li>
        </ul>
      </div>

      <div className="mt-2">
        <span>
          Other objectives that we have received from the service provider at
          present have been accepted by the user in accordance with the
          objectives that have been agreed by both parties.{" "}
        </span>
      </div>

      <div className="row">
        <h5>
           How the company protects the privacy information of the users?
        </h5>
      </div>

      <div className="row">
        <span>
          In performing the roles and management of the privacy information of
          the user, the company acts in accordance with the agreement; service
          and privacy information collection under confidential manner;
          implement security standards in terms of the techniques and
          information that are suitable, as well as enforcing the industrial
          safety preventative measures to protect the access or unauthorized
          disclosure of the information; prevention of the improper use;
          tampering of information or sabotage of user information.{" "}
        </span>
      </div>

      <div className="row mt-2">
        <h5> How long does the company keep information of the users?</h5>
      </div>

      <div className="row">
        <span>
          The company shall collect the privacy information at all times through
          out the service that is active as necessary in order to achieve the
          objectives or through the cooperation agreement. The use is in
          accordance with the details of the privacy information management
          policy or conform to the laws and regulations being enforced.{" "}
        </span>
      </div>
      <div className="row mt-2">
        <span>
          The company may collect the privacy information from the users. Even
          though the service has been used, in case of necessary to collect the
          information. The reasons are:
        </span>
      </div>

      <div className="row mt-2">
        <span>
          • To conform with the laws and regulations being enforced or as being
          assigned;
        </span>
        <span>
          • To provide support to the service provider under complete manners.{" "}
        </span>
      </div>

      <div className="row mt-2">
        <h5>IV. Data Safety</h5>
      </div>

      <div className="row">
        <span>
          In terms of the data protection policy, SLMS complies with the
          existing data projection laws as enacted by Lao National Assembly,
          this is to enhance the trustworthy of the brand. There are points that
          we take into consideration, these include:
        </span>
      </div>

      <div className="row">
        <span>
          • The procedure for managing personal data: we have implemented the
          audit trail in terms of tracking the log of user login in terms of
          username, timestamp, comparison of the ‘before’ and ‘after’ data
          modification in order to find out the owners or sources accordingly;
        </span>
        <span>
          • We use the data for analytic purposes in terms of finding out their
          usage pattern, behaviors in order to adjust the solutions to meet
          their needs accordingly;
        </span>
        <span>
          • We also plan to assign the Data Protection Officer in the future to
          manage this matter;
        </span>
        <span>
          • We have set clear guidelines, principles, and ‘Code of Conduct’ to
          disseminate to the staffs to handle personal data accordingly;
        </span>
        <span>
          • These principles applied to our vendors and external contractors.
        </span>
      </div>

      <div className="row mt-2">
        <h5>V. Privacy Policy</h5>
      </div>

      <div className="row">
        <span>This Privacy Policy was last updated on 16-Aug-2023.</span>
      </div>

      <div className="row mt-2">
        <span>
          Thank you for joining SLMS. We at Lao Development Hub (“SLMS”, “we”,
          “us”) respect your privacy and want you to understand how we collect,
          use, and share data about you. This Privacy Policy covers our data
          collection practices and describes your rights regarding your personal
          data.
        </span>
      </div>

      <div className="row mt-2">
        <span>
          Unless we link to a different policy or state otherwise, this Privacy
          Policy applies when you visit or use SLMS platform, mobile
          applications, APIs, or related services (the “Services”). It also
          applies to prospective students and other institutions.
        </span>
      </div>

      <div className="row">
        <span>
          By using the SLMS platform, you agree to the terms of this Privacy
          Policy. You shouldn’t use the Services if you don’t agree with this
          Privacy Policy or any other agreement that governs your use of the
          Services.
        </span>
      </div>

      <div className="row mt-2">
        <span>1. What Data We Get:</span>
      </div>

      <div className="row">
        <span>
          We collect data from the users directly in terms of the usage as well
          as from third-party platforms that are connected with SLMS, these
          include Moodle and other tools. All the data is under the activities
          of: collecting, recording, structuring, storing, altering, retrieving,
          encrypting, erasing, combining, and transmitting.
        </span>
      </div>

      <div className="row">
        <span>2. How We Get Data About You:</span>
      </div>

      <div className="row">
        <span>
          We use the tools like cookies and other similar technologies to gather
          the data. We use the data collection tools for the purpose of:
        </span>
      </div>

      <div className="row mt-2">
        <span>
          • Strictly Necessary: These Data Collection Tools enable you to access
          the site, provide basic functionality (like logging in or accessing
          content), secure the site, protect against fraudulent logins, and
          detect and prevent abuse or unauthorized use of your account. These
          are required for the Services to work properly, so if you disable
          them, parts of the site will break or be unavailable.
        </span>
      </div>
      <div className="row">
        <span>
          • Functional: These Data Collection Tools remember data about your
          browser and your preferences, provide additional site functionality,
          customize content to be more relevant to you, and remember settings
          affecting the appearance and behavior of the Services (like your
          preferred language or volume level for video playback).
        </span>
      </div>
      <div className="row">
        <span>
          • Performance: These Data Collection Tools help measure and improve
          the Services by providing usage and performance data, visit counts,
          traffic sources, or where an application was downloaded from. These
          tools can help us test different versions of Udemy to see which
          features or content users prefer and determine which email messages
          are opened.
        </span>
      </div>
      <div className="row">
        <span>
          • Advertising: These Data Collection Tools are used to deliver
          relevant ads (on the site and/or other sites) based on things we know
          about you like your Usage and System Data and things that the ad
          service providers know about you based on their tracking data. The ads
          can be based on your recent activity or activity over time and across
          other sites and services. To help deliver tailored advertising, we may
          provide these service providers with a hashed, anonymized version of
          your email address (in a non-human-readable form) and content that you
          share publicly on the Services.
        </span>
      </div>
      <div className="row">
        <span>
          • Social Media: These Data Collection Tools enable social media
          functionality, like sharing content with friends and networks. These
          cookies may track a user or device across other sites and build a
          profile of user interests for targeted advertising purposes.
        </span>
      </div>

      <div className="row">
        <span>
          • Users can set your web browser to alert you about attempts to place
          cookies on your computer, limit the types of cookies you allow, or
          refuse cookies altogether. If you do, you may not be able to use some
          or all features of the Services, and your experience may be different
          or less functional. To learn more about managing Data Collection Tools
        </span>
      </div>

      <div className="row mt-2">
        <span>3. What We Use the Data For:</span>
      </div>

      <div className="row">
        <span>
          We use the data to various purposes, these include: provide the
          services, communication, troubleshooting issues; secure against fraud
          and abuse; improve and update the services; analyze the usage pattern.
          Moreover, we retain the data for as long as it is needed to serve the
          purposes for which it was collected.
        </span>
      </div>

      <div className="row mt-2">
        <span>4. Who We Share the Data With:</span>
      </div>

      <div className="row">
        <span>
          We share certain data about the admins, teachers and students using
          the services in terms of the promotional campaign and surveys, on
          social media providers. Also, we may share the data as need for
          security, legal compliance, or as part of a corporate structuring.{" "}
        </span>
      </div>

      <div className="row">
        <span>5. Security:</span>
      </div>

      <div className="row">
        <span>
          We use appropriate security based on the type and sensitive of data
          being stored, these include the enabling of temporary password and
          allow the users to change the password on their own after the first
          log-in; integrate the audit and trackability tool in the system; as
          well as allow the user to contact us if case of the unauthorized
          access to the accounts. Moreover, we plan to implement the ‘stress
          test’ from an independent security firm to find the loophole and
          enhance our security levels in due course.
        </span>
      </div>

      <div className="mt-2 row">
        <span>
          SLMS takes appropriate security measures to protect against
          unauthorized access, alteration, disclosure, or destruction of the
          personal data that we collect and store. These measures vary based on
          the type and sensitivity of the data. Unfortunately, however, no
          system can be 100% secured, so we cannot guarantee that communications
          between you and SLMS, the Services, or any information provided to us
          in connection with the data we collect through the Services will be
          free from unauthorized access by third parties. The password is an
          important part of our security system, and it is your responsibility
          to protect it. The users should not share your password with any third
          party, and if you believe your password or account has been
          compromised.
        </span>
      </div>

      <div className="row">
        <span>
          The users have certain rights on the use of their data, including the
          ability to opt out of promotional emails, cookies, and collection of
          data by certain their parties. The users can either update or
          terminate the account from the service and can contact us for
          individual rights requests about the personal data.
        </span>
      </div>

      <div className="mt-2 row">
        <span>7. Jurisdiction-Specific Rules:</span>
      </div>

      <div className="row">
        <span>
          The jurisdiction covers the right to make a formal complaint with the
          appropriate government agencies or bodies under the laws of Lao PDR.
        </span>
      </div>
      <div className="row mt-2">
        <h5>VI. Inquiry/Recommendation</h5>
      </div>

      <div className="row">
        <span>
          In case the users need to enquire/make recommendation on the privacy
          protection, the policy, the direction on the privacy management of the
          company, please contact via the company inquiry form or send the
          letter to the following address:{" "}
        </span>
      </div>

      <div className="row mt-2">
        <span>Lao Development Hub and Consulting Sole Co. Ltd</span>
      </div>

      <div className="row">
        <span>
          Watchan Village, Chanthabouly District, Vientiane Capital, Lao PDR
        </span>
      </div>

      <div className="row">
        <span>Email: info.01slms@gmail.com</span>
      </div>

      <div className="row">
        <span>Tel: +856 21 771 989; +856 20 5407 7999</span>
      </div>
    </div>
  );
};
