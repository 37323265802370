import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

/**
 *
 * @Component
 *
 */
import { Form, Row, Col } from "react-bootstrap";
import { Breadcrumb, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
import Loading from "../../common/Loading";
import SearchModal from "./SearchModal";
import EditPasswordModal from "./EditPasswordModal";
/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";

/**
 *
 * @Apollo
 *
 */
import { USER_KEY } from "../../consts/user";
import { UPDATE_USER_PASSWORD } from "../../apollo/user";
import { GET_FACULTIES } from "../../apollo/faculty";
import { GET_DEPARTMENTS } from "../../apollo/department";
import { COURSES } from "../../apollo/course";
import { STUDENT } from "../../apollo/user";
import { MAJORS } from "../../apollo/major";
import { removeDuplicates } from "../../helpers/user";

/**
 *
 * @Function
 *
 */

function CourseListBrowserView() {
  const { history } = useReactRouter();
  /**
   *
   * @State
   *
   */
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
  const [title, setTitle] = useState("ວິຊາທັງຫມົດ");
  const [courses, setCourses] = useState();
  const [userLoginData, setUserLoginData] = useState({});
  const [faculty, setFaculty] = useState("");
  const [yearCourse, setYearCourse] = useState("");
  const [semester, setSemester] = useState("");
  const [courseCode, setCourseCode] = useState("");
  /**
   *
   * @Apollo
   *
   */
  const [loadStudentData, { data: student }] = useLazyQuery(STUDENT);
  const [loadCoursesData, { loading, data }] = useLazyQuery(COURSES);
  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);
  // const [searchCourse, { data: courseSearchData }] = useLazyQuery(COURSES);

  const { loading: loadingFaculties, data: faculties } = useQuery(GET_FACULTIES);

  const [loadDepartment, { loading: loadingDepartments, data: departments }] = useLazyQuery(GET_DEPARTMENTS);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS);

  /**
   *
   * @UseEffect
   *
   */
  

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user && user.studentData) {
      setUserLoginData(user.studentData);
    }
    if (localStorage?.editPassword) {
      setShowEditPasswordModal(!showEditPasswordModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!_.isEmpty(userLoginData)) {
      loadStudentData({
        variables: { where: { id: userLoginData?.id } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoginData]);

  useEffect(() => {
    if(student) {
      setFaculty(student?.student?.faculty?.id)
      loadDepartment({
        variables: { where: { faculty: student?.student?.faculty?.id } },
      });
      loadMajors({
        variables: { where: { department: student?.student?.department?.id } },
      });
      setYearCourse(student?.student?.yearLevel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  useEffect(() => {
    let _where = {
      isDeleted: false,
      assessmentYear: student?.student?.assessmentYear,
      major: student?.student?.major.id,
      classRoom: student?.student?.classRoom?.id,
    }
    // console.log(_where)
    if(yearCourse) _where = {..._where, year: parseInt(yearCourse)}
    if(courseCode) _where = {..._where, courseCode: courseCode}
    if(semester) _where = {..._where, semester: parseInt(semester)}
    loadCoursesData({
      fetchPolicy: "network-only",
      variables: {
        where: _where,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearCourse, courseCode, semester]);

  useEffect(() => {
      if (data && data?.courses?.data) {
        setCourses(removeDuplicates(data?.courses?.data));
      }
  }, [data]);

  const _courseDetail = (courseId) => {
    history.push(`/course-detail/${courseId}`);
    // window.location.reload();
  };
  /**
   *
   * @Function
   *
   */

  const _onSearch = (value) => {
    if (
      value.courseCode != null ||
      value.faculty != null ||
      value.department != null ||
      value.year != null
    ) {
      let condition = {};
      if (!_.isEmpty(value.courseCode)) {
        condition["courseCode"] = value.courseCode;
      } else {
        if (!_.isEmpty(value.faculty)) {
          condition["faculty"] = { name: value.faculty };
        }
        if (!_.isEmpty(value.department)) {
          condition["department"] = { name: value.department };
        }
        if (!_.isEmpty(value.year)) {
          condition["year"] = parseInt(value.year);
        }
      }
      loadCoursesData({
        variables: {
          where: {
            ...condition,
            isDeleted: false,
            assessmentYear: student?.student?.assessmentYear,
            classRoom: student?.student?.classRoom?.id,
          },
        },
      });
      setTitle("ຜົນການຄົ້ນຫາ");
    } else {
      setTitle("ວິຊາທັງຫມົດ");
    }
  };

  const _onEdit = async (graphQL) => {
    // console.log("graphQL1sssss", graphQL);
    const res = await updateUserPassword({ variables: graphQL });
    if (res?.data) {
      localStorage.removeItem("editPassword");
      setShowEditPasswordModal(false);
    }
  };

  const _selectYearCourse = (e) => {
    setYearCourse(e.target.value)
  }
  const _selectSemester = (e) => {
    setSemester(e.target.value)
  }
  const _keyCoursCode = (e) => {
    if(e.key === "Enter") {
      setCourseCode(e.target.value)
    }
  }

  if (loading) return <Loading />;
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/course-list")}>
          ລົງທະບຽນວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ວິຊາທັງຫມົດ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Title text={title} />
        <Row>
          <Col sm={3}>
            <p>ຄະນະວິຊາ</p>
            <Form.Control
              as="select"
              name="faculty"
              value={faculty}
              disabled
            >
              <option value={null}>
                {loadingFaculties ? "ກຳລັງໂຫລດ..." : "ກະລຸນາເລືອກ"}
              </option>
              {faculties &&
                faculties.faculties &&
                faculties.faculties.map((faculty, index) => (
                  <option key={index} value={faculty?.id}>{faculty.name}</option>
                ))}
            </Form.Control>
          </Col>
          <Col sm={3}>
            <p>ພາກວິຊາ</p>
            <Form.Control
              as="select"
              name="department"
              value={student?.student?.department?.id}
              disabled
            >
              <option value={null}>
                {loadingDepartments ? "ກຳລັງໂຫລດ..." : "ກະລຸນາເລືອກ"}
              </option>
              {departments &&
                departments.departments &&
                departments.departments.map((department, index) => (
                  <option key={index} value={department?.id}>{department?.name}</option>
                ))}
            </Form.Control>
          </Col>
          <Col sm={3}>
            <p>ສາຂາວິຊາ</p>
            <Form.Control
              as="select"
              name="department"
              value={student?.student?.major?.id}
              disabled
            >
              <option value={null}>
                {loadingDepartments ? "ກຳລັງໂຫລດ..." : "ກະລຸນາເລືອກ"}
              </option>
              {majorData &&
                majorData?.majors?.data &&
                majorData?.majors?.data.map((department, index) => (
                  <option key={index} value={department?.id}>{department?.name}</option>
                ))}
            </Form.Control>
          </Col>
          <Col sm={3}>
            <p>ປີ</p>
            <Form.Control
              as="select"
              name="year"
              value={yearCourse}
              disabled
              onChange={(e) => _selectYearCourse(e)}
            >
              <option value={null}>ກະລຸນາເລືອກ</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </Form.Control>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={3}>
            <p>ພາກຮຽນ</p>
            <Form.Control
              as="select"
              name="semester"
              value={semester}
              onChange={(e) => _selectSemester(e)}
            >
              <option value="">ທັງໝົດ</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </Form.Control>
          </Col>
          <Col sm={3}>
            <p>ລະຫັດວິຊາ</p>
            <Form.Control
              type="text"
              name="courseCode"
              placeholder="ປ້ອນລະຫັດວິຊາແລ້ວກົດ Enter"
              defaultValue={courseCode}
              onKeyDown={(e) => _keyCoursCode(e)}
            />
          </Col>
        </Row>
      </CustomContainer>
      <CustomContainer className="mt-2">
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          ທັງຫມົດ {courses && courses?.length}
        </div>

        {/* Table list */}
        <div>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader>
                <th style={{ width: 80 }}>ລຳດັບ</th>
                <th>ລະຫັດວິຊາ</th>
                <th>ຊື່ວິຊາ</th>
                <th>ປີຮຽນ</th>
                <th>ພາກຮຽນ</th>
                <th>ອາຈານ</th>
                <th>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {courses &&
                courses?.length > 0 &&
                courses?.map((course, index) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{course?.courseCode ?? "-"}</TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {course?.title ?? "-"}{" "}
                      </TableCell>
                      <TableCell>{course?.year ?? "-"} </TableCell>
                      <TableCell>{course?.semester ?? "-"} </TableCell>
                      <TableCell>
                        {course?.teacher?.length > 0 &&
                          course?.teacher?.map((item, index) => (
                            <span key={index}>
                              {item?.firstname +
                                " " +
                                (item?.lastname ?? "") +
                                (index < course.teacher.length - 1 ? ", " : "")}
                            </span>
                          ))}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          {["right"].map((placement) => (
                            <OverlayTrigger
                              placement={placement}
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  ເບິ່ງລາຍລະອຽດ
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <button
                                  onClick={() => _courseDetail(course.id)}
                                  style={{
                                    width: 60,
                                    height: 30,
                                    borderRadius: 3,
                                    border: "1px solid #ddd",
                                    outline: "none",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={["fa", "eye"]}
                                    color={Consts.COLOR_EDIT}
                                  />
                                </button>
                              </span>
                            </OverlayTrigger>
                          ))}
                        </div>
                      </TableCell>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </CustomContainer>
      <SearchModal
        showSearchView={showSearchModal}
        _handleSearchViewClose={() => setShowSearchModal(false)}
        onSearch={(value) => _onSearch(value)}
      />
      {/* <EditPasswordModal
        showEditPassword={showEditPasswordModal}
        _handleEditPasswordClose={() => setShowEditPasswordModal(false)}
        onEdit={(graphQL) => _onEdit(graphQL)}
        userId={userLoginData?.id}
      /> */}
    </div>
  );
}

export default CourseListBrowserView;
