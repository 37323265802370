import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Breadcrumb } from "react-bootstrap";
import * as _ from "lodash";
import Consts from "../../consts";
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
import { formatDateTime, noticeStatus } from "../../super";
import { useLazyQuery } from "@apollo/react-hooks";
import { NOTICS } from "../../apollo/notic";
import Loading from "../../common/Loading";

function NoticeList() {
  const { history } = useReactRouter();
  /**
   * use states
   */
  const [typeNotice, setTypeNotice] = useState("NOTIC");
  const [isGrid, setIsGrid] = useState(false);
  /**
   * use effect
   */
  const [getNotics, { loading: noticLoading, data: noticData }] = useLazyQuery(
    NOTICS,
    { fetchPolicy: "network-only" }
  );
  useEffect(() => {
    let _where = {}
    if(typeNotice) {
      _where = {type: typeNotice, isPublish: true}
    }else {
      _where = {isPublish: true}
    }
    getNotics({
      variables: {
        where: _where,
        orderBy: "createdAt_DESC",
      },
    });
  }, [typeNotice]);
  const noticeType = (e) => {
    setTypeNotice(e);
  };
  const _displayStyle = () => {
    setIsGrid(!isGrid);
  };
  if (noticLoading) return <Loading />;
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item active>
          {"ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title text={"ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ"} />
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            onClick={_displayStyle}
            icon={["fas", isGrid === true ? "list" : "th-large"]}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 30,
          }}
        >
          <div style={{ width: 80, color: "#057CAE", fontWeight: "bold" }}>
            ຫມວດ
          </div>
          <div
            className={
              typeNotice === "NOTIC" ? "menuItem active-menu" : "menuItem"
            }
            onClick={() => noticeType("NOTIC")}
          >
            ແຈ້ງການ
          </div>
          <div
            className={
              typeNotice === "AGREEMENT" ? "menuItem active-menu" : "menuItem"
            }
            onClick={() => noticeType("AGREEMENT")}
          >
            ຂໍ້ຕົກລົງ
          </div>
          <div
            className={
              typeNotice === "REGULATION" ? "menuItem active-menu" : "menuItem"
            }
            onClick={() => noticeType("REGULATION")}
          >
            ລະບຽບການ
          </div>
        </div>

        {/* ເອກະສານທັງຫມົດ */}
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          ທັງຫມົດ {noticData?.notics?.data?.length}
        </div>

        {/* Table list */}
        <div>
          {noticLoading ? (
            <Spinner animation="border" style={{ color: "#057CAE" }} />
          ) : (
            <div>
              <div style={{ display: isGrid === true ? "none" : "block" }}>
                <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                  <thead>
                    <TableHeader>
                      <th style={{ width: 80 }}>ລຳດັບ</th>
                      <th style={{ width: 110, alignContent: "center" }}>
                        ຮູບພາບ
                      </th>
                      <th>ຫົວຂໍ້</th>
                      <th style={{ width: 150 }}>ປະເພດ</th>
                      <th>ສ້າງ</th>
                      <th>ອັບເດດ</th>
                      <th style={{ width: 200 }}>ຈັດການ</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {noticData?.notics?.data?.length > 0 &&
                      noticData?.notics?.data?.map((x, index) => {
                        return (
                          <tr
                            style={{
                              borderBottom: "2px solid #ffff",
                              textAlign: "center",
                            }}
                            key={index}
                          >
                            <TableCell style={{ width: 70 }}>
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              <img
                                src={
                                  x.coverImage?.url
                                    ? x.coverImage?.url
                                    : "/assets/wallpaper-image.png"
                                }
                                // src="assets/wallpaper-image.png"
                                className="box-image"
                              />
                            </TableCell>
                            <TableCell
                              style={{ textAlign: "center", paddingLeft: 10 }}
                            >
                              <a
                                // className="title"
                                style={{
                                  overflowX: "hidden",
                                  overflowY: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  fontWeight:"bold"
                                }}
                              >
                               {x?.title}
                              </a>
                            </TableCell>
                            <TableCell>{noticeStatus(x.type)}</TableCell>
                            <TableCell style={{ width: 200 }}>
                              {formatDateTime(x.createdAt)}
                              <br />( ໂດຍ: {x?.createdBy?.firstname}{" "}
                              {x?.createdBy?.lastname
                                ? x?.createdBy?.lastname
                                : ""}{" "}
                              )
                            </TableCell>
                            <TableCell style={{ width: 200 }}>
                              {formatDateTime(x.updatedAt)}
                              <br />( ໂດຍ: {x?.updatedBy?.firstname}
                              {x?.updatedBy?.lastname
                                ? x?.updatedBy?.lastname
                                : ""}{" "}
                              )
                            </TableCell>
                            <TableCell style={{ width: 200 }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-around",
                                  width: 200,
                                }}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip">
                                      ເບິ່ງລາຍລະອຽດ
                                    </Tooltip>
                                  }
                                >
                                  <span className="d-inline-block">
                                    <div
                                      onClick={() =>
                                        history.push(
                                          "./notice-detail/" +
                                            x.id +
                                            "/" +
                                            x.type
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#FFFFFF",
                                        padding: 3,
                                        width: 64,
                                        borderRadius: 4,
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fas", "eye"]}
                                        color="#7BB500"
                                      />
                                    </div>
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </TableCell>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: isGrid === true ? "block" : "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {noticData?.notics?.data?.length > 0 &&
                    noticData?.notics?.data?.map((x, index) => {
                      return (
                        <div
                          onClick={() =>
                            history.push(`/notice-detail/${x.id}/${x.type}`)
                          }
                          key={index}
                          style={{
                            backgroundColor: "#ffffff",
                            width: 230,
                            border: "1px solid #ddd",
                            borderRadius: 5,
                            marginBottom: 10,
                            cursor: "pointer",
                            marginRight: 8,
                          }}
                        >
                          <div
                            style={{
                              height: 171,
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#EAEAEA",
                            }}
                          >
                            <img
                              style={{ width: "100%" }}
                              src={
                                x.coverImage?.url
                                  ? x.coverImage?.url
                                  : "/assets/wallpaper-image.png"
                              }
                            />
                          </div>
                          <div style={{ padding: 10 }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: Consts.FONT_SIZE,
                              }}
                            >
                              <a>
                                ສ້າງໂດຍ:{x?.createdBy?.firstname}{" "}
                                {x?.createdBy?.lastname
                                  ? x?.createdBy?.lastname
                                  : ""}
                              </a>
                              <a>{noticeStatus(x.type)}</a>
                            </div>
                            {/* <div> */}
                              <a
                                className=""
                                style={{
                                  fontSize:"16px",
                                  color: Consts.FONT_COLOR_PRIMARY,
                                  fontWeight: "bolder",
                                }}
                              >
                                {x?.title}
                              </a>
                            {/* </div> */}
                            <br />
                            <a style={{ fontSize: Consts.FONT_SIZE }}>
                              {formatDateTime(x.createdAt)}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            // </div>
          )}
        </div>
      </CustomContainer>
    </div>
  );
}

export default NoticeList;
