import React, { useState, useEffect } from "react";

import axios from "axios";
import "./index.css";
import { USER_KEY } from "../../consts/user";
import useReactRouter from "use-react-router";
import { API_KEY, NEW_API_URL } from "../../common/constraint";

const TimeListWeb = () => {
  const [studentId, setStudentId] = useState();
  const [faculty, setFaculty] = useState("");
  const [yearLevel, setYearLevel] = useState(1);
  const user = JSON.parse(localStorage.getItem(USER_KEY));

  useEffect(() => {
    if (user) {
      setYearLevel(Number(user.studentData?.yearLevel) || 1);
      setStudentId(user.studentData.id || "");
    }
  }, [user]);

  const [loginData, setLoginData] = useState();
  useEffect(() => {
    const data = localStorage.getItem(USER_KEY);
    if (data) {
      const user = JSON.parse(data);
      setLoginData(user);
    }
  }, [USER_KEY]);

  const [dataTable, setDataTable] = useState({});
  const [timeList, setTineList] = useState([]);

  const [selectedYear, setSelectedYear] = useState(yearLevel); // State to store the selected option
  const [selectedSemester, setSelectedSemester] = useState(1); // State to store the selected option

  const { history, match } = useReactRouter();

  const fetchTimeTableData = async () => {
    if (studentId) {
      axios
        .get(
          NEW_API_URL +
            "grade/" +
            studentId +
            "/" +
            selectedYear +
            "/" +
            selectedSemester,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            const data = res.data.data;
            setDataTable(data);
          } else {
            setDataTable([]);
          }
        })
        .catch((err) => {
          setDataTable([]);
        });
    }
  };

  const fetchStudentInfo = () => {
    if (studentId) {
      axios
        .get(NEW_API_URL + "/student/" + studentId, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((res) => {
          if (res.data.success) {
            setFaculty(res.data.data?.faculty);
          }
        })
        .catch((err) => {});
    }
  };

  const fetchTimeList = async () => {
    if (faculty) {
      axios
        .get(NEW_API_URL + "time/" + faculty + "/2023 - 2024", {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data.data;
            setTineList(data);
          }
        })
        .catch((err) => {
          console.log("error fetch time");
          console.log(err);
        });
    }
  };

  const _onCourseDetail = (_id) => {
    history.push(`/registered-course-detail/${_id}`);
  };

  useEffect(() => {
    fetchTimeTableData();
    fetchStudentInfo();
    fetchTimeList();
  }, [selectedYear, selectedSemester, studentId, faculty]);

  const Nothing = () => {
    return <div className="nothing"></div>;
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value); // Update the selected option when the user selects a new value
  };

  const handleSemesterChange = (event) => {
    setSelectedSemester(event.target.value); // Update the selected option when the user selects a new value
  };

  const getDayData = (day, time, data) => {
    let courseData = [];
    if (data) {
      for (const courses of data) {
        if (courses) {
          for (const course of courses) {
            for (const timeStudy of course.timesStudy) {
              for (const times of timeStudy.times) {
                if (
                  timeStudy.day === day &&
                  times?.hour === time.hour
                  // times?.time.replace(/\s/g, "").toLowerCase() ===
                  //   time?.time.replace(/\s/g, "").toLowerCase()
                ) {
                  let week = "";
                  if (timeStudy?.oddWeek) week = "ອາທິດຄີກ";
                  if (timeStudy?.evenWeek) week = "ອາທິດຄູ່";
                  if (timeStudy?.allWeek) week = "";

                  course.week = week;
                  courseData.push(course);
                }
              }
            }
          }
        }
      }
    }

    return courseData;
  };

  useEffect(() => {
    setSelectedYear(yearLevel);
  }, [yearLevel]);

  return (
    <div className="content">
      <div className="d-flex justify-content-center mb-3">
        <div className="col-6">
          <div className="d-flex align-items-center">
            <span className="me-2">ປີຮຽນ:</span>
            <select
              className="form-select mx-4 py-2"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option selected>ປິຮຽນ</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex align-items-center">
            <span className="me-2">ພາກຮຽນ:</span>
            <select
              value={selectedSemester}
              onChange={handleSemesterChange}
              className="form-select px-5 mx-4 py-2"
              aria-label="Default select example"
            >
              <option selected>ພາກຮຽນ</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-1"
          style={{
            marginTop: "90px",
          }}
        >
          {timeList.map((time, index) => {
            return (
              <div key={index} className="hour">
                <p className="label">{"ຊົ່ວໂມງ " + time?.hour}</p>
                <p className="label">{time?.time}</p>
              </div>
            );
          })}
        </div>
        <div className="col-10">
          <table className="my-table">
            <thead>
              <tr>
                <th className="my-column-header">ຈັນ</th>
                <th className="my-column-header">ອັງຄານ</th>
                <th className="my-column-header">ພຸດ</th>
                <th className="my-column-header">ພະຫັດ</th>
                <th className="my-column-header">ສຸກ</th>
                <th className="my-column-header">ເສົາ</th>
                <th className="my-column-header">ອາທິດ</th>
              </tr>
            </thead>

            <tbody>
              <td>
                {timeList.map((time, index) => {
                  const data = getDayData("ຈັນ", time, dataTable.monday);
                  if (data.length > 0) {
                    return (
                      <div key={index} className="my-column-row">
                        {data.map((item) => (
                          <div
                            key={item.id}
                            onClick={() => _onCourseDetail(item.id)}
                            className="tb-content"
                          >
                            <p>{item.title + "   (" + item.week + ")"}</p>
                          </div>
                        ))}
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Nothing />
                      </div>
                    );
                  }
                })}
              </td>

              <td>
                {timeList.map((time, index) => {
                  const data = getDayData("ອັງຄານ", time, dataTable.tuesday);

                  if (data.length > 0) {
                    return (
                      <div key={index} className="my-column-row">
                        {data.map((item) => (
                          <div
                            key={item.id}
                            onClick={() => _onCourseDetail(item.id)}
                            className="tb-content"
                          >
                            <p>{item.title + "   (" + item.week + ")"}</p>
                          </div>
                        ))}
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Nothing />
                      </div>
                    );
                  }
                })}
              </td>

              <td>
                {timeList.map((time, index) => {
                  const data = getDayData("ພຸດ", time, dataTable.wednsday);
                  if (data.length > 0) {
                    return (
                      <div key={index} className="my-column-row">
                        {data.map((item) => (
                          <div
                            key={item.id}
                            onClick={() => _onCourseDetail(item.id)}
                            className="tb-content"
                          >
                            <p>{item.title + "   (" + item.week + ")"}</p>
                          </div>
                        ))}
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Nothing />
                      </div>
                    );
                  }
                })}
              </td>

              <td>
                {timeList.map((time, index) => {
                  const data = getDayData("ພະຫັດ", time, dataTable.thursday);
                  if (data.length > 0) {
                    return (
                      <div key={index} className="my-column-row">
                        {data.map((item) => (
                          <div
                            key={item.id}
                            onClick={() => _onCourseDetail(item.id)}
                            className="tb-content"
                          >
                            <p>{item.title + "   (" + item.week + ")"}</p>
                          </div>
                        ))}
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Nothing />
                      </div>
                    );
                  }
                })}
              </td>

              <td>
                {timeList.map((time, index) => {
                  const data = getDayData("ສຸກ", time, dataTable.friday);
                  if (data.length > 0) {
                    return (
                      <div key={index} className="my-column-row">
                        {data.map((item) => (
                          <div
                            key={item.id}
                            onClick={() => _onCourseDetail(item.id)}
                            className="tb-content"
                          >
                            <p>{item.title + "   (" + item.week + ")"}</p>
                          </div>
                        ))}
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Nothing />
                      </div>
                    );
                  }
                })}
              </td>

              <td>
                {timeList.map((time, index) => {
                  const data = getDayData("ເສົາ", time, dataTable.saturday);
                  if (data.length > 0) {
                    return (
                      <div key={index} className="my-column-row">
                        {data.map((item) => (
                          <div
                            key={item.id}
                            onClick={() => _onCourseDetail(item.id)}
                            className="tb-content"
                          >
                            <p>{item.title + "   (" + item.week + ")"}</p>
                          </div>
                        ))}
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Nothing />
                      </div>
                    );
                  }
                })}
              </td>

              <td>
                {timeList.map((time, index) => {
                  const data = getDayData("ອາທິດ", time, dataTable.sunday);
                  if (data.length > 0) {
                    return (
                      <div key={index} className="my-column-row">
                        {data.map((item) => (
                          <div
                            key={item.id}
                            onClick={() => _onCourseDetail(item.id)}
                            className="tb-content"
                          >
                            <p>{item.title + "   (" + item.week + ")"}</p>
                          </div>
                        ))}
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Nothing />
                      </div>
                    );
                  }
                })}
              </td>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TimeListWeb;
