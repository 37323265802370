import React from "react";
import { MobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import "./index.css";
import { useQuery } from "@apollo/react-hooks";
import { GRADE } from "../../apollo/grade";
import { formatDateTime, upgradeStatus } from "../../super";
const UpGradeDetailMobileView = () => {
  const { history, match } = useReactRouter();
  const { courseId } = match.params;
  /**
   * apollo
   */
  const { data, error, loading } = useQuery(GRADE, {
    variables: { where: { id: courseId } },
  });
  const _handleclick = () => {
    history.goBack();
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <MobileHeader
        title="ຂໍ້ມູນຄະແນນ"
        showLeftArrow
        leftButtonClick={() => history.goBack()}
      />
      <br />
      {/* body div */}
      <div style={{ backgroundColor: "#fff", padding: 10 }}>
        <br />
        <br />
        <div className="row">
          <div className="container-fluid">
            <div>
              &nbsp; ຂໍ້ມູນການລົງທະບຽນອັບເກຣດ
            </div>
            <div style={{ padding: 15 }}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>ສະຖານະ:</td>
                    <td style={{ 
                        color:
                            data?.grade?.gredeStatus === "REQUESTING"
                            ? "#F29702"
                            : data?.grade?.gredeStatus === "APROVED" ||
                              data?.grade?.gredeStatus === "FINISHED"
                            ? "#1B8900"
                            : "red",
                      }}>
                      {upgradeStatus(data?.grade?.gredeStatus ?? "REQUESTING")}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>ວິຊາທິແກ້ເກຣດ:</td>
                    <td style={{ color: "#909699" }}>
                      {data?.grade?.course?.title ?? "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>ພາກຮຽນ:</td>
                    <td style={{ color: "#909699" }}>
                      {data?.grade?.semester ?? "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>ປີຮຽນ:</td>
                    <td style={{ color: "#909699" }}>
                      {data?.grade?.yearLevel ?? "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>ວັນທີເວລາລົງທະບຽນ:</td>
                    <td style={{ color: "#909699" }}>
                      {formatDateTime(data?.grade?.createdAt)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* div table */}
      </div>
    </div>
  );
};
export default UpGradeDetailMobileView;
