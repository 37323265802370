import React from "react";
import { Breadcrumb, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
// component ----------------------------------------------------------------->
import { CustomContainer, Title } from "../../common";
import Loading from "../../common/Loading";
// const --------------------------------------------------------------------->
import { FILE } from "../../apollo/file";
import { formatDateWithoutTime } from "../../super";
// import CourseDeleteConfirm from "./CourseDeleteConfirm";

function CourseDetail() {
  const { history, match } = useReactRouter();
  const { docId } = match.params;

  // apollo ------------------------------------------------------------------>
  const { loading, error, data } = useQuery(FILE, {
    variables: { where: { id: docId } },
  });
  if (loading) return <Loading />;
  if (error) return <h1>GraphGL error</h1>;
  if (data) {
  }
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/doc-list")}>
          ເອກະສານທັງຫມົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍລະອຽດ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text="ລາຍລະອຽດເອກະສານ" />

          {/* Button group */}
          <div>
            {/* ເອກະສານບົດສອນ */}
            <a href={data && data.file && data.file.file}>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip">ດາວໂຫລດເອກະສານ</Tooltip>}
              >
                <button
                  style={{
                    backgroundColor: "#057CAE",
                    width: 140,
                    height: 40,
                    color: "#fff",
                    outline: "none",
                    border: "none",
                  }}
                  // onClick={() => _onDownloadFile()}
                >
                  <FontAwesomeIcon icon={["fa", "download"]} /> ດາວໂຫລດ
                </button>
              </OverlayTrigger>
            </a>
          </div>
        </div>

        <div
          style={{
            width: 500,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            paddingBottom: 80,
          }}
        >
          {/* -------- ຄະນະແລະສາຂາວິຊາ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div>
              <FontAwesomeIcon color="#057CAE" icon={["fa", "sort-down"]} />{" "}
              <b>ຂໍ້ມູນເອກະສານ</b>
            </div>
            <div style={{ paddingLeft: 40, fontSize: 14 }}>
              <Row>
                <Col>
                  <p>ໝວດ</p>
                </Col>
                <Col>
                  <p>
                    {data && data.file && data.file.cate === "RESEARCH"
                      ? "ບົດຄົ້ນຄ້ວາ"
                      : data && data.file && data.file.cate === "SPECIFIC"
                      ? "ເອກະສານວິຊາສະເພາະ"
                      : data && data.file && data.file.cate === "GENERAL"
                      ? "ຄວາມຮູ້ທົ່ວໄປ"
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>ຊື່ເອກະສານ</p>
                </Col>
                <Col>
                  <p>
                    {data && data.file && data.file.title
                      ? data.file.title
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Keyword</p>
                </Col>
                <Col>
                  <p>
                    {data && data.file && data.file.keyword
                      ? data.file.keyword.map((keyword, index) => (
                          <span key={index}>{keyword} </span>
                        ))
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          {/* -------- ປີຮຽນແລະພາກຮຽນ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip">ອັບໂຫລດເອກະສານ</Tooltip>}
            >
              <div>
                {" "}
                <FontAwesomeIcon
                  color="#057CAE"
                  icon={["fa", "sort-down"]}
                />{" "}
                <b>ອັບໂຫລດເອກະສານ11</b>
              </div>
            </OverlayTrigger>
            <div style={{ paddingLeft: 40, fontSize: 14 }}>
              <Row>
                <Col>
                  <p>ວັນທີອັບໂຫລດ</p>
                </Col>
                <Col>
                  {data && data.file && data.file.createdAt
                    ? formatDateWithoutTime(data.file.createdAt)
                    : "-"}
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>ວັນທີແກ້ໄຂ</p>
                </Col>
                <Col>
                  {data && data.file && data.file.updatedAt
                    ? formatDateWithoutTime(data.file.updatedAt)
                    : "-"}
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>ເອກະສານ</p>
                </Col>
                <Col className="wrap-text">
                  <a href={data.file.file}>
                    {" "}
                    {data && data.file && data.file.file
                      ? data.file.file.split("/")[4]
                      : "-"}
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* ------- Delete Modal ------ */}
        {/* DeleteConfirm Modal */}
        {/* <CourseDeleteConfirm
          showDeleteConfirmView={showDeleteConfirmView}
          _handleDeleteConfirmViewClose={_handleDeleteConfirmViewClose}
        /> */}
      </CustomContainer>
    </div>
  );
}

export default CourseDetail;
