import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import useReactRouter from "use-react-router";
// component -------------------------------------------------->
import CourseDetailBrowserView from "./CourseDetailBrowserView";
import CourseDetailMobileView from "./CourseDetailMobileView";
import { MobileHeader } from "../../common";
const CourseDetail = () => {
  const { history } = useReactRouter();
  return (
    <div>
      <BrowserView>
        <CourseDetailBrowserView />
      </BrowserView>
      <MobileView>
        <MobileHeader
          title="ລາຍລະອຽດວິຊາ"
          showLeftArrow
          leftButtonClick={() => history.push("/course-list")}
        />
        <CourseDetailMobileView />
      </MobileView>
    </div>
  );
};

export default CourseDetail;
