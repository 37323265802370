import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import CourseDetailV2 from "./CourseDetailV2";

function BrowserRegisteredCourseDetail() {
  const { history, match } = useReactRouter();
  const ID = match.params.id;

  return (
    <div className="w-100 bg-white">
      <CourseDetailV2 courseId={ID || "NoId"} />
    </div>
  );
}

export default BrowserRegisteredCourseDetail;
