import React, { useState } from "react";
import { WhiteMobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import { Col, Nav, Row, Tab } from "react-bootstrap";

import "./index.css";
const ScholarShipDetail = () => {
  const { history, location } = useReactRouter();
  const { state } = location;
  const [key, setKey] = useState("detail");
  return (
    <div style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
      <WhiteMobileHeader
        title="ລາຍລະອຽດເພິ່ມເຕີມ"
        showLeftArrow
        leftButtonClick={() => history.push("/scholarship-list")}
      />
      <div style={{ paddingTop: 60 }}>
        <div>
          <img
            alt=""
            src={state?.image ? state?.image : "assets/wallpaper-image.png"}
            className="card-image-detail"
          />
          <div
            style={{marginLeft:15,marginRight:15 }}
          >
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Row
                className="pt-3 pl-1 pr-2 text-center"
                style={{ borderBottom: "2px solid #7BB500" }}
              >
                <Col className="p-0">
                  <Nav.Link
                    eventKey="detail"
                    className={key === "detail" ? "activeTab" : "inActiveTab"}
                  >
                    ລາຍລະອຽດ
                  </Nav.Link>
                </Col>
                <Col className="p-0">
                  <Nav.Link
                    eventKey="skill"
                    className={key === "skill" ? "activeTab" : "inActiveTab"}
                  >
                    ເງື່ອນໄຂ
                  </Nav.Link>
                </Col>
                <Col className="p-0" style={{}}>
                  <Nav.Link
                    eventKey="attachment"
                    className={
                      key === "attachment" ? "activeTab" : "inActiveTab"
                    }
                  >
                    ເພີ່ມເຕີມ
                  </Nav.Link>
                </Col>
              </Row>

              <Tab.Content>
                <Tab.Pane eventKey="detail">
                  <div className="pt-3 pl-1 pr-1">
                      <b>ລາຍລະອຽດການສະໝັກທຶນການສຶກສາ</b>
                    <div >
                      <div  dangerouslySetInnerHTML={{ __html: state?.detail }} />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="skill">
                  <div className="pt-3 pl-1 pr-1">
                      <b>ເງື່ອນໄຂສະໝັກທຶນການສຶກສາ</b>
                    <div className="mt-3">
                      <div dangerouslySetInnerHTML={{ __html: state?.skill }} />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="attachment">
                  <div className="pt-3 pl-1 pr-1">
                      <b>ເອກະສານປະກອບສະໝັກ</b>
                    <div className="mt-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: state?.attachment,
                        }}
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ScholarShipDetail;
