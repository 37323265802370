import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import "./index.css";
import UpgradeBrowserView from "./UpgradeBrowserView";
import UpgradeMobileView from "./UpgradeMobileView";

const GradeMobileView = () => {
  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <BrowserView>
        <UpgradeBrowserView />
      </BrowserView>
      <MobileView>
        <UpgradeMobileView />
      </MobileView>
    </div>
  );
};
export default GradeMobileView;
