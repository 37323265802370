import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import { Progress } from "reactstrap";
import { USER_KEY } from "../../consts/user";
import { BrowserView, MobileView } from "react-device-detect";
// component ------------------------------------------>
import { CustomButton } from "../../common";

// constance ------------------------------------------>
const EditPasswordModal = ({
  showEditPassword,
  _handleEditPasswordClose,
  onEdit,
  userId,
}) => {
  // states -------------------------------------------------------------->
  const [strengthPass, setStrengthPass] = useState(0);
  const [checkPasswordLength, setCheckPasswordLength] = useState("");
  const [loginUserData, setLoginUserData] = useState([]);
  // apollo -------------------------------------------------------------->

  function checkpassword(password) {
    var strength = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!%_?]+/)) {
      strength += 1;
    }

    switch (strength) {
      case 0:
        setStrengthPass(0);
        break;

      case 1:
        setStrengthPass(25);
        break;

      case 2:
        setStrengthPass(50);
        break;

      case 3:
        setStrengthPass(75);
        break;

      case 4:
        setStrengthPass(100);
        break;
    }
  }
  const checkLength = (password) => {
    if (password && password.length < 6) {
      setCheckPasswordLength("ກະລຸນາປ້ອນໜ້ອຍສຸດຢ່າງໜ້ອຍ 6 ໂຕຂຶ້ນໄປ");
    } else {
      setCheckPasswordLength("");
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY);
    if (userData) {
      const user = JSON.parse(userData);
      setLoginUserData(user?.studentData?.id);
    }
  }, []);
  return (
    <div>
      <BrowserView>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validate={(values) => {
            checkpassword(values.password);
            checkLength(values.password);
            const errors = {};
            if (!values.password) {
              errors.password = "ກະລຸນາຕື່ມລະຫັດຜ່ານ";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "ກະລຸນາຍືນຍັນລະຫັດຜ່ານ";
            }
            if (
              values.confirmPassword &&
              values.confirmPassword != values.password
            ) {
              errors.confirmPassword = "ລະຫັດບໍ່ຕົງກັນ";
            }
            return errors;
          }}
          onSubmit={(values) => {
            if (values.password === values.confirmPassword) {
              let graphQL = {
                data: {
                  password: values.password,
                },
                where: {
                  id: loginUserData,
                },
              };
              onEdit(graphQL);
              _handleEditPasswordClose();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Modal
              show={showEditPassword}
              onHide={_handleEditPasswordClose}
              size="lg"
            >
              <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
                ກະລຸນາປ່ຽນລະຫັດ
              </Modal.Title>

              <Modal.Body
                style={{ marginLeft: 50, marginRight: 50, padding: 50 }}
              >
                <div style={{ border: "1px solid #eee", padding: 20 }}>
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ລະຫັດຜ່ານ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນ"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <div>
                        {/* <ProgressBar variant="progress-bar-low" now={100} /> */}
                        <Progress multi>
                          <Progress
                            bar
                            color="danger"
                            value={strengthPass >= 25 ? 25 : 0}
                          >
                            {strengthPass < 25 ? "" : "ຕ່ຳ"}
                          </Progress>
                          <Progress
                            bar
                            color="warning"
                            value={strengthPass >= 50 ? 25 : 0}
                          >
                            {strengthPass < 50 ? "" : "ກາງ"}
                          </Progress>
                          <Progress
                            bar
                            color="info"
                            value={strengthPass >= 75 ? 25 : 0}
                          >
                            {strengthPass < 75 ? "" : "ສູງ"}
                          </Progress>
                          <Progress
                            bar
                            color="success"
                            value={strengthPass >= 100 ? 25 : 0}
                          >
                            {strengthPass < 100 ? "" : "ສຸດຍອດ"}
                          </Progress>
                        </Progress>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                      {checkPasswordLength ? (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {checkPasswordLength}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Form.Group>

                  {/* ຍືນຍັນລະຫັດຜ່ານ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ຍືນຍັນລະຫັດຜ່ານ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນ"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
                <div style={{ height: 20 }} />
                <div className="row">
                  <div style={{ padding: 15 }} className="col">
                    <CustomButton
                      confirm
                      onClick={handleSubmit}
                      width="100%"
                      title="ຢືນຢັນ"
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Formik>
      </BrowserView>

      <MobileView>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validate={(values) => {
            checkpassword(values.password);
            checkLength(values.password);
            const errors = {};
            if (!values.password) {
              errors.password = "ກະລຸນາຕື່ມລະຫັດຜ່ານ";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "ກະລຸນາຍືນຍັນລະຫັດຜ່ານ";
            }
            if (
              values.confirmPassword &&
              values.confirmPassword != values.password
            ) {
              errors.confirmPassword = "ລະຫັດບໍ່ຕົງກັນ";
            }
            return errors;
          }}
          onSubmit={(values) => {
            if (values.password === values.confirmPassword) {
              let graphQL = {
                data: {
                  password: values.password,
                },
                where: {
                  id: loginUserData,
                },
              };
              onEdit(graphQL);
              _handleEditPasswordClose();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Modal
              show={showEditPassword}
              onHide={_handleEditPasswordClose}
              size="lg"
            >
              <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
                ກະລຸນາປ່ຽນລະຫັດ
              </Modal.Title>

              <Modal.Body>
                <div style={{ border: "1px solid #eee", padding: 20 }}>
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ລະຫັດຜ່ານ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນ"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <div>
                        {/* <ProgressBar variant="progress-bar-low" now={100} /> */}
                        <Progress multi>
                          <Progress
                            bar
                            color="danger"
                            value={strengthPass >= 25 ? 25 : 0}
                          >
                            {strengthPass < 25 ? "" : "ຕ່ຳ"}
                          </Progress>
                          <Progress
                            bar
                            color="warning"
                            value={strengthPass >= 50 ? 25 : 0}
                          >
                            {strengthPass < 50 ? "" : "ກາງ"}
                          </Progress>
                          <Progress
                            bar
                            color="info"
                            value={strengthPass >= 75 ? 25 : 0}
                          >
                            {strengthPass < 75 ? "" : "ສູງ"}
                          </Progress>
                          <Progress
                            bar
                            color="success"
                            value={strengthPass >= 100 ? 25 : 0}
                          >
                            {strengthPass < 100 ? "" : "ສຸດຍອດ"}
                          </Progress>
                        </Progress>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                      {checkPasswordLength ? (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {checkPasswordLength}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Form.Group>

                  {/* ຍືນຍັນລະຫັດຜ່ານ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ຍືນຍັນລະຫັດຜ່ານ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນ"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
                <div style={{ height: 20 }} />
                <div className="row">
                  <div style={{ padding: 15 }} className="col">
                    <CustomButton
                      confirm
                      onClick={handleSubmit}
                      width="100%"
                      title="ຢືນຢັນ"
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Formik>
      </MobileView>
    </div>
  );
};

export default EditPasswordModal;
