import React, { useEffect, useState, useRef } from "react";
import { MobileHeader } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileView } from "react-device-detect";
import {
  faEnvelope,
  faGlobe,
  faMapMarkerAlt,
  faPhoneSquareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Container, Navbar, Modal, Image } from "react-bootstrap";
import Consts from "../../consts";
import useReactRouter from "use-react-router";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

export default function Contact() {
  const { history } = useReactRouter();
  const makeDivHeight = useRef(0);
  return (
    <div className="body">
      <MobileHeader
        title="ຕິດ​ຕໍ່​ພວກ​ເຮົາ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <div style={{ padding: 16 }}>
        <Row
          style={{ marginTop: 30, marginLeft: 0, marginRight: 0 }}
          className="col-sm-12"
        >
          <div style={{ textAlign: "center" }} className="col-sm-12">
            <img
              style={{ width: 230, height: 120 }}
              src="/assets/logo-SLMS1.png"
            />
          </div>
        </Row>
        <Row className="col-sm-12" style={{ margin: 0 }}>
          <div style={{ textAlign: "center" }} className="col-sm-12">
            ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ​
          </div>
        </Row>
        <Row className="col-sm-12" style={{ margin: 0, marginTop: 18 }}>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: 60 }}>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="2x"
                  style={{ color: Consts.PRIMARY_COLOR }}
                />
              </td>
              <td>
                <div style={{ whiteSpace: "normal" }}>
                  ມະຫາວິທະຍາໄລແຫ່ງຊາດ, ວິທະຍາເຂດດົງໂດກ, ເມືອງໄຊທານີ,
                  ນະຄອນຫຼວງວຽງຈັນ, ສປປ ລາວ <br />{" "}
                  <a
                    href="https://www.google.la/maps/place/SLMS+Center/@18.0396435,102.6362092,96m/data=!3m1!1e3!4m5!3m4!1s0x31245d8ab2a82305:0x3b3ac0f062e7cced!8m2!3d18.0396497!4d102.6364749?hl=en"
                    style={{ color: Consts.PRIMARY_COLOR }}
                  >
                    ເບິ່ງ​ແຜ່ນ​ທີ່
                  </a>{" "}
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ width: 60 }}>
                <FontAwesomeIcon
                  icon={faPhoneSquareAlt}
                  size="2x"
                  style={{ color: Consts.PRIMARY_COLOR }}
                />
              </td>
              <td>021 771 989</td>
            </tr>
            <tr>
              <td style={{ width: 60 }}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="2x"
                  style={{ color: Consts.PRIMARY_COLOR }}
                />
              </td>
              <td>info.01slms@gmail.com</td>
            </tr>
            <tr>
              <td style={{ width: 60 }}>
                <FontAwesomeIcon
                  icon={faGlobe}
                  size="2x"
                  style={{ color: Consts.PRIMARY_COLOR }}
                />
              </td>
              <td>http://slms.laodevhub.com</td>
            </tr>
            <tr>
              <td style={{ width: 60 }}>
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="2x"
                  style={{ color: Consts.PRIMARY_COLOR }}
                />
              </td>
              <td style={{ whiteSpace: "normal" }}>
                ລະບົບບໍລິຫານຈັດການ ການຮຽນ-ການສອນ ທັນສະໄໝ
              </td>
            </tr>
          </table>
          <div style={{textAlign: 'center', width: "100%"}}>
            <p className="mt-5" />
            <p className="mt-5" />
            <p className="text-center" style={{ fontSize: 12 }}>
              ລິຂະສິດ <br />
              ກົມຊັບສິນທາງປັນຍາ ສະບັບເລກທີ 727, ລົງວັນທີ່ 19/08/2021.
              <br />
              ກະຊວງອຸດສາຫະກຳ ແລະ ການຄ້າ
              <br />
              Copyright ©️ 2021 Smart Learning Management System.
              <br />
              All Rights Reserved.
              <br />
              By Lao Development Hub and Consulting Sole Co. Ltd
            </p>
            <div className="text-center">
              <img src="/assets/laodevhub-logo.png" width="50px" />
            </div>
            <div style={{ height: makeDivHeight.current }} />
          </div>
        </Row>
      </div>
    </div>
  );
}
