import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { CustomSideNav, MobileHeader } from "../../common";
import TimeListMobile from "./TimeListMobile";
import NewTimeListMobile from "./NewTimeListMobile";
import TimeListWeb from "./TimeListWeb";
import CustomSideNavMobile from "../../common/CustomSideNavMobile";

function TimeList() {
  const { history, location } = useReactRouter();
  const [showNav, setShowNav] = useState(false);
  const closeNav = () => {
    setShowNav(false);
  };

  return (
    <div style={{ marginTop: 60 }}>
      <BrowserView>
        <TimeListWeb />
      </BrowserView>
      <MobileView>
        <MobileHeader
          title="ຕາຕະລາງຮຽນ"
          showLeftArrow
          leftButtonClick={() => history.push("/dashboard")}
        />
        <NewTimeListMobile />
      </MobileView>
    </div>
  );
}

export default TimeList;
