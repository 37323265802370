import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@mui/material/Pagination";
import { MobileHeader } from "../../common";
import useReactRouter from "use-react-router";
import "./index.css";
import axios from "axios";
import { Typography } from "@material-ui/core";

export const ScopusPage = () => {
  const [selectedFilter, setSelectedFilter] = React.useState("");
  const { history, location, match } = useReactRouter();

  const [keyword, setKeyword] = useState("");

  const onInputChange = (event) => {
    setKeyword(event.target.value);
  };

  const [result, setResult] = useState([]);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const onSearch = async () => {
    try {
      setLoading(true);
      setResult([]);
      const countPerPage = 10; // Number of results per page

      const start = (page - 1) * countPerPage; // Calculate the starting point

      const response = await axios.get(
        `https://api.elsevier.com/content/search/scopus?query=${keyword}&count=${countPerPage}&start=${start}&apiKey=716138752e96bfba2e969d44c25eac3d`
      );

      if (response.data && response.data["search-results"]) {
        // Access the 'search-results' field in the response
        const searchResults = response.data["search-results"];
        // Assuming 'entry' is an array within 'search-results'
        const entries = searchResults.entry;
        // Do something with 'entries'

        setResult(entries);
        setLoading(false);
      } else {
        console.log("No search results found.");
      }
    } catch (error) {
      console.log("Error:");
      console.error(error);
    }
  };
  const goToScopusPage = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Scopus URL not found in the response.");
    }
  };

  useEffect(() => {
    if (keyword) onSearch();
  }, [page]);

  const handlePageChange = (event, page) => {
    setPage(page);
    // You can now use the "page" value to know the selected page.
    console.log("Selected Page:", page);
    // You can perform any actions you need with the selected page.
  };

  return (
    <div className="container">
      <MobileHeader
        showLeftArrow
        title={"Scopus"}
        leftButtonClick={() => history.goBack()}
      />
      <div className="row justify-content-center align-items-center mt-5 pt-3">
        <div className="col-12 col-md-8 col-lg-8 mt-3">
          <div className="d-flex align-items-center w-100 pl-5 ml-3">
            <img className="scopus-logo" src="/scopus.png" alt="Scopus Logo" />
          </div>
          <div className="d-flex align-items-center bg-white px-2  mt-3 scopus-search">
            <div className="d-flex align-items-center mr-2">
              <IconButton onClick={onSearch} aria-label="search">
                <SearchIcon />
              </IconButton>
            </div>
            <InputBase
              value={keyword}
              // onKeyDown={onSearch}
              onChange={onInputChange}
              placeholder="ຄົ້ນຫາບົດຄວາມ"
              inputProps={{ "aria-label": "search" }}
              style={{ flex: 1 }}
            />
          </div>
        </div>

        {result.length > 0 && (
          <div className="mt-4">
            <div className="mt-2">
              {result.map((item, index) => (
                <div key={index} className="bg-white mb-3 p-2 mx-3">
                  <h5
                    onClick={() => goToScopusPage(item.link[2]["@href"])}
                    className="scopus-link"
                  >
                    {"ຫົວຂໍ້: " + item["dc:title"]}
                  </h5>
                  <br></br>
                  <p> {"ຜູ້ຂຽນ: " + item["dc:creator"]}</p>
                  <br></br>
                  <p> {"ສຳນັກພິມ: " + item["prism:publicationName"]}</p>
                  <br></br>
                  <p> {"ຫມວດຫມຸ່: " + item["prism:aggregationType"]}</p>
                </div>
              ))}
            </div>
            <div className="mt-3 mb-5 text-center d-flex justify-content-center w-100">
              <Pagination
                count={10}
                variant="outlined"
                page={page}
                onChange={handlePageChange}
              />
            </div>
          </div>
        )}

        {loading && (
          <div className="text-center mt-5 pt-5">
            <div>
              <CircularProgress />
            </div>
            <div className="mt-2">
              <Typography>ກຳລັງຄົ້ນຫາ...</Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
