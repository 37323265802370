import { gql } from "apollo-boost";

export const CREATE_GRADE = gql`
  mutation CreateGrade($data: CustomGradeCreateInput!) {
    createGrade(data: $data) {
      id
    }
  }
`;

export const GRADES = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      data {
        id
        gradeType {
          name
          score
          maxScore
        }
        student {
          id
          userId
          firstNameL
          lastNameL
          role
          yearLevel
          assessmentYear
          email
          phoneMobile
          note
        }
        course {
          isUpgrade
          id
          title
          courseCode
          year
          semester
          startDate
          assessmentYear
        }
        numberTest
        sendingGradeStatus
        numbericGrade
        letterGrade
        gredeStatus
        yearLevel
        semester
        createdAt
        createdBy
        updatedBy
      }
    }
  }
`;
export const GRADES_FILTER = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      data {
        id
        gradeType {
          name
          score
          maxScore
        }
        student {
          id
          userId
          firstNameL
          lastNameL
          role
          yearLevel
          assessmentYear
          email
          phoneMobile
          note
        }
        course {
          isUpgrade
          id
          title
          courseCode
          year
          semester
          startDate
          assessmentYear
        }
        numberTest
        sendingGradeStatus
        numbericGrade
        letterGrade
        gredeStatus
        yearLevel
        semester
        createdAt
      }
    }
  }
`;

export const DELETE_GRADE = gql`
  mutation DeleteGrade($where: GradeWhereUniqueInput!) {
    deleteGrade(where: $where) {
      id
    }
  }
`;

export const UPGRADE_GRADE = gql`
  mutation UpgradeGrade($where: GradeWhereUniqueInput!) {
    upgradeGrade(where: $where) {
      id
    }
  }
`;

export const GRADE = gql`
  query Grade($where: GradeWhereUniqueInput!) {
    grade(where: $where) {
      id
      course {
        id
        title
        courseCode
      }
      gredeStatus
      yearLevel
      semester
      createdAt
    }
  }
`;
