// import React from "react";
// import useReactRouter from "use-react-router";
// import { Card, Col, Divider, Row} from 'antd';

// import "./index.css"
// const Proquest = () => {
//     const { history, match } = useReactRouter();
//     const { Meta } = Card;
//     const NavigateToResearch = (uri) => {
//         history.push(uri);
//       };

//   return (
//     <div>
//         <div className="pt-3"> 
//             <div>
//                 <h1>Hello ProQuest</h1>
//             </div>
//         </div>
//     </div>
//   );
// };

// export default Proquest;


import React from "react";
import useReactRouter from "use-react-router";
import { BrowserView, MobileView } from "react-device-detect";

import { MobileHeader } from "../../../common";
import Webofsciencewebsite from "./WebOfScienceWebsite";
import Webofsciencemobile from "./WebOfScienceMobile";
const Proquest = () => {
    const { history, match } = useReactRouter();
  return (
    <div>
      <BrowserView>
        <Webofsciencewebsite />
      </BrowserView>
      <MobileView>
      <MobileHeader
          title="ບົດຄົ້ນຄວ້າ Web Of Science"
          showLeftArrow
          leftButtonClick={() => {history.goBack()}}
        />
        <Webofsciencemobile />
      </MobileView>
    </div>
  );
};

export default Proquest;



