import React from "react";
import "./index.css"
const ProquestWebsite = () => {
  return (
    <div>
      <div className="proquest-container">
        <h1 style={{color:"grey", fontSize:"30px"}}>ProQuest</h1>
        <h1 style={{color:"grey", fontSize:"40px"}}>Comming Soon</h1>
      </div>
    </div>
  );
};

export default ProquestWebsite;