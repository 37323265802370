import React, {useState} from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import './navBar.css'
// component -------------------------------------------------------->
import Profile from "../pages/profile/Profile";
// const ------------------------------------------------------------>
import { USER_KEY } from "../consts/user";
import { STUDENT } from "../apollo/user";
import { useQuery } from "@apollo/react-hooks";
import Consts from "../consts";
import { Button, Modal } from 'antd';
import {WarningOutlined} from '@ant-design/icons';

export default function NavBar({ handleShowUserProfile }) {
  const { history } = useReactRouter();
  const [userLoginData, setUserLoginData] = useState();
  const [showProfileModal, setShowProfileModal] = useState();
  const [showConfirmLogouModal, setShowConfirmLogoutModal] = useState(
    false
  );
  // apollo ------------------------------------------>
  const { data } = useQuery(STUDENT, {
    variables: { where: { id: userLoginData && userLoginData.id } },
  });
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user) {
      setUserLoginData(user.studentData);
    }
  }, []);
  const _onConfirmLogout = async () => {
    await localStorage.clear();
    history.push("/");
  };
  // modal
  const _handleShowProfileModal = () => setShowProfileModal(true);
  const _handleCloseProfileModal = () => setShowProfileModal(false);
  const _onClickLogout = () => setShowConfirmLogoutModal(true);

  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleCancel = () => {
    setShowConfirmLogoutModal(false);
  };


  return (
    <div>
      <Navbar fixed="top" style={{
        backgroundColor: '#fff',
        boxShadow: '3px 0px 3px rgba(0, 0, 0, 0.16)',
        color: '#CC0000',
        width: '100%',
        height: 64,
        position: 'fixed',
        zIndex: 1,
        marginLeft: 64,
        paddingRight: 80,
        zIndex: 1001
      }} bg="white">
        <Navbar.Collapse className="justify-content-between">
          <Nav>
            <div style={{ fontSize: 12, color: "grey" }}>{Consts.VERSION}</div>
          </Nav>
          <Nav>
            <NavDropdown
              title={
                userLoginData && userLoginData.firstNameL
                  ? (userLoginData.firstNameL + ' ' + (userLoginData.lastNameL ? userLoginData.lastNameL : ''))
                  : "ຊື່ຜູ້ໃຊ້"
              }
              id="dropdown-menu-align-right"
              alignRight
            >
              <NavDropdown.Item onClick={_handleShowProfileModal}>
                <FontAwesomeIcon style={{ color: "#057CAE" }} icon={faUser} /> ຂໍ້ມູນຜູ້ໃຊ້
              </NavDropdown.Item>
              <NavDropdown.Item onClick={_onClickLogout}>
                <FontAwesomeIcon style={{ color: "#057CAE" }} icon={faSignOutAlt} /> ອອກຈາກລະບົບ
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Profile
        showUserProfile={showProfileModal}
        _handleShowUserProfile={_handleCloseProfileModal}
        data={data}
      />
      


      <Modal
        centered
        open={showConfirmLogouModal}
        onCancel={handleCancel}
        footer={null}
        >
        <div className="text-center">
        <p className="mb-4"><WarningOutlined style={{ fontSize: '80px', color: '#FF7F50' }} theme="outlined" /></p>
        <h3><b>ຕ້ອງການອອກຈາກລະບົບແທ້ບໍ ?</b></h3>
        <div className="mt-5">
            <hr />
            <Button type="primary" className="mx-3" size="large" onClick={_onConfirmLogout}>ຕົກລົງ</Button>
            <Button type="primary" danger className="mx-3" size="large" onClick={handleCancel}>ຍົກເລີກ</Button>
        </div>
        </div>
      </Modal>


    </div>
  );
}
