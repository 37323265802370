import React from "react";
import moment from "moment";
// import 'moment/locale/lo'

// Convert gender to english
export const setGenderE = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "FEMALE":
      res = "Ms.";
      break;
    case "MALE":
      res = "Mr.";
      break;
    default:
      return res;
  }
  return res;
};

// ຄອນເວິດ ເພດ
export const setGender = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "FEMALE":
      res = "ຊາຍ";
      break;
    case "MALE":
      res = "ຍິງ";
      break;
    case "OTHER":
      res = "ອື່ນໆ";
      break;
    default:
      return res;
  }
  return res;
};
export const setGenders = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "FEMALE":
      res = "ນາງ.";
      break;
    case "MALE":
      res = "ທ້າວ.";
      break;
    case "OTHER":
      res = "ອື່ນໆ";
      break;
      default:
        return res;
  }
  return res;
};
// ຄອນເວິດ ສະຖານະປິດເປີດ
export const setSwich = (string) => {
  let res = "";
  switch (string) {
    case true:
      res = "ເປີດ";
      break;
    case false:
      res = "ປິດ";
      break;
      default:
        return res;
  }
  return res;
};

// Convert role
export const role = (role) => {
  let rl = "";
  switch (role) {
    case "STUDENT":
      rl = "ນັກສຶກສາ";
      break;
  }
  return rl;
};

// ຄອນເວິດ ຄ່າເລີ່ມຕົ້ນ(ເພດ)
export const checkgender = (gender) => {
  let gd = "";
  switch (gender) {
    case "FEMALE":
      gd = "checked";
      break;
    case "MALE":
      gd = "checked";
      break;
    case "OTHER":
      gd = "checked";
      break;

  }
  return gd;
};

// ຄອນເວິດ ສະຖານະພາບ
export const noticeStatus = (notice) => {
  let status = "";
  switch (notice) {
    case "IMPORTANT":
      status = "ເອກະສານທີ່ສຳຄັນ";
      break;
    case "NORMAL":
      status = "ທົ່ວໄປ";
      break;
    case "NOTIC":
      status = "ແຈ້ງການ";
      break;
    case "AGREEMENT":
      status = "ຂໍ້ຕົກລົງ";
      break;
    case "REGULATION":
      status = "ສະຖານະພາບ";
      break;
    case "NEWS":
      status = "ໜັງສືພິມ";
      break;
      default:
        return status;
  }
  return status;
};
export const upgradeStatus = (upgradeStatus) => {
  let status = "ລໍຖ້າອະນຸມັດ";
  switch (upgradeStatus) {
    case "REQUESTING":
      status = "ລໍຖ້າອະນຸມັດ";
      break;
    case "APROVED":
      status = "ລົງທະບຽນສຳເລັດ";
      break;
    case "REJECTED":
      status = "ປະຕິດເສດ";
      break;
    case "FINISHED":
      status = "ແກ້ເກຣດແລ້ວ";
      break;
    default:
        return status;
  }
  return status;
};
// ຄອນເວິດ ສະຖານະພາບ
export const MaritualStatus = (maritualStatus) => {
  let status = "";
  switch (maritualStatus) {
    case "SINGLE":
      status = "ໂສດ";
      break;
    case "MARRIAGE":
      status = "ເເຕ່ງງານ";
      break;
    default:
        return status;
  }
  return status;
};

export const sc = (tag) => {
  let st = "'";
  let en = "'";
  let i = st + tag + en;
  return i;
};

//  ຄອນເວິດ ອາຍຸ
export const age = (age) => {
  let today = new Date();
  let y = today.getFullYear();
  let dob = moment(age).format("YYYY");
  return y - dob;
};

// ຄອນເວິດ ຟໍແມັດເງິນ
export const currency = (value) => {
  let currencys = new Intl.NumberFormat("en-CA").format(value);
  return currencys;
};

// ຄອນເວິດ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const formatDateTime = (dateTime) => {
  let date;
  if (dateTime) {
    date = new Date(dateTime);
  } else {
    date = new Date();
  }
  let h = date.getHours();
  let m = date.getMinutes();
  if (h.toString().length < 2) {
    h = "0" + h;
  }
  if (m.toString().length < 2) {
    m = "0" + m;
  }
  let str = `${date.getDate()} ${convertMonth(
    date.getMonth() + 1
  )} ${date.getFullYear()}, ${h}:${m}`;
  return str;
};

// ຄອນເວິດ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const formatDateWithoutTime = (dateTime) => {
  let date;
  if (dateTime) {
    date = new Date(dateTime);
  } else {
    date = new Date();
  }
  let str = `${date.getDate()} ${convertMonth(date.getMonth() + 1)} ${date.getFullYear()}`;
  return str;
};

const convertMonth = (month) => {
  let strMonth = "";
  switch (month) {
    case 1:
      strMonth = "ມັງກອນ";
      break;
    case 2:
      strMonth = "ກຸມພາ";
      break;
    case 3:
      strMonth = "ມີນາ";
      break;
    case 4:
      strMonth = "ເມສາ";
      break;
    case 5:
      strMonth = "ພຶດສະພາ";
      break;
    case 6:
      strMonth = "ມີຖຸນາ";
      break;
    case 7:
      strMonth = "ກໍລະກົດ";
      break;
    case 8:
      strMonth = "ສິງຫາ";
      break;
    case 9:
      strMonth = "ກັນຍາ";
      break;
    case 10:
      strMonth = "ຕຸລາ";
      break;
    case 11:
      strMonth = "ພະຈິກ";
      break;
    case 12:
      strMonth = "ທັນວາ";
      break;
    default:
      strMonth = "ມັງກອນ";
  }
  return strMonth;
};

// ຄອນເວິດ ວັນທີປັດຈຸບັນ(ພາສາລາວ)
export const formatDate = (dateTime) => {
  moment.locale("lo");
  let resp = moment(dateTime).format("DD MMMM YYYY");
  return resp;
};

// ຄອນເວິດ ວັນທີປັດຈຸບັນ(/)
export const formateDateSlash = (slashData) => {
  let resp = moment(slashData).format("YYYY/MM/DD");
  return resp;
};

// ຄອນເວິດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate) => {
  let resp = moment(dashDate).format("YYYY-MM-DD");
  return resp;
};

// ຄອນເວິດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ອາທິດ)
export const nextSevenDay = () => {
  var nextSenvenDay = moment().add(7, "days");
  var nextSevenDays = moment(nextSenvenDay).format("YYYY-MM-DD");
  return nextSevenDays;
};

// ຄອນເວິດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ມື້)
export const nextOneDay = () => {
  var nextOneDay = moment().add(1, "days");
  var nextOneDays = moment(nextOneDay).format("YYYY-MM-DD");
  return nextOneDays;
};

// ຄອນເວິດ ວັນທີ ແລະ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const toDay = () => {
  moment.locale("lo");
  var today = new Date();
  var todays = moment(today).format("DD/MM/YY, HH:mm");
  return todays;
};

// ຄອນເວິດ ວັນທີປັດຈຸບັນ(-)
export const toDayDash = () => {
  var today = new Date();
  var todays = moment(today).format("YYYY-MM-DD");
  return todays;
};

export const reconvertDay = (day) => {
  let result = "";
  switch (day) {
    case "MONDAY":
      result = "ຈັນ";
      break;
    case "TUESDAY":
      result = "ອັງຄານ";
      break;
    case "WEDNESDAY":
      result = "ພຸດ";
      break;
    case "THURSDAY":
      result = "ພະຫັດ";
      break;
    case "FRIDAY":
      result = "ສຸກ";
      break;
    case "SATURDAY":
      result = "ເສົາ";
      break;
    case "SUNDAY":
      result = "ອາທິດ";
      break;
    default:
      result = "ຈັນ";
  }
  return result;
};
// ຄອນເວິດ ເຄື່ອງໝາຍ ບັງຄັບໃຫ້ປ້ອນຂໍ້ມູນ
export const valiDate = () => {
  return <font style={{ color: "red" }}> * </font>;
};

export const convertWeek = (week) => {
  let result = "";
  switch (week) {
    case "ALL":
      result = "ທຸກອາທິດ";
      break;
    case "SINGLE":
      result = "ອາທິດຄີກ";
      break;
    case "DOUBLE":
      result = "ອາທິດຄູ່";
      break;
    default:
      result = "ທຸກອາທິດ";
  }
  return result;
};