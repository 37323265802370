import { gql } from "apollo-boost";

export const GET_FACULTIES = gql`
  query Faculty(
    $where: CustomFacultyWhereInput
    $orderBy: OrderDataInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    faculties(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      name
      description
    }
  }
`;

export const FACULTIES = gql`
  query Faculties(
    $where: CustomFacultyWhereInput
    $orderBy: OrderDataInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    faculties(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
        id
        name
        departments{
            id
            name
        }
    }
  }
`