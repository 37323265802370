import React from "react";
import { CustomButton, MobileHeader } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useReactRouter from "use-react-router";
import Consts from "../../consts";
import "./index.css";
import { useQuery } from "@apollo/react-hooks";
import { GRADES } from "./../../apollo/grade";
import { USER_KEY } from "../../consts/user";
import { upgradeStatus } from "../../super";
const UpgradeMobileView = () => {
  const { history } = useReactRouter();
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  const { data, error, loading } = useQuery(GRADES, {
    variables: {
      where: {
        // student: user && user.studentData && user.studentData.id,
        student: user && user.studentData && user.studentData.userId,
        numberTest: 2,
      },
    },
  });

  const _registerToUpgrade = () => {
    history.push("/register-to-upgrade");
  };
  const _handleDetail = (id) => {
    history.push(`/upgrade-detail/${id}`);
  };
  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <MobileHeader
        title="ລາຍ​ການ​ອັບ​ເກ​ຣດ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <br />
      {/* body div */}
      <div style={{ backgroundColor: "#eee", padding: 10 }}>
        <br />
        <br />
        <div className="row">
          <div className="container-fluid">
            <CustomButton
              title="ເພີ່ມລາຍ​​ການ​ອັບ​ເກ​ຣດ"
              addIcon
              onClick={_registerToUpgrade}
              confirm
              width={180}
            />
            <p style={{ marginTop: 10 }}>
              ລວມທັງໝົດ {data?.grades?.total ?? "0"}
            </p>
            {data?.grades?.data.map((grade, index) => (
              <div
                key={index}
                style={{
                  position: "relative",
                  backgroundColor: "#fff",
                  width: "100%",
                  height: 90,
                  padding: 10,
                  marginTop: 12,
                }}
                onClick={() => _handleDetail(grade?.id)}
              >
                <div
                  style={{
                    color: "#929292",
                  }}
                >
                  <p
                    style={{
                      float: "left",
                      fontSize: 16,
                    }}
                  >
                    {grade?.course?.courseCode ?? "-"}
                  </p>
                  <h6
                    style={{
                      float: "right",
                      marginRight: 20,
                      fontSize: 16,
                    }}
                  >
                    ພາກຮຽນ {grade?.semester ?? "-"}, ປີ{" "}
                    {grade?.yearLevel ?? "-"}
                  </h6>
                </div>
                <div
                  style={{
                    position: "absolute",
                    float: "left",
                    left: 10,
                    marginTop: 20,
                    clear: "left",
                    clear: "right",
                  }}
                >
                  <h5>{grade?.course?.title ?? "-"}</h5>
                  ສະຖານະ:{" "}
                  <span
                    style={{
                      color:
                        grade?.gredeStatus === "REQUESTING"
                          ? "#F29702"
                          : grade?.gredeStatus === "APROVED" ||
                            grade?.gredeStatus === "FINISHED"
                          ? "#1B8900"
                          : "red",
                    }}
                  >
                    {grade?.gredeStatus
                      ? upgradeStatus(grade?.gredeStatus)
                      : "-"}
                  </span>
                </div>
                <FontAwesomeIcon
                  icon={["fa", "chevron-right"]}
                  color={Consts.PRIMARY_COLOR}
                  style={{
                    position: "absolute",
                    left: "92%",
                    bottom: 30,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpgradeMobileView;
