export const VIDEOS = [
  {
    id: "1",
    image: "https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/luang-voun2.jpg",
    name: "ເລື່ອງວຸ້ນໆໃນມື້ເປີດສົກຮຽນ (SLMS)",
    video: "https://www.youtube.com/embed/BZMqRl2_juY",
    description: "ບົດສຳພາດ ທ່ານອະທິການບໍດີມະຫາວິທະຍາໄລແຫ່ງຊາດ, ຄະນະບໍດີ ຄະນະວິທະຍາສາດ ແລະ ທຳມະຊາດ, ຮອງປະທານບໍລິສັດ ຮັບພັດທະນາລາວ ແລະ ທີ່ປຶກສາ ຈຳກັດຜູ້ດຽວ ກ່ຽວກັບຄວາມເປັນມາ ແລະ ຄວາມສຳຄັນຂອງລະບົບ ບໍລິຫານ-ຈັດການ ການຮຍນ-ການສອນ ທັນສະໄໝ (ລຮສທ)"
  },
  {
    id: "2",
    image: "https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/slms.jpg",
    name: "ຄວາມໝາຍ ແລະ ຄວາມສຳຄັນຂອງລະບົບ SLMS",
    video: "https://www.youtube.com/embed/-nzyZiMabx4",
    description: "ບົດສຳພາດ ທ່ານອະທິການບໍດີມະຫາວິທະຍາໄລແຫ່ງຊາດ, ຄະນະບໍດີ ຄະນະວິທະຍາສາດ ແລະ ທຳມະຊາດ, ຮອງປະທານບໍລິສັດ ຮັບພັດທະນາລາວ ແລະ ທີ່ປຶກສາ ຈຳກັດຜູ້ດຽວ ກ່ຽວກັບຄວາມເປັນມາ ແລະ ຄວາມສຳຄັນຂອງລະບົບ ບໍລິຫານ-ຈັດການ ການຮຍນ-ການສອນ ທັນສະໄໝ (ລຮສທ)"
  },
  {
    id: "3",
    image: "https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/step-register.jpg",
    name: "ຂັ້ນຕອນການລົງທະບຽນວິຊາຮຽນໃນແອັບ SLMS",
    video: "https://www.youtube.com/embed/3Vk5-Mulm2k",
    description: "ບົດສຳພາດ ທ່ານອະທິການບໍດີມະຫາວິທະຍາໄລແຫ່ງຊາດ, ຄະນະບໍດີ ຄະນະວິທະຍາສາດ ແລະ ທຳມະຊາດ, ຮອງປະທານບໍລິສັດ ຮັບພັດທະນາລາວ ແລະ ທີ່ປຶກສາ ຈຳກັດຜູ້ດຽວ ກ່ຽວກັບຄວາມເປັນມາ ແລະ ຄວາມສຳຄັນຂອງລະບົບ ບໍລິຫານ-ຈັດການ ການຮຍນ-ການສອນ ທັນສະໄໝ (ລຮສທ)"
  },
  {
    id: "4",
    name: "SLMS Application",
    image: "https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/slms-app.jpg",
    video: "https://www.youtube.com/embed/Eq8uYjDdFFc",
    description: "ບົດສຳພາດ ທ່ານອະທິການບໍດີມະຫາວິທະຍາໄລແຫ່ງຊາດ, ຄະນະບໍດີ ຄະນະວິທະຍາສາດ ແລະ ທຳມະຊາດ, ຮອງປະທານບໍລິສັດ ຮັບພັດທະນາລາວ ແລະ ທີ່ປຶກສາ ຈຳກັດຜູ້ດຽວ ກ່ຽວກັບຄວາມເປັນມາ ແລະ ຄວາມສຳຄັນຂອງລະບົບ ບໍລິຫານ-ຈັດການ ການຮຍນ-ການສອນ ທັນສະໄໝ (ລຮສທ)"
  },
  {
    id: "5",
    name: "ຟັງຊັ່ນຕະຕາລາງໃນລະບົບ SLMS",
    image: "https://nuol.s3.ap-southeast-1.amazonaws.com/dev/table.png",
    video: "https://www.youtube.com/embed/4SJqqcu7qao",
    description: "ລະບົບ SLMS ຂອງພວກເຮົາມີຟັງຊັ່ນ \nຕາຕາລາງຮຽນໄວ້ໃຫ້ນ້ອງໆນັກສຶກສາໄດ້ເບີ່ງ \nລາຍລະອຽດຕ່າງໆເຊັ່ນ: ຊົ່ວໂມງຮຽນ, ວິຊາຮຽນ, ຫ້ອງຮຽນ..."
  },
  {
    id: "6",
    name: "ຟັງຊັ່ນຄະແນນ ຂອງລະບົບ SLMS",
    image: "https://nuol.s3.ap-southeast-1.amazonaws.com/dev/grade.png",
    video: "https://www.youtube.com/embed/IL869NtO4bY",
    description: "ນ້ອງໆນັກສຶກສາສາມາດເບີ່ງຄະແນນຂອງຕົນເອງໄດ້ງ່າຍໆ \nເທິງມືຖືຜ່ານລະບົບ SLMS ໂດຍບໍ່ຕ້ອງເສຍເວລາເຂົ້າໄປ \nເບີ່ງຢູ່ກະດານຂ່າວໃຫ້ເສຍເວລາຄືແຕ່ກ່ອນອີກ"
  },
  
];