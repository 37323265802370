import { gql } from "apollo-boost";

export const COURSES = gql`
  query Courses(
    $where: CustomCourseWhereInput
    $orderBy: CourseOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    courses(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
    	data{
        id
        courseCode
        moodleCourseId
        title
        unit
        year
        semester
        description
        note
        teacher {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

export const COURSE_FACULTY_DEPARTMENT = gql`
  query CourseAndFacultyAndDepartmentAndMajor(
    $where: CourseWhereUniqueInput!
    ) {
      courseAndFacultyAndDepartmentAndMajor(where: $where) {
        dataCourse {
        id
        courseCode
        title
        faculty {
          id
          name
        }
        department {
          id
          name
        }
        syllabusFile {
          file
          title
        }
        files {
          id
          description
          title
          file
          createdAt
        }
        addressLearn
        dayTimeIndexes {
          day
          timeIndexes
        }
        teacher {
          id
          userId
          firstname
          lastname
        }
        gradeType{
          name
          detail
          score
          maxScore
        }
        isRegistration
        moodleCourseId
        assessmentYear
        year
        semester
        unit
        description
        note
        createdAt
        updatedAt
      }
    dataDepartment{
        id
        department{
          id
          name
        }
        faculty{
          id
          name
        }
    }
    dataFaculty{
        id
        faculty{
          id
          name
        }
    }
    }
  }
`;

export const GET_COURSE = gql`
  query Course($where: CourseWhereUniqueInput!) {
    course(where: $where) {
      id
      courseCode
      title
      department {
        id
        name
      }
      faculty {
        id
        name
      }
      syllabusFile {
        id
        file
        title
      }
      files {
        id
        description
        title
        file
        createdAt
      }
      addressLearn
      dayTimeIndexes {
        day
        timeIndexes
      }
      teacher {
        id
        userId
        firstname
        lastname
      }
      gradeType {
        name
        detail
        score
        maxScore
      }

      year
      semester
      unit
      description
      note
      createdAt
      updatedAt
      isRegistration
      endDateRegister
      isUpgrade
    }
  }
`;

export const CREATE_COURSE = gql`
  mutation CreateCourse($data: CourseCreateInput!) {
    createCourse(data: $data) {
      id
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation UpdateCourse(
    $data: CourseUpdateInput!
    $where: CourseWhereUniqueInput!
  ) {
    updateCourse(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation DeleteCourse($where: CourseWhereUniqueInput!) {
    deleteCourse(where: $where) {
      id
    }
  }
`;
