import React, { useEffect, useState } from "react";
import { Col, Row, Container, Navbar, Image, Button } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { USER_KEY } from "../../consts/user";
import { STUDENT } from "../../apollo/user";
import { useLazyQuery } from "@apollo/react-hooks";
import {CustomButton} from "../../common/CustomButton"
import { setGenders } from "../../super";
import Consts from "../../consts"
import "../../index.css";
import DevelopingModal from "../../common/DevelopingModal";
import NavbarFooterApp from "../../common/NavbarFooterApp";
const ListMessenger = () => {
  /**
   *
   */
  const { history, location } = useReactRouter();

  const active = location.state;

  /**
   * use states
   */
  const [showDeveloping, setShowDeveloping] = useState(false);

  /**
   * apollo
   */
  const [loadStudent, { data }] = useLazyQuery(STUDENT);

  /**
   * use effect
   */
  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY);
    if (userData) {
      const user = JSON.parse(userData);
      loadStudent({ variables: { where: { id: user?.studentData?.id } } });
    }
  }, []);

  return (
    // style={{ height: "100vh", backgroundColor: "white" }}

    <div className="body">
      <Navbar expand="lg" fixed="top" variant="light" className="header">
        <Container className="justify-content-center">
          <p
            style={{
              fontSize: "20px",
              color: "#FFFFFF",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            ກ່ອງຂໍ້ຄວາມ
          </p>
        </Container>
      </Navbar>
      <Row>
        <Col xs={12}>
          <div style={{ marginTop: "20%", textAlign: "center" }}>
            <Image src="/assets/welcom.png" width="75%" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="10" style={{ marginLeft: 28 }}>
          <h4 style={{ color: "##4b4b4b" }}>
            <strong>ສະບາຍດີ, ຍິນດີຕອນຮັບ</strong>
          </h4>
          <div style={{ color: "#4b4b4b" }}>
            ເຂົ້າສູ່ ລະບົບບໍລິຫານຈັດການ ການຮຽນ-ການສອນ ທັນສະໄໝ
          </div>
          <h6 style={{ marginTop: 8, color: "#4b4b4b" }}>
            <strong>ຕຽມພົບກັບ Features ໃໝ່ໆໄວໆນີ້</strong>
          </h6>
          <br />
          <div>
            <button 
            style={{backgroundColor: Consts.PRIMARY_COLOR}}
            onClick={()=> history.push('/dashboard/')}
            className="btn btn-primary btn-block">
              ເລີ່ມຕົ້ນໃຊ້ງານ
            </button>
          </div>
        </Col>
      </Row>

      <NavbarFooterApp active={active} />
      <DevelopingModal
        show={showDeveloping}
        handleClose={() => setShowDeveloping(false)}
      />
    </div>
  );
};
const styles = {
  title: {
    fontSize: 12,
  },
  iconStyle: {
    fontSize: "2.3em",
    height: 50,
    color: "#057CAE",
  },
};

const stylesFooter = {
  title: {
    fontSize: 12,
  },
  iconStyle: {
    fontSize: "2.3em",
    height: 50,
    color: "#C7C7C7",
  },
};
export default ListMessenger;
