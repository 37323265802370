import React from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import Consts from "../consts";
import { BrowserView, MobileView } from "react-device-detect";

import CustomSideNavWeb from "./CustomSideNavWeb";
import CustomSideNavMobile from "./CustomSideNavMobile";

function CustomSideNav({ location, history }) {
  return (
    <div>
      <BrowserView>
        <CustomSideNavWeb location={location} history={history} />
      </BrowserView>
      <MobileView>
        <CustomSideNavMobile location={location} history={history} />
        <CustomSideNavMobile location={location} history={history} />
      </MobileView>
    </div>
  );
}

export default CustomSideNav;
