import React, { useEffect, useState } from "react";
import { Row, Container, Navbar } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { USER_KEY } from "../../consts/user";
import { STUDENT } from "../../apollo/user";
import { useLazyQuery } from "@apollo/react-hooks";
import "../../index.css";
import DevelopingModal from "../../common/DevelopingModal";
import NavbarFooterApp from "../../common/NavbarFooterApp";
import { formatDateWithoutTime } from "../../super";

import { NOTIFICATIONS } from "../../apollo/notification";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

const ListNotification = () => {
  /**
   *
   */
  const { history, location } = useReactRouter();
  const active = location.state;
  /**
   * use states
   */
  const [showDeveloping, setShowDeveloping] = useState(false);
  const [dataNotification, setDataNotification] = useState();
  const [notificationCount, setNotificationCount] = useState();

  /**
   * apollo
   */
  const [loadStudent] = useLazyQuery(STUDENT);
  const [loadNotifications, { data: notificationsData }] =
    useLazyQuery(NOTIFICATIONS);

  /**
   * use effect
   */
  useEffect(() => {
    const userData = localStorage.getItem(USER_KEY);
    if (userData) {
      const user = JSON.parse(userData);
      loadStudent({ variables: { where: { id: user?.studentData?.id } } });
    }
    loadNotifications({
      variables: {
        orderBy: "createdAt_DESC",
      },
    });
  }, []);

  useEffect(() => {
    setDataNotification(notificationsData?.notifications);
    setNotificationCount(notificationsData?.notifications.length);
  }, [notificationsData]);

  const _reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className="body">
      <Navbar expand="lg" fixed="top" variant="light" className="header">
        <Container className="justify-content-between">
          <p></p>
          <p
            style={{
              fontSize: "20px",
              color: "#FFFFFF",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
             ການແຈ້ງເຕືອນ
          </p>
          <div onClick={() => _reloadPage()}>
            <FontAwesomeIcon
              icon={faSyncAlt}
              style={{ marginRight: 14, color: "white", fontSize: 24 }}
            />
          </div>
        </Container>
      </Navbar>
      <Row>
        <div
          style={{
            marginTop: 78,
            height: window.innerHeight,
            overflow: "scroll",
          }}
          className="col-sm-12"
        >
          {dataNotification?.map((item, index) => {
            return (
              <div
                onClick={() =>
                  history.push(`/notice-detail/${item.data}/${item.type}`)
                }
              >
                <div
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderBottom: "1px solid #C7C7C7C7",
                    marginBottom: notificationCount === index + 1 ? 40 : 0,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      paddingBottom: 10,
                      paddingTop: 10,
                    }}
                  >
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <img
                        alt=""
                        style={{ width: 50, height: 50, borderRadius: "50%" }}
                        src={item?.image ? item?.image : "/assets/covid19.jpeg"}
                      />
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                      <div
                        className="classOverflow"
                        style={{
                          width: window.innerWidth - parseInt("95%"),
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      >
                        {item?.title}
                      </div>
                      {/* <div
                        className='classP'
                        style={{
                          fontSize: 12,
                          width: window.innerWidth - parseInt('95%'),
                        }}
                        dangerouslySetInnerHTML={{ __html: item?.content?.substring(0,90) +"..." }}
                      /> */}
                      <div
                        style={{
                          fontSize: 12,
                          width: window.innerWidth - parseInt("95%"),
                        }}
                      >
                        {item?.content
                          ?.substring(0, 100)
                          .replaceAll("<p>", "")
                          .replaceAll("</p>", "") + "..."}
                      </div>
                      <div style={{ fontSize: 12 }}>
                        ປະເພດ: <b>{item?.type ? "ແຈ້ງການ" : "-"}</b>
                      </div>
                      <div style={{ fontSize: 12 }}>
                        {" "}
                        {formatDateWithoutTime(item?.updatedAt)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              // <div
              //     className="list-item"
              //     key={index}
              //     onClick={() => history.push(`/notice-detail/${item.data}/${item.type}`)}
              //   >
              //     <table border="0" style={{ width: "100%" }}>
              //       <thead>
              //         <tr>
              //           <th style={{ width: 80, border: "none" }}>
              //             <img
              //               src={
              //                 item?.image
              //                   ? item?.image
              //                   : "assets/wallpaper-image.png"
              //               }
              //               className="box-image"
              //             />
              //           </th>
              //           {/* <th style={{ border: "none", width: "20%" }}>
              //             <p>ຫົວຂໍ້: </p>
              //             <p>ສ້າງ: </p>
              //           </th> */}
              //           <td
              //             style={{
              //               border: "none",
              //               width: "80%",
              //               paddingRight: 10,
              //               paddingBottom: 5,
              //             }}
              //           >
              //             <div
              //               style={{
              //                 display: "flex",
              //                 justifyContent: "space-between",
              //               }}
              //             >
              //               <a style={{ fontSize: 14, marginLeft: 5 }}>
              //                 {item?.title}
              //               </a>
              //               <a style={{ fontSize: 14 }}>
              //                 {formatDateTime(item?.createdAt) || "-"}
              //               </a>
              //             </div>
              //             <div>
              //               <a
              //                 className=""
              //                 style={{
              //                   width: "100%",
              //                   fontSize: 16,
              //                   fontWeight: "bold",
              //                   overflowX: "hidden",
              //                   overflowY: "hidden",
              //                   whiteSpace: "nowrap",
              //                   textOverflow: "ellipsis",
              //                 }}
              //               >
              //                 {item?.title}
              //               </a>
              //             </div>
              //             <a style={{ fontSize: 14, marginLeft: 5 }}>
              //               ສ້າງໂດຍ:{item?.createdBy?.firstname}{" "}
              //               {item?.createdBy?.lastname
              //                 ? item?.createdBy?.lastname
              //                 : ""}
              //             </a>
              //           </td>
              //         </tr>
              //       </thead>
              //     </table>
              //   </div>
            );
          })}
        </div>
      </Row>
      <NavbarFooterApp active={active} />
      <DevelopingModal
        show={showDeveloping}
        handleClose={() => setShowDeveloping(false)}
      />
    </div>
  );
};
export default ListNotification;
