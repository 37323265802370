import React, {useState, useEffect} from "react";
import { Breadcrumb, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import useReactRouter from "use-react-router";
import Consts from "../../consts";
import Container from 'react-bootstrap/Container';
import "./index.css"

import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GET_COURSE } from "../../apollo/course";
import { formatDateTime } from "../../super";
import { FILES } from "../../apollo/file";
import {ArrowDownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {
  BrowserView,
  MobileView
} from "react-device-detect";

const RegisteredCourseDocList = () => {
  const { history, match } = useReactRouter();

  const regisid = match.params.regisid;
  const ID = match.params.id;

  const [dataFile, setDataFile] = useState([])

  const { data } = useQuery(GET_COURSE, {
    variables: { where: { id: ID } },
  });
  const [loadFile, {data: courseFileData}] = useLazyQuery(FILES)

  useEffect(() => {
    if(ID) {
      loadFile({ 
        variables: { 
          where: { course: ID, isDeleted: false }
        } })
    }
  }, [ID])
  
  useEffect(() => {
    if(courseFileData) setDataFile(courseFileData?.files?.data)
  }, [courseFileData])

  const _courseDetail = (id) => {
    history.push(`/registered-course-detail/${id}/${regisid}`);
  };

  // const openFileInBrowser = (fileUrl) => {
  //   window.location.href = fileUrl;
  // };

  return (
    <div className="page-conten">
      {/* Breadcrumb */}
      <Breadcrumb style={{fontSize:"14px"}}>
        <span onClick={() => history.goBack()} className="mr-2">
          <ArrowLeftOutlined style={{fontSize:"20px", color:"#007bff", fontWeight:"bold"}} />
        </span>
        <Breadcrumb.Item
          onClick={() => history.push("/registered-course-list")}
        >
          ວິຊາທັງຫມົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item href={`/registered-course-detail/${ID}/${regisid}`}>
          ຂໍ້ມູນວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ເອກະສານ</Breadcrumb.Item>
      </Breadcrumb>

      {/* Container */}
      <BrowserView>
        <CustomContainer>
          {/* --------- Title and Button groups ----------- */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title text="ເອກະສານວິຊາ" />

            {/* Button group */}
            <div>
              {/* ລາຍລະອຽດວິຊາ */}
              <OverlayTrigger
                overlay={<Tooltip id="tooltip">ເບິ່ງລາຍລະອຽດ</Tooltip>}
              >
                <button
                  style={{
                    backgroundColor: "#fff",
                    color: Consts.PRIMARY_COLOR,
                    width: 160,
                    height: 35,
                    border: `1px solid ${Consts.PRIMARY_COLOR}`,
                    outline: "none",
                    borderRadius: 0,
                    marginRight: 20,
                  }}
                  onClick={() => _courseDetail(ID)}
                >
                  ລາຍລະອຽດວິຊາ
                </button>
              </OverlayTrigger>
            </div>
          </div>
          {/* -------- ຂໍ້ມູນວິຊາ ----------- */}
          <div style={{ marginTop: 10 }}>
            {/* ------ detail box ------ */}
            <div
              style={{
                border: "1px solid #ddd",
                width: "60%",
                padding: 20,
                fontSize: 14,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 20,
                paddingLeft: 80,
              }}
            >
              <Row>
                <Col>ຊື່ວິຊາ</Col>
                <Col
                  style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
                >
                  {data?.course?.title}
                </Col>
              </Row>
              <div style={{ height: 10 }} />
              <Row>
                <Col>ລະຫັດວິຊາ</Col>
                <Col
                  style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
                >
                  {data?.course?.courseCode}
                </Col>
              </Row>
              <div style={{ height: 10 }} />
              <Row>
                <Col>ຈໍານວນຫນ່ວຍກິດ</Col>
                <Col
                  style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
                >
                  {data?.course?.unit}
                </Col>
              </Row>
            </div>
          </div>

          <div style={{ height: 80 }} />

          {/* ---------- table --------- */}
          <div>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th>ລຳດັບ</th>
                    <th style={{ width: 250 }}>ຫົວຂໍ້</th>
                    <th style={{ width: 300 }}>ໄຟລ</th>
                    <th>ອັບເດດ</th>
                    <th>ຈັດການ</th>
                  </TableHeader>
                </thead>
                <tbody>
                  {dataFile?.map((data, index) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {/* {data?.title} */}
                        </TableCell>
                        <TableCell>
                          {decodeURI(data?.file?.split("/")[4])}
                        </TableCell>
                        <TableCell>
                          {data?.createdAt ? formatDateTime(data?.createdAt) : "-"}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <a href={data?.file}>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip">ດາວໂຫລດເອກະສານ</Tooltip>
                                }
                              >
                                <button
                                  // onClick={() => }
                                  style={{
                                    width: 80,
                                    height: 30,
                                    borderRadius: 3,
                                    border: "1px solid #ddd",
                                    outline: "none",
                                  }}
                                >
                                  <i
                                    href={data?.file}
                                    className="fa fa-download"
                                    style={{ color: Consts.PRIMARY_COLOR }}
                                  />
                                </button>
                              </OverlayTrigger>
                            </a>
                          </div>
                        </TableCell>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
           
          </div>
        </CustomContainer>
      </BrowserView>

      <MobileView>
        <div className="">
          {/* --------- Title and Button groups ----------- */}
          <div>
            <Container>
              <Row>
                <Col xs={6} className="doc-padding" style={{textAlign: "right", marginTop:"6px"}} >
                  ເອກະສານວິຊາ
                </Col>
                <Col xs={6} className="doc-padding" >
                  <OverlayTrigger overlay={<Tooltip id="tooltip">ເບິ່ງລາຍລະອຽດ</Tooltip>}>
                  <button
                    style={{
                      backgroundColor: "#fff",
                      color: Consts.PRIMARY_COLOR,
                      width: 160,
                      height: 35,
                      border: `1px solid ${Consts.PRIMARY_COLOR}`,
                      outline: "none",
                      borderRadius: 0,
                      marginRight: 20,
                    }}
                    onClick={() => _courseDetail(ID)}
                  >
                    ລາຍລະອຽດວິຊາ
                  </button>
                </OverlayTrigger>
                </Col>
              </Row>
            </Container>

          </div>
          {/* -------- ຂໍ້ມູນວິຊາ ----------- */}
          <div style={{ marginTop: 10 }}>
            {/* ------ detail box ------ */}
            <div className="subject-info-mobile">
              <Row>
                <Col style={{textAlign: "right"}}><b>ຊື່ວິຊາ:</b></Col>
                <Col style={{textAlign: "left" }}>{data?.course?.title}</Col>
              </Row>
              <Row>
                <Col  style={{textAlign: "right"}}><b>ລະຫັດວິຊາ:</b></Col>
                <Col  style={{textAlign: "left"}}>{data?.course?.courseCode}</Col>
              </Row>
              <div style={{ height: 10 }} />
              <Row>
                <Col  style={{textAlign: "right"}}><b>ຈໍານວນຫນ່ວຍກິດ:</b></Col>
                <Col  style={{textAlign: "left"}}>{data?.course?.unit}</Col>
              </Row>
            </div>
          </div>

          {/* ---------- table --------- */}
          <div className="course-doc-mobile">
            <Container>
              <Row>
                <Col xs={2} style={{background: "#057CAE"}} className="col-padding" >ລຳດັບ</Col>
                <Col xs={7} style={{background: "#057CAE"}} className="col-padding" >ຊື່ໄຟ</Col>
                <Col xs={3} style={{background: "#057CAE"}} className="col-padding" >ອ໋ອບເຊີນ</Col>
              </Row>
            </Container>
              {dataFile?.map((data, index) => {
                    return (
                      <div>
                        <div className="item-date">
                          {data?.createdAt ? formatDateTime(data?.createdAt) : "-"}
                        </div>
                        <div  className="devide-line"></div>
                        <Container key={index}>
                            <div>
                              <Row>
                                <Col xs={2} className="col-padding" >{index + 1}</Col>
                                <Col xs={7} className="col-padding" >{data?.title}</Col>
                                <Col xs={3} className="col-padding" >
                                <div className="download-btn">
                                  <a href={data?.file} >
                                        <OverlayTrigger overlay={<Tooltip id="tooltip">ດາວໂຫລດເອກະສານ</Tooltip>}>
                                          <ArrowDownOutlined /> 
                                        </OverlayTrigger>
                                        ບັນທືກ
                                  </a>
                                  {/* <a href="#" onClick={(e)=>{
                                    e.preventDefault();
                                    openFileInBrowser(data?.file)
                                  }}>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip">ດາວໂຫລດເອກະສານ</Tooltip>}>
                                          <ArrowDownOutlined /> 
                                        </OverlayTrigger>
                                        abc
                                  </a> */}
                                </div>
                                </Col>
                              </Row>
                            </div>
                          </Container>                       
                      </div>
                    );
                  })}
          </div>
        </div>
      </MobileView>
      
    </div>
  );
};

export default RegisteredCourseDocList;
