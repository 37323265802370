import { gql } from "apollo-boost";

export const LOGIN_USER = gql`
  mutation LoginUser($data: loginUserInput!) {
    loginUser(data: $data) {
      studentData {
        id
        userId
        firstNameL
        lastNameL
        phoneMobile
        email
        role
        gender
        firstNameE
        lastNameE
        yearLevel
      }
      accessToken
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      firstname
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
      firstname
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      id
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation updateStudent(
    $data: StudentWhereDataInputWithPassword!
    $where: StudentWhereUniqueInput!
  ) {
    updateStudent(data: $data, where: $where) {
      id
    }
  }
`;

export const STUDENT = gql`
  query Student($where: StudentWhereUniqueInput!) {
    student(where: $where) {
      id
      gender
      userId
      firstNameL
      lastNameL
      firstNameE
      lastNameE
      studentNumber
      yearLevel
      assessmentYear
      image {
        url
      }
      phoneMobile
      email
      faculty{
        id
        name
      }
      department {
        id
        name
      }
      major {
        id
        name
      }
      classRoom{
        id
        name
      }
    }
  }
`;

export const USERS = gql`
  query User(
    $where: UserWhereInput
    $orderBy: UserOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    users(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      userId
      firstname
      lastname
      userId
      password
      email
      phones
      role
      description
      note
      createdAt
      updatedAt
    }
  }
`;

export const STUDENTS = gql`
  query Students(
    $where: UserWhereInput
    $orderBy: UserOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    users(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      userId
      firstname
      lastname
      userId
      password
      email
      phones
      role
      description
      note
      createdAt
      updatedAt
    }
  }
`;
export const STUDENTS_LOGIN = gql`
  query Students(
    $where: CustomStudentWhereInput
    $orderBy: StudentOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    students(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        firstNameL
        lastNameL
        phoneMobile
        role
        yearLevel
        email
        image {
          url
        }
        faculty {
          id
          name
        }
        department {
          id
          name
        }
        yearLevel
        assessmentYear
        email
        role
        phoneMobile
        userId
        note
      }
    }
  }
`;
