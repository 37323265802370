import React from "react";
import { Modal, Button } from "react-bootstrap";
import Consts from "../../consts";

const CourseDocDeleteConfirm = ({
  courseDocDeleteConfirmModal,
  _handlCourseDocDeleteConfirmModalClose,
}) => {
  return (
    <div>
      <Modal
        show={courseDocDeleteConfirmModal}
        onHide={_handlCourseDocDeleteConfirmModalClose}
        size="lg"
      >
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          ຕ້ອງການລຶບເອກະສານ?
        </Modal.Title>
        <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
          <p className="text-center">ບົດທີ1: ວິຊາຖານຂໍ້ມູນ</p>

          <div style={{ height: 20 }} />
          <div className="row" style={{ textAlign: "center" }}>
            <div style={{ padding: 15 }} className="col">
              <Button
                onClick={_handlCourseDocDeleteConfirmModalClose}
                style={{
                  width: "60%",
                  backgroundColor: "#fff",
                  color: "#6f6f6f",
                  borderColor: "#6f6f6f",
                  borderRadius: 0,
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className="col">
              <Button
                style={{
                  width: "60%",
                  backgroundColor: Consts.SECONDARY_COLOR,
                  color: "#fff",
                  borderColor: Consts.SECONDARY_COLOR,
                  borderRadius: 0,
                }}
              >
                ບັນທຶກການແກ້ໃຂ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CourseDocDeleteConfirm;
