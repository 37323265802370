import React from "react";
import { Typography, Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Folder = ({ folderName, date, id }) => {
  const history = useHistory();
  const handleClick = () => {
    const currentPath = history.location.pathname;
    const newPath = `${currentPath}/${id}`;
    history.push(newPath);
  };
  return (
    <div
      onClick={handleClick}
      className="d-flex flex-row align-items-center col-12 folder"
      style={{ position: "relative" }}
    >
      <div className="mx-3">
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/color/48/folder-invoices--v1.png"
          alt="folder-invoices--v1"
        />
      </div>

      <div>
        <Typography>{folderName}</Typography>
        <Typography
          style={{
            fontSize: "14px",
          }}
        >
          {"ສ້າງວັນທີ : " + new Date(date).toLocaleDateString()}
        </Typography>
      </div>
    </div>
  );
};

export default Folder;
