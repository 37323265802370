import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import useReactRouter from "use-react-router";
import { MobileHeader } from "../../common";
import RegisterToUpgradeBrowserView from "./RegisterToUpgradeBrowserView";
import RegisterToUpgradeMobileView from "./RegisterToUpgradeMobileView";
import { useQuery } from "@apollo/react-hooks";
import { USER_KEY } from "../../consts/user";
import { COURSES } from "../../apollo/course";
import { STUDENT } from "../../apollo/user";
import Loading from "../../common/Loading";
function GradeDetail() {
  const { history } = useReactRouter();
  const [user, setUser] = React.useState();
  /**
   * apollo
   */

  const { data, loading } = useQuery(STUDENT, {
    variables: { where: { id: user && user.id } },
  });

  const { data: courseData } = useQuery(COURSES, {
    variables: {
      where: {
        department: 
            data &&
            data.student &&
            data.student.department &&
            data.student.department.id,
      },
    },
  });
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user.studentData.id) {
      // console.log("userDAta:::::::::", user);
      setUser(user.studentData);
    }
  }, []);
  return (
    <div>
      {user ? (
        <>
          <BrowserView>
            {loading && !data && !data?.student && !courseData ? (
              <Loading />
            ) : (
              <RegisterToUpgradeBrowserView
                student={data?.student}
                courses={courseData?.courses}
              />
            )}
          </BrowserView>
          <MobileView>
            <MobileHeader
              title="ການອັບເກຣດຄະແນນ"
              showLeftArrow
              leftButtonClick={() => history.push("/upgrade-list")}
            />
            {loading && !data && !data?.student ? (
              <Loading />
            ) : (
              <RegisterToUpgradeMobileView
                student={data?.student}
                courses={courseData?.courses}
              />
            )}
          </MobileView>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
export default GradeDetail;
