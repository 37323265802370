export const ACTIVITIES = [
    {
        id: '1',
        type: 'ງານຕະຫຼາດນັດ',
        title: `ພົບກັບເວທີສຳມະນາໃນຫົວຂໍ້
        “ ເຕັກນິກການເວົ້າໃຫ້ເຈົ້າສຳພາດວຽກຜ່ານ “
        ຈາກວິທະຍາກອນຊື່ດັງ ອຈ ທອງດີ ພົມມະວົງສາ.`,
        detail: `&nbsp; ທີ່ງານງານຕະຫຼາດນັດສະໜັກງານແລະບຸນມະໂຫລານສີລະປະວັດທະນະທຳ
        ບັນດາເຜົ່າ ທີ່ມະຫາວິທະຍາໄລ່ແຫ່ງຊາດ ສະໂມສອນໃຫ່ຍ ມຊ 
        ເຊີ່ງຈະໄດ້ຈັດຂື້ນໃນລະຫວ່າງວັນທີ 22 ຫາ 23 ມິຖຸນາ 2022 
        ພາຍໃນງານທ່ານຈະພົບກັບບໍລິສັດຫ້າງຮ້ານຕ່າງໆທີ່ເປີດບູດຮັບສະແດງ
        ສະໜັກງານ ຫຼາຍກວ່າ 30 ບໍລິສັດ
        ໂອກາດດີໆແບບນີ້ບໍ່ຄວນພາດ`,
        img: 'https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/WhatsApp+Image+2022-08-30+at+14.13.51.jpeg',
    },
    {
        id: '2',
        type: 'ງານຕະຫຼາດນັດ',
        title: `ມາແລ້ວ ມາແລ້ວ \n\n
        ໂອກາດດີໆສຳລັບນັກສຶກສາທີ່ກຳລັງຈະຈົບ ຫຼື ຜູ້ທິ່ມີຄວາມສົນໃຈຊອກຫາວຽກເຮັດງານທຳ`,
        detail: `&nbsp; ຂໍເຊີນຊວນເຂົ້າຮ່ວມງານຕະຫຼາດນັດ
        ສະໜັກງານ ແລະ ບຸນມະໂຫລານສີລະປະວັດທະນະທຳບັນດາເຜົ່າ 
        ເຊີ່ງຈະໄດ້ຈັດຂື້ນໃນລະຫວ່າງວັນທີ 22 ຫາ 23 ມິຖຸນາ 2022 
        ທີ່ມະຫາວິທະຍາໄລ່ແຫ່ງຊາດ ສະໂມສອນໃຫ່ຍ ມຊ 
        ເຊີ່ງພາຍໃນງານທ່ານຈະພົບກັບບໍລິສັດຫ້າງຮ້ານຕ່າງໆທີ່ເປີດບູດຮັບສະແດງ
        ສະໜັກງານ ຫຼາຍກວ່າ 30 ບໍລິສັດ`,
        img: 'https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/WhatsApp+Image+2022-08-30+at+14.12.09.jpeg',
    },
]