import React, { useEffect, useState } from 'react'
import { MobilePDFReader } from 'react-read-pdf'
import { MobileHeader } from '../../common'
import useReactRouter from 'use-react-router'
import ControlPanel from "../notice/ControlPanel"
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


export default function MobileNoticeReaderSetting () {
  const { history, location, match } = useReactRouter()
  const [numPages, setNumPages] = useState(null)
  const [scale, setScale] = useState(1.0);
  const [pageNumber, setPageNumber] = useState(1)
  const [urlPDF, seturlPDF] = useState('https://nuol.s3-ap-southeast-1.amazonaws.com/dev/%E0%BA%84%E0%BA%B9%E0%BB%88%E0%BA%A1%E0%BA%B7%E0%BA%81%E0%BA%B2%E0%BA%99%E0%BA%99%E0%BA%B3%E0%BB%83%E0%BA%8A%E0%BB%89%E0%BB%81%E0%BA%AD%E0%BA%9A(V4.0).pdf')

  function onDocumentLoadSuccess ({ numPages }) {
    setNumPages(numPages)
  }
  return (
    <div>
      <MobileHeader 
        showLeftArrow
        title="ຄູ່ມືນຳໃຊ້"
        leftButtonClick={() => history.goBack()}
      />  
      <section
        style={{overflow:'scroll', marginTop: 70}}
        id="pdf-section"
        className="d-flex flex-column align-items-center w-100"
      >
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={urlPDF}
        />
        <div >
        <Document
          file={urlPDF}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
        </div>
      </section>
    </div>
  )
}
