export const SLIDEIMAGE= [
    {
        id: "1",
        // image: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/banner+book+garden.jpg"
        image: "https://drive.google.com/uc?id=1jYWHz9Zd827RzCmfm91gJeHzwAyA1QmI",
    },
    {
        id: "2",
        // image: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/banner+AEC.jpg"
        image: "https://drive.google.com/uc?id=1zMxaE3D9zaWy_aa02tPcrN-jx6Qg2KOJ",
        
    },
    // {
    //     id: "3",
    //     image: "https://nuol.s3.ap-southeast-1.amazonaws.com/shop-image/banner+taifar.jpg"
    // },
];