import React from "react";
import { Modal, Image } from "react-bootstrap";
const DevelopingModal = (props) => {
  const { handleClose } = props;
  return (
    <Modal centered show={props.show} onHide={handleClose}>
      <Modal.Body className="text-center">
        <p style={{ color: "#057CAE", fontWeight: "bold" }}>ກຳລັງພັດທະນາ...</p>
        <div>
          <Image src="/assets/developing.gif" width="50%" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DevelopingModal;
