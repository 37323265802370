/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import Consts from "../../consts";
import { MobileHeader } from "../../common";
import { CONTAINER_PADDING } from "../../consts";
import { formatDateTime, noticeStatus } from "../../super";
import { useLazyQuery } from "@apollo/react-hooks";
import { NOTICS } from "../../apollo/notic";
import Loading from "../../common/Loading";

function NoticeListMobile() {
  const { history } = useReactRouter();

  const [typeNotice, setTypeNotice] = useState("NOTIC");
  const [isGrid, setIsGrid] = useState(true);
  const [loadNoticeData, { loading: noticeLoading, data: noticeData }] =
    useLazyQuery(NOTICS);

  useEffect(() => {
    let _where = {};
    if (typeNotice) {
      _where = { type: typeNotice, isPublish: true };
    } else {
      _where = { isPublish: true };
    }
    loadNoticeData({
      variables: {
        where: _where,
        orderBy: "createdAt_DESC",
      },
    });
  }, [typeNotice]);

  const _displayStyle = () => {
    setIsGrid(!isGrid);
  };

  const [isClicked, setIsClicked] = useState(1);

  function showBorder(status) {
    setIsClicked(status);
    if (status === 1) {
      setTypeNotice("NOTIC");
    } else if (status === 2) {
      setTypeNotice("AGREEMENT");
    } else if (status === 3) {
      setTypeNotice("REGULATION");
    } else if (status === 4) {
      setTypeNotice("NEWS");
    }
  }
  if (noticeLoading) return <Loading />;
  return (
    <div>
      {/* Breadcrumb */}
      <MobileHeader
        title="ແຈ້ງການ ແລະ ລະບຽບການ"
        showLeftArrow
        leftButtonClick={() => history.push("/dashboard")}
      />
      <div style={{ marginTop: 80, backgroundColor: "white", height: "100hv" }}>
        <div
          style={{
            width: "100%",
            overflowX: "auto",
            whiteSpace: "nowrap",
            backgroundColor: "white",
          }}
        >
          <table>
            <thead>
              <tr style={{ paddingTop: "10%", height: 50 }}>
                <th>
                  <span
                    onClick={() => showBorder(1)}
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontSize: 16,
                      width: 80,
                      marginRight: 30,
                      textAlign: "center",
                      cursor: "pointer",
                      borderBottom:
                        isClicked === 1
                          ? `4px solid ${Consts.COLOR_EDIT}`
                          : "none",
                    }}
                  >
                    ແຈ້ງການ
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => showBorder(2)}
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontSize: 16,
                      width: 80,
                      marginRight: 30,
                      textAlign: "center",
                      cursor: "pointer",
                      borderBottom:
                        isClicked === 2
                          ? `4px solid ${Consts.COLOR_EDIT}`
                          : "none",
                    }}
                  >
                    ຂໍ້ຕົກລົງ
                  </span>
                </th>
                <th>
                  <span
                    onClick={() => showBorder(3)}
                    style={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontSize: 16,
                      width: 80,
                      marginRight: 30,
                      textAlign: "center",
                      cursor: "pointer",
                      borderBottom:
                        isClicked === 3
                          ? `4px solid ${Consts.COLOR_EDIT}`
                          : "none",
                    }}
                  >
                    ລະບຽບ
                  </span>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div
        style={{
          paddingLeft: CONTAINER_PADDING,
          paddingRight: CONTAINER_PADDING,
          backgroundColor: "white",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold", fontSize: 16 }}>
            ທັງຫມົດ {noticeData?.notics?.data?.length}
          </span>
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            onClick={_displayStyle}
            icon={["fas", isGrid === true ? "list" : "th-large"]}
          />
        </div>

        <div
          style={{
            display: isGrid === true ? "block" : "none",
            paddingBottom: 20,
          }}
        >
          {noticeData?.notics?.data?.length > 0 &&
            noticeData?.notics?.data?.map((x, index) => {
              return (
                <div
                  onClick={() =>
                    history.push(`/notice-detail/${x.id}/${x.type}`)
                  }
                  key={index}
                  style={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    border: "1px solid #ddd",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      height: 171,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      style={{ width: "100%" }}
                      src={
                        x?.coverImage?.url
                          ? x?.coverImage?.url
                          : "assets/wallpaper-image.png"
                      }
                    />
                  </div>

                  <div style={{ padding: 10 }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a style={{ fontSize: 14 }}>
                        ສ້າງໂດຍ:{x?.createdBy?.firstname}{" "}
                        {x?.createdBy?.lastname ? x?.createdBy?.lastname : ""}
                      </a>
                      <a style={{ fontSize: 14 }}>{noticeStatus(x.type)}</a>
                    </div>
                    <a
                      className=""
                      style={{
                        fontSize: "16px",
                        marginLeft: -1,
                        marginBottom: 10,
                        color: Consts.FONT_COLOR_PRIMARY,
                        fontWeight: "bolder",
                      }}
                    >
                      {x?.title}
                    </a>{" "}
                    <br />
                    <a style={{ fontSize: 14 }}>
                      {formatDateTime(x.createdAt)}
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{
            display: isGrid === true ? "none" : "block",
            paddingBottom: 20,
          }}
        >
          {noticeData?.notics?.data?.length > 0 &&
            noticeData?.notics?.data?.map((item, index) => {
              return (
                <div
                  className="list-item"
                  key={index}
                  onClick={() =>
                    history.push(`/notice-detail/${item.id}/${item.type}`)
                  }
                >
                  <table border="0" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: 80, border: "none" }}>
                          <img
                            alt=""
                            src={
                              item?.coverImage?.url
                                ? item?.coverImage?.url
                                : "assets/wallpaper-image.png"
                            }
                            className="box-image"
                          />
                        </th>
                        {/* <th style={{ border: "none", width: "20%" }}>
                          <p>ຫົວຂໍ້: </p>
                          <p>ສ້າງ: </p>
                        </th> */}
                        <td
                          style={{
                            border: "none",
                            width: "80%",
                            paddingRight: 10,
                            paddingBottom: 5,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <a style={{ fontSize: 14, marginLeft: 5 }}>
                              {noticeStatus(item?.type)}
                            </a>
                            <a style={{ fontSize: 14 }}>
                              {formatDateTime(item?.createdAt) || "-"}
                            </a>
                          </div>
                          <div>
                            <a
                              className=""
                              style={{
                                width: "100%",
                                fontSize: 16,
                                fontWeight: "bold",
                                overflowX: "hidden",
                                overflowY: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.title}
                            </a>
                          </div>
                          <a style={{ fontSize: 14, marginLeft: 5 }}>
                            ສ້າງໂດຍ:{item?.createdBy?.firstname}{" "}
                            {item?.createdBy?.lastname
                              ? item?.createdBy?.lastname
                              : ""}
                          </a>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default NoticeListMobile;
