import { gql } from 'apollo-boost'
export const NOTIFICATIONS = gql`
  query Notifications(
    $where: NotificationWhereInput
    $orderBy: NotificationOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    notifications(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      title
      content
      detail
      image
      type
      data
      group
      sender {
        id
        firstname
        lastname
      }
      createdAt
      updatedAt
      note
    }
  }
`
